/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const company = window.location.pathname.split('/')[1]

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path={company ? `${company}/*` : 'kidling/*'} element={<PrivateRoutes />} />
              <Route
                index
                element={
                  <Navigate
                    to={
                      company ? `/${company}/kid-management/kids` : '/kidling/kid-management/kids'
                    }
                    state={{company: company ?? 'kidling'}}
                  />
                }
              />
            </>
          ) : (
            <>
              <Route
                path={company ? `${company}/auth/*` : 'kidling/auth/*'}
                element={<AuthPage />}
              />
              <Route
                path='*'
                element={
                  <Navigate
                    to={company ? `${company}/auth` : 'kidling/auth'}
                    state={{company: company ?? 'kidling'}}
                  />
                }
              />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
