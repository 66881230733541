import {useIntl} from 'react-intl'
import {useMediaQuery} from 'react-responsive'
import {Link} from 'react-router-dom'
import {DrawerMessenger} from '../../../../../../../_metronic/partials'
import {useListView} from '../../core/ListViewProvider'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'

const UsersListGrouping = () => {
  const intl = useIntl()
  const {selected, setItemIdForUpdate, setCurrentKidModal} = useListView()
  const {kidsList, mobileConfiguration}: any = useAppContext()
  const company = window.location.pathname.split('/')[1]

  const isMobile = useMediaQuery({
    query: '(max-width: 1199px)',
  })

  return (
    <div className='d-flex   align-items-center flex-wrap'>
      <div className='form-group '>
        <div className='form-label form-label-no-wrap'>
          <label className='font-bold font-danger'>
            <span className='fs-4'>
              {intl.formatMessage({
                id: selected.length > 0 ? 'COMMON.SELECTED_RECORDS_COUNT' : 'KIDLING.TOTAL_RECORDS',
              })}
              <b className='fs-4 ms-2 badge badge-primary'>
                {selected.length > 0 ? selected.length : kidsList?.length ? kidsList?.length : 0}
              </b>
            </span>
          </label>
        </div>
        <div className='d-flex align-items-center'>
          {mobileConfiguration?.KidAttendance &&
            mobileConfiguration?.KidAttendance[0]?.showInEducatorApp && (
              <>
                <button
                  type='button'
                  className='btn btn-light-primary font-weight-bolder font-size-sm d-flex align-items-center btn-sm '
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('in')
                  }}
                  disabled={selected.length === 0}
                >
                  <i className='fa-solid fa-turn-down me-2'></i> In
                </button>
                &nbsp;
                <button
                  type='button'
                  className='btn btn-light-primary font-weight-bolder font-size-sm d-flex align-items-center btn-sm '
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('out')
                  }}
                  disabled={selected.length === 0}
                >
                  <i className='fa-solid fa-turn-up me-2'></i>
                  {intl.formatMessage({id: 'COMMON.OUT'})}
                </button>
              </>
            )}
          &nbsp;
          {mobileConfiguration?.KidAbsent && mobileConfiguration?.KidAbsent[0]?.showInEducatorApp && (
            <button
              type='button'
              className='btn btn-light-primary font-weight-bolder font-size-sm d-flex align-items-center btn-sm '
              onClick={() => {
                setItemIdForUpdate(selected[0])
                setCurrentKidModal('absense')
              }}
              disabled={selected.length === 0}
            >
              <i className='fa-solid fa-person-circle-minus me-2'></i>
              {intl.formatMessage({id: 'KIDLING.HOME.ABSENSE'})}
            </button>
          )}
          {!isMobile && (
            <>
              &nbsp;
              {/* <button
                type='button'
                className='btn btn-light-primary font-weight-bolder font-size-sm d-flex align-items-center btn-sm '
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('remark')
                }}
                disabled={selected.length === 0}
              >
                <i className='fa-solid fa-note-sticky me-2'></i> 
                {intl.formatMessage({id: 'KIDLING.HOME.REMARK'})}
              </button> */}
              &nbsp;
              {mobileConfiguration?.KidMedia &&
                mobileConfiguration?.KidMedia[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn btn-light-primary font-weight-bolder font-size-sm d-flex align-items-center btn-sm '
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('media')
                    }}
                    disabled={selected.length === 0}
                  >
                    <i className='fa-solid fa-photo-film me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})}
                  </button>
                )}
              &nbsp;
              {mobileConfiguration?.KidActivity &&
                mobileConfiguration?.KidActivity[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn btn-light-primary font-weight-bolder font-size-sm d-flex align-items-center btn-sm'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('activity')
                    }}
                    disabled={selected.length === 0}
                  >
                    <i className='fa fa-sync-alt me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.ACTIVITY'})}
                  </button>
                )}
              &nbsp;
            </>
          )}
          <div className='btn-group'>
            &nbsp;
            <button
              type='button'
              className='btn btn-primary dropdown-toggle btn-sm  '
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              disabled={selected.length === 0}
            >
              {intl.formatMessage({id: 'KIDLING.HOME.MORE'})}
            </button>
            <div className='dropdown-menu' style={{width: '15rem'}}>
              {isMobile && (
                <>
                  {/* <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm  dropdown-item'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('remark')
                    }}
                  >
                    <i className='fa-solid fa-note-sticky me-2'></i> 
                    {intl.formatMessage({id: 'KIDLING.HOME.REMARK'})}
                  </button> */}

                  {mobileConfiguration?.KidMedia &&
                    mobileConfiguration?.KidMedia[0]?.showInEducatorApp && (
                      <button
                        type='button'
                        className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm  dropdown-item py-2'
                        onClick={() => {
                          setItemIdForUpdate(selected[0])
                          setCurrentKidModal('media')
                        }}
                      >
                        <i className='fa-solid fa-photo-film me-2'></i>
                        {intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})}
                      </button>
                    )}
                  {mobileConfiguration?.KidActivity &&
                    mobileConfiguration?.KidActivity[0]?.showInEducatorApp && (
                      <button
                        type='button'
                        className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm  dropdown-item py-3'
                        onClick={() => {
                          setItemIdForUpdate(selected[0])
                          setCurrentKidModal('activity')
                        }}
                      >
                        <i className='fa fa-sync-alt me-2'></i>
                        {intl.formatMessage({id: 'KIDLING.HOME.ACTIVITY'})}
                      </button>
                    )}
                </>
              )}

              {mobileConfiguration?.KidMealTime &&
                mobileConfiguration?.KidMealTime[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('meal')
                    }}
                  >
                    <i className='fa-solid fa-utensils me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.MEAL'})}
                  </button>
                )}

              {mobileConfiguration?.KidSleepTime &&
                mobileConfiguration?.KidSleepTime[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3 '
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('sleepsStart')
                    }}
                  >
                    <i className='fa-solid fa-bed me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.ADD_SLEEPS_START'})}
                  </button>
                )}
              {mobileConfiguration?.KidSleepTime &&
                mobileConfiguration?.KidSleepTime[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3 '
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('sleepsEnd')
                    }}
                  >
                    <i className='fa-solid fa-child me-4'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.ADD_SLEEPS_END'})}
                  </button>
                )}
              {mobileConfiguration?.KidDrink &&
                mobileConfiguration?.KidDrink[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('drinks')
                    }}
                  >
                    <i className='fa-solid fa-mug-hot me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.ADD_DRINKS'})}
                  </button>
                )}

              {mobileConfiguration?.KidMedicalRecord &&
                mobileConfiguration?.KidMedicalRecord[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('medical')
                    }}
                  >
                    <i className='fa-solid fa-notes-medical me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.MEDICAL'})}
                  </button>
                )}

              {mobileConfiguration?.KidProtocolRemark &&
                mobileConfiguration?.KidProtocolRemark[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('protocol')
                    }}
                  >
                    <i className='fa-solid fa-handshake-simple me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.PROTOCOL'})}
                  </button>
                )}
              {mobileConfiguration?.KidMood && mobileConfiguration?.KidMood[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('moods')
                  }}
                >
                  <i className='fa-solid fa-face-smile me-2'></i>

                  {intl.formatMessage({id: 'KIDLING.HOME.MOODS'})}
                </button>
              )}

              {mobileConfiguration?.KidAssessment &&
                mobileConfiguration?.KidAssessment[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('assessment')
                    }}
                  >
                    <i className='fa-solid fa-table-list me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})}
                  </button>
                )}

              {mobileConfiguration?.RequestKid &&
                mobileConfiguration?.RequestKid[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('request')
                    }}
                  >
                    <i className='fa-solid fa-people-arrows me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.REQUEST'})}
                  </button>
                )}

              {mobileConfiguration?.KidWindingProtocol &&
                mobileConfiguration?.KidWindingProtocol[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('diapers')
                    }}
                  >
                    <i className='fa-solid fa-baby me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})}
                  </button>
                )}
              {mobileConfiguration?.Events && mobileConfiguration?.Events[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('event')
                  }}
                >
                  <i className='fa-solid fa-calendar-check me-2'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.EVENT'})}
                </button>
              )}
              {mobileConfiguration?.MealSubscription &&
                mobileConfiguration?.MealSubscription[0]?.showInEducatorApp && (
                  <button
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                    onClick={() => {
                      setItemIdForUpdate(selected[0])
                      setCurrentKidModal('deregisteration')
                    }}
                  >
                    <i className='fa-solid fa-square-minus  me-2'></i>

                    {intl.formatMessage({id: 'KIDLING.HOME.DEREGISTERATION'})}
                  </button>
                )}
              {/* <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3'
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('assignMeal')
                }}
              >
                <i className='fa-solid fa-child-reaching me-2'></i> 
                {intl.formatMessage({id: 'KIDLING.HOME.ASSIGN_MEAL'})}
              </button> */}

              {/* <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3 '
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('attach')
                }}
              >
                <i className='fa-solid fa-paperclip me-2'></i> 
                {intl.formatMessage({id: 'KIDLING.HOME.ATTACH'})}
              </button> */}

              {mobileConfiguration?.Messaging &&
                mobileConfiguration?.Messaging[0]?.showInEducatorApp && (
                  <Link
                    type='button'
                    className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-3 '
                    to={company ? `/${company}/messages` : '/kidling/messages'}
                  >
                    <i className='fa-solid fa-people-group me-2'></i>
                    {intl.formatMessage({id: 'KIDLING.HOME.MESSAGE'})}
                  </Link>
                )}
            </div>
          </div>
        </div>
      </div>
      <DrawerMessenger />
    </div>
  )
}

export {UsersListGrouping}
