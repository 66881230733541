/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import moment from 'moment-timezone'
import {useDropzone} from 'react-dropzone'
import {useIntl} from 'react-intl'
import SweetAlert from 'react-bootstrap-sweetalert'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
export const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

export const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

export const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

type Props = {
  isUserLoading: boolean
}

const KidAttachModalForm: FC<Props> = ({isUserLoading}) => {
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [files, setFiles] = useState<any>([])
  const [showAlert, setShowAlert] = useState<any>(false)
  const [isAllowed, setIsAllowed] = useState(false)
  const intl = useIntl()

  const kidSchema = Yup.object().shape({
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    startTime: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    endTime: Yup.date()
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
      .test(
        'isValidTime',
        intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
        (value: any, context: any) => {
          const totalMin = moment.duration(moment(value).diff(context.parent.startTime)).asMinutes()

          if (totalMin > 0) {
            return true
          }
          return false
        }
      ),
  })

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles: any) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ])
    },
  })

  const removeFile = (file: any) => {
    const temp = files.filter((item: any) => item !== file)
    setFiles(temp)
  }
  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name} className='position-relative image__preview'>
      <div style={thumbInner}>
        <img
          src={file.preview}
          className='image-input image-input-outline image-input-wrapper w-125px h-125px'
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
          alt='file'
        />
      </div>

      <span
        className='btn btn-icon position-absolute p-0 end-0 btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        title='Remove'
        onClick={() => removeFile(file)}
      >
        <i className='fa-solid fa-xmark'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])

  const [userForEdit] = useState({
    notes: '',
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        console.log('values', values)

        // if (isNotEmpty(values.id)) {
        //   // await updateUser(values)
        // } else {
        //   // await createUser(values)
        // }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className='symbol symbol-circle symbol-50px' key={index}>
                  <img
                    src={item.imagePath !== '' ? item.imagePath : '/Attach/avatars/blank.png'}
                    alt=''
                  />
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
        </div>
        <section className='container mt-7'>
          <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'>
              {' '}
              {intl.formatMessage({id: 'KIDLING.HOME.FILE_NAME'})}
            </label>
            <input
              {...formik.getFieldProps('notes')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.notes && formik.errors.notes},
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              name='notes'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'>
              {' '}
              {intl.formatMessage({id: 'KIDLING.HOME.DESCRIPTION'})}
            </label>
            <textarea
              {...formik.getFieldProps('notes')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.notes && formik.errors.notes},
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              name='notes'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
            />

            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>

          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid my-7'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={isAllowed}
              name='notifications'
              onChange={() => setIsAllowed(!isAllowed)}
            />
            <label className='form-check-label'>
              {intl.formatMessage({id: 'KIDLING.HOME.SHARE_WITH_PARENTS'})}
            </label>
          </div>
          <div className='fv-row my-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HOME.EXPIRY_DATE'})}
            </label>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                dateTime
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>
              {intl.formatMessage({
                id: 'KIDLING.HOME.DRAG_AND_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES',
              })}
            </p>
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
        </section>
      </form>

      {/* begin::Actions */}
      <div className='text-center pt-8'>
        <button
          type='reset'
          onClick={() => cancel()}
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
          disabled={formik.isSubmitting || isUserLoading}
        >
          {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
        </button>

        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
          </span>
          {(formik.isSubmitting || isUserLoading) && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Actions */}

      <SweetAlert
        show={showAlert}
        success
        title='Success!'
        onConfirm={(e) => setShowAlert(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </>
  )
}

export {KidAttachModalForm}
