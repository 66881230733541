import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getDiapersContent = async () => {
  return axios
    .get(`${endpoints.DIAPER_CONTENT}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteWindingProtocol = async (id: string) => {
  return axios
    .delete(`${endpoints.WINDING_PROTOCOL}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllWindingProtocols = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.GET_ALL_WINDING_PROTOCOLS}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
