import * as React from 'react'
import {styled} from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'

export const StyledBadge = styled(Badge)(({theme, color}) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const SmallAvatar = styled(Avatar)(({theme}) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}))

export default function BadgeAvatars({image1, image2}: any) {
  return (
    <Badge
      overlap='circular'
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      badgeContent={<SmallAvatar alt='group1' src={image1} />}
    >
      <Avatar alt='group2' src={image2} />
    </Badge>
  )
}
