import {MealFilter} from './MealFilter'

const MealToolbar = () => {
  return (
    <div className='d-flex justify-content-end w-100 w-md-auto' data-kt-user-table-toolbar='base'>
      <MealFilter />
    </div>
  )
}

export {MealToolbar}
