import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'

const DeliveredFilter = () => {
  const intl = useIntl()
  const {delivered, setDelivered} = useAppContext()

  return (
    <>
      <ul className='nav nav-pills flex-nowrap'>
        <li className='nav-item'>
          <button
            className={`nav-link ${delivered === 0 && 'active'}`}
            aria-current='page'
            onClick={() => setDelivered(0)}
          >
            {intl.formatMessage({id: 'KIDLING.NOT_DELIVERED'})}
          </button>
        </li>
        <li className='nav-item'>
          <button
            className={`nav-link ${delivered === 1 && 'active'}`}
            onClick={() => setDelivered(1)}
          >
            {intl.formatMessage({id: 'KIDLING.DELIVERED'})}
          </button>
        </li>
      </ul>
    </>
  )
}

export {DeliveredFilter}
