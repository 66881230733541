import {FC, ReactElement, useEffect, useState} from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {Avatar, AvatarGroup} from '@mui/material'
import TaggedKidsModal from '../../modules/apps/Media/TaggedKidsModal'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../error-text/ErrorText'
import {deleteMediaFile} from '../../../actions/media'
import {toast} from 'react-toastify'
import ImageWithEnlargeMedia from '../image-with-enlarge-media'
import CustomVideo from '../custom-video/CustomVideo'
interface Props {
  image: string
  userImagePath?: string
  userName: string
  date: string
  kidName?: string
  title: string
  details: string
  KidMediaFileName?: string
  locked?: boolean
  Hashtags?: any
  KidMediaFileId?: number
  readData?: any
  KidId?: number
  hideCheckbox: boolean
  enableDelete: boolean
  kidsGroup?: any
}
const DashboardImageCard: FC<Props> = ({
  image,
  userImagePath,
  userName,
  title,
  kidName,
  details,
  date,
  KidMediaFileName,
  locked,
  Hashtags,
  KidMediaFileId,
  readData,
  KidId,
  hideCheckbox,
  enableDelete,
  kidsGroup,
}): ReactElement => {
  const intl = useIntl()
  const [showTaggedKids, setShowTaggedKids] = useState(false)
  const [showImageDelete, setShowImageDelete] = useState(false)
  const [fileExtension, setFileExtension] = useState('')

  useEffect(() => {
    if (KidMediaFileName) {
      const splitted = KidMediaFileName?.split('.')
      setFileExtension(splitted[splitted?.length - 1])
    }
  }, [KidMediaFileName])

  const handleDeleteFile = async () => {
    const resp: any = await deleteMediaFile(KidMediaFileId ?? -1)
    if (resp.status === 200) {
      if (readData) readData()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
  }
  return (
    <div
      className='d-flex flex-column justify-content-between shadow rounded p-3 h-auto'
      style={{minHeight: '100%'}}
    >
      <div className=''>
        <div className='d-flex align-items-center mt-4 mb-2'>
          {userImagePath && <Avatar src={userImagePath} alt={userName?.charAt(0)?.toUpperCase()} />}
          <div className='ms-2 w-100 d-flex justify-content-between'>
            <div>
              <h5 className='m-0 p-0'>{userName}</h5>
              <p className='fs-7 text-muted m-0 p-0'>{moment(date).format('DD.MM.yyyy')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='position-relative' style={{height: '90%'}}>
        {fileExtension === 'mp4' || fileExtension === 'mov' ? (
          // <ReactPlayer url={image} width='100%' height='100%' controls={true} />
          <CustomVideo id={image} />
        ) : (
          <ImageWithEnlargeMedia
            img={image}
            desc={KidMediaFileName}
            KidMediaFileId={KidMediaFileId}
            activateLongPress
            hideCheckbox={hideCheckbox}
          />
        )}
        {locked && (
          <div className='position-absolute ' style={{right: '3rem', top: '12px'}}>
            <i className='fa-solid fa-lock fs-3 text-info'></i>
          </div>
        )}
        <p className='mb-4 p-0 text-muted '>{details}</p>
        <div className='d-flex align-items-center justify-content-between'>
          {kidsGroup?.length > 0 && (
            <AvatarGroup
              max={4}
              renderSurplus={(surplus) => <span>+{surplus.toString()[0]}</span>}
              sx={{
                '& .MuiAvatar-root': {width: 26, height: 26, fontSize: 12},
              }}
              onClick={() => setShowTaggedKids(true)}
            >
              {kidsGroup.map((kid: any) => (
                <Avatar alt={kid.KidName} src={kid?.KidImageUrl} key={kid?.KidName} />
              ))}
            </AvatarGroup>
          )}
          {enableDelete && (
            <button
              type='button'
              className='btn   p-0 m-0'
              onClick={() => setShowImageDelete(true)}
            >
              <i className='fa-solid fa-trash fs-3 text-danger p-0'></i>
            </button>
          )}
        </div>
      </div>
      <SweetAlert
        show={showImageDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          handleDeleteFile()
          setShowImageDelete(false)
        }}
        onCancel={() => setShowImageDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      <TaggedKidsModal show={showTaggedKids} setShow={setShowTaggedKids} kids={kidsGroup} />
    </div>
  )
}

export default DashboardImageCard
