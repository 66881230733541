import {MedicalFilter} from './MedicalFilter'

const MedicalToolbar = () => {
  return (
    <div className='d-flex justify-content-end w-100 w-md-auto' data-kt-user-table-toolbar='base'>
      <MedicalFilter />
    </div>
  )
}

export {MedicalToolbar}
