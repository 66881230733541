import {FC, ReactElement} from 'react'
import {CalenderFilter} from '../filter/CalenderFilter'
import {useMediaQuery} from 'react-responsive'

interface Props {
  setModal: any
}
const CalenderHeader: FC<Props> = ({setModal}): ReactElement => {
  const isMobile = useMediaQuery({
    query: '(max-width:1000px)',
  })

  return (
    <div className='card-header border-0 pt-6 d-flex flex-column align-items-center ps-0 pb-8'>
      <CalenderFilter />
      {isMobile && (
        <button
          className='btn btn-primary align-self-end py-2 px-4'
          type='button'
          onClick={() => setModal({showModal: true, data: null, enableEdit: true})}
        >
          <i className='fa-solid fa-plus fs-2 p-0 m-0'></i>
        </button>
      )}
    </div>
  )
}

export {CalenderHeader}
