import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import moment from 'moment-timezone'
import {StatusColumnFormatter} from '../../../../../../general-components/status-formatter/StatusColumnFormatter'
import {UserCustomHeader} from '../../../../../../general-components/table-custom-header/UserCustomHeader'
import DatetimeEditableCell from '../../../../../../general-components/datetime-editable/DatetimeEditableCell'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import {useAuth} from '../../../../../auth'
import {useIntl} from 'react-intl'
import TextEditableCell from '../../../../../../general-components/text-editable/TextEditableCell'

function _columns({handleDeleteAttendance, editCell}: any) {
  const {currentUser} = useAuth()
  const intl = useIntl()
  return [
    {
      id: 'avatar',
      width: 2,
      accessor: 'avatar',
      Cell: ({cell}: any) => {
        const row = cell?.row?.original
        return (
          <AvatarWithLetters
            image={row.imagePath}
            alt={`${row.kid?.firstName?.charAt(0)?.toUpperCase()} ${row.kid?.lastName
              ?.charAt(0)
              ?.toUpperCase()}`}
            small
          />
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.NAME' />,
      id: 'Name',
      accessor: 'kid.firstName',
      sortable: true,

      Cell: ({cell}: any) => {
        const row = cell.row.original
        return (
          <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row mb-md-0 mb-8'>
            <div className='ml-4  d-flex flex-column justify-content-center'>
              <h5>{row?.kid?.firstName + ' ' + row?.kid?.lastName}</h5>
              {row.classes.length > 0 && (
                <p className='m-0 text-muted d-flex align-items-center  fs-7'>
                  <i className='fa-solid fa-people-roof me-2'></i>
                  {row.classes.map((cc: any, index: number) =>
                    cc.employeeId === currentUser?.employee?.employeeId && cc.classesName
                      ? `${cc.classesName} ${row.classes.length - 1 > index ? ',' : ' '} `
                      : ''
                  )}
                </p>
              )}
              <div className='d-block'>
                <div className='d-flex align-items-center'>
                  {row.dateOfBirth && (
                    <p className='m-0 text-muted d-flex align-items-center fs-7'>
                      <i className='fa-solid fa-cake-candles me-2'></i>
                      {moment(row.dateOfBirth).format('DD.MM.YYYY')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.CHECKIN' />,
      accessor: 'dateTimeIn',
      id: 'in',
      sortable: true,
      Cell: ({cell}: any) => {
        const attendance = cell.row.original

        const handleStartTimeChange = (value: any) => {
          editCell(
            {
              ...attendance,
              dateTimeOut: attendance.dateTimeOut,
            },
            'dateTimeIn',
            value
          )
        }
        return (
          <DatetimeEditableCell
            value={cell.row.original.dateTimeIn ? moment(cell.row.original.dateTimeIn) : ''}
            onChange={handleStartTimeChange}
          />
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.CHECKOUT' />,

      id: 'out',
      sortable: true,
      accessor: 'dateTimeOut',
      Cell: ({cell}: any) => {
        const attendance = cell.row.original
        const handleStartTimeChange = (value: any) => {
          editCell(
            {
              ...attendance,
              dateTimeIn: attendance.dateTimeIn,
            },
            'dateTimeOut',
            value
          )
        }
        return (
          <DatetimeEditableCell
            value={
              cell.row.original.dateTimeOut !== null ? moment(cell.row.original.dateTimeOut) : ''
            }
            onChange={handleStartTimeChange}
            minDate={moment(attendance.dateTimeIn).toDate()}
          />
        )
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.TIMESPENT' />
      ),

      id: 'timespent',
      sortable: true,
      width: 2,
      Cell: ({cell}: any) => {
        const duration = moment.duration(
          moment(cell.row.original.dateTimeOut).diff(cell.row.original.dateTimeIn)
        )
        return (
          <div>
            <i className='fa-solid fa-clock me-2'></i>
            {cell.row.original.dateTimeOut
              ? moment.utc(duration.as('milliseconds')).format('HH:mm')
              : '--:--'}
          </div>
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.LOCATION' />,

      id: 'attendanceLocationName',
      sortable: true,

      Cell: ({cell}: any) => {
        return <p>{cell.row.original.attendanceLocationName}</p>
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.EMPLOYEE_REMARKS' />
      ),

      id: 'employeeNote',
      sortable: true,
      width: 2,
      Cell: ({cell}: any) => {
        const attendance = cell.row.original
        const handleNoteChange = (value: any) => {
          editCell(
            {
              ...attendance,
              dateTimeOut: attendance.dateTimeOut,
            },
            'employeeRemarks',
            value
          )
        }
        return (
          <TextEditableCell
            value={cell.row.original.employeeRemarks ? cell.row.original.employeeRemarks : ''}
            onChange={handleNoteChange}
          />
        )
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.CONTACT_REMARKS' />
      ),
      width: 2,
      id: 'contactRemarks',
      sortable: true,

      Cell: ({cell}: any) => {
        return <p>{cell.row.original.contactRemarks}</p>
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.STATUS' />,
      accessor: 'presentStatus',
      id: 'status',
      sortable: true,
      Cell: ({cell}: any) => {
        return <h5>{StatusColumnFormatter(cell.row.original.presentStatus - 1)} </h5>
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='MENU.ACTIONS' className='  ' />
      ),
      id: 'Attributes',
      Cell: ({cell}: any) => {
        return (
          <div className='d-flex   align-items-center'>
            <OverlayTrigger
              placement='left'
              delay={{show: 250, hide: 400}}
              overlay={(props: any) => (
                <Tooltip id='button-tooltip' {...props}>
                  {intl.formatMessage({id: 'KIDLING.DELETE'})}
                </Tooltip>
              )}
            >
              <button
                className='btn text-muted p-0 me-4'
                type='button'
                onClick={() => handleDeleteAttendance(cell.row.original.kidAttendanceId)}
              >
                <i className='fa-solid fa-trash text-danger   fs-3'></i>
              </button>
            </OverlayTrigger>
          </div>
        )
      },
    },
  ]
}

export default _columns
