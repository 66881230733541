import {SleepsFilter} from './SleepsFilter'

const SleepsToolbar = () => {
  return (
    <div className='d-flex justify-content-end w-100 w-md-auto' data-kt-user-table-toolbar='base'>
      <SleepsFilter />
    </div>
  )
}

export {SleepsToolbar}
