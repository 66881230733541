import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const addHashtag = async (data: any) => {
  return axios
    .post(`${endpoints.HASHTAG}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getHashtags = async () => {
  return axios
    .get(`${endpoints.HASHTAG}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
export const addHashtagToImage = async (data: any) => {
  return axios
    .post(`${endpoints.ADD_HASHTAG_TO_IMAGE}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
