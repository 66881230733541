/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'
import {useAuth} from '../modules/auth'
import {deleteKidMedicalRecord, getKidsMedicalRecords} from '../../actions/medical'
import moment from 'moment'
import {toast} from 'react-toastify'

const useMedicalRecords = () => {
  const {
    kidsMedicalRecords,
    setKidsMedicalRecords,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    globalLoader,
    setInternalPagesFilters,
    setInternalPagesDatesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [editModal, setEditModal] = useState<any>({show: false, data: null})
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)
  const [date, setDate] = useState<any>({})

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.medical = {
      ...newFilters.medical,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readMedicalRecords()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    let temp: any = []
    kidsMedicalRecords?.forEach((item: any) => {
      let medicals: any = []
      item.medicalRecors?.forEach((med: any) =>
        medicals.push({
          id: med.kidMedicalRecordId,
          title: med.medicalRecordTypeName,
          subtitle: med.medicineName,
          tag: med.temperatureCelcius ? `${med.temperatureCelcius} C` : '',
          date: med.recordedAtDateTime,
          desc: med.remarks,
          covid: med.covid19Case,
          setShowEditModal: () =>
            setEditModal((prev: any) => ({
              ...prev,
              show: !prev.show,
              data: {kid: item, item: med},
            })),
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: medicals,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsMedicalRecords])

  const readMedicalRecords = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.medical.classValue && !internalPagesFilters.medical.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.medical.classValue)
      filter = `Classes.Any(e=>e.ClassesId==${internalPagesFilters.medical.classValue})`
    if (internalPagesFilters.medical.classValue && internalPagesFilters.medical.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.medical.searchTerm)
      filter = filter + internalPagesFilters.medical.searchTerm

    const res = await getKidsMedicalRecords(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.medical.From
        ? moment(internalPagesDatesFilters.medical.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.medical.To
        ? moment(internalPagesDatesFilters.medical.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsMedicalRecords(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidMedicalRecord(id)
      if (resp) {
        await readMedicalRecords()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.medical = {
      ...newFilters.medical,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }

  return {
    doDelete,
    readMedicalRecords,
    data,
    editModal,
    setEditModal,
    clicked,
    setClicked,
    searchTerm,
    setSearchTerm,
    handleDateChange,
  }
}

export default useMedicalRecords
