import React from 'react'
import moment from 'moment-timezone'
import ResponsiveDatePicker from '../responsive-material-datepicker/ResponsiveDatePicker'

const DatetimeEditableCell = ({value, onChange, minDate}: any) => {
  const [mode, setMode] = React.useState('read')
  const [text, setText] = React.useState(value ?? undefined)

  React.useEffect(() => {
    setText(value)
  }, [value])

  if (mode === 'edit') {
    const handleInputChange: any = (val: any) => {
      setText(val)
    }
    const handleSaveClick = () => {
      setMode('read')
      if (onChange) {
        onChange(text)
      }
    }
    return (
      <div className='p-2px d-flex align-items-center gap-2 '>
        <ResponsiveDatePicker
          dateTime
          onAccept={handleInputChange}
          value={text ? new Date(text) : undefined}
          minDate={minDate}
        />

        <button
          className='btn btn-sm btn-success btn-shadow rounded py-1 px-0 d-flex justify-content-center align-items-center'
          onClick={handleSaveClick}
        >
          <i className='fa-solid fa-check text-white ms-1'></i>
        </button>
      </div>
    )
  }
  if (mode === 'read') {
    const handleEditClick = () => {
      setMode('edit')
    }
    return (
      <div className='p-5px d-flex align-items-center' onClick={handleEditClick}>
        <i className='fa-solid fa-calendar-days me-2'></i>
        {text ? moment(text).format('DD.MM.yyyy HH:mm') : '--:--'}
      </div>
    )
  }
  return null
}

export default DatetimeEditableCell
