import {KidProtocolModalForm} from './KidProtocolModalForm'
import {FC, ReactElement} from 'react'

interface Props {
  data?: any
  setEditModal?: any
}
const KidProtocolModalFormWrapper: FC<Props> = ({data, setEditModal}): ReactElement => {
  return <KidProtocolModalForm isUserLoading={false} data={data} setEditModal={setEditModal} />
}

export {KidProtocolModalFormWrapper}
