import moment from 'moment'
import * as endpoints from '../config/endpoints'
import axios from 'axios'

export const addNewEvent = async (data: any) => {
  return axios
    .post(endpoints.EVENTS, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateEvent = async (data: any) => {
  return axios
    .put(endpoints.EVENTS, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteEvent = async (id: number) => {
  return axios
    .delete(`${endpoints.EVENTS}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const addNewEventSchedule = async (data: any) => {
  return axios
    .post(`${endpoints.EVENTS_SCHEDULE}/list`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEventSchedule = async (id: number) => {
  return axios
    .get(`${endpoints.EVENTS_SCHEDULE}/listwhere/eventsId=${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllEventsWithRecurrents = async (
  filter: string,
  From?: string,
  To?: string,
  filterBykid?: string
) => {
  const emp: any = localStorage.getItem('kt-auth-react-v')
  const EmployeeId = JSON.parse(emp).employeeId
  // const selComp: any = localStorage.getItem('kidling-selectedCompany')
  // const companyId = JSON.parse(selComp).value

  if (!filter) filter = `EmployeeId=${EmployeeId}`

  return (
    axios
      // .get(`${endpoints.EVENTS_WITH_RECURRENTS}/companyId=${companyId}`, {
      .get(`${endpoints.EVENTS_WITH_RECURRENTS}/${filterBykid ?? '1=1'}?${filter}`, {
        params: {From: From && To ? From : null, To: From && To ? To : null},
      })
      .then((response) =>
        response.data.data.sort((a: any, b: any) => moment(a?.startsOn).diff(b?.startsOn))
      )
      .catch((error: any) => {
        console.log(error)
      })
  )
}

export const readEventsCategory = async (filter: string) => {
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${endpoints.EVENTS_CATEGORY}/listwhere/companyId=${companyId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const readHolidays = async () => {
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${endpoints.HOLIDAY}/listwhere/companyId=${companyId}`)
    .then((response) =>
      response.data?.sort((a: any, b: any) => moment(a?.holidayDate).diff(b?.holidayDate))
    )
    .catch((error) => {
      console.log(error)
    })
}

export const readDaysOff = async () => {
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${endpoints.DAYOFF}/listwhere/companyId=${companyId}`)
    .then((response) =>
      response.data?.sort((a: any, b: any) => moment(a?.dayOffDate).diff(b?.dayOffDate))
    )
    .catch((error) => {
      console.log(error)
    })
}

export const addKidEvent = async (data: any) => {
  return axios
    .post(`${endpoints.EVENTS_KID}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteKidEvent = async (id: number) => {
  return axios
    .delete(`${endpoints.EVENTS_KID}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
export const addContactEvent = async (data: any) => {
  return axios
    .post(`${endpoints.EVENTS_CONTACT}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteContactEvent = async (id: number) => {
  return axios
    .delete(`${endpoints.EVENTS_CONTACT}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateEmployeeAttendanceStatus = async (filter: any) => {
  return axios
    .post(`${endpoints.EMPLOYEE_ATTENDACE_UPDATE}?${filter} `)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const AddEmployeeToEvent = async (data: any) => {
  return axios
    .post(endpoints.EMPLOYEE_EVENT, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteEmployeeFromEvent = async (id: number) => {
  return axios
    .delete(`${endpoints.EMPLOYEE_EVENT}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
