/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getAllKids} from '../../../../../../actions/kids'
import {useAuth} from '../../../../auth'
import {KidSearchComponent} from '../seach-kids/KidSearchComponent'
import KidsList from './kids-list'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'

const KidsPage: FC = (): ReactElement => {
  const navigate = useNavigate()
  const {currentCompany} = useCompanyContext()
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const company = window.location.pathname.split('/')[1]
  const {classesId} = useParams()

  useEffect(() => {
    readKids('')
  }, [classesId])

  const readKids = async (search?: string) => {
    setIsLoading(true)
    setData([])

    let filter = `Classes.any(x => x.classesId = ${classesId})`
    if (!!search) {
      if (filter !== '') filter += ' AND '
      filter += `kidName.toLower().contains("${search.toLowerCase().trim()}")`
    }

    const resp = await getAllKids({
      filter: filter,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    setData(resp)

    setIsLoading(false)
  }

  return (
    <div className='d-flex flex-column  position-relative'>
      <button
        className='btn  position-absolute '
        style={{top: '1rem', left: '0rem', width: '1rem'}}
        onClick={() =>
          navigate(
            company
              ? `/${company}/kid-management/kids/simplified`
              : `/kidling/kid-management/kids/simplified`
          )
        }
      >
        <i className='fa-solid fa-arrow-left-long' style={{fontSize: '1.5rem'}}></i>
      </button>
      <div className='mt-16 w-md-50 w-75  mx-auto'>
        <KidSearchComponent filterData={readKids} />
      </div>
      <KidsList isLoading={isLoading} data={data} readAllKids={readKids} />
    </div>
  )
}

export default KidsPage
