/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../modules/auth'
import {useIntl} from 'react-intl'
import {deleteKidRequest, getKidsRequests, updateKidRequest} from '../../actions/requests'
import moment from 'moment'
import {toast} from 'react-toastify'

const useRequests = () => {
  const {
    setKidsRequests,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    delivered,
    globalLoader,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const {currentUser} = useAuth()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [data, setData] = useState([])
  const [currentRequest, setCurrentRequest] = useState({})
  const intl = useIntl()
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readRequests()
      setClicked(false)
    }
  }, [clicked])
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.requests = {
      ...newFilters.requests,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])
  const readRequests = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.requests.classValue && !internalPagesFilters.requests.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.requests.classValue)
      filter = `classesId=${internalPagesFilters.requests.classValue}`
    if (internalPagesFilters.requests.classValue && internalPagesFilters.requests.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.requests.searchTerm)
      filter = filter + internalPagesFilters.requests.searchTerm

    const res = await getKidsRequests(
      filter,
      currentUser?.employee?.employeeId || 0,
      delivered === 1,
      internalPagesDatesFilters.requests.From
        ? moment(internalPagesDatesFilters.requests.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.requests.To
        ? moment(internalPagesDatesFilters.requests.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsRequests(res)
    setGlobalLoader(false)
  }

  const doUpdateDelivered = async () => {
    const data = {
      ...currentRequest,
      deliveredDate: new Date(),
      delivered: true,
    }
    setGlobalLoader(true)
    const resp = await updateKidRequest(data)
    setGlobalLoader(false)
    if (resp) {
      await readRequests()
      setGlobalLoader(false)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      setGlobalLoader(false)
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidRequest(id)
      if (resp) {
        await readRequests()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.requests = {
      ...newFilters.requests,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {
    doUpdateDelivered,
    doDelete,
    readRequests,
    data,
    showConfirmation,
    setShowConfirmation,
    setCurrentRequest,
    setData,
    handleDateChange,
    setSearchTerm,
    clicked,
    searchTerm,
    setClicked,
  }
}

export default useRequests
