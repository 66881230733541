import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {QueryResponseContextProps, QueryState} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'items_per_page'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<any>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: any,
  selected: Array<any>,
  setSelected: Dispatch<SetStateAction<Array<any>>>
) {
  if (id === null || id === undefined) {
    return
  }

  const exists = selected.find((i) => {
    if (
      i === id ||
      (id?.notificationId &&
        i?.notificationUsersId === id?.notificationUsersId &&
        i?.notificationId === id?.notificationId)
    )
      return true
    return false
  })

  if (exists !== null && exists !== undefined) {
    const filterd = selected.filter((itemId) => {
      if (id?.notificationId) {
        if (
          !(itemId?.notificationUsersId === id?.notificationUsersId,
          itemId?.notificationId === id?.notificationId)
        ) {
          return itemId
        }
        return -1
      } else {
        if (itemId !== id) {
          return itemId
        }
        return -1
      }
    })

    setSelected(filterd)
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<any>>>,
  data?: Array<any>,
  isMarkedTable?: boolean
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }
  const newVal = isMarkedTable
    ? data.map((item) => ({
        notificationUsersId: item.notificationUsersId,
        notificationId: item.notificationId,
      }))
    : data.map((item) =>
        item.notificationUsersId ? item.notificationUsersId : item.notificationId
      )

  setSelected(newVal)
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
}
