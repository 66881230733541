import {KidAssessmentModalForm} from './KidAssessmentModalForm'
import {FC} from 'react'
type Props = {
  intitialPositionId?: string
}
const KidAssessmentModalFormWrapper: FC<Props> = ({intitialPositionId}) => {
  return <KidAssessmentModalForm isUserLoading={false} intitialPositionId={intitialPositionId} />
}

export {KidAssessmentModalFormWrapper}
