import React, {useState, createContext, useContext} from 'react'
const DateContext: any = createContext<any>(null)

export const useDateContext = (): any => {
  return useContext(DateContext)
}

export default function DateContextProvider({children}: any) {
  const [absenceDate, setAbsenceDate] = useState({})

  return (
    <DateContext.Provider
      value={{
        absenceDate,
        setAbsenceDate,
      }}
    >
      {children}
    </DateContext.Provider>
  )
}
