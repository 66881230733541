import {RequestsFilter} from './RequestsFilter'

const RequestsToolbar = () => {
  return (
    <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
      <RequestsFilter />
    </div>
  )
}

export {RequestsToolbar}
