import React, {FC, ReactElement} from 'react'
import AvatarWithLetters from '../avatar-with-letters'
import {Link} from 'react-router-dom'
type KidType = {
  kidName: string
  imageUrl: string
}
type ActivityType = {
  image: string
  link: string
  title: string
}
interface Props {
  kid: KidType
  activity: ActivityType
  title: string
  desc: string
  date: string
  key: number
}
const FeedCard: FC<Props> = ({kid, activity, title, desc, date, key}): ReactElement => {
  const company = window.location.pathname.split('/')[1]

  return (
    <Link to={company ? `/${company}/${activity.link}` : `/kidling/${activity.link}`}>
      <div
        className='d-flex align-items-center justify-content-between w-100 my-4   border-bottom p-4'
        key={key}
      >
        <div className='d-flex align-items-start  w-100'>
          <div className='d-flex flex-column align-items-start  w-100'>
            <div className='d-flex align-items-start w-100'>
              <AvatarWithLetters
                small
                image={kid.imageUrl}
                alt={kid.kidName.charAt(0).toUpperCase()}
              />
              <div className='ms-4'>
                <h6 className='m-0'>{activity.title}</h6>

                <div className='mt-1'>
                  <p className='m-0 badge badge-primary'>{kid.kidName}</p>

                  <p className='m-0 text-muted'>{title}</p>
                </div>
              </div>
            </div>
            <p className='mt-2 text-muted '>{desc}</p>
            <p className='m-0 fs-7 text-muted ms-auto'>{date}</p>
          </div>
        </div>
        <Link to={company ? `/${company}/${activity.link}` : `/kidling/${activity.link}`}>
          <i className='fa-solid fa-arrow-right'></i>
        </Link>
      </div>
    </Link>
  )
}

export default FeedCard
