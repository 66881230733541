/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {KidServicesHeader} from './header/KidServicesHeader'
import useServices from '../../../../hooks/useServices'

function KidServices() {
  const {internalPagesFilters, internalPagesDatesFilters} = useAppContext()
  const intl = useIntl()
  const {readServices, data} = useServices()

  useEffect(() => {
    readServices()
  }, [internalPagesFilters, internalPagesDatesFilters])

  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <KidServicesHeader title={intl.formatMessage({id: 'KIDLING.MENU.KIDSSERVICES'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default KidServices
