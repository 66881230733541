/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import makeAnimated from 'react-select/animated'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {addHashtag, getHashtags} from '../../../../../../actions/hashtag'
import SelectWithAllOption from '../../../../../general-components/select-with-all/SelectWithAllOption'
import {selectStyle, selectStyleDarkMode} from '../../../../../utils/select-style'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
const animatedComponents = makeAnimated()
interface Props {
  show: boolean
  setShow: (b: boolean) => void
  KidMediaFileId?: number
  readData?: any
  Hashtags?: any
  setHashtags?: any
}
const AddHashtagDialog: FC<Props> = ({show, setShow, Hashtags, setHashtags}): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const {allHashtags, setAllHashtags} = useAppContext()
  const [enableNew, setEnableNew] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hashtagName, setHashtagName] = useState('')
  const [newHashtag, setNewHashtag] = useState<any>(null)
  const [initialValues, setInitialValues] = useState({
    tag: [],
  })

  const eventSchema = Yup.object().shape({
    tag: Yup.mixed().required('Required'),
  })

  useEffect(() => {
    let temp: any = []
    Hashtags.map((item: any) =>
      temp.push({hashtagId: item.hashtagId, hashtagName: item.hashtagName})
    )
    if (newHashtag) {
      temp.push(newHashtag)
      setNewHashtag(null)
    }

    setInitialValues((prev: any) => ({...prev, tag: [...prev.tag, ...temp]}))
  }, [allHashtags, Hashtags])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: eventSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (values.tag) {
          setHashtags((prev: any) => [...prev, ...values.tag])
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        handleClose()
      }
    },
  })
  const handleClose = () => {
    setShow(false)
    setEnableNew(false)
    setHashtagName('')
  }
  const addNewHashtag = async () => {
    if (!!hashtagName) {
      setIsLoading(true)
      const resp = await addHashtag({hashtagName: hashtagName})
      if (resp) {
        setNewHashtag(resp)
        setHashtagName('')
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      }
      getAllHashtags()
      setIsLoading(false)
    }
  }

  const getAllHashtags = async () => {
    const resp = await getHashtags()
    setAllHashtags(resp)
  }
  return (
    <Modal
      isOpen={show}
      toggle={handleClose}
      centered
      zIndex={1100}
      onClosed={handleClose}
      modalClassName='EventModal'
    >
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        <ModalHeader toggle={handleClose}>
          {intl.formatMessage({id: 'KIDLING.HOME.ADD_HASHTAG'})}
        </ModalHeader>
        <ModalBody>
          <SelectWithAllOption
            value={formik.values.tag}
            onChange={(val: any) => formik.setFieldValue('tag', val)}
            options={allHashtags}
            isClearable={true}
            getOptionLabel={(option: any) => option.hashtagName}
            getOptionValue={(option: any) => option.hashtagId}
            components={animatedComponents}
            isMulti
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
          />
          <div className='row my-4'>
            <hr />
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='enableNew'
              checked={enableNew}
              onChange={() => setEnableNew((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.HOME.ADD'})}
            </span>
          </label>
          <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HASHTAG_NAME'})}
            </label>
            <div className='d-flex'>
              <input
                {...formik.getFieldProps('hashtagName')}
                className={clsx(
                  'form-control   mb-3 mb-lg-0 me-2',
                  {'is-invalid': enableNew && (hashtagName === '' || !hashtagName)},
                  {
                    'is-valid': hashtagName !== '' || hashtagName,
                  }
                )}
                name='hashtagName'
                autoComplete='off'
                value={hashtagName}
                disabled={formik.isSubmitting || !enableNew}
                onChange={(e) => setHashtagName(e.target.value)}
              />
              <Button
                color='primary'
                type='button'
                onClick={() => addNewHashtag()}
                disabled={formik.isSubmitting || !enableNew}
                className='d-flex align-items-center justify-content-center'
              >
                {!isLoading ? (
                  <i className='fa-solid fa-plus p-0 m-0'></i>
                ) : (
                  <span className='indicator-progress ' style={{display: 'inline-block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={handleClose}>
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </Button>
          <Button color='primary' type='submit'>
            {!formik.isSubmitting ? (
              intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})
            ) : (
              <span className='indicator-progress ' style={{display: 'inline-block'}}>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AddHashtagDialog
