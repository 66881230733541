/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {RequestsHeader} from './header/RequestsHeader'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../general-components/error-text/ErrorText'
import useRequests from '../../../../hooks/useRequests'

function Requests() {
  const {internalPagesDatesFilters, delivered, globalLoader, kidsRequests} = useAppContext()
  const intl = useIntl()
  const {
    data,
    readRequests,
    doUpdateDelivered,
    setShowConfirmation,
    showConfirmation,
    setCurrentRequest,
    setData,
    doDelete,
  } = useRequests()
  useEffect(() => {
    let temp: any = []

    kidsRequests?.map((item: any, index: number) => {
      let requests: any = []
      item.requests?.map((request: any) =>
        requests.push({
          kidId: item.kidId,
          id: request.requestKidId,
          title: request.requestItemName,
          desc: request.note,
          date: request.dueDate,
          Icon: !request.delivered && (
            <button
              className='btn p-0 m-0 d-flex justify-content-end align-items-center'
              onClick={() => {
                setCurrentRequest({...request, kidId: item.kidId})
                setShowConfirmation(true)
              }}
            >
              <i className='fa-solid fa-people-arrows m-0 p-0' />
            </button>
          ),
        })
      )
      return temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: requests,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsRequests])

  useEffect(() => {
    if (!globalLoader) readRequests()
  }, [internalPagesDatesFilters, delivered])

  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <RequestsHeader title={intl.formatMessage({id: 'KIDLING.MENU.REQUESTS'})} />
      <TableCardsContainer data={data} columns={columns} />
      <SweetAlert
        show={showConfirmation}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='success'
        title={
          <ErrorText
            text={intl.formatMessage({
              id: 'KIDLING.MENU.ARE_YOU_SURE_YOU_WANT_TO_MAKE_IT_DELIVERED?',
            })}
          />
        }
        focusCancelBtn
        onConfirm={(e) => {
          setShowConfirmation(false)
          doUpdateDelivered()
        }}
        onCancel={() => setShowConfirmation(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default Requests
