import {FC} from 'react'
import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import {DeregistrationSearchComponent} from './DeregistrationSearchComponent'
import {Deregistrationtoolbar} from './DeregistrationToolbar'
import useDeregisteration from '../../../../../hooks/useDeregisteration'

interface Props {
  title?: string
}
const DeregistrationHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useDeregisteration()
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100'>
      {title && <h4>{title}</h4>}
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <DeregistrationSearchComponent />
          <Deregistrationtoolbar />
        </div>
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>
    </div>
  )
}

export {DeregistrationHeader}
