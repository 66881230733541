/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {MessageAttachModalHeader} from './MessageAttachModalHeader'
import MessageAttachModalFormWrapper from './MessageAttachModalFormWrapper'

const MessageAttachModal = ({setSelectedFiles, setShowAttachModal}: any) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block '
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <MessageAttachModalHeader setShowAttachModal={setShowAttachModal} />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y overflow-auto mx-5 mx-xl-15 my-0'>
              <MessageAttachModalFormWrapper
                setShowAttachModal={setShowAttachModal}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {MessageAttachModal}
