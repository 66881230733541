import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from '../../../../../../general-components/table-custom-header/UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import CustomAttribuesCell from './CustomAttribuesCell'
import {StatusColumnFormatter} from '../../../../../../general-components/status-formatter/StatusColumnFormatter'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import {useAuth} from '../../../../../auth'
import {Link} from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import AvatarWithLettersLoadBackend from '../../../../../../general-components/avatar-with-letters-load-image-by-endpoint'

function _columns() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const company = window.location.pathname.split('/')[1]

  return [
    {
      Header: (props: any) => <UserSelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({cell}: any) => <UserSelectionCell item={cell?.row.original} />,
      width: 5,
    },
    {
      id: 'avatar',
      accessor: 'avatar',
      width: 2,
      Cell: ({cell}: any) => {
        const row = cell?.row?.original

        return (
          <AvatarWithLettersLoadBackend
            image={row.imagePath?.split('FilePath=')[1]?.split('&OTP=')[0]}
            alt={`${row.firstName.charAt(0).toUpperCase()} ${row.lastName.charAt(0).toUpperCase()}`}
            small
          />
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.NAME' />,
      id: 'Name',
      width: 20,
      accessor: 'firstName',
      sortbyOrder: 'asc',
      Cell: ({cell}: any) => {
        const row = cell?.row?.original

        const sortedContracts: any = row?.contracts?.sort(
          (a: any, b: any) =>
            +new Date(b?.subscriptions[0]?.subscriptionStartDate) -
            +new Date(a?.subscriptions[0]?.subscriptionStartDate)
        )

        return (
          <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row mb-md-0 mb-8'>
            <div className='ml-4  d-flex flex-column justify-content-center'>
              <h5 className='d-flex align-items-center'>
                <Link
                  to={
                    company
                      ? `/${company}/kidprofile?kidId=${row.kidId}`
                      : `/kidling/kidprofile?kidId=${row.kidId}`
                  }
                  className='text-gray-800 text-hover-primary  fw-bolder me-1'
                >
                  {row.firstName + ' ' + row.lastName}
                </Link>

                {row.kidParentVerificationCode && (
                  <span className='ms-1 mb-1'>
                    <OverlayTrigger
                      placement='left'
                      delay={{show: 250, hide: 400}}
                      overlay={(props: any) => (
                        <Tooltip id='button-tooltip' {...props}>
                          <div className='d-flex flex-column'>
                            {intl.formatMessage({id: 'KIDLING.CODE'})}
                            <h1>{row.kidParentVerificationCode}</h1>
                          </div>
                        </Tooltip>
                      )}
                    >
                      <button className='btn   p-0'>
                        <i className='fa-solid fa-lock-open text-primary '></i>
                      </button>
                    </OverlayTrigger>
                  </span>
                )}
              </h5>
              {row.classes.length > 0 && (
                <p className='m-0   d-flex align-items-center fs-7'>
                  <i className='fa-solid fa-people-roof me-2'></i>
                  {row.classes.map((cc: any, index: number) =>
                    cc.employeeId === currentUser?.employee?.employeeId && cc.classesName
                      ? `${cc.classesName} ${row.classes.length - 1 > index ? ',' : ' '} `
                      : ''
                  )}
                </p>
              )}
              <div className='d-block'>
                {sortedContracts && sortedContracts[0]?.subscriptions[0]?.careTimeName && (
                  <p className='m-0   d-flex align-items-center fs-7'>
                    <i className='fa-solid fa-hands-holding-child me-2'></i>
                    {sortedContracts[0]?.subscriptions[0]?.careTimeName ?? ''}
                  </p>
                )}
                <div className='d-flex align-items-center'>
                  {row.dateOfBirth && (
                    <p className='m-0   d-flex align-items-center fs-7'>
                      <i className='fa-solid fa-cake-candles me-2'></i>
                      {moment(row.dateOfBirth).format('DD.MM.YYYY')}
                    </p>
                  )}
                </div>
                {sortedContracts && sortedContracts[0]?.subscriptions[0]?.fromTime && (
                  <div className='d-flex align-items-center'>
                    <i className='fa-solid fa-clock me-2'></i>
                    <p className='m-0   fs-7'>
                      {sortedContracts[0]?.subscriptions[0]?.fromTime
                        ? moment(sortedContracts[0]?.subscriptions[0]?.fromTime).format('HH:mm')
                        : ''}
                      -
                      {sortedContracts[0]?.subscriptions[0]?.toTime
                        ? moment(sortedContracts[0]?.subscriptions[0]?.toTime).format('HH:mm')
                        : ''}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.ATTENDANCE' />,
      id: 'attendance',
      width: 250,
      accessor: 'presentStatus',
      Cell: ({cell}: any) => {
        const row = cell.row.original

        return (
          <div className=' mb-md-0 mb-4'>
            <div className='row'>
              <div className='col-12 d-flex  w-100 gap-3 flex-column'>
                <div className='d-flex align-items-center'>
                  <h5>{StatusColumnFormatter(row?.presentStatus - 1)} </h5>
                  <span className='mx-2'>
                    {row.presentStatus === 1 && (
                      <h5 className=' label  label-inline justify-self-center fs-7'>
                        {row.absents[row.absents.length - 1]?.absentReasonName
                          ? row.absents[row.absents.length - 1]?.absentReasonName
                          : ''}
                      </h5>
                    )}
                    {row.presentStatus === 2 && (
                      <h5 className=' label  label-inline justify-self-center fs-7'>
                        {row.attendance.length > 0 &&
                        row?.attendance[row.attendance.length - 1]?.attendanceLocationName
                          ? row.attendance[row.attendance.length - 1]?.attendanceLocationName
                          : ''}
                      </h5>
                    )}
                  </span>
                </div>

                {row.presentStatus === 1 && (
                  <div className='row'>
                    {row.absents?.map((item: any, index: number) => (
                      <Link
                        to={
                          company
                            ? `/${company}/kid-management/absense`
                            : '/kidling/kid-management/absense'
                        }
                        key={index}
                        className='col-12 d-flex align-items-center justify-content-start'
                      >
                        <div className='d-flex align-items-center fs-7' style={{width: '4rem'}}>
                          <i className='bi bi-clock-fill me-2 text-secondary'></i>
                          <span className=' fs-7 text-secondary'>
                            {item.absentFromDate
                              ? moment(item.absentFromDate).format('DD.MMM')
                              : '--'}
                          </span>
                        </div>
                        <span className='mx-3 '>
                          <i className='fa-solid fa-angles-right'></i>
                        </span>
                        <span className=' fs-7 text-secondary'>
                          {item.absentToDate ? moment(item.absentToDate).format('DD.MMM') : '--'}
                        </span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {(row.presentStatus === 2 || row.presentStatus === 4) && (
              <div className='row'>
                {row.attendance?.map((item: any, index: number) => (
                  <Link
                    to={
                      company
                        ? `/${company}/kid-management/attendance`
                        : '/kidling/kid-management/attendance'
                    }
                    key={index}
                    className='col-12 d-flex align-items-center justify-content-start'
                  >
                    <div className='d-flex align-items-center fs-7' style={{width: '4rem'}}>
                      <i className='bi bi-clock-fill me-2 text-secondary'></i>
                      <span className=' fs-7 text-secondary'>
                        {item.attendanceDateTimeIn
                          ? moment(item.attendanceDateTimeIn).format('HH:mm')
                          : '--'}
                      </span>
                    </div>
                    <span className='mx-3'>
                      <i className='fa-solid fa-angles-right'></i>
                    </span>
                    <span className=' fs-7 text-secondary'>
                      {item.attendanceDateTimeOut
                        ? moment(item.attendanceDateTimeOut).format('HH:mm')
                        : '--'}
                    </span>
                  </Link>
                ))}
              </div>
            )}

            <div className='row'>
              {row.sleeps.map((item: any, index: number) => (
                <div className='row'>
                  <Link
                    to={
                      company
                        ? `/${company}/kid-management/sleeptimes`
                        : '/kidling/kid-management/sleeptimes'
                    }
                    key={index}
                    className='col-12 d-flex align-items-center justify-content-start text-primary'
                  >
                    <div className='d-flex align-items-center fs-7' style={{width: '4rem'}}>
                      <i className='fa-solid fa-bed me-2 text-secondary'></i>

                      <span className=' fs-7 text-secondary'>
                        {item.fromTime ? moment(item.fromTime).format('HH:mm') : '--'}
                      </span>
                    </div>
                    <span className='mx-3'>
                      <i className='fa-solid fa-angles-right'></i>
                    </span>
                    <span className=' fs-7 text-secondary'>
                      {item.toTime ? moment(item.toTime).format('HH:mm') : '--'}
                    </span>
                    <span className='badge badge-secondary my-1 mx-2'>{`${Math.ceil(
                      moment
                        .duration(
                          moment(
                            item?.toTime
                              ? moment(item?.date)
                                  .set('hours', moment(item?.toTime).hours())
                                  .set('minutes', moment(item?.toTime).minutes())
                                  .format('yyyy-MM-DDTHH:mm:ss')
                              : moment().format('yyyy-MM-DDTHH:mm:ss')
                          ).diff(
                            moment(item?.date)
                              .set('hours', moment(item?.fromTime).hours())
                              .set('minutes', moment(item?.fromTime).minutes())
                              .format('yyyy-MM-DDTHH:mm:ss')
                          )
                        )
                        .asMinutes()
                    )} m`}</span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.CONTACTS' />,

      id: 'contacts',
      width: 50,
      Cell: ({cell}: any) => {
        return (
          <div
            className='accordion accordion-flush  mb-md-0 mb-4'
            style={{width: '15rem'}}
            id={`${cell.row.original.kidId}`}
          >
            {cell.row.original.contacts.map(
              (item: any, index: number) =>
                item.contactName && (
                  <div className='accordion-item' key={item.contactId}>
                    <h2 className='accordion-header' id={`${cell.row.original.kidId}`}>
                      <button
                        className='accordion-button collapsed py-1 px-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#${cell.row.original.kidId}-${item.contactId}`}
                        aria-expanded='false'
                        aria-controls={`${cell.row.original.kidId}`}
                      >
                        {' '}
                        <i className='fa-solid fa-angle-down ms-2'></i>
                        {item.canPickUpChild ? (
                          <i className='fa-solid fa-baby mx-2 text-success'></i>
                        ) : (
                          <img
                            src='/media/icons/no-pickup.png'
                            alt='notauth'
                            className='w-20px h-20px object-contain mx-2'
                          />
                        )}
                        {item.contactName}
                      </button>
                    </h2>
                    <div
                      id={`${cell.row.original.kidId}-${item.contactId}`}
                      className='accordion-collapse collapse'
                      aria-labelledby={`${cell.row.original.kidId}`}
                      data-bs-parent={`#${cell.row.original.kidId}`}
                    >
                      <div className='accordion-body pt-1 pl-0'>
                        <p className='m-0  fs-7'>{item.contactRelationTypeName}</p>
                        <p className='m-0  fs-7'>{item.emailAddress}</p>
                        <p className='m-0  fs-7'>{item.cellPhoneNumber}</p>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        )
      },
    },
    {
      width: 100,
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.ATTRIBUTES' />,
      accessor: 'Attributes',
      id: 'Attributes',
      Cell: ({cell}: any) => {
        return <CustomAttribuesCell row={cell.row.original} />
      },
    },
  ]
}

export default _columns
function _mobileColumns() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const company = window.location.pathname.split('/')[1]
  return [
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.NAME' />,
      id: 'Name',
      width: 20,
      accessor: 'Name',
      Cell: ({cell}: any) => {
        const row = cell?.row?.original

        const sortedContracts: any = row?.contracts?.sort(
          (a: any, b: any) =>
            +new Date(b?.subscriptions[0]?.subscriptionStartDate) -
            +new Date(a?.subscriptions[0]?.subscriptionStartDate)
        )

        return (
          <div className='d-flex align-items-center  w-100 ps-10  px-sm-8 py-0'>
            <div className='row   w-100 shadow-sm p-4'>
              <div className=' d-flex justify-content-between '>
                <div className='d-flex align-items-start '>
                  <UserSelectionCell item={cell?.row.original} />
                  <AvatarWithLetters
                    image={row.imagePath}
                    alt={`${row.firstName.charAt(0).toUpperCase()} ${row.lastName
                      .charAt(0)
                      .toUpperCase()}`}
                    verysmall
                  />
                  <div className=' ms-4 d-flex   flex-column flex-md-row m-0  '>
                    <div className='  d-flex flex-column justify-content-center'>
                      <h5 className='d-flex align-items-start m-0'>
                        <Link
                          to={
                            company
                              ? `/${company}/kidprofile?kidId=${row.kidId}`
                              : `/kidling/kidprofile?kidId=${row.kidId}`
                          }
                          className='text-gray-800 text-hover-primary fw-bolder me-1'
                        >
                          <span className='nowrap'> {row.firstName + ' ' + row.lastName}</span>
                        </Link>
                        {row.kidParentVerificationCode && (
                          <span className='ms-1 mb-1'>
                            <OverlayTrigger
                              placement='left'
                              delay={{show: 250, hide: 400}}
                              overlay={(props: any) => (
                                <Tooltip id='button-tooltip' {...props}>
                                  <div className='d-flex flex-column'>
                                    {intl.formatMessage({id: 'KIDLING.CODE'})}
                                    <h1>{row.kidParentVerificationCode}</h1>
                                  </div>
                                </Tooltip>
                              )}
                            >
                              <button className='btn   p-0'>
                                <i className='fa-solid fa-lock-open text-primary '></i>
                              </button>
                            </OverlayTrigger>
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className=' mb-md-0  '>
                      {(row.presentStatus === 2 || row.presentStatus === 4) && (
                        <div className='row'>
                          {row.attendance?.map((item: any, index: number) => (
                            <Link
                              to={
                                company
                                  ? `/${company}/kid-management/attendance`
                                  : '/kidling/kid-management/attendance'
                              }
                              key={index}
                              className='col-12 d-flex align-items-center justify-content-start'
                            >
                              <div
                                className='d-flex align-items-center fs-7'
                                style={{width: '4rem'}}
                              >
                                <i className='bi bi-clock-fill me-2 text-secondary'></i>
                                <span className=' fs-7 text-secondary'>
                                  {item.attendanceDateTimeIn
                                    ? moment(item.attendanceDateTimeIn).format('HH:mm')
                                    : '--'}
                                </span>
                              </div>
                              <span className='mx-3'>
                                <i className='fa-solid fa-angles-right'></i>
                              </span>
                              <span className=' fs-7 text-secondary'>
                                {item.attendanceDateTimeOut
                                  ? moment(item.attendanceDateTimeOut).format('HH:mm')
                                  : '--'}
                              </span>
                            </Link>
                          ))}
                        </div>
                      )}

                      <div className='row'>
                        {row.sleeps.map((item: any, index: number) => (
                          <div className='row'>
                            <Link
                              to={
                                company
                                  ? `/${company}/kid-management/sleeptimes`
                                  : '/kidling/kid-management/sleeptimes'
                              }
                              key={index}
                              className='col-12 d-flex align-items-center justify-content-start'
                            >
                              <div
                                className='d-flex align-items-center fs-7'
                                style={{width: '4rem'}}
                              >
                                <i className='fa-solid fa-bed me-2 text-primary'></i>

                                <span className=' fs-7 text-secondary'>
                                  {item.fromTime ? moment(item.fromTime).format('HH:mm') : '--'}
                                </span>
                              </div>
                              <span className='mx-3'>
                                <i className='fa-solid fa-angles-right'></i>
                              </span>
                              <span className=' fs-7 text-secondary'>
                                {item.toTime ? moment(item.toTime).format('HH:mm') : '--'}
                              </span>
                              <span className='badge badge-primary my-1 mx-2'>{`${Math.ceil(
                                moment
                                  .duration(
                                    moment(
                                      item?.toTime
                                        ? moment(item?.date)
                                            .set('hours', moment(item?.toTime).hours())
                                            .set('minutes', moment(item?.toTime).minutes())
                                            .format('yyyy-MM-DDTHH:mm:ss')
                                        : moment().format('yyyy-MM-DDTHH:mm:ss')
                                    ).diff(
                                      moment(item?.date)
                                        .set('hours', moment(item?.fromTime).hours())
                                        .set('minutes', moment(item?.fromTime).minutes())
                                        .format('yyyy-MM-DDTHH:mm:ss')
                                    )
                                  )
                                  .asMinutes()
                              )} m`}</span>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                    {row.classes.length > 0 && (
                      <p className='m-0   d-flex align-items-center fs-7'>
                        <i className='fa-solid fa-people-roof w-20px '></i>
                        {row.classes.map((cc: any, index: number) =>
                          cc.employeeId === currentUser?.employee?.employeeId && cc.classesName
                            ? `${cc.classesName} ${row.classes.length - 1 > index ? ',' : ' '} `
                            : ''
                        )}
                      </p>
                    )}
                    <div className='d-block'>
                      {sortedContracts && sortedContracts[0]?.subscriptions[0]?.careTimeName && (
                        <p className='m-0   d-flex align-items-center fs-7'>
                          <i className='fa-solid fa-hands-holding-child w-20px '></i>
                          {sortedContracts[0]?.subscriptions[0]?.careTimeName ?? ''}
                        </p>
                      )}
                      <div className='d-flex align-items-center'>
                        {row.dateOfBirth && (
                          <p className='m-0   d-flex align-items-center fs-7'>
                            <i className='fa-solid fa-cake-candles fs-4 w-20px '></i>
                            {moment(row.dateOfBirth).format('DD.MM.YYYY')}
                          </p>
                        )}
                      </div>
                      {sortedContracts && sortedContracts[0]?.subscriptions[0]?.fromTime && (
                        <div className='d-flex align-items-center'>
                          <i className='fa-solid fa-clock w-20px '></i>
                          <p className='m-0   fs-7'>
                            {sortedContracts[0]?.subscriptions[0]?.fromTime
                              ? moment(sortedContracts[0]?.subscriptions[0]?.fromTime).format(
                                  'HH:mm'
                                )
                              : ''}
                            -
                            {sortedContracts[0]?.subscriptions[0]?.toTime
                              ? moment(sortedContracts[0]?.subscriptions[0]?.toTime).format('HH:mm')
                              : ''}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row me-2'>
                  <div className='col-12 d-flex  w-100 gap-3 flex-column p-0 m-0'>
                    <div className='d-flex align-items-center justify-content-end'>
                      <h5 className='text-end mx-1'>
                        {StatusColumnFormatter(row?.presentStatus - 1)}{' '}
                      </h5>
                      <span className=' '>
                        {row.presentStatus === 1 && (
                          <h5 className=' label  label-inline justify-self-center fs-7'>
                            {row.absents[row.absents.length - 1]?.absentReasonName
                              ? row.absents[row.absents.length - 1]?.absentReasonName
                              : ''}
                          </h5>
                        )}
                        {row.presentStatus === 2 && (
                          <h5 className=' label  label-inline justify-self-center fs-7'>
                            {row.attendance.length > 0 &&
                            row?.attendance[row.attendance.length - 1]?.attendanceLocationName
                              ? row.attendance[row.attendance.length - 1]?.attendanceLocationName
                              : ''}
                          </h5>
                        )}
                      </span>
                    </div>

                    {row.presentStatus === 1 && (
                      <div className='row'>
                        {row.absents?.map((item: any, index: number) => (
                          <Link
                            to={
                              company
                                ? `/${company}/kid-management/absense`
                                : '/kidling/kid-management/absense'
                            }
                            key={index}
                            className='col-12 d-flex align-items-center justify-content-start'
                          >
                            <div className='d-flex align-items-center fs-7' style={{width: '4rem'}}>
                              <i className='bi bi-clock-fill me-2 text-secondary'></i>
                              <span className=' fs-7 text-secondary'>
                                {item.absentFromDate
                                  ? moment(item.absentFromDate).format('DD.MMM')
                                  : '--'}
                              </span>
                            </div>
                            <span className='mx-3 '>
                              <i className='fa-solid fa-angles-right'></i>
                            </span>
                            <span className=' fs-7 text-secondary'>
                              {item.absentToDate
                                ? moment(item.absentToDate).format('DD.MMM')
                                : '--'}
                            </span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mt-4'>
                <div
                  className='accordion accordion-flush  mb-md-0 mb-4 col-12 row'
                  id={`${cell.row.original.kidId}`}
                >
                  {cell.row.original.contacts.map(
                    (item: any, index: number) =>
                      item.contactName && (
                        <div className='accordion-item col-6' key={item.contactId}>
                          <h2 className='accordion-header' id={`${cell.row.original.kidId}`}>
                            <button
                              className='accordion-button collapsed py-1 px-0 justify-content-start z-2'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#${cell.row.original.kidId}-${item.contactId}`}
                              aria-expanded='false'
                              aria-controls={`${cell.row.original.kidId}`}
                            >
                              <i className='fa-solid fa-angle-down me-2'></i>
                              {item.canPickUpChild ? (
                                <i className='fa-solid fa-baby mx-2 text-success'></i>
                              ) : (
                                <img
                                  src='/media/icons/no-pickup.png'
                                  alt='notauth'
                                  className='w-15px h-15px object-contain mx-2'
                                />
                              )}
                              {item.contactName}
                            </button>
                          </h2>
                          <div
                            id={`${cell.row.original.kidId}-${item.contactId}`}
                            className='accordion-collapse collapse'
                            aria-labelledby={`${cell.row.original.kidId}`}
                            data-bs-parent={`#${cell.row.original.kidId}`}
                          >
                            <div className='accordion-body pt-1 pl-0'>
                              <p className='m-0  fs-7'>{item.contactRelationTypeName}</p>
                              <p className='m-0  fs-7'>{item.emailAddress}</p>
                              <p className='m-0  fs-7'>{item.cellPhoneNumber}</p>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className=' '>
                <CustomAttribuesCell row={cell.row.original} />
              </div>
            </div>
          </div>
        )
      },
    },
  ]
}
export {_mobileColumns}
