import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import moment from 'moment-timezone'
import DateEditableCell from '../../../../../../general-components/date-editable/DateEditableCell'
import {UserCustomHeader} from '../../../../../../general-components/table-custom-header/UserCustomHeader'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import {useAuth} from '../../../../../auth'
import TextEditableCell from '../../../../../../general-components/text-editable/TextEditableCell'
import {useIntl} from 'react-intl'
function _columns({handleDeleteAbsense, editCell}: any) {
  const {currentUser} = useAuth()
  const intl = useIntl()
  return [
    // {
    //   Header: (props: any) => <UserSelectionHeader tableProps={props} />,
    //   id: 'selection',
    //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index]} />,
    //   width: 5,
    // },
    {
      id: 'avatar',
      width: 2,
      accessor: 'avatar',
      Cell: ({cell}: any) => {
        const row = cell?.row?.original

        return (
          <AvatarWithLetters
            image={row.imagePath}
            alt={`${row.kid?.firstName?.charAt(0)?.toUpperCase()} ${row.kid?.lastName
              ?.charAt(0)
              ?.toUpperCase()}`}
            small
          />
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.NAME' />,
      id: 'Name',
      accessor: 'kid.firstName',
      sortable: true,
      width: 20,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        return (
          <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row mb-md-0 mb-8'>
            <div className='ml-4  d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center mb-2'>
                <h5 className='mb-0'>{row.kid?.firstName + ' ' + row.kid?.lastName}</h5>
                {!row?.employeeId && (
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={(props) => (
                      <Tooltip id='button-tooltip' {...props}>
                        <h5 className='border-bottom  border-dark'>
                          {intl.formatMessage({id: 'KIDLING.ADDED_BY_PARENT'})}
                        </h5>
                      </Tooltip>
                    )}
                  >
                    <button className='btn text-muted p-0 mx-4'>
                      <img
                        src='/media/icons/parent.png'
                        alt='parents'
                        className='w-20xp h-20px object-contain'
                      />
                    </button>
                  </OverlayTrigger>
                )}
              </div>

              {row.classes.length > 0 && (
                <p className='m-0 text-muted d-flex align-items-center fs-7'>
                  <i className='fa-solid fa-people-roof me-2'></i>
                  {row.classes.map((cc: any, index: number) =>
                    cc.employeeId === currentUser?.employee?.employeeId && cc.classesName
                      ? `${cc.classesName} ${row.classes.length - 1 > index ? ',' : ' '} `
                      : ''
                  )}
                </p>
              )}
              <div className='d-block'>
                <div className='d-flex align-items-center'>
                  {row.kid?.dateOfBirth && (
                    <p className='m-0 text-muted d-flex align-items-center fs-7'>
                      <i className='fa-solid fa-cake-candles me-2'></i>
                      {moment(row.kid?.dateOfBirth).format('DD.MM.YYYY')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.FROM' />,
      accessor: 'fromDate',
      id: 'in',
      sortable: true,
      Cell: ({cell}: any) => {
        const handleStartTimeChange = (value: any) => {
          const attendance = cell.row.original
          editCell(
            {
              ...attendance,
              fromDate: attendance.fromDate,
              toDate: attendance.toDate,
            },
            'fromDate',
            value
          )
        }
        return (
          <DateEditableCell
            value={cell.row.original.fromDate ? moment(cell.row.original.fromDate) : ''}
            onChange={handleStartTimeChange}
          />
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.TO' />,

      id: 'out',
      sortable: true,
      accessor: 'toDate',
      Cell: ({cell}: any) => {
        const attendance = cell.row.original
        const handleStartTimeChange = (value: any) => {
          editCell(
            {
              ...attendance,
              fromDate: attendance.fromDate,
              toDate: attendance.toDate,
            },
            'toDate',
            value
          )
        }
        return (
          <DateEditableCell
            value={cell.row.original.toDate ? moment(cell.row.original.toDate) : ''}
            onChange={handleStartTimeChange}
            minDate={moment(attendance.fromDate).toDate()}
          />
        )
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.LASTUPDATE' />
      ),

      id: 'lastupdate',
      sortable: true,
      accessor: 'updatedDateTime',
      Cell: ({cell}: any) => {
        const handleStartTimeChange = (value: any) => {
          const absense = cell.row.original
          editCell(
            {
              ...absense,
              fromDate: absense.fromDate,
              toDate: absense.toDate,
            },
            'updatedDateTime',
            value
          )
        }
        return (
          <DateEditableCell
            value={
              cell.row.original.updatedDateTime ? moment(cell.row.original.updatedDateTime) : ''
            }
            onChange={handleStartTimeChange}
          />
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.REASON' />,
      id: 'reason',
      accessor: 'reason',
      width: 50,
      sortable: true,
      Cell: ({cell}: any) => {
        return (
          <div>
            {cell.row.original?.kidAbsentReasonName && (
              <>
                <i className='fa-solid fa-note-sticky text-primary'></i>{' '}
                {cell.row.original?.kidAbsentReasonName}
              </>
            )}
          </div>
        )
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.EMPLOYEE_REMARKS' />
      ),

      id: 'employeeNote',
      sortable: true,
      Cell: ({cell}: any) => {
        const attendance = cell.row.original
        const handleNoteChange = (value: any) => {
          editCell(
            {
              ...attendance,
              dateTimeOut: attendance.dateTimeOut,
            },
            'employeeRemarks',
            value
          )
        }
        return (
          <TextEditableCell
            value={cell.row.original.employeeRemarks ? cell.row.original.employeeRemarks : ''}
            onChange={handleNoteChange}
          />
        )
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.CONTACT_REMARKS' />
      ),

      id: 'contactRemarks',
      sortable: true,
      Cell: ({cell}: any) => {
        return <p>{cell.row.original.contactRemarks}</p>
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='MENU.ACTIONS' className='  ' />
      ),
      id: 'Attributes',
      Cell: ({cell}: any) => {
        return (
          <div className='d-flex   align-items-center'>
            <OverlayTrigger
              placement='left'
              delay={{show: 250, hide: 400}}
              overlay={(props: any) => (
                <Tooltip id='button-tooltip' {...props}>
                  Delete
                </Tooltip>
              )}
            >
              <button
                className='btn text-muted p-0 me-4'
                type='button'
                onClick={() => handleDeleteAbsense(cell.row.original.kidAbsentId)}
              >
                <i className='fa-solid fa-trash text-danger   fs-3'></i>
              </button>
            </OverlayTrigger>
          </div>
        )
      },
    },
  ]
}

export default _columns
