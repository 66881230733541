/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr'
import {useAuth} from '../../modules/auth'
import {useChatContext} from '../../store/context/ChatProvider'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'

function ChatInitializer() {
  const {setConnection, setOnlineUsers, connection} = useChatContext()
  const {mobileConfiguration} = useAppContext()
  const {auth, socketUrl} = useAuth()

  useEffect(() => {
    if (
      ((connection?._connectionState !== 'Connected' &&
        connection?._connectionState !== 'Connecting') ||
        !connection) &&
      auth &&
      mobileConfiguration?.ChatGroup &&
      mobileConfiguration?.ChatGroup[0]?.showInEducatorApp &&
      !!socketUrl
    )
      startConnection()
  }, [connection, auth, mobileConfiguration, socketUrl])

  const startConnection = async () => {
    try {
      if (!!socketUrl) {
        const connection: any = new HubConnectionBuilder()
          .withUrl(socketUrl + 'chatHub', {
            accessTokenFactory: () => `${auth?.token}`,
          })
          .configureLogging(LogLevel.Error)
          .build()
        setConnection(connection)
        await connection.start()

        connection.on('OnlineUsers', function (response: any) {
          setOnlineUsers(response)
        })
      }
    } catch (e) {}
  }
  return <></>
}

export default ChatInitializer
