/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../../auth'
import {KidDeRegistration} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {getAlldeRegisterMeal, getMealTimes} from '../../../../../../actions/meal'
import moment from 'moment-timezone'
import {handleAddCheckBox} from '../../../../../utils/common'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {Link} from 'react-router-dom'
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {momentDateFormat} from '../../../../../../config/constants'

type Props = {
  isUserLoading: boolean
}

const KidDeRegisterationModalForm: FC<Props> = ({isUserLoading}) => {
  const company = window.location.pathname.split('/')[1]
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {setMealtime, mealtime, mobileConfiguration} = useAppContext()
  const [kidsDeregistrations, setKidsDeregistrations] = useState([])
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    notes: '',
    date: new Date(),
    meal: [],
  })

  useEffect(() => {
    if (!mealtime) readMealTimes()
  }, [])

  const kidSchema = Yup.object().shape({
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    meal: Yup.mixed(),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        let kidIds: number[] = []
        selected.map((kid) => kidIds.push(kid.kidId))

        values.meal?.map(async (item: number, index: number) => {
          const data = {
            kidId: kidIds[0],
            employeeId: currentUser?.employee?.employeeId,
            mealTimeId: item,
            recordDateTime: moment().format('yyyy-MM-DDTHH:mm:ss'),
            deRegisteredForDate: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            remarks: values.notes,
            mobileConfigurationId:
              mobileConfiguration?.MealSubscription[0]?.mobileConfigurationMasterId,
            notifyByEmail: false,
          }
          const resp = await KidDeRegistration(data)
          if (index === values.meal.length - 1) {
            if (!!resp) {
              // clearSelected()
              toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
              // cancel(true)
            } else {
              toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
            }
          }
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        reset()
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    readDeregisterations()
  }, [selected, formik.values])
  const reset = () => formik.resetForm()
  const readMealTimes = async () => {
    const resp = await getMealTimes()
    if (resp) setMealtime(resp)
  }
  const readDeregisterations = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'

    const res = await getAlldeRegisterMeal(
      filter,
      currentUser?.employee?.employeeId || 0,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )

    setKidsDeregistrations(res)
  }
  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map(
              (item: any, index: number) =>
                index === 0 && (
                  <div
                    className='d-flex flex-column justify-content-center align-items-center'
                    key={index}
                  >
                    <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                      {item.imagePath ? (
                        <img
                          src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                          alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                            .charAt(0)
                            .toUpperCase()}`}
                          className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                        />
                      ) : (
                        <div
                          className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                          style={{
                            backgroundColor: `#F2E5E5`,
                          }}
                        >
                          {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                            .charAt(0)
                            .toUpperCase()}`}
                        </div>
                      )}
                    </div>
                    <div className='dispaly-6'>{item.firstName}</div>
                  </div>
                )
            )}
          </div>
          {kidsDeregistrations?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {kidsDeregistrations?.map((item: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.deregistrations?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.mealTimeName}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal.remarks}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.deRegisteredForDate).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <Link
                                to={
                                  company
                                    ? `/${company}/kid-management/deregistration`
                                    : '/kidling/kid-management/deregistration'
                                }
                                className='btn btn-light p-1 px-4 mt-2'
                              >
                                <i className='fa-solid fa-arrow-right'></i>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row mt-7 mb-4'>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
                minDate={moment().endOf('day').toDate()}
              />
            </div>
          </div>

          <div className='fv-row  '>
            <div className='row'>
              <div className='col-6'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.MEAL_TIME'})}
                </label>
              </div>
            </div>
            <div className='d-flex   flex-md-row flex-column flex-wrap gap-md-4'>
              {mealtime.map((time: any) => {
                let timeDate = moment(time.dailyRegisterBeforeTime)
                  .date(moment().date())
                  .month(moment().month())
                  .year(moment().year())

                const isDisabled =
                  moment.duration(moment(timeDate).diff(new Date())).asMinutes() < 0 &&
                  Math.trunc(
                    moment.duration(moment(timeDate).diff(formik.values.date)).asDays()
                  ) === 0
                const isChecked = formik.values.meal?.find((i: number) => i === time.mealTimeId)

                return (
                  <div className='d-flex flex-column justify-content-start  '>
                    <label
                      className={`btn btn-outline w-100 btn-outline-dashed btn-active-light-primary  text-start px-2 pb-5 my-2  d-flex align-items-center position-relative  ${
                        isChecked && 'active'
                      } ${isDisabled && 'disabled__btn'}
                      `}
                      data-kt-button='true'
                      key={time.mealTimeId}
                    >
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start  '>
                        <input
                          className={`form-check-input  `}
                          type='checkbox'
                          name='meal'
                          checked={isChecked}
                          onChange={() =>
                            formik.setFieldValue(
                              'meal',
                              handleAddCheckBox(formik.values.meal, time.mealTimeId)
                            )
                          }
                          disabled={isDisabled}
                        />
                      </span>

                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>
                          {time.mealTimeName}
                        </span>
                      </span>
                      <span className='fs-7   text-muted d-block position-absolute bottom-0 end-0 py-0 px-2'>
                        {moment(time.dailyRegisterBeforeTime).format('HH:mm')}
                      </span>
                    </label>
                    {
                      <p className='text-danger fs-7'>
                        {isDisabled
                          ? intl.formatMessage({id: 'KIDLING.UNSUBSCRIPTION_FINISHED'})
                          : ''}
                      </p>
                    }
                  </div>
                )
              })}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('notes')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.notes && formik.errors.notes},
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              name='notes'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {' '}
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  )
}

export {KidDeRegisterationModalForm}
