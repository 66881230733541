/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'
import {useAuth} from '../modules/auth'
import {deleteKidMeal, getKidsMealTimes} from '../../actions/meals'
import moment from 'moment'
import {toast} from 'react-toastify'

const useMealTimes = () => {
  const {
    kidsMealTimes,
    setKidsMealTimes,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesFilters,
    setInternalPagesDatesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [date, setDate] = useState<any>({})

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.mealtimes = {
      ...newFilters.mealtimes,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked) {
      readMealTimes()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    let temp: any = []
    kidsMealTimes.forEach((item: any) => {
      let mealTimes: any = []
      item.kidMealTimes?.forEach((meal: any) =>
        mealTimes.push({
          id: meal.kidMealTimeId,
          title: meal.foodItemName,
          tag: meal.mealPortionName,
          date: meal.dateTime,
          desc: meal.notes,
          subtitle: meal.mealTimeName,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: mealTimes,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsMealTimes])

  const readMealTimes = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.mealtimes.classValue && !internalPagesFilters.mealtimes.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.mealtimes.classValue)
      filter = `classesId=${internalPagesFilters.mealtimes.classValue}`
    if (internalPagesFilters.mealtimes.classValue && internalPagesFilters.mealtimes.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.mealtimes.searchTerm)
      filter = filter + internalPagesFilters.mealtimes.searchTerm

    const res = await getKidsMealTimes(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.mealtimes.From
        ? moment(internalPagesDatesFilters.mealtimes.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.mealtimes.To
        ? moment(internalPagesDatesFilters.mealtimes.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsMealTimes(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidMeal(id)
      if (resp) {
        await readMealTimes()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.mealtimes = {
      ...newFilters.mealtimes,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  return {
    readMealTimes,
    doDelete,
    data,
    searchTerm,
    setSearchTerm,
    clicked,
    setClicked,
    handleDateChange,
  }
}

export default useMealTimes
