import React, {useEffect} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import Messages from './Messages'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

function MessagesWrapper() {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.Messaging[0]?.showInEducatorApp) {
      navigate('/')
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileConfiguration])

  return (
    <ListViewProvider>
      <Messages />
    </ListViewProvider>
  )
}

export default MessagesWrapper
