/* eslint-disable react-hooks/exhaustive-deps */
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {FC, ReactElement, useEffect} from 'react'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {getAllKids, getClassesByEmployee} from '../../../../../../../actions/kids'
import {useAuth} from '../../../../../auth'
import {UsersListFilter} from './UsersListFilter'
import {useIntl} from 'react-intl'
import {useCompanyContext} from '../../../../../../../_metronic/layout/core/CompanyProvider'

type Props = {
  show: boolean
}
const UsersListHeader: FC<Props> = ({show}): ReactElement => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setGlobalLoader, mainSearchTerm, setKidsList, setClasses, setFilters}: any =
    useAppContext()
  const {currentCompany} = useCompanyContext()

  useEffect(() => {
    if (currentCompany) {
      filterData()
      getClassesList()
    }
  }, [currentCompany])

  const filterData = async () => {
    const classValue: any = JSON.parse(localStorage.getItem('kidling-class') || '')

    const status: any = JSON.parse(localStorage.getItem('kidling-status') || '')
    const photoPremit: any = JSON.parse(localStorage.getItem('kidling-photoPremit') || '')

    let temp = ''
    if (!!classValue) {
      classValue?.forEach((item: any, i: number) => {
        if (!!item.value) {
          if (i === 0) temp = '('
          temp += `Classes.any(x => x.classesId = ${item.value})`
        }

        if (i !== classValue?.length - 1) temp += ' OR '
        else if (!!item.value) temp += ')'
      })
    }
    if (!!status && status?.value !== '') {
      if (temp !== '') temp += ' AND '
      temp += ` presentStatus=${status.value}`
    }

    if (!!mainSearchTerm) {
      if (temp !== '') temp += ' AND '
      temp += `kidName.toLower().contains("${mainSearchTerm.toLowerCase().trim()}")`
    }

    if (!!photoPremit && photoPremit?.value !== '') {
      if (temp !== '') temp += ' AND '
      temp += `allowTakePhoto=${photoPremit.value === 0 ? false : true}`
    }

    if (temp === '') temp = '1=1'

    setFilters(temp)
    readData(temp)
  }

  const readData = async (filtersFields: any) => {
    setGlobalLoader(true)
    const data = await getAllKids({
      filter: filtersFields,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (data) setKidsList(data)
    setGlobalLoader(false)
  }

  const getClassesList = async () => {
    const resp = await getClassesByEmployee()

    if (resp) {
      let temp: any = [{value: '', label: intl.formatMessage({id: 'COMMON.ALL'})}]
      resp?.map((option: any, index: number) =>
        temp.push({value: option.classesId, label: option.classesName})
      )
      setClasses(temp)
    }
  }

  return show ? (
    <div className='d-flex align-items-sm-center align-items-start flex-wrap flex-sm-row flex-column w-100 w-md-auto'>
      <UsersListSearchComponent filterData={filterData} />
      <div
        className='d-flex justify-content-start w-100 w-lg-auto'
        data-kt-user-table-toolbar='base'
      >
        <UsersListFilter filterData={filterData} />
      </div>
    </div>
  ) : (
    <></>
  )
}

export {UsersListHeader}
