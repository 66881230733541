/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {InboxSelectionHeader} from './InboxSelectionHeader'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useListView} from '../../../core/ListViewProvider'
import {toast} from 'react-toastify'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../../../auth'
import {getAllMessagesCounts, updateReceivedMessages} from '../../../../../../../actions/messages'
import ErrorText from '../../../../../../general-components/error-text/ErrorText'
import {useNavigate} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'
import SearchBox from '../../../../../../general-components/search-textbox/SearchBox'

function InboxTableHeader({getAllMessages, data}: any) {
  const company = window.location.pathname.split('/')[1]
  const navigate = useNavigate()
  const isMobile = useMediaQuery({
    query: '(max-width:776px)',
  })
  const intl = useIntl()
  const {setGlobalLoader2, messagesFilters, setMessagesFilters, setTabMessagesCounts} =
    useAppContext()
  const {selected, clearSelected} = useListView()
  const [showDelete, setShowDelete] = useState(false)
  const [filterCheck, setFilterCheck] = useState('')
  const {currentUser} = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)

  const filterMenuItems = [
    {id: 'all', name: intl.formatMessage({id: 'COMMON.ALL'})},
    {id: 'read', name: intl.formatMessage({id: 'KIDLING.READ'})},
    {id: 'unread', name: intl.formatMessage({id: 'KIDLING.UNREAD'})},
    {id: 'starred', name: intl.formatMessage({id: 'KIDLING.STARRED'})},
    {id: 'unstarred', name: intl.formatMessage({id: 'KIDLING.UNSTARRED'})},
  ]

  const filterData = async () => {
    const newFilters: any = {...messagesFilters}

    newFilters.inbox = {
      ...newFilters.inbox,
      searchTerm: `notificationTitle.toLower().contains("${searchTerm
        ?.toLowerCase()
        .trim()}") || usersName.toLower().contains("${searchTerm?.toLowerCase().trim()}") `,
    }

    setClicked(false)

    setMessagesFilters(newFilters)
  }

  useEffect(() => {
    filterData()
    if (searchTerm === '') setClicked(true)
  }, [searchTerm])

  useEffect(() => {
    if (clicked || filterCheck) {
      let filter: string = ''
      if (!filterCheck) filter = '1=1'
      if (filterCheck) {
        switch (filterCheck) {
          case 'read':
            filter = `readDateTime!=null`
            break
          case 'unread':
            filter = `readDateTime=null`
            break
          case 'starred':
            filter = `pinned=true`
            break
          case 'unstarred':
            filter = `pinned=false`
            break
          default:
            filter = '1=1'
            break
        }
      }
      const temp = `${filter}&${messagesFilters.inbox.searchTerm}`

      getAllMessages(temp)
      setClicked(false)
    }
  }, [clicked, filterCheck])

  useEffect(() => {
    const handleSubmit = (event: any) => {
      if (event.keyCode === 13) {
        setClicked(true)
      }
    }
    window.addEventListener('keydown', handleSubmit)

    return () => {
      window.removeEventListener('keydown', handleSubmit)
      setClicked(false)
    }
  }, [])

  const handleRefresh = async () => {
    setGlobalLoader2(true)
    getAllMessages()
    clearSelected()
    setGlobalLoader2(false)
  }

  const handleDelete = async () => {
    const data = {ids: selected, notificationStatusId: 3}
    const resp = await updateReceivedMessages(data)

    if (resp) {
      getAllMessages()
      clearSelected()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const markUnread = async () => {
    const data = {
      ids: selected,
      isRead: false,
    }

    await updateReceivedMessages(data)

    const count = await getAllMessagesCounts(currentUser?.usersId || 0)
    if (count) setTabMessagesCounts(count)
    getAllMessages()

    clearSelected()
  }

  const markread = async () => {
    const data = {
      ids: selected,
      isRead: true,
    }

    await updateReceivedMessages(data)

    const count = await getAllMessagesCounts(currentUser?.usersId || 0)
    if (count) setTabMessagesCounts(count)
    getAllMessages()

    clearSelected()
  }

  return (
    <div className='card-header align-items-center py-5  px-3 w-100'>
      {isMobile ? (
        <div className='w-100'>
          <div className='d-flex align-items-center justify-content-between flex-wrap gap-2 w-100'>
            <div
              className='d-flex align-items-center position-relative form-control form-control-sm form-control-solid py-0 '
              style={{width: '85%'}}
            >
              <i className='fa-solid fa-magnifying-glass text-muted'></i>
              <input
                type='text'
                data-kt-inbox-listing-filter='search'
                className='form-control form-control-sm form-control-solid mw-100   ps-4'
                placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <a
              href='#!'
              className='btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-placement='top'
              title='Toggle inbox menu'
              id='kt_inbox_aside_toggle'
            >
              <i className='fa-solid fa-bars fs-3 m-0'></i>
            </a>
          </div>
          <div className='d-flex flex-wrap gap-2 my-2'>
            <div className='form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7'>
              <InboxSelectionHeader data={data} />
            </div>

            <button
              type='button'
              className='btn btn-sm btn-icon btn-light btn-active-light-primary '
              onClick={handleRefresh}
              title={intl.formatMessage({id: 'KIDLING.RELOAD'})}
            >
              <i className='fa-solid fa-arrows-rotate fs-3'></i>
            </button>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-light btn-active-light-primary'
              onClick={() => {
                if (selected.length > 0) setShowDelete(true)
              }}
              title={intl.formatMessage({id: 'KIDLING.DELETE'})}
            >
              <i className='fa-solid fa-trash fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title={intl.formatMessage({id: 'KIDLING.MARK_AS_UNREAD'})}
              onClick={markUnread}
            >
              <i className='fa-solid fa-envelope fs-5 m-0'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title={intl.formatMessage({id: 'KIDLING.MARK_AS_READ'})}
              onClick={markread}
            >
              <i className='fa-solid fa-envelope-open fs-5 m-0'></i>
            </button>

            <div>
              <div className='dropdown'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-light btn-active-light-primary dropdown-toggle '
                  data-bs-toggle='dropdown'
                >
                  {/* <i className='fa-solid fa-angle-down '></i> */}
                </button>
                <ul className='dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'>
                  {filterMenuItems.map((item: any, index: number) => (
                    <div className='menu-item px-3 d-flex align-items-center' key={index}>
                      <input
                        type='checkbox'
                        checked={filterCheck === item.id}
                        onChange={() => setFilterCheck(item.id)}
                      />
                      <a href='#!' className='menu-link px-3'>
                        {item.name}
                      </a>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <button
            className='btn btn-primary btn-active-light-primary mt-2 px-2 py-2 w-100 '
            onClick={() =>
              navigate(company ? `/${company}/messages/compose` : '/kidling//messages/compose')
            }
          >
            {intl.formatMessage({id: 'KIDLING.HOME.NEW_MESSAGE'})}
          </button>
        </div>
      ) : (
        <div className='w-100 d-flex align-items-center justify-content-between'>
          <div className='d-flex flex-wrap gap-2'>
            <div className='form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7'>
              <InboxSelectionHeader data={data} />
            </div>

            <button
              type='button'
              className='btn btn-sm btn-icon btn-light btn-active-light-primary '
              onClick={handleRefresh}
              title={intl.formatMessage({id: 'KIDLING.RELOAD'})}
            >
              <i className='fa-solid fa-arrows-rotate fs-3'></i>
            </button>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-light btn-active-light-primary'
              onClick={() => {
                if (selected.length > 0) setShowDelete(true)
              }}
              title={intl.formatMessage({id: 'KIDLING.DELETE'})}
            >
              <i className='fa-solid fa-trash fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title={intl.formatMessage({id: 'KIDLING.MARK_AS_UNREAD'})}
              onClick={markUnread}
            >
              <i className='fa-solid fa-envelope fs-5 m-0'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title={intl.formatMessage({id: 'KIDLING.MARK_AS_READ'})}
              onClick={markread}
            >
              <i className='fa-solid fa-envelope-open fs-5 m-0'></i>
            </button>

            <div>
              <div className='dropdown'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-light btn-active-light-primary dropdown-toggle'
                  data-bs-toggle='dropdown'
                >
                  {/* <i className='fa-solid fa-angle-down '></i> */}
                </button>
                <ul className='dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'>
                  {filterMenuItems.map((item: any, index: number) => (
                    <div className='menu-item px-3 d-flex align-items-center' key={index}>
                      <input
                        type='checkbox'
                        checked={filterCheck === item.id}
                        onChange={() => setFilterCheck(item.id)}
                      />
                      <a href='#!' className='menu-link px-3'>
                        {item.name}
                      </a>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center gap-2  min-w-200px min-w-lg-250px min-w-xxl-500px'>
            <SearchBox value={searchTerm} onChange={setSearchTerm} />
            <a
              href='#!'
              className='btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-placement='top'
              title='Toggle inbox menu'
              id='kt_inbox_aside_toggle'
            >
              <i className='fa-solid fa-bars fs-3 m-0'></i>
            </a>
          </div>
        </div>
      )}

      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          handleDelete()
        }}
        onCancel={() => setShowDelete(false)}
      />
    </div>
  )
}

export default InboxTableHeader
