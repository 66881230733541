import {KidMedicalModalForm} from './KidMedicalModalForm'
import {FC, ReactElement} from 'react'

interface Props {
  setEditModal?: any
  data?: any
}
const KidMedicalModalFormWrapper: FC<Props> = ({setEditModal, data}): ReactElement => {
  return <KidMedicalModalForm isUserLoading={false} setEditModal={setEditModal} data={data} />
}

export {KidMedicalModalFormWrapper}
