import {ActivityFilter} from './ActivityFilter'

const Activitytoolbar = () => {
  return (
    <div
      className='d-flex flex-md-row flex-column  w-100  w-md-auto '
      data-kt-user-table-toolbar='base'
    >
      <ActivityFilter />
    </div>
  )
}

export {Activitytoolbar}
