/* eslint-disable react-hooks/exhaustive-deps */
import {toast} from 'react-toastify'
import {deleteKidDrink, getKidsDrinks} from '../../actions/drinks'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useAuth} from '../modules/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'

const useDrinks = () => {
  const {
    kidsDrinks,
    setKidsDrinks,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [date, setDate] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked) {
      readDrinks()
      setClicked(false)
    }
  }, [clicked])
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.drinks = {
      ...newFilters.drinks,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsDrinks.forEach((item: any) => {
      let Drinks: any = []
      item.kidDrinks?.forEach((drink: any) =>
        Drinks.push({
          id: drink.kidDrinkId,
          title: drink.kidDrinkName,
          date: drink.dateTime,
          tag: drink.kidDrinkMl,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: Drinks,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kidsDrinks])

  const readDrinks = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.drinks.classValue && !internalPagesFilters.drinks.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.drinks.classValue)
      filter = `classesId=${internalPagesFilters.drinks.classValue}`
    if (internalPagesFilters.drinks.classValue && internalPagesFilters.drinks.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.drinks.searchTerm)
      filter = filter + internalPagesFilters.drinks.searchTerm

    const res = await getKidsDrinks(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.drinks.From
        ? moment(internalPagesDatesFilters.drinks.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.drinks.To
        ? moment(internalPagesDatesFilters.drinks.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )
    setKidsDrinks(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidDrink(id)
      if (resp) {
        await readDrinks()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.drinks = {
      ...newFilters.drinks,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {
    data,
    doDelete,
    readDrinks,
    handleDateChange,
    setSearchTerm,
    searchTerm,
    clicked,
    setClicked,
  }
}
export default useDrinks
