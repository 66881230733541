/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {NoRecords} from '../../../general-components/NoRecords'
import {KTCard} from '../../../../_metronic/helpers'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {TitleHeader} from './TitleHeader'
import {UsersListLoadingCenter} from '../../../general-components/loading/UsersListLoadingCenter'
import DownloadImagesButton from '../../../general-components/download-images/DownloadImagesButton'
import DashboardImageCard from '../../../general-components/Dashboard-image-card/DashboardImageCard'
import ImagesSlider from '../../../general-components/Images-slider/ImagesSlider'
import useMedia from '../../../hooks/useMedia'

const Media: FC = () => {
  const intl = useIntl()
  const {globalLoader, selectedImages, mobileConfiguration, internalPagesDatesFilters} =
    useAppContext()
  const {data, meta, load, loadMore, pageIndex, pageSize, setPageIndex, isLoadingMore} = useMedia()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (!mobileConfiguration?.KidMedia[0]?.showInEducatorApp) {
      navigate('/')
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileConfiguration])

  useEffect(() => {
    if (!globalLoader) load()
  }, [searchParams, internalPagesDatesFilters])

  useEffect(() => {
    if (!globalLoader && pageIndex > 1) loadMore()
  }, [pageIndex])

  useEffect(() => {
    const onscroll = () => {
      const scrolledTo = window.scrollY + window.innerHeight
      const isReachBottom = document.body.scrollHeight <= scrolledTo + 100

      if (isReachBottom) {
        if (meta.total > pageIndex * pageSize && !isLoadingMore) setPageIndex((prev) => prev + 1)
      }
    }
    window.addEventListener('scroll', onscroll)
    return () => {
      window.removeEventListener('scroll', onscroll)
    }
  }, [meta])

  return (
    <KTCard>
      <div className='card-header border-0 p-md-4 p-3  d-flex flex-column align-items-center w-100 py-8'>
        <TitleHeader title={intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})} />
        {/* begin::Col */}

        <div className='row w-100 gy-6 p-0 justify-content-center'>
          <div className='row g-4 justify-content-center'>
            {data?.map((item: any, index: number) => (
              <div className={`col-12 col-sm-6 col-md-4`} key={item?.MediaFiles[0]?.FilePath}>
                {item?.MediaFiles?.length > 1 ? (
                  <div className='shadow rounded h-100 w-100' key={item?.MediaFiles[0]?.FilePath}>
                    <ImagesSlider data={item} readData={load} />
                  </div>
                ) : (
                  item?.MediaFiles?.length === 1 && (
                    <DashboardImageCard
                      date={item?.CreatedDate}
                      image={item?.MediaFiles[0]?.FilePath}
                      userName={item?.UserName}
                      userImagePath={item?.UserImagePath}
                      details={item?.MediaFiles[0].KidMediaFileDescription}
                      title={item?.MediaFiles[0].Title}
                      KidMediaFileName={item?.MediaFiles[0].KidMediaFileName}
                      locked={item?.MediaFiles[0].Confidential}
                      Hashtags={item?.MediaFiles[0]?.Hashtags}
                      KidMediaFileId={item?.MediaFiles[0]?.KidMediaFileId}
                      readData={load}
                      hideCheckbox={false}
                      enableDelete
                      kidsGroup={item.Kids}
                    />
                  )
                )}
              </div>
            ))}
          </div>

          {selectedImages?.length > 0 && <DownloadImagesButton />}
        </div>

        {isLoadingMore && (
          <div className='d-flex justify-content-center w-100 my-8'>
            <span className=' d-flex align-items-center'>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
              <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
            </span>
          </div>
        )}

        {!globalLoader && data?.length === 0 && <NoRecords />}
        {globalLoader && <UsersListLoadingCenter />}
        {/* end::Col */}
      </div>
    </KTCard>
  )
}

export default Media
