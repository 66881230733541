/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, FC, ReactElement} from 'react'
import {selectStyle, selectStyleDarkMode} from '../../../../utils/select-style'
import makeAnimated from 'react-select/animated'
import SelectWithAllOption from '../../../../general-components/select-with-all/SelectWithAllOption'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import AvatarWithLetters from '../../../../general-components/avatar-with-letters'
import {useAuth} from '../../../auth'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {toast} from 'react-toastify'
import {getAllEmployeesByKita} from '../../../../../actions/employee'
import {addNewChat, addUsersToChat} from '../../../../../actions/chat'
import {getAllKids, getClassesByEmployee} from '../../../../../actions/kids'
import {useCompanyContext} from '../../../../../_metronic/layout/core/CompanyProvider'

const animatedComponents = makeAnimated()

interface Props {
  showModal: boolean
  setShowModal: any
  chatId?: number
}
const NewChatDialog: FC<Props> = ({showModal, setShowModal, chatId}): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [selectedClasses, setSelectedClasses] = useState<any>([])
  const {employees, setEmployees, classes, setClasses, setKidsList} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [selectedEmployee, setSelectedEmployee] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [selectedKids, setSelectedKids] = useState<any>([])
  const [filterdKids, setFilterdKids] = useState([])
  const [showClassToggle, setShowClassToggle] = useState(true)
  const [showKidToggle, setShowKidToggle] = useState(false)

  useEffect(() => {
    if (currentCompany) {
      readEmployees()
      getClassesList()
      readKids()
    }
  }, [currentCompany])

  useEffect(() => {
    if (selectedKids?.length === 1) setGroupName(selectedKids[0]?.label)
  }, [selectedKids])

  useEffect(() => {
    setSelectedEmployee(employees.filter((i: any) => i?.usersId === currentUser?.usersId))
  }, [employees, currentUser])

  const getClassesList = async () => {
    const resp = await getClassesByEmployee()
    if (resp) {
      let temp: any = []
      resp?.map((option: any, index: number) =>
        temp.push({value: option.classesId, label: option.classesName})
      )
      setClasses(temp)
    }
  }

  const readEmployees = async () => {
    try {
      let filter = '('
      currentUser?.employee?.companyIds?.forEach((id: number) => {
        if (filter !== '(') filter += ' OR '
        filter += ` CompanyIds.Any(x => x == ${id}) `
      })
      filter += ')'

      const resp = await getAllEmployeesByKita(
        `employeeReceiveCommunicationFromEmployees=true AND ${filter} `
      )

      let temp: any = []
      resp?.map(
        (item: any) =>
          item.employeeId !== currentUser?.employee?.employeeId &&
          item.active &&
          temp.push({
            value: item.employeeId,
            label: item.employeeName,
            email: item.email,
            imagePath: item.imagePath,
            usersId: item.usersId,
            companyId: item.companyId,
          })
      )

      temp.sort((a: any, b: any) => a.label.localeCompare(b.label))

      setEmployees(temp)
    } catch (e) {}
  }

  const close = () => {
    setShowModal(false)
    setSelectedClasses([])
    setSelectedEmployee([])
    setSelectedKids([])
    setGroupName('')
  }

  const addChat = async () => {
    setIsLoading(true)
    let classesIds: any = [],
      kidIds: any = [],
      employeeIds: any = [currentUser?.employee?.employeeTypeId]
    selectedClasses?.map((i: any) => classesIds.push(i?.value))
    selectedKids?.map((i: any) => kidIds.push(i?.value))
    selectedEmployee?.map((i: any) => employeeIds.push(i?.value))
    if (chatId) {
      const data = {
        chatGroupId: chatId,
        classesIds,
        employeeIds,
        kidIds,
      }

      await addUsersToChat(data)

      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      close()
    } else {
      const data = {
        chatGroupName: groupName,
        isPublic: false,
        companyId: selectedEmployee?.companyId,
        classesIds,
        employeeIds,
        kidIds,
      }

      const resp = await addNewChat(data)
      if (resp) {
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        close()
      }
    }

    setIsLoading(false)
  }

  const readKids = async () => {
    const data = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (data) {
      setKidsList(data)
      let temp: any = []

      data?.forEach((item: any) => {
        let myClasses: any = []
        item.classes.map(
          (cc: any, index: number) =>
            cc.employeeId === currentUser?.employee?.employeeId &&
            cc.classesName &&
            myClasses.push(cc)
        )

        temp.push({
          kidId: item.kidId,
          value: item.kidId,
          label: item.kidName,
          classes: myClasses,
          imagePath: item.imagePath,
          hasUserContacts: item.contacts.find((i: any) => i.usersId && i.usersId !== 0)
            ? true
            : false,
          contacts: item.contacts,
        })
      })

      temp.sort((x: any, y: any) => {
        // true values first
        // return x === y ? 0 : x ? -1 : 1
        // false values first
        return x.hasUserContacts === y.hasUserContacts ? 0 : x.hasUserContacts ? 1 : -1
      })

      setFilterdKids(temp)
    }
  }

  return (
    <Modal isOpen={showModal} toggle={close}>
      <ModalHeader toggle={close}>
        {intl.formatMessage({id: chatId ? 'KIDLIGN.ADD_USERS_TO_GROUP' : 'KIDLING.CHAT_NEW_CHAT'})}
      </ModalHeader>
      <ModalBody>
        <div className='d-flex align-items-center'>
          <label className='form-check form-switch form-check-custom form-check-solid   p-6'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='classCheckbox'
              checked={showClassToggle}
              onChange={() => {
                setShowClassToggle(!showClassToggle)
                setShowKidToggle(false)
                setSelectedKids([])
              }}
            />
            <span className='form-check-label text-body fs-7'>
              {intl.formatMessage({id: 'KIDLING.HOME.CLASS'})}
            </span>
          </label>
          <label className='form-check form-switch form-check-custom form-check-solid   p-6'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='classCheckbox'
              checked={showKidToggle}
              onChange={() => {
                setShowKidToggle(!showKidToggle)
                setShowClassToggle(false)
                setSelectedClasses([])
              }}
            />
            <span className='form-check-label text-body fs-7'>
              {intl.formatMessage({id: 'COMMON.KIDS'})}
            </span>
          </label>
        </div>
        {showClassToggle && (
          <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
            <SelectWithAllOption
              openMenuOnFocus={true}
              value={selectedClasses}
              onChange={(val: any) => setSelectedClasses(val)}
              options={classes}
              isClearable={true}
              components={animatedComponents}
              isMulti
              allowSelectAll
              isLoading={classes.length === 0}
              className='w-100'
              styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              theme={(theme: any) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.CLASS'})}
            />
          </div>
        )}

        {showKidToggle && (
          <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
            <SelectWithAllOption
              openMenuOnFocus={true}
              value={selectedKids}
              onChange={(val: any) => setSelectedKids(val)}
              options={filterdKids}
              isClearable={true}
              components={animatedComponents}
              isMulti
              allowSelectAll
              formatOptionLabel={(item: any) => (
                <div className='d-flex align-items-center '>
                  {item.value !== '*' && (
                    <AvatarWithLetters
                      image={item.imagePath}
                      alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                      rounded
                      extrasmall
                    />
                  )}
                  <div className='d-flex flex-column ms-2'>
                    <p className='p-0 m-0'>
                      {item.label}{' '}
                      <span>
                        {item.value !== '*' && !item.hasUserContacts && (
                          <i className='fa-solid fa-circle-minus text-danger'></i>
                        )}
                      </span>
                    </p>
                    <div className='d-flex align-items-center flex-wrap'>
                      {item.contacts?.map((val: any, index: number) => (
                        <p className='p-0 m-0 text-muted'>
                          {val.contactName}
                          {index !== item.contacts.length - 1 && ','}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              className='w-100  '
              styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              theme={(theme: any) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
              placeholder={intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'})}
            />
          </div>
        )}
        <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
          <SelectWithAllOption
            openMenuOnFocus={true}
            value={selectedEmployee}
            onChange={(val: any) => setSelectedEmployee(val)}
            options={employees?.length > 0 ? employees : []}
            isClearable={true}
            components={animatedComponents}
            isLoading={false}
            isMulti
            allowSelectAll
            formatOptionLabel={(item: any) => (
              <div className='d-flex align-items-center '>
                {item.value !== '*' && (
                  <AvatarWithLetters
                    image={item.imagePath}
                    alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                    rounded
                    extrasmall
                  />
                )}

                <div className='d-flex flex-column ms-2  '>
                  <p className='p-0 m-0'>{item.label}</p>
                  <p className='p-0 m-0 text-muted'>{item.email}</p>
                </div>
              </div>
            )}
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            placeholder={intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
          />
        </div>
        {!chatId && (
          <div className='d-flex flex-column align-items-center border-bottom px-8 min-h-50px'>
            <input
              type='text'
              className={'form-control w-100 text-body  mb-1'}
              name='groupName'
              autoComplete='off'
              placeholder={intl.formatMessage({id: 'KIDLING.CHAT_NAME'})}
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
            />
          </div>
        )}
        {/* <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
          <SelectWithAllOption
            openMenuOnFocus={true}
            value={selectedContacts}
            getOptionLabel={(option: any) => option?.contactName}
            getOptionValue={(option: any) => option?.contactId}
            onChange={(val: any) => setSelectedContacts(val)}
            options={contacts?.length > 0 ? contacts : []}
            isClearable={true}
            components={animatedComponents}
            isLoading={false}
            isMulti
            allowSelectAll
            formatOptionLabel={(item: any) => (
              <div className='d-flex align-items-center '>
                {item.value !== '*' && (
                  <AvatarWithLetters
                    image={item.imagePath}
                    alt={`${
                      item.contactName && item?.contactName.split(' ')[0]?.charAt(0).toUpperCase()
                    }  `}
                    rounded
                    extrasmall
                  />
                )}

                <div className='d-flex flex-column ms-2  '>
                  <p className='p-0 m-0'>{item.contactName}</p>
                  <p className='p-0 m-0 text-muted'>{item.email}</p>
                </div>
              </div>
            )}
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            placeholder={intl.formatMessage({id: 'KIDLING.HOME.CONTACT'})}
          />
        </div> */}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={close}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </Button>
        <Button color='primary' type='submit' onClick={addChat}>
          {!isLoading &&
            intl.formatMessage({
              id: 'AUTH.GENERAL.SUBMIT_BUTTON',
            })}

          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default NewChatDialog
