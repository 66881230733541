/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {deleteAttendance, getAttendanceByDate, updateAttendance} from '../../actions/attendace'
import {useAuth} from '../modules/auth'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const useKidAttendance = ({setShowDelete, _columns}: any) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {
    internalPagesDatesFilters,
    internalPagesFilters,
    setGlobalLoader,
    setAllAttendance,
    setGlobalLoader2,
    globalLoader,
    allAttendance,
    setDay,
    day,
    setInternalPagesFilters,
    setInternalPagesDatesFilters,
  } = useAppContext()
  const [currentId, setCurrentId] = useState('')
  const data: any = allAttendance
  const [date, setDate] = useState<any>({
    startDate: moment.tz(moment.now(), 'Europe/Berlin').startOf('day'),
    endDate: moment.tz(moment.now(), 'Europe/Berlin').endOf('day'),
  })
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readAttendace()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.attendance = {
      ...newFilters.attendance,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    if (!globalLoader) readAttendace()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPagesDatesFilters])

  useEffect(() => {
    if (!globalLoader) {
      setGlobalLoader(true)
      readAttendace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPagesDatesFilters.attendance])

  const printPage = () => {
    window.print()
  }
  const handleDeleteAttendance = async (id: string) => {
    setCurrentId(id)
    setShowDelete(true)
  }

  const readAttendace = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    const attendanceDate = internalPagesDatesFilters.attendance

    if (!internalPagesFilters.attendance.searchTerm) filter = '1=1'

    if (internalPagesFilters.attendance.searchTerm)
      filter = filter + internalPagesFilters.attendance.searchTerm

    const res = await getAttendanceByDate(
      filter,
      currentUser?.employee?.employeeId || 0,
      attendanceDate.From
        ? moment(attendanceDate.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      attendanceDate.To
        ? moment(attendanceDate.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setAllAttendance(res)
    setGlobalLoader(false)
  }

  const editCell = async (row: any, col: string, value: any) => {
    setGlobalLoader2(true)

    const data = {
      ...row,
      ...row.kid,
      [col]: value,
    }

    const resp = await updateAttendance(data)
    if (resp) {
      await readAttendace()
      setGlobalLoader2(false)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      setGlobalLoader2(false)
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }
  const doDelete = async () => {
    setShowDelete(false)
    if (currentId !== '') {
      setGlobalLoader2(true)
      const resp = await deleteAttendance(currentId)
      if (resp) {
        await readAttendace()
        setGlobalLoader2(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader2(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}
    if (searchTerm && searchTerm !== '')
      newFilters.attendance = {
        ...newFilters.attendance,
        searchTerm: `(kid.firstName.toLower().contains("${searchTerm.trim()}") || kid.lastName.toLower().contains("${searchTerm.trim()}"))`,
      }
    else {
      newFilters.attendance = {
        ...newFilters.attendance,
        searchTerm: '',
      }
    }

    setInternalPagesFilters(newFilters)
  }
  const columns: any = _columns && _columns({handleDeleteAttendance, editCell})

  return {
    readAttendace,
    editCell,
    doDelete,
    handleDeleteAttendance,
    columns,
    data,
    setDay,
    day,
    handleDateChange,
    printPage,
    searchTerm,
    setSearchTerm,
    setClicked,
  }
}

export default useKidAttendance
