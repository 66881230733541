export const selectStyle = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}
export const selectStyleDarkMode = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
  singleValue: (base: any) => ({...base, color: '#fff'}),
  input: (base: any) => ({
    ...base,
    color: '#fff',
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: '#dede',
    }
  },
}

export const selectStyleNoBorder = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),

  menu: (provided: any) => ({...provided, zIndex: 9999}),
}

export const selectStyleNoBorderColerFull = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
  multiValue: (styles: any) => {
    return {
      ...styles,
      backgroundColor: 'var(--bs-primary)',
    }
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      backgroundColor: 'red',
      color: '#fff',
    },
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}
