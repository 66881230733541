import {Avatar} from '@mui/material'
import {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

interface Props {
  show: boolean
  setShow: (b: boolean) => void
  kids?: any
}
const TaggedKidsModal: FC<Props> = ({show, setShow, kids}): ReactElement => {
  const company = window.location.pathname.split('/')[1]

  const intl = useIntl()
  const handleClose = () => {
    setShow(false)
  }
  return (
    <Modal
      isOpen={show}
      toggle={handleClose}
      centered
      zIndex={1100}
      onClosed={handleClose}
      modalClassName='EventModal'
    >
      <ModalHeader toggle={handleClose}>
        {intl.formatMessage({id: 'KIDLING.MEDIA.TAGGED_KIDS'})}
      </ModalHeader>
      <ModalBody>
        {kids?.map((kid: any, index: number) => (
          <div className='d-flex align-items-center my-2' key={index}>
            <Avatar
              alt={kid.KidName ?? kid.kidName}
              src={kid?.KidImageUrl ?? kid?.kidImageUrl ?? kid.KidImagePath}
            />
            <Link
              to={
                company
                  ? `/${company}/kidprofile?kidId=${kid.KidId ?? kid.kidId}`
                  : `/kidling/kidprofile?kidId=${kid.KidId ?? kid.kidId}`
              }
              className='text-gray-800 text-hover-primary  fw-bolder me-1'
            >
              <h5 className='ms-2'>{kid.KidName ?? kid.kidName}</h5>
            </Link>
          </div>
        ))}
      </ModalBody>
    </Modal>
  )
}

export default TaggedKidsModal
