import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment-timezone'

export const getAbsenseByDate = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.KID_ABSENSE}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => {
      return response.data.data?.sort((a: any, b: any) => moment(b?.fromDate).diff(a?.fromDate))
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteAbsense = async (id: string) => {
  return axios
    .delete(`${endpoints.ABSENCE}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateAbsense = async (data: any) => {
  return axios
    .put(`${endpoints.ABSENCE}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
