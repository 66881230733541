import {Outlet, Route, Routes} from 'react-router-dom'
import {KTCard} from '../../../../_metronic/helpers'
import ClassPage from './components/class-page'
import KidsWrapper from './components/kids-page/KidsWrapper'

function SimplifiedMainPage() {
  return (
    <div id='kt_app_content' className='app-content flex-column-fluid '>
      <Routes>
        <Route element={<Outlet />}>
          {/* <Route path='simplifiedView' element={<Main />} /> */}

          <Route
            path='kids/:classesId'
            element={
              <KTCard>
                <KidsWrapper />
              </KTCard>
            }
          />
          <Route
            path='*'
            element={
              <KTCard>
                <ClassPage />
              </KTCard>
            }
          />
          {/* <Route
            path='*'
            element={
              <>
                <KTCard>
                  <Main />
                </KTCard>
              </>
            }
          /> */}
        </Route>
      </Routes>
    </div>
  )
}

export default SimplifiedMainPage
