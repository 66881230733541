/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {BulkWindingProtocols} from '../../../../../../actions/bulk-actions'
import {useAuth} from '../../../../auth'
import {toast} from 'react-toastify'
import {selectStyle} from '../../../../../utils/select-style'
import moment from 'moment-timezone'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {Link} from 'react-router-dom'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {getAllWindingProtocols} from '../../../../../../actions/diapers'
import {momentDateFormat} from '../../../../../../config/constants'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
type Props = {
  isUserLoading: boolean
}

const KidDiapersModalForm: FC<Props> = ({isUserLoading}) => {
  const company = window.location.pathname.split('/')[1]
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {mobileConfiguration, diapersContent} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [notifyContact, setNotifyContact] = useState(false)
  const [kidsDiapers, setKidsDiapers] = useState([])
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    diaperContent: null,
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    diaperContent: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (values.diaperContent?.length > 0) {
          let kidIds: number[] = [],
            diaperContentIdIds: number[] = []
          values.diaperContent?.forEach((diaper: any) => {
            diaperContentIdIds.push(diaper.diaperContentId)
          })
          selected.forEach((kid) => {
            kidIds.push(kid.kidId)
          })

          const data = {
            kidIds,
            diaperContentIdIds,
            employeeId: currentUser?.employee?.employeeId,
            dateTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            companyId: currentCompany?.value,
            notes: values.employee,
            mobileConfigurationId:
              mobileConfiguration?.KidWindingProtocol[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkWindingProtocols(data)

          if (!!resp) {
            //  clearSelected()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            //cancel(true)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        reset()
        setSubmitting(false)

        //cancel(true)
      }
    },
  })

  useEffect(() => {
    readWindingProtocols()
  }, [selected, formik.values])

  const reset = () => formik.resetForm()

  const readWindingProtocols = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'

    const res = await getAllWindingProtocols(
      filter,
      currentUser?.employee?.employeeId || 0,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )

    setKidsDiapers(res)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidsDiapers?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'0'}>
                {kidsDiapers?.map((item: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.windingProtocols?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.diaperContentName}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal.notes}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.dateTime).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <div className='d-flex flex-column align-items-center'>
                                <Link
                                  to={
                                    company
                                      ? `/${company}/kid-management/diapers`
                                      : '/kidling/kid-management/diapers'
                                  }
                                  className='btn btn-light p-1 px-4 mt-2'
                                >
                                  <i className='fa-solid fa-arrow-right'></i>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row my-7'>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                dateTime
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <Select
              openMenuOnFocus={true}
              name='diaperContent'
              // value={value}
              onChange={(val: any) => formik.setFieldValue('diaperContent', val)}
              options={diapersContent}
              isMulti
              isClearable={true}
              isLoading={diapersContent.length === 0}
              getOptionLabel={(option: any) => option?.diaperContentName}
              getOptionValue={(option: any) => option?.diaperContentId}
              formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.diaperContentName}</p>}
              className='w-100  '
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.DIAPER_CONTENT'})}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
              styles={selectStyle}
            />
            {formik.touched.diaperContent && formik.errors.diaperContent && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.diaperContent}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('employee')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.employee && formik.errors.employee},
                {
                  'is-valid': formik.touched.employee && !formik.errors.employee,
                }
              )}
              name='employee'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.REMARKS'})}
            />
            {formik.touched.employee && formik.errors.employee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.employee}</span>
                </div>
              </div>
            )}
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
          {/* <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'>
              {' '}
              {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
            </label>
            <textarea
              {...formik.getFieldProps('contact')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.contact && formik.errors.contact},
                {
                  'is-valid': formik.touched.contact && !formik.errors.contact,
                }
              )}
              name='contact'
              autoComplete='off'
              disabled={true}
              rows={1}
            />
            {formik.touched.contact && formik.errors.contact && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.contact}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidDiapersModalForm}
