import {FC, ReactElement, useEffect, useState} from 'react'
import {
  KidStatusCssClasses,
  KidStatusTitles,
} from '../../../../../../general-components/status-formatter/StatusColumnFormatter'
import {useIntl} from 'react-intl'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import moment from 'moment'
import {NoRecords} from '../../../../../../general-components/NoRecords'
import {UsersListLoading} from '../../../../../../general-components/loading/UsersListLoading'
import CheckinDialog from '../components/kid-check-in-modal/CheckinDialog'
import CheckoutDialog from '../components/kid-check-out-modal/CheckoutDialog'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../../../general-components/error-text/ErrorText'
import {groupBy} from '../../../../../../utils/common'

interface Props {
  data: any
  isLoading: boolean
  readAllKids: any
}
const KidsList: FC<Props> = ({data, isLoading, readAllKids}): ReactElement => {
  const intl = useIntl()
  const [showCheckin, setShowCheckin] = useState({kidId: null, kidAttendanceId: null, show: false})
  const [showCheckout, setShowCheckout] = useState({
    kidId: null,
    kidAttendanceId: null,
    show: false,
  })

  const [showError, setShowError] = useState(false)
  const [status, setStatus] = useState<any>({})

  useEffect(() => {
    const grouped = groupBy(data, 'presentStatus')
    setStatus(grouped)
  }, [data])

  return (
    <>
      <div className='row p-2 p-md-8 g-6  mt-4    w-100 justify-content-sm-center mx-auto '>
        <div className='d-flex align-items-center flex-wrap'>
          {Object.keys(status).map((i: any) => (
            <div className='d-flex align-items-center ms-4 pe-2 my-1'>
              <span className={`fs-5 badge badge-${KidStatusCssClasses[i - 1]}`}>
                {intl.formatMessage({id: KidStatusTitles[i - 1]})}
                <span
                  className={` 
                  } text-bold fs-5 border-start ms-2 ps-2`}
                >
                  {status[i]?.length}
                </span>
              </span>
            </div>
          ))}
        </div>

        {data?.map((kid: any, index: number) => (
          <div className='col-6 col-md-4 col-lg-3 ' key={index}>
            <div className='d-flex align-items-center justify-content-center flex-column '>
              {/* <div className='d-flex align-items-center justify-content-between w-100 px-2'>
                <UserSelectionCell item={kid} />

                {selected?.length > 0 && <DropDownMenu data={optionsDropDownMenu} />}
              </div> */}

              <div
                className={`cursor-pointer w-155px h-155px rounded-circle border border-5 border-${
                  KidStatusCssClasses[kid.presentStatus - 1]
                } d-flex align-items-center justify-content-center flex-column`}
                onClick={() =>
                  kid.presentStatus === 2
                    ? setShowCheckout({
                        kidAttendanceId: kid?.attendance[0]?.kidAttendanceId,
                        kidId: kid?.kidId,
                        show: true,
                      })
                    : kid.presentStatus === 1
                    ? setShowError(true)
                    : setShowCheckin({
                        kidAttendanceId: kid?.attendance[0]?.kidAttendanceId,
                        kidId: kid?.kidId,
                        show: true,
                      })
                }
              >
                <AvatarWithLetters
                  image={kid.imagePath}
                  alt={`${kid.firstName.charAt(0).toUpperCase()} ${kid.lastName
                    .charAt(0)
                    .toUpperCase()}`}
                  large
                  rounded
                />
              </div>
              <p className='my-2'>{kid.kidName}</p>
              <div className='row mt-0'>
                {kid.attendance?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className='col-12 d-flex align-items-center justify-content-start'
                  >
                    <div className='d-flex align-items-center fs-7' style={{width: '4rem'}}>
                      <i className='bi bi-clock-fill me-2 text-primary'></i>
                      <span className=' fs-7'>
                        {item.attendanceDateTimeIn
                          ? moment(item.attendanceDateTimeIn).format('HH:mm')
                          : '--'}
                      </span>
                    </div>
                    <span className='mx-3'>
                      <i className='fa-solid fa-angles-right'></i>
                    </span>
                    <span className=' fs-7'>
                      {item.attendanceDateTimeOut
                        ? moment(item.attendanceDateTimeOut).format('HH:mm')
                        : '--'}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {!isLoading && data?.length === 0 && <NoRecords />}
      {isLoading && <UsersListLoading />}
      <CheckinDialog
        showModal={showCheckin}
        setShowModal={setShowCheckin}
        readAllKids={readAllKids}
      />
      <CheckoutDialog
        showModal={showCheckout}
        setShowModal={setShowCheckout}
        readAllKids={readAllKids}
      />
      <SweetAlert
        show={showError}
        error
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.KID_IS_ABSENT'})} />}
        onConfirm={(e) => setShowError(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </>
  )
}

export default KidsList
