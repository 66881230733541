import {FC, ReactElement, useEffect} from 'react'
import {KidProtocolModalHeader} from './KidProtocolModalHeader'
import {KidProtocolModalFormWrapper} from './KidProtocolModalFormWrapper'
import ActionsDrawerContainer from '../../../../../general-components/ActionsDrawerContainer/ActionsDrawerContainer'

interface Props {
  data?: any
  setEditModal?: any
}
const KidProtocolModal: FC<Props> = ({data, setEditModal}): ReactElement => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block '
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidProtocolModalHeader setEditModal={setEditModal} />
            {/* begin::Modal body */}
            <ActionsDrawerContainer
              children={
                <div className='modal-body scroll-y overflow-auto mx-5 mx-xl-15 my-0'>
                  <KidProtocolModalFormWrapper data={data} setEditModal={setEditModal} />
                </div>
              }
              classes='mh-500px'
            />

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidProtocolModal}
