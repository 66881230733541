/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'

type Props = {
  options: any[]
  onChange: (n: number) => void
  smallSize?: boolean
  value?: number
}
const CustomTabs: FC<Props> = ({options, onChange, smallSize, value}): ReactElement => {
  const [val, setVal] = useState(0)

  useEffect(() => {
    onChange(val)
  }, [val])

  useEffect(() => {
    if (value) setVal(value)
  }, [value])
  return (
    <div className='d-flex align-items-center w-100'>
      {options.map((item: any, index: number) => (
        <button
          className={`btn rounded-0 w-100 ${smallSize && 'btn-sm'} ${
            index === 0 ? 'rounded-start' : index === options.length - 1 && 'rounded-end'
          } ${index === val ? 'btn-primary' : 'btn-secondary  '}   `}
          key={index}
          onClick={() => setVal(index)}
        >
          {item}
        </button>
      ))}
    </div>
  )
}

export default CustomTabs
