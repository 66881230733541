/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import useLocalStorage from '../../../../../../hooks/useLocalStorage'
import {getAllKids} from '../../../../../../../actions/kids'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {Spinner} from 'react-bootstrap'
import Select from 'react-select'
import {useAuth} from '../../../../../auth'
import {useCompanyContext} from '../../../../../../../_metronic/layout/core/CompanyProvider'

const style = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    border: 0,
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'var(--bs-gray-100)',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
}
type Props = {
  filterData: any
}
const UsersListFilter: FC<Props> = ({filterData}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {globalLoader, classes, setKidsList, setMainSearchTerm, translations} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [status, setStatus] = useLocalStorage('status', localStorage.getItem('status'))
  const [photoPremit, setPhotoPremit] = useLocalStorage(
    'photoPremit',
    localStorage.getItem('photoPremit')
  )
  const [classValue, setClassValue] = useLocalStorage('class', localStorage.getItem('class'))
  const [statusData, setStatusData] = useState<any>([])
  const [permitPhotoData, setPermitPhotoData] = useState<any>([])

  useEffect(() => {
    setStatusData([
      {value: '', label: intl.formatMessage({id: 'COMMON.ALL'})},
      {value: 1, label: intl.formatMessage({id: 'KIDLING.HOME.ABSENSE'})},
      {value: 2, label: intl.formatMessage({id: 'KIDLING.HOME.PRESENT'})},
      {value: 3, label: intl.formatMessage({id: 'KIDLING.HOME.NO_STATUS'})},
      {value: 4, label: intl.formatMessage({id: 'KIDLING.HOME.CHECKED_OUT'})},
    ])
    setPermitPhotoData([
      {value: '', label: intl.formatMessage({id: 'COMMON.ALL'})},
      {value: 0, label: intl.formatMessage({id: 'KIDLING.HOME.PHOTOS_NOT_ALLOWED'})},
      {value: 1, label: intl.formatMessage({id: 'KIDLING.HOME.PHOTOS_ALLOWED'})},
    ])
  }, [translations])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [filterData])
  const resetData = async () => {
    const data = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (data) setKidsList(data)
    setMainSearchTerm('')
    setPhotoPremit(null)
    setClassValue(null)
    setStatus(null)
  }
  return (
    <>
      {/* begin::Content */}
      <div
        className='my-2  d-flex align-items-end flex-column flex-sm-row w-100 w-md-auto  flex-wrap flex-lg-nowrap'
        data-kt-user-table-filter='form'
      >
        <div className='d-flex flex-column flex-sm-row w-100 w-md-auto'>
          {/* begin::Input group */}
          <div className='me-5 w-100 w-md-auto  mt-2'>
            <Select
              value={classValue}
              options={classes}
              isLoading={classes.length === 0}
              name='class'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.CLASS'})}
              onChange={(val: any) => setClassValue(val)}
              isMulti
              styles={style}
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='me-5 w-100 w-md-auto mt-2'>
            <Select
              value={status}
              options={statusData}
              name='Language'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder='Status'
              onChange={(val: any) => setStatus(val)}
              styles={style}
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>

          <div className='me-5 w-100 w-md-auto mt-2'>
            <Select
              value={photoPremit}
              options={permitPhotoData}
              name='Language'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.PHOTO_PERMIT'})}
              onChange={(val: any) => setPhotoPremit(val)}
              styles={style}
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>
        </div>
        {/* end::Input group */}

        {/* begin::Actions */}
        <div className='d-flex justify-content-between align-items-center align-self-sm-end  align-self-start me-2   my-sm-0   w-100 w-lg-auto flex-nowrap flex-lg-wrap '>
          <button
            type='button'
            disabled={globalLoader}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-semibold me-2 px-6 py-3 mt-2 w-100 w-lg-auto'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='reset'
          >
            {intl.formatMessage({id: 'COMMON.RESET'})}
          </button>
          <button
            disabled={globalLoader}
            type='button'
            onClick={() => filterData()}
            className='btn btn-primary fw-semibold px-6 py-3 mt-2 min-w-150px w-100 w-lg-auto ms-2'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='filter'
          >
            {globalLoader ? (
              <Spinner animation={'border'} />
            ) : (
              intl.formatMessage({id: 'COMMON.APPLY'})
            )}
          </button>
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Content */}

      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
