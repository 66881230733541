/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../modules/auth'
import moment from 'moment'
import {getKidsMediaByEmplpyee} from '../../actions/kids'

const useMedia = () => {
  const [data, setData] = useState<any>([])
  const [meta, setMeta] = useState<any>({})
  const {
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const {currentUser} = useAuth()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked) {
      load()
      setClicked(false)
    }
  }, [clicked])

  const load = async () => {
    setGlobalLoader(true)
    let filter = {
      pagination: {
        generalSearch: '',
        page: pageIndex,
        perpage: pageSize,
        sort: 'desc',
        field: 'CreatedDate',
        columnSearch: [],
        additionalSearch: ``,
      },
      employeeId: currentUser?.employee.employeeId,
    }

    if (internalPagesFilters.media.searchTerm)
      filter.pagination.additionalSearch += `${internalPagesFilters.media.searchTerm}`
    if (internalPagesDatesFilters.media.From && internalPagesDatesFilters.media.To) {
      filter.pagination.additionalSearch += ` AND CreatedDate>="${moment(
        internalPagesDatesFilters.media.From
      ).format('yyyy-MM-DDTHH:mm:ss')}" AND CreatedDate<="${moment(
        internalPagesDatesFilters.media.To
      ).format('yyyy-MM-DDTHH:mm:ss')}"`
    }

    const resp = JSON.parse(await getKidsMediaByEmplpyee(filter))

    setMeta(resp?.meta)
    setData(resp?.data)
    setGlobalLoader(false)
  }

  const loadMore = async () => {
    setIsLoadingMore(true)

    let filter = {
      pagination: {
        generalSearch: '',
        page: pageIndex,
        perpage: pageSize,
        sort: 'desc',
        field: 'CreatedDate',
        columnSearch: [],
        additionalSearch: ``,
      },
      employeeId: currentUser?.employee.employeeId,
    }

    if (internalPagesFilters.media.searchTerm)
      filter.pagination.additionalSearch += `${internalPagesFilters.media.searchTerm}`
    if (internalPagesDatesFilters.media.From && internalPagesDatesFilters.media.To) {
      filter.pagination.additionalSearch += ` AND CreatedDate>="${moment(
        internalPagesDatesFilters.media.From
      ).format('yyyy-MM-DDTHH:mm:ss')}" AND CreatedDate<="${moment(
        internalPagesDatesFilters.media.To
      ).format('yyyy-MM-DDTHH:mm:ss')}"`
    }
    const resp = JSON.parse(await getKidsMediaByEmplpyee(filter))

    setMeta(resp?.meta)
    setData((prev: any) => [...prev, ...resp?.data])
    setIsLoadingMore(false)
  }
  const handleDateChange = (selected: any) => {
    const date = selected[0]
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.media = {
      ...newFilters.mealtimes,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.media = {
      ...newFilters.media,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {
    load,
    loadMore,
    isLoadingMore,
    setPageSize,
    setPageIndex,
    pageIndex,
    pageSize,
    meta,
    data,
    handleDateChange,
    setClicked,
    clicked,
    searchTerm,
    setSearchTerm,
  }
}
export default useMedia
