import {MoodFilter} from './MoodFilter'

const MoodToolbar = () => {
  return (
    <div className='d-flex justify-content-end w-100 w-md-auto' data-kt-user-table-toolbar='base'>
      <MoodFilter />
    </div>
  )
}

export {MoodToolbar}
