import {FC, ReactElement} from 'react'
import './styles.scss'
interface Props {
  images: string[]
}
const StackedImagesWithEnlarge: FC<Props> = ({images}): ReactElement => {
  return (
    <div
      className='d-flex align-items-center mw-100'
      style={{width: `${images.length > 1 ? 100 + images.length * 20 : 100}px`}}
    >
      {images?.map((image: string, index: number) => (
        <div className=' position-relative w-100px h-75px  '>
          <img
            src={image}
            alt={`stackimage-${index}`}
            key={index}
            className='position-absolute  w-100px h-75px d3__image'
            style={{
              left: index > 0 ? `${index * -60}%` : 0,
              top: index > 0 ? `${index * 5}px` : 0,
              zIndex: index > 0 ? index * 2 : 1,
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default StackedImagesWithEnlarge
