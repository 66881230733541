import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllUserChats = async () => {
  return axios
    .get(`${endpoints.GET_AL_CHATS} `)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getChatDetails = async (groupId: string) => {
  return axios
    .get(`${endpoints.GET_CHAT_DETAILS}/${groupId} `)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getChatDetailsWithPagination = async (groupId: number, count: number, to: any) => {
  return axios
    .get(`${endpoints.GET_CHAT_DETAILS_WITH_PAGINATION}`, {
      params: {groupId, count, to},
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const uploadFileToGroup = async (groupId: number, data: any) => {
  return axios
    .post(`${endpoints.UPLOAD_FILE_TO_GROUP}/${groupId}`, data)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const DownloadChatFile = async (filePath: any) => {
  return axios
    .get(`${endpoints.GET_CHAT_FILE}?filePath=${filePath}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const addNewChat = async (data: any) => {
  return axios
    .post(`${endpoints.ADD_NEW_CHAT}`, data)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteChatMessage = async (id: number) => {
  return axios
    .delete(`${endpoints.CHAT_MESSAGE}/${id}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const markMessagesAsRead = async (data: any) => {
  return axios
    .put(`${endpoints.MARK_MESSAGES_READ}`, data)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const markSingleMessageAsReaded = async (id: Number) => {
  return axios
    .put(`${endpoints.MARK_SINGLE_READ_MESSAGE}?chatMessageId=${id}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteChatGroup = async (id: number) => {
  return axios
    .delete(`${endpoints.CHAT_GROUP}/${id}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const addUsersToChat = async (data: any) => {
  return axios
    .post(`${endpoints.ADD_USERS_TO_CHAT}`, data)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteUsersFromChat = async (removeUserId: number, groupId: number) => {
  return axios
    .delete(`${endpoints.REMOVE_USERS_FROM_CHAT}?groupId=${groupId}&removeUserId=${removeUserId}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log(error)
    })
}
