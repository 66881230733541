/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {selectStyle, selectStyleDarkMode} from '../../utils/select-style'
import AvatarWithLetters from '../avatar-with-letters'
import makeAnimated from 'react-select/animated'
import SelectWithAllOption from '../select-with-all/SelectWithAllOption'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuth} from '../../modules/auth'
import {
  BulkEmployeeEvent,
  BulkEvent,
  BulkEventByClasses,
  BulkEventContact,
} from '../../../actions/bulk-actions'
import {getKidContacts} from '../../../actions/contacts'
import {toast} from 'react-toastify'
import {getAllKids, getClassesByEmployee} from '../../../actions/kids'
import {getAllEmployeesByKita} from '../../../actions/employee'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useCompanyContext} from '../../../_metronic/layout/core/CompanyProvider'

const animatedComponents = makeAnimated()
interface Props {
  modal: any
  setModal: any
  readEvents?: any
}
const AddRecipientsModal: FC<Props> = ({modal, setModal, readEvents}): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const {
    contacts,
    setContacts,
    mobileConfiguration,
    setClasses,
    classes,
    setKidsList,
    kidsList,
    setEmployees,
    employees,
  } = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [initialValues] = useState<any>({})
  const [notifyContact, setNotifyContact] = useState(false)

  const {currentUser} = useAuth()
  const schema = Yup.object().shape({
    contacts: Yup.mixed().nullable(),
    kids: Yup.mixed().nullable(),
    groups: Yup.mixed().nullable(),
    employee: Yup.mixed().nullable(),
  })

  useEffect(() => {
    if (contacts.length === 0) readContacts()
    if (kidsList.length === 0) readKids()
    if (classes.length === 0) getClassesList()
    if (employees.length === 0) readEmployees()
  }, [])

  const readContacts = async () => {
    const resp = await getKidContacts(`companyId=${currentCompany.value} AND Email!=null`)
    setContacts(resp)
  }
  const readKids = async () => {
    const data = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (data) {
      setKidsList(data)
    }
  }
  const getClassesList = async () => {
    const resp = await getClassesByEmployee()

    if (resp) {
      let temp: any = []
      resp?.map((option: any, index: number) =>
        temp.push({value: option.classesId, label: option.classesName})
      )
      setClasses(temp)
    }
  }

  const readEmployees = async () => {
    const resp = await getAllEmployeesByKita(
      `employeeReceiveCommunicationFromEmployees=true AND companyId=${currentCompany.value} `
    )
    let temp: any = []
    resp?.map((item: any) =>
      temp.push({
        value: item.employeeId,
        label: item.employeeName,
        email: item.email,
        imagePath: item.imagePath,
        usersId: item.usersId,
      })
    )

    setEmployees(temp)
  }
  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        if (modal?.data?.contact) {
          let contactIds: number[] = []

          formik.values?.contact?.map((cont: any) => contactIds.push(cont.contactId))
          const contactData = {
            contactIds,
            eventsId: modal.data.event.eventsId,
            employeeId: currentUser?.employee?.employeeId,
            companyId: currentCompany?.value,
            mobileConfigurationId: mobileConfiguration?.Events[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }
          const resp4 = await BulkEventContact(contactData)

          if (resp4) {
            await readEvents()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            handleClose()
          }
        } else if (modal?.data?.kid) {
          let kidIds: number[] = []

          formik.values?.kids?.map((cont: any) => kidIds.push(cont.kidId))
          const data = {
            kidIds,
            eventsId: modal.data.event.eventsId,
            employeeId: currentUser?.employee?.employeeId,
            companyId: currentCompany?.value,
            mobileConfigurationId: mobileConfiguration?.Events[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkEvent(data)
          if (resp) {
            await readEvents()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            handleClose()
          }
        } else if (modal?.data?.group) {
          let classesIds: number[] = []

          formik.values?.groups?.map((cont: any) => classesIds.push(cont.value))
          const data = {
            classesIds,
            eventsId: modal.data.event.eventsId,
            employeeId: currentUser?.employee?.employeeId,
            companyId: currentCompany?.value,
            mobileConfigurationId: mobileConfiguration?.Events[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkEventByClasses(data)
          if (resp) {
            await readEvents()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            handleClose()
          }
        } else if (modal?.data?.employee) {
          let employeeIds: number[] = []

          formik.values?.employee?.map((cont: any) => employeeIds.push(cont.value))
          const data = {
            employeeIds,
            eventsId: modal.data.event.eventsId,
            companyId: currentCompany?.value,
            mobileConfigurationId: notifyContact
              ? mobileConfiguration?.Events[0]?.mobileConfigurationMasterId
              : 0,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkEmployeeEvent(data)
          if (resp) {
            await readEvents()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            handleClose()
          }
        }
      } catch (ex) {
        console.log(ex)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setModal({data: null, show: false})
  }

  return (
    <Modal isOpen={modal.show} toggle={handleClose} zIndex={1100} onClosed={handleClose}>
      <form
        id='kt_modal_add_user_form'
        className='form container'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <ModalHeader toggle={handleClose}>
          {intl.formatMessage({
            id: modal?.data?.contact
              ? 'KIDLING.HOME.INVITE_CONTACT'
              : modal?.data?.kid
              ? 'KIDLING.HOME.INVITE_KID'
              : modal?.data?.group
              ? 'KIDLING.HOME.INVITE_GROUP'
              : 'KIDLING.HOME.INVITE_EMPLOYEE',
          })}
        </ModalHeader>
        <ModalBody>
          {modal?.data?.contact && (
            <SelectWithAllOption
              openMenuOnFocus={true}
              value={formik.values.contact}
              getOptionLabel={(option: any) => option?.contactName}
              getOptionValue={(option: any) => option?.contactId}
              onChange={(val: any) => formik.setFieldValue('contact', val)}
              options={contacts}
              isClearable={true}
              components={animatedComponents}
              isMulti
              allowSelectAll
              isLoading={contacts.length === 0}
              formatOptionLabel={(item: any) => (
                <div className='d-flex align-items-center '>
                  {item.value !== '*' && (
                    <AvatarWithLetters
                      image={item.imagePath}
                      alt={`${
                        item.contactName && item?.contactName.split(' ')[0]?.charAt(0).toUpperCase()
                      }  `}
                      rounded
                      extrasmall
                    />
                  )}

                  {item.value !== '*' && (
                    <div className='d-flex flex-column ms-2  '>
                      <p className='p-0 m-0'>{item.contactName}</p>
                      <p className='p-0 m-0 text-muted'>{item.email}</p>
                    </div>
                  )}
                  {item.value === '*' && <p className='p-0 m-0'>{item.label}</p>}
                </div>
              )}
              className='w-100'
              styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              theme={(theme: any) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          )}
          {modal?.data?.kid && (
            <SelectWithAllOption
              openMenuOnFocus={true}
              value={formik.values.kids}
              onChange={(val: any) => formik.setFieldValue('kids', val)}
              options={kidsList}
              isClearable={true}
              components={animatedComponents}
              isMulti
              allowSelectAll
              getOptionLabel={(option: any) => option?.kidName}
              getOptionValue={(option: any) => option?.kidId}
              formatOptionLabel={(item: any) => (
                <div className='d-flex align-items-center '>
                  {item.value !== '*' && (
                    <AvatarWithLetters
                      image={item.imagePath}
                      alt={`${item.kidName[0].charAt(0).toUpperCase()}  `}
                      rounded
                      extrasmall
                    />
                  )}
                  <div className='d-flex flex-column ms-2'>
                    <p className='p-0 m-0'>{item.kidName}</p>
                  </div>
                </div>
              )}
              className='w-100  '
              styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              theme={(theme: any) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          )}

          {modal?.data?.group && (
            <SelectWithAllOption
              openMenuOnFocus={true}
              value={formik.values.groups}
              onChange={(val: any) => formik.setFieldValue('groups', val)}
              options={classes}
              isClearable={true}
              components={animatedComponents}
              isMulti
              allowSelectAll
              isLoading={classes.length === 0}
              className='w-100'
              styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              theme={(theme: any) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          )}

          {modal?.data?.employee && (
            <SelectWithAllOption
              openMenuOnFocus={true}
              value={formik.values.employee}
              onChange={(val: any) => formik.setFieldValue('employee', val)}
              options={employees}
              isClearable={true}
              components={animatedComponents}
              isLoading={employees.length === 0}
              isMulti
              allowSelectAll
              formatOptionLabel={(item: any) => (
                <div className='d-flex align-items-center '>
                  {item.value !== '*' && (
                    <AvatarWithLetters
                      image={item.imagePath}
                      alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                      rounded
                      extrasmall
                    />
                  )}

                  <div className='d-flex flex-column ms-2  '>
                    <p className='p-0 m-0'>{item.label}</p>
                    <p className='p-0 m-0 text-muted'>{item.email}</p>
                  </div>
                </div>
              )}
              className='w-100'
              styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              theme={(theme: any) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          )}

          {!modal?.data?.employee && (
            <label className='form-check form-switch form-check-custom form-check-solid my-2'>
              <input
                className='form-check-input w-30px h-20px'
                type='checkbox'
                name='notifications'
                checked={notifyContact}
                onChange={() => setNotifyContact((prev) => !prev)}
              />
              <span className='form-check-label text-muted fs-7'>
                {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
              </span>
            </label>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={handleClose}>
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </Button>

          <Button color='primary' type='submit' disabled={formik.isSubmitting}>
            {!formik.isSubmitting ? (
              intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})
            ) : (
              <span className='indicator-progress ' style={{display: 'inline-block'}}>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AddRecipientsModal
