/* eslint-disable react-hooks/exhaustive-deps */
import {toast} from 'react-toastify'
import {deleteKiddeRegisterMeal, getAlldeRegisterMeal} from '../../actions/meal'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../modules/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useDeregisteration = () => {
  const {
    kidsDeregistrations,
    setKidsDeregistrations,
    setGlobalLoader,
    internalPagesFilters,
    setInternalPagesFilters,
    setInternalPagesDatesFilters,
    internalPagesDatesFilters,
    globalLoader,
  } = useAppContext()
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [date, setDate] = useState<any>({})
  const intl = useIntl()
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.deregistration = {
      ...newFilters.deregistration,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsDeregistrations?.forEach((item: any) => {
      let deregistration: any = []
      item.deregistrations?.map((item: any) =>
        deregistration.push({
          id: item.kidMealDailyDeRegistrationId,
          title: item.mealTimeName,
          desc: item.remarks,
          date: item.deRegisteredForDate,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: deregistration,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsDeregistrations])

  const readDeregisterations = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (
      !internalPagesFilters.deregistration.classValue &&
      !internalPagesFilters.deregistration.searchTerm
    )
      filter = '1=1'
    if (internalPagesFilters.deregistration.classValue)
      filter = `classesId=${internalPagesFilters.deregistration.classValue}`
    if (
      internalPagesFilters.deregistration.classValue &&
      internalPagesFilters.deregistration.searchTerm
    )
      filter = filter + ' AND '
    if (internalPagesFilters.deregistration.searchTerm)
      filter = filter + internalPagesFilters.deregistration.searchTerm

    const res = await getAlldeRegisterMeal(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.deregistration.From
        ? moment(internalPagesDatesFilters.deregistration.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.deregistration.To
        ? moment(internalPagesDatesFilters.deregistration.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsDeregistrations(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKiddeRegisterMeal(id)
      if (resp) {
        await readDeregisterations()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }

  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.deregistration = {
      ...newFilters.deregistration,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}
    newFilters.deregistration = {
      ...newFilters.deregistration,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }
    setClicked(false)
    setInternalPagesFilters(newFilters)
  }

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readDeregisterations()
      setClicked(false)
    }
  }, [clicked])

  return {
    data,
    readDeregisterations,
    handleDateChange,
    searchTerm,
    setSearchTerm,
    clicked,
    setClicked,
  }
}

export default useDeregisteration
