import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllAssessments = async () => {
  return axios
    .get(`${endpoints.ASSESSMENT}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllAssessmentCategory = async () => {
  return axios
    .get(`${endpoints.ASSESSMENT_CATEGORY}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllAssessmentItem = async () => {
  return axios
    .get(`${endpoints.ASSESSMENT_ITEM}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllAssessmentItemRate = async () => {
  return axios
    .get(`${endpoints.ASSESSMENT_ITEM_RATE}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllAssessmentRateMethod = async () => {
  return axios
    .get(`${endpoints.ASSESSMENT_RATE_METHOD}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllAssessmentSubCategory = async () => {
  return axios
    .get(`${endpoints.ASSESSMENT_SUBCATEGORY}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllAssessmentsWithCat = async (filter?: string) => {
  return axios
    .get(`${endpoints.GET_KIDS_ASSESSMENTS}?${filter}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addKidAssessmentList = async (data: any) => {
  return axios
    .post(`${endpoints.KID_ASSESSMENT}/list`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addKidAssessment = async (data: any) => {
  return axios
    .post(`${endpoints.KID_ASSESSMENT}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidAssessmentList = async (data: any) => {
  return axios
    .put(`${endpoints.KID_ASSESSMENT}/list`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidAssessment = async (data: any) => {
  return axios
    .put(`${endpoints.KID_ASSESSMENT}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getKidAssessmentList = async (filter: string) => {
  return axios
    .get(`${endpoints.KID_ASSESSMENT}/listwhere/${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllkKidsAssessments = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.ALL_KID_ASSESSEMTS}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
