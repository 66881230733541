import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getKidsMealTimes = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.KID_MEALS}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteKidMeal = async (id: string) => {
  return axios
    .delete(`${endpoints.KID_MEALTIME}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidMealtime = async (data: any) => {
  return axios
    .put(`${endpoints.ATTENDACE}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
export const getFOODMenu = async (params: any) => {
  return axios
    .get(`${endpoints.FOOD_MENU}`, {params: {...params}})
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
