/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import {readFileFrom} from '../../actions/image'
import {getClassesByEmployee} from '../../actions/kids'
import {groupBy} from '../utils/common'
import {decodeBase64} from '../utils/getBase64'
import {momentDateFormat} from '../../config/constants'
import {getFOODMenu} from '../../actions/meals'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useCompanyContext} from '../../_metronic/layout/core/CompanyProvider'

const useFoodMenu = () => {
  const [start, setStart] = useState(new Date())
  const {setGlobalLoader} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [currentClasses, setCurrentClasses] = useState([])
  const [employeeClasses, setEmployeeClasses] = useState<any>({})
  const [FoodItems, setFoodItems] = useState<any>(null)
  const [isReading, setIsReading] = useState('')
  const [currentDownload, setCurrentDownload] = useState<any>({})
  const [currentTab, setCurrentTab] = useState(0)
  useEffect(() => {
    getClassesList()
  }, [])

  useEffect(() => {
    setCurrentTab(0)

    const groupped = groupBy(currentClasses, 'classesId')

    let classesNames: any = []
    Object.keys(groupped).forEach((key, index) => {
      classesNames.push({classId: key, name: groupped[key][0].name})
    })

    setEmployeeClasses(classesNames)
  }, [currentClasses])

  const readFoodMenu = async () => {
    try {
      setGlobalLoader(true)

      let temp: any = {}
      const length = Object.keys(employeeClasses).length

      employeeClasses.map(async (item: any, index: any) => {
        const resp: any = await getFOODMenu({
          classesId: item.classId,
          companyId: currentCompany.companyId,
          date: moment(start).format(momentDateFormat),
        })

        const sub = item.name
        let val: any = []
        if (resp?.length > 0) {
          val = groupBy(resp, 'mealTimeName')
        }
        temp = {...temp, [sub]: val}
        if (index === length - 1) {
          console.log('FoodItems[item.name]', temp[employeeClasses[0].name])
          setFoodItems(temp)
          setGlobalLoader(false)
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  const triggerDateChanged = async (dateObj: any) => {
    setStart(dateObj.start)
  }

  const openFile = async (item: any) => {
    try {
      setIsReading(item.filePath)
      const resp = await readFileFrom(item.filePath)
      const type = item.filePath.split('/')[2].split('.')[1]

      const blob = await decodeBase64({base64: resp, name: item.foodItemName, type: type})

      const url = URL.createObjectURL(blob)

      window.open(url)
      setIsReading('')
      let temp = {...currentDownload}

      temp[item.filePath] = url
      setCurrentDownload(temp)
      if ((window as any)['ReactNativeWebView'])
        (window as any)['ReactNativeWebView'].postMessage(
          JSON.stringify({file: {id: item.filePath, base64: resp}})
        )
    } catch (e) {
      setIsReading('')
      console.log(e)
    }
  }

  const getClassesList = async () => {
    const resp = await getClassesByEmployee()

    if (resp) {
      let temp: any = []
      resp?.map((option: any, index: number) =>
        temp.push({classesId: option.classesId, name: option.classesName})
      )
      setCurrentClasses(temp)
    }
  }
  return {
    triggerDateChanged,
    openFile,
    readFoodMenu,
    employeeClasses,
    FoodItems,
    currentDownload,
    isReading,
    currentTab,
    setCurrentTab,
    start,
    setFoodItems,
  }
}

export default useFoodMenu
