/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../modules/auth'
import moment from 'moment'
import {deleteKidActivity, getKidsAcivities} from '../../actions/activities'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
const useActivities = () => {
  const intl = useIntl()
  const [data, setData] = useState([])
  const [date, setDate] = useState<any>({})
  const {
    kidsActivites,
    setKidsActivites,
    setGlobalLoader,
    internalPagesFilters,
    setInternalPagesFilters,
    setInternalPagesDatesFilters,
    internalPagesDatesFilters,
    globalLoader,
  } = useAppContext()
  const {currentUser} = useAuth()
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readActivities()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.activity = {
      ...newFilters.activity,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsActivites?.forEach((item: any) => {
      let activities: any = []
      item.kidActivities?.map((activity: any) =>
        activities.push({
          id: activity.kidActivityId,
          title: activity.activityName,
          desc: '',
          subtitle1: moment(activity.dateTime).format('DD.MM.yyyy'),
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: activities,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kidsActivites])

  const readActivities = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.activity.classValue && !internalPagesFilters.activity.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.activity.classValue)
      filter = `classesId=${internalPagesFilters.activity.classValue}`
    if (internalPagesFilters.activity.classValue && internalPagesFilters.activity.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.activity.searchTerm)
      filter = filter + internalPagesFilters.activity.searchTerm

    const res = await getKidsAcivities(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.activity.From
        ? moment(internalPagesDatesFilters.activity.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.activity.To
        ? moment(internalPagesDatesFilters.activity.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsActivites(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidActivity(id)
      if (resp) {
        await readActivities()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }

  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}
    newFilters.activity = {
      ...newFilters.activity,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }
    setClicked(false)
    setInternalPagesFilters(newFilters)
  }

  return {
    handleDateChange,
    doDelete,
    readActivities,
    data,
    filterData,
    setSearchTerm,
    searchTerm,
    clicked,
    setClicked,
  }
}
export default useActivities
