import {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  gotoPage: any
  canPreviousPage: boolean
  previousPage: any
  pageCount: number
  pageIndex: number
  pageOptions: any
  pageSize: number
  setPageSize: any
  nextPage: any
  canNextPage: boolean
  show: boolean
  total: number
}
const CustomPagination: FC<Props> = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  nextPage,
  canNextPage,
  show,
  total,
}): ReactElement => {
  const intl = useIntl()
  return show ? (
    <div className='d-flex justify-content-between align-items-center flex-wrap'>
      <div className='d-flex gap-1 flex-wrap py-2 mr-3'>
        <button
          className='btn btn-icon btn-sm btn-light-primary mr-2 my-1'
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <i className='fa-solid fa-angles-left icon-xs'></i>
        </button>
        <button
          className='btn btn-icon btn-sm btn-light-primary mr-2 my-1'
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <i className='fa-solid fa-angle-left icon-xs mx-2'></i>
        </button>

        {pageCount > 5
          ? [...Array(pageCount)]
              .slice(1)
              .slice(pageCount > 5 ? -5 : -1)
              .map((_, index) => (
                <button
                  className={`btn btn-icon btn-sm border-0 btn-hover-primary  mr-2 my-1 ${
                    index === pageIndex && 'btn-primary'
                  }`}
                  onClick={() => gotoPage(index)}
                  key={index}
                >
                  {index + 1}
                </button>
              ))
          : [...Array(pageCount)].map((_, index) => (
              <button
                className={`btn btn-icon btn-sm border-0 btn-hover-primary  mr-2 my-1 ${
                  index === pageIndex && 'btn-primary'
                }`}
                onClick={() => gotoPage(index)}
                key={index}
              >
                {index + 1}
              </button>
            ))}

        {pageCount > 5 && (
          <>
            <a href='#!' className='btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1'>
              ...
            </a>
            <button
              className={`btn btn-icon btn-sm border-0 btn-hover-primary mx-2    my-1 ${
                pageIndex === pageCount - 1 && 'btn-primary'
              }`}
              onClick={() => gotoPage(pageCount - 1)}
            >
              {pageCount}
            </button>
          </>
        )}
        <button
          className='btn btn-icon btn-sm btn-light-primary mr-2 my-1'
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <i className='fa-solid fa-angle-right icon-xs'></i>
        </button>
        <button
          className='btn btn-icon btn-sm btn-light-primary mr-2 my-1'
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <i className='fa-solid fa-angles-right icon-xs'></i>
        </button>
        <div className='mx-4 mt-3 text-muted text-nowrap'>
          {intl.formatMessage({id: 'COMMON.TOTAL'})}:{total}
        </div>
      </div>
      <div className='d-flex align-items-center py-3 px-3'>
        <div className='mx-2 text-muted text-nowrap'>
          {intl.formatMessage({id: 'COMMON.ITEMS_PER_PAGE'})}:
        </div>

        <select
          className='form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary'
          onChange={(e) => setPageSize(Number(e.target.value))}
          defaultValue={pageSize}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
        <span className='text-muted text-nowrap mx-2'>
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CustomPagination
