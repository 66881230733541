import {ProtocolsFilter} from './ProtocolsFilter'

const ProtocolsToolbar = () => {
  return (
    <div className='d-flex justify-content-end w-100 w-md-auto' data-kt-user-table-toolbar='base'>
      <ProtocolsFilter />
    </div>
  )
}

export {ProtocolsToolbar}
