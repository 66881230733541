import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import moment from 'moment'
import {NoRecords} from '../../../general-components/NoRecords'
import './styles.scss'
const colors = ['danger', 'success', 'info', 'warning', 'secondary', 'light', 'primary']
function Pinboard() {
  const {pinboard} = useAppContext()

  return (
    <div
      className=' rounded py-lg-16 d-flex flex-column align-items-center w-100 bg-body'
      style={{minHeight: '20rem'}}
    >
      {pinboard?.length > 0 && (
        <div className='d-flex flex-column w-100 p-4 p-sm-8 '>
          {pinboard?.map((item: any, index: number) => (
            <div
              className={`alert alert-${colors[index % colors.length]}`}
              role='alert'
              key={index}
            >
              <div className='d-flex'>
                <i
                  className={`fa-solid fa-thumbtack me-2 fs-2 text-${
                    colors[index % colors.length]
                  }`}
                ></i>
                <div className='d-flex flex-column'>
                  <h5 className='my-0 py-0'>{item.pinboardName}</h5>
                  <p className='my-0 py-0 text-muted fs-7'>
                    {moment(item.pinboardDatetime).format('DD.MM.yyyy')}
                  </p>
                </div>
              </div>

              <p
                className='my-0 mt-1 py-0 text-muted fs-5'
                dangerouslySetInnerHTML={{__html: item.pinboardMessage}}
              ></p>
            </div>
          ))}
        </div>
      )}
      {pinboard?.length === 0 && <NoRecords />}
    </div>
  )
}

export default Pinboard
