/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../modules/auth'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {deleteKidSleeptime, getKidsSleeptimes} from '../../actions/sleeps'
import {toast} from 'react-toastify'

const useSleepTimes = () => {
  const {
    kidsSleeptimes,
    setKidsSleeptimes,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    globalLoader,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [date, setDate] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readSleeptimes()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.sleeptimes = {
      ...newFilters.sleeptimes,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsSleeptimes?.forEach((item: any) => {
      let Sleeptimes: any = []
      item.kidSleeps?.map((sleep: any) =>
        Sleeptimes.push({
          id: sleep.kidSleepTimeId,
          title: sleep?.sleepHabit?.sleepHabitName,
          subtitle: sleep?.sleepHabit?.description,
          subtitle1: `${moment(sleep.date).format('DD.MM.yyyy')} ${
            sleep.fromTime ? moment(sleep.fromTime).format('HH:mm') : '--'
          } - ${sleep.toTime ? moment(sleep.toTime).format('HH:mm') : '--'}`,

          tag: `${Math.ceil(
            moment
              .duration(
                moment(
                  sleep?.toTime
                    ? moment(sleep?.date)
                        .set('hours', moment(sleep?.toTime).hours())
                        .set('minutes', moment(sleep?.toTime).minutes())
                        .format('yyyy-MM-DDTHH:mm:ss')
                    : moment().format('yyyy-MM-DDTHH:mm:ss')
                ).diff(
                  moment(sleep?.date)
                    .set('hours', moment(sleep?.fromTime).hours())
                    .set('minutes', moment(sleep?.fromTime).minutes())
                    .format('yyyy-MM-DDTHH:mm:ss')
                )
              )
              .asMinutes()
          )} minutes`,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: Sleeptimes,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsSleeptimes])

  const readSleeptimes = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.sleeptimes.classValue && !internalPagesFilters.sleeptimes.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.sleeptimes.classValue)
      filter = `classesId=${internalPagesFilters.sleeptimes.classValue}`
    if (internalPagesFilters.sleeptimes.classValue && internalPagesFilters.sleeptimes.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.sleeptimes.searchTerm)
      filter = filter + internalPagesFilters.sleeptimes.searchTerm

    const res = await getKidsSleeptimes(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.sleeptimes.From
        ? moment(internalPagesDatesFilters.sleeptimes.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.sleeptimes.To
        ? moment(internalPagesDatesFilters.sleeptimes.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsSleeptimes(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidSleeptime(id)
      if (resp) {
        await readSleeptimes()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.sleeptimes = {
      ...newFilters.sleeptimes,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {
    readSleeptimes,
    doDelete,
    data,
    handleDateChange,
    setClicked,
    clicked,
    setSearchTerm,
    searchTerm,
  }
}

export default useSleepTimes
