/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {DeregistrationHeader} from './header/DeregistrationHeader'
import useDeregisteration from '../../../../hooks/useDeregisteration'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'

function Deregistration() {
  const columns: any = useMemo(() => _columns(), [])
  const intl = useIntl()
  const {globalLoader, internalPagesDatesFilters} = useAppContext()
  const {data, readDeregisterations} = useDeregisteration()

  useEffect(() => {
    if (!globalLoader) readDeregisterations()
  }, [internalPagesDatesFilters])

  return (
    <div className='container p-md-16'>
      <DeregistrationHeader title={intl.formatMessage({id: 'KIDLING.HOME.DEREGISTERATION'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Deregistration
