import {FC} from 'react'
interface Props {
  id: string
  className?: string
  currentIndex?: number
  index?: number
}
const CustomImage: FC<Props> = ({id, index, currentIndex, className}) => {
  return (
    <div className='position-relative'>
      <img src={id} alt={id} loading='lazy' className={className} />
    </div>
  )
}

export default CustomImage
