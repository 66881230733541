/* eslint-disable react-hooks/exhaustive-deps */
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {KidsTable} from './table/KidsTable'
import {KidCheckinModal} from './kid-check-in-modal/KidCheckinModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {KidCheckoutModal} from './kid-check-out-modal/KidCheckOutModal'
import {KidAbsenseModal} from './kid-absense-modal/KidAbsenseModal'
import {KidActivityModal} from './kid-activity-modal/KidActivityModal'
import {KidMealModal} from './kid-meal-modal/KidMealModal'
import {KidSleepsStartModal} from './kid-sleeps-start-modal/KidSleepsStartModal'
import {KidSleepsEndModal} from './kid-sleeps-end-modal/KidSleepsEndModal'
import {KidDrinksModal} from './kid-drinks-modal/KidDrinksModal'
import {KidProtocolModal} from './kid-protocol-modal/KidProtocolModal'
import {KidMoodModal} from './kid-mood-modal/KidMoodModal'
import {KidRequestModal} from './kid-request-modal/KidRequestModal'
import {KidEventModal} from './kid-event-modal/KidEventModal'
import {KidMediaModal} from './kid-media-modal/KidMediaModal'
import {KidMedicalModal} from './kid-medical-modal/KidMedicalModal'
import {KidAssessmentModal} from './kid-assessment-modal/KidAssessmentModal'
import {KidAttachModal} from './kid-attach-modal/KidAttachModal'
import {useEffect, useState} from 'react'
import {KidDeRegisterationModal} from './kid-deregistration-modal/KidDeRegisterationModal'
import {UsersListGrouping} from './components/header/UsersListGrouping'
import {KidDiapersModal} from './kid-diapers-modal/KidDiapersModal'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {useIntl} from 'react-intl'
import '../styles.scss'
import Feed from '../Feed/Feed'
import {CustomTabPanel, a11yProps} from '../../chat/components/ChatSidebar'
import CustomTabs from '../../../../general-components/custom-tabs'
import SimplifiedMainPage from '../../SimplifiedMainPage/SimplifiedMainPage'
import {useLocation, useNavigate} from 'react-router-dom'
const KidsList = () => {
  const {itemIdForUpdate, currentKidModal} = useListView()
  const [selectedTab, setSelectedTab] = useState(0)
  const [stickMenu, setStickMenu] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    const splitted = location?.pathname?.split('/')
    let sel = splitted[splitted?.length - 1].toString() === 'simplified' ? 1 : 0
    if (sel === 0) {
      const view = localStorage?.getItem('mainPageView')
      if (view === 'simplified') sel = 1
    }
    setSelectedTab(sel)
  }, [])

  useEffect(() => {
    localStorage?.setItem('mainPageView', selectedTab === 0 ? 'details' : 'simplified')
    navigate(
      selectedTab === 0
        ? `/${company}/kid-management/kids`
        : `/${company}/kid-management/kids/simplified`,
      {replace: true}
    )
  }, [selectedTab])

  useEffect(() => {
    const toggleVisibility = () => {
      const scrollPoint = window.scrollY

      if (scrollPoint > 400) {
        setStickMenu(true)
      } else {
        setStickMenu(false)
      }
    }
    window.addEventListener('scroll', toggleVisibility, {passive: true})
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <>
      <KTCard>
        <div className={`d-flex justify-content-center  w-100 `}>
          <div className='container'>
            <div className='card-header border-0 pt-6 d-flex flex-column align-items-start px-2'>
              <div className={`w-100px ms-auto`}>
                <CustomTabs
                  value={selectedTab}
                  onChange={setSelectedTab}
                  options={[
                    <i className='fa-solid fa-bars fs-4'></i>,
                    <i className='fa-solid fa-note-sticky fs-4'></i>,
                  ]}
                  smallSize
                />
              </div>
              <div className={`card-toolbar  w-100 `}>
                {selectedTab === 0 && (
                  <div className={`w-100`}>
                    <UsersListHeader show={true} />
                    <UsersListGrouping />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {selectedTab === 0 && (
          <>
            <div
              className={`d-flex justify-content-center  w-100 bg-body shadow-lg  z-index-1 `}
              style={{
                transition: 'all .5s ease',
                position: 'fixed',
                top: stickMenu ? '-1rem' : '-20rem',
                left: 0,
                paddingTop: stickMenu ? '7rem' : '0',
              }}
            >
              <div className='container'>
                <div className='card-header border-0 pt-6 d-flex flex-column align-items-start px-2'>
                  <div
                    className={`card-toolbar    px-2 pb-4'
                 `}
                  >
                    <div className={`container pb-4`}>
                      <UsersListGrouping />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=''>
              <KidsTable />
            </div>
          </>
        )}
        {selectedTab === 1 && <SimplifiedMainPage />}
      </KTCard>
      {itemIdForUpdate !== undefined && currentKidModal === 'in' && <KidCheckinModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'out' && <KidCheckoutModal />}
      {/* {itemIdForUpdate !== undefined && currentKidModal === 'remark' && <KidInternalRemarkModal />} */}
      {itemIdForUpdate !== undefined && currentKidModal === 'absense' && <KidAbsenseModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'activity' && <KidActivityModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'meal' && <KidMealModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'sleepsStart' && (
        <KidSleepsStartModal />
      )}
      {itemIdForUpdate !== undefined && currentKidModal === 'sleepsEnd' && <KidSleepsEndModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'drinks' && <KidDrinksModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'protocol' && <KidProtocolModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'moods' && <KidMoodModal />}
      {/* {itemIdForUpdate !== undefined && currentKidModal === 'assignMeal' && <KidAssignMealModal />} */}
      {itemIdForUpdate !== undefined && currentKidModal === 'request' && <KidRequestModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'event' && <KidEventModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'media' && <KidMediaModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'medical' && <KidMedicalModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'assessment' && <KidAssessmentModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'attach' && <KidAttachModal />}
      {itemIdForUpdate !== undefined && currentKidModal === 'deregisteration' && (
        <KidDeRegisterationModal />
      )}
      {itemIdForUpdate !== undefined && currentKidModal === 'diapers' && <KidDiapersModal />}
    </>
  )
}

const KidsListWrapper = () => {
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }
  return (
    <ListViewProvider>
      <div className='kidsList'>
        <Tabs value={selectedTab} onChange={handleChange} aria-label='basic tabs example'>
          <Tab
            label={intl.formatMessage({id: 'KIDLING.DASHBOARD'})}
            {...a11yProps(0)}
            style={{
              minWidth: '50%',
              border: '1rem',
              borderTopLeftRadius: '1rem',
            }}
          />
          <Tab
            label={
              <div className='w-100 position-relative  '>
                {intl.formatMessage({id: 'KIDLING.FEED'})}
                <img
                  src='/new.png'
                  alt='new'
                  className='position-absolute  object-cover '
                  style={{height: '50px', top: '-16px', right: '-15px'}}
                />
              </div>
            }
            {...a11yProps(1)}
            style={{
              minWidth: '50%',
              border: '1rem',
              borderTopRightRadius: '1rem',
            }}
          />
        </Tabs>
        <CustomTabPanel value={selectedTab} index={0}>
          <KidsList />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1}>
          <Feed />
        </CustomTabPanel>
      </div>
    </ListViewProvider>
  )
}

export {KidsListWrapper}
