/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import HrCard from '../../../../../general-components/hr-card'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {getAllKids, getClassesByEmployee} from '../../../../../../actions/kids'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {KidSearchComponent} from '../seach-kids/KidSearchComponent'
import KidsList from '../kids-page/kids-list'
import {useAuth} from '../../../../auth'
import CustomTabs from '../../../../../general-components/custom-tabs'
import {NoRecords} from '../../../../../general-components/NoRecords'
import {KTCard} from '../../../../../../_metronic/helpers'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
function ClassPage() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const {groupTab, setGroupTab} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [data, setData] = useState([])
  const [menuData, setMenuData] = useState<any>([])
  const [showKids, setShowKids] = useState(false)
  const [classes, setClasses] = useState([])

  useEffect(() => {
    readClasses()
  }, [])
  useEffect(() => {
    if (groupTab === 0) {
      readAllKids()
    }
  }, [groupTab])

  useEffect(() => {
    if (classes?.length > 0) {
      let temp: any = []
      classes?.map((singleClass: any) =>
        temp.push({
          url: `kids/${singleClass.classesId}`,
          title: singleClass.classesName,
          count: '',
          show: true,
        })
      )
      setMenuData(temp)
    }
  }, [classes])

  const readKids = async (search?: string) => {
    let filter = ``
    if (!!search) {
      setShowKids(true)
    } else {
      setShowKids(false)
    }

    setIsLoading(true)
    setData([])
    filter = search ? `kidName.toLower().contains("${search.toLowerCase().trim()}")` : '1=1'
    const resp = await getAllKids({
      filter: filter,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    setData(resp)
    setIsLoading(false)
  }

  const readAllKids = async () => {
    setIsLoading(true)
    setData([])

    const resp = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    setData(resp)
    setIsLoading(false)
  }
  const readClasses = async () => {
    setIsLoading(true)
    const resp = await getClassesByEmployee()

    setClasses(resp)
    setIsLoading(false)
  }
  return (
    <KTCard>
      <div id='kt_app_content' className='app-content flex-column-fluid card'>
        <div id='kt_app_content_container' className='   position-relative  '>
          <div className='d-flex flex-column flex-lg-row  '>
            <div className='flex-lg-row-fluid   z-index-2'>
              <div className='h-100 '>
                <div className='d-flex flex-column  position-relative'>
                  {/* <button
                    className='btn  position-absolute '
                    style={{top: '1rem', left: '0rem', width: '2rem'}}
                    onClick={() =>
                      navigate(
                        company ? `/${company}/kid-management/kids` : `/kidling/kid-management/kids`
                      )
                    }
                  >
                    <i className='fa-solid fa-arrow-left-long' style={{fontSize: '1.5rem'}}></i>
                  </button> */}
                  <div className='mt-16 w-md-50 w-sm-75 w-100 mx-sm-auto'>
                    <KidSearchComponent filterData={readKids} />
                  </div>

                  {!showKids && (
                    <>
                      <div className='row p-4 p-sm-8  g-6 h-50 mt-2   w-100 mx-auto'>
                        <CustomTabs
                          onChange={(val: any) => {
                            setGroupTab(val)
                          }}
                          options={[
                            intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'}),
                            intl.formatMessage({id: 'KIDLING.HOME.CLASS'}),
                          ]}
                        />
                        {groupTab === 1 ? (
                          <>
                            {menuData.map((item: any, index: number) => (
                              <div className='col-6  ' key={index}>
                                <HrCard {...item} />
                              </div>
                            ))}
                            {!isLoading && classes?.length === 0 && <NoRecords />}
                          </>
                        ) : (
                          <KidsList isLoading={isLoading} data={data} readAllKids={readAllKids} />
                        )}
                      </div>
                      {isLoading && <UsersListLoading />}
                    </>
                  )}
                  {showKids && (
                    <KidsList isLoading={isLoading} data={data} readAllKids={readAllKids} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </KTCard>
  )
}

export default ClassPage
