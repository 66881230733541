import {ListViewProvider} from './core/ListViewProvider'
import {AbsenceHeader} from './components/header/AbsenceHeader'
import {KidsTable} from './table/KidsTable'
import {KTCard} from '../../../../../_metronic/helpers'

const AbsenseList = () => {
  return (
    <>
      <KTCard>
        <AbsenceHeader />
        <KidsTable />
      </KTCard>
    </>
  )
}

const AbsenseListWrapper = () => (
  <ListViewProvider>
    <AbsenseList />
  </ListViewProvider>
)

export {AbsenseListWrapper}
