import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {BulkSleep} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {useAuth} from '../../../../auth'
import {getAllKids} from '../../../../../../actions/kids'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'

type Props = {
  isUserLoading: boolean
}

const KidSleepsStartModalForm: FC<Props> = ({isUserLoading}) => {
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {mobileConfiguration, setKidsList, filters} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [filterdKids, setFilterdKids] = useState<any>([])
  const [notifyContact, setNotifyContact] = useState(false)
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    notes: '',
    date: new Date(),
    startTime: new Date(),
  })

  useEffect(() => {
    setFilterdKids(selected.filter((item) => item.sleeps.length === 0))
  }, [selected])

  const kidSchema = Yup.object().shape({
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    startTime: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = []
        filterdKids.map((kid: any) => kidIds.push(kid.kidId))
        if (kidIds.length > 0) {
          const data = {
            kidIds,
            sleepHabitIds: [0],
            employeeId: currentUser?.employee?.employeeId,
            date: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            fromTime: moment(values.startTime).format('yyyy-MM-DDTHH:mm:ss'),
            toTime: null,
            companyId: filterdKids[0]?.classes[0]?.companyId || currentCompany?.value,
            // notes: values.notes,
            // totalSleepTimeMinute: 0,

            mobileConfigurationId:
              mobileConfiguration?.KidSleepTime[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkSleep(data)

          if (!!resp) {
            await getkidsData()
            //  clearSelected()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            //  cancel(true)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        reset()
        setSubmitting(true)
        // cancel(true)
        // clearSelected()
      }
    },
  })
  const reset = () => formik.resetForm()
  const getkidsData = async () => {
    const temp = await getAllKids({
      filter: filters,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (temp) setKidsList(temp)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {filterdKids?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>

          <div className='row my-7 w-100'>
            <div className='col-8'>
              <label className='label  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.HOME.DATETIME'})}
              </label>
              <div
                className={clsx(
                  {'is-invalid': formik.touched.date && formik.errors.date},
                  {
                    'is-valid': formik.touched.date && !formik.errors.date,
                  }
                )}
              >
                <ResponsiveDatePicker
                  onAccept={(date: any) => {
                    formik.setFieldValue('date', date)
                  }}
                  value={formik.values.date}
                />
              </div>
            </div>
            <div className='col-4'>
              <label className='label  form-label fs-6 fw-semibold text-muted'>
                {' '}
                {intl.formatMessage({id: 'KIDLING.HOME.START_TIME'})}
              </label>
              <div
                className={clsx(
                  {'is-invalid': formik.touched.startTime && formik.errors.startTime},
                  {
                    'is-valid': formik.touched.startTime && !formik.errors.startTime,
                  }
                )}
              >
                <ResponsiveDatePicker
                  timeOnly
                  onAccept={(date: any) => {
                    formik.setFieldValue('startTime', date)
                  }}
                  value={formik.values.startTime}
                />
              </div>
            </div>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {' '}
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidSleepsStartModalForm}
