import CustomDateRangePicker from '../../../../../../general-components/date-range-picker/DateRangePicker'
import {AbsenceSearchComponent} from './AbsenceSearchComponent'
import {useIntl} from 'react-intl'
import useKidAbsence from '../../../../../../hooks/useKidAbsence'

const AbsenceHeader = () => {
  const {handleDateChange} = useKidAbsence({})
  const intl = useIntl()
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100 p-md-16'>
      <h4>{intl.formatMessage({id: 'KIDLING.MENU.KIDSABSENSES'})}</h4>
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <AbsenceSearchComponent />
        </div>
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>
    </div>
  )
}

export {AbsenceHeader}
