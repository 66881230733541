/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  item: any
}

const UserSelectionCell: FC<Props> = ({item}) => {
  const {selected, onSelect} = useListView()
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    const exists = selected.find((e) => e.kidId === item.kidId)
    setIsSelected(exists ? exists : null)
  }, [selected])

  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid w-25px'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => {
          onSelect(item)
        }}
      />
    </div>
  )
}

export {UserSelectionCell}
