import {useMemo} from 'react'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import _columns, {_mobileColumns} from './columns/_columns'
import TableContainer from '../../../../../general-components/table-container/TableContainer'
import {useIntl} from 'react-intl'
import {TableLoader} from '../../../../../general-components/table-loader/TableLoader'

const KidsTable = () => {
  const {globalLoader, kidsList}: any = useAppContext()
  const columns = useMemo(() => _columns(), [])
  const data: any = useMemo(() => kidsList, [kidsList])
  const intl = useIntl()

  return (
    <KTCardBody className='py-sm-4 px-0 px-sm-16 '>
      <div className=' '>
        {globalLoader ? (
          <TableLoader colNum={5} rows={10} />
        ) : data?.length > 0 ? (
          <TableContainer
            columns={columns}
            data={data}
            shrinkedView
            _mobileColumns={_mobileColumns()}
            tableName='main'
          />
        ) : (
          <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
            <i className='fa-solid fa-database  ' style={{fontSize: '5rem'}}></i>
            <p className='mt-4 text-muted fs-3'>
              {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_RECORDS_YET'})}
            </p>
          </div>
        )}
      </div>
    </KTCardBody>
  )
}

export {KidsTable}
