/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {ActivityHeader} from '../../../../general-components/ActivityHeader'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import useActivities from '../../../../hooks/useActivities'

function Activities() {
  const {internalPagesDatesFilters, globalLoader} = useAppContext()
  const {data, handleDateChange, readActivities} = useActivities()
  const intl = useIntl()
  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])

  useEffect(() => {
    if (!globalLoader) readActivities()
  }, [internalPagesDatesFilters])

  return (
    <div className='container p-md-16'>
      <ActivityHeader
        title={intl.formatMessage({id: 'KIDLING.MENU.ACTIVITIES'})}
        handleDateChange={handleDateChange}
      />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Activities
