/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../modules/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {deleteAbsense, getAbsenseByDate, updateAbsense} from '../../actions/absense'
import {toast} from 'react-toastify'
import moment from 'moment'
import {useListView} from '../modules/apps/kid-management/kids-list/core/ListViewProvider'

function useKidAbsence({setShowDelete, _columns}: any) {
  const intl = useIntl()
  const {selected} = useListView()
  const {currentUser} = useAuth()
  const {
    internalPagesFilters,
    setGlobalLoader,
    globalLoader,
    allAbsense,
    setGlobalLoader2,
    setAllAbsense,
    setInternalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
  } = useAppContext()
  const [currentId, setCurrentId] = useState('')
  const data: any = allAbsense
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readAbsense()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.absense = {
      ...newFilters.absense,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    if (!globalLoader) {
      setGlobalLoader(true)
      readAbsense()
    }
  }, [internalPagesDatesFilters])

  const handleDeleteAbsense = async (id: string) => {
    setCurrentId(id)
    setShowDelete(true)
  }

  const editCell = async (row: any, col: string, value: any) => {
    setGlobalLoader2(true)
    const data = {
      ...row,
      ...row.kid,
      [col]: value,
    }

    const resp = await updateAbsense(data)
    if (resp) {
      await readAbsense()
      setGlobalLoader2(false)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      setGlobalLoader2(false)
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }
  const doDelete = async () => {
    setShowDelete(false)
    if (currentId !== '') {
      setGlobalLoader2(true)
      const resp = await deleteAbsense(currentId)
      if (resp) {
        await readAbsense()
        setGlobalLoader2(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader2(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }

  const readAbsense = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (selected?.length === 0 && !internalPagesFilters.absense.searchTerm) filter = '1=1'
    if (internalPagesFilters.absense.searchTerm)
      filter = filter + internalPagesFilters.absense.searchTerm.toLowerCase().trim()

    if (internalPagesFilters.absense.searchTerm !== '' && selected?.length > 0) filter = ' AND '
    selected.forEach((kid, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kid.kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'

    const res = await getAbsenseByDate(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.absense.From
        ? moment(internalPagesDatesFilters.absense.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.absense.To
        ? moment(internalPagesDatesFilters.absense.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )
    setAllAbsense(res)
    setGlobalLoader(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}
    if (searchTerm && searchTerm !== '') {
      newFilters.absense = {
        ...newFilters.absense,
        searchTerm: `(kid.firstName.toLower().contains("${searchTerm.trim()}") || kid.lastName.toLower().contains("${searchTerm.trim()}"))`,
      }
    } else {
      newFilters.absense = {
        ...newFilters.absense,
        searchTerm: '',
      }
    }
    setInternalPagesFilters(newFilters)
  }
  const columns: any = _columns && _columns({handleDeleteAbsense, editCell})

  return {
    readAbsense,
    editCell,
    doDelete,
    handleDeleteAbsense,
    columns,
    data,
    handleDateChange,
    setSearchTerm,
    setClicked,
    searchTerm,
  }
}

export default useKidAbsence
