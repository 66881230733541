import moment from 'moment-timezone'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {Link} from 'react-router-dom'

function _columns() {
  const company = window.location.pathname.split('/')[1]

  return [
    {
      id: 'avatar',
      width: 2,
      accessor: 'avatar',
      Cell: ({cell}: any) => {
        const row = cell?.row?.original

        return (
          <AvatarWithLetters
            image={row.imagePath}
            alt={`${row.kid?.firstName?.charAt(0)?.toUpperCase()} ${row.kid?.lastName
              ?.charAt(0)
              ?.toUpperCase()}`}
            extrasmall
          />
        )
      },
    },
    {
      id: 'Name',
      accessor: 'kid.firstName',
      sortable: true,
      width: 20,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        return (
          <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row mb-md-0 mb-8'>
            <div className='ml-4  d-flex flex-column justify-content-center'>
              <h5 className='m-0 p-0'>{row.kid?.firstName + ' ' + row.kid?.lastName}</h5>
              <p className='m-0 p-0 text-muted'>
                {cell.row.original.fromDate
                  ? moment(cell.row.original.fromDate).format('DD.MM.yyyy')
                  : ''}{' '}
                {' - '}{' '}
                {cell.row.original.toDate
                  ? moment(cell.row.original.toDate).format('DD.MM.yyyy')
                  : ''}
              </p>
            </div>
          </div>
        )
      },
    },

    {
      id: 'reason',
      accessor: 'reason',
      width: 50,
      sortable: true,
      Cell: ({cell}: any) => {
        return (
          <div>
            {cell.row.original?.kidAbsentReasonName && (
              <>
                <i className='fa-solid fa-note-sticky text-primary'></i>{' '}
                {cell.row.original?.kidAbsentReasonName}
              </>
            )}
          </div>
        )
      },
    },
    {
      id: 'contactRemarks',
      sortable: true,
      Cell: ({cell}: any) => {
        return <p>{cell.row.original.contactRemarks?.substring(0, 20)}</p>
      },
    },
    {
      id: 'Attributes',
      Cell: ({cell}: any) => {
        return (
          <Link
            to={company ? `/${company}/kid-management/absense` : '/kidling/kid-management/absense'}
            className='menu-link px-5'
          >
            <i className='fa-solid fa-arrow-right'></i>
          </Link>
        )
      },
    },
  ]
}

export default _columns
