/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../modules/auth'
import {useIntl} from 'react-intl'
import {deleteKidProtocol, getKidsProtocols} from '../../actions/protocol'
import moment from 'moment'
import {toast} from 'react-toastify'

const useProtocols = () => {
  const {
    kidsProtocol,
    setKidsProtocol,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
    globalLoader,
  } = useAppContext()
  const [data, setData] = useState([])
  const [editModal, setEditModal] = useState<any>({show: false, data: null})
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readProtocol()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.protocols = {
      ...newFilters.protocols,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsProtocol?.forEach((item: any) => {
      let protocols: any = []
      item.kidProtocols?.forEach((protocol: any) =>
        protocols.push({
          id: protocol.kidProtocolRemarkId,
          title: protocol.title,
          desc: protocol.remarks,
          date: protocol.dateTime,
          setShowEditModal: () =>
            setEditModal((prev: any) => ({
              ...prev,
              show: !prev.show,
              data: {kid: item, item: protocol},
            })),
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: protocols,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsProtocol])

  const readProtocol = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.protocols.classValue && !internalPagesFilters.protocols.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.protocols.classValue)
      filter = `classesId=${internalPagesFilters.protocols.classValue}`
    if (internalPagesFilters.protocols.classValue && internalPagesFilters.protocols.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.protocols.searchTerm)
      filter = filter + internalPagesFilters.protocols.searchTerm

    const res = await getKidsProtocols(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.protocols.From
        ? moment(internalPagesDatesFilters.protocols.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.protocols.To
        ? moment(internalPagesDatesFilters.protocols.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsProtocol(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidProtocol(id)
      if (resp) {
        await readProtocol()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.protocols = {
      ...newFilters.protocols,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }

  return {
    readProtocol,
    doDelete,
    data,
    editModal,
    setEditModal,
    handleDateChange,
    searchTerm,
    setSearchTerm,
    setClicked,
    clicked,
  }
}

export default useProtocols
