import {FC} from 'react'
import CustomDateRangePicker from '../../../general-components/date-range-picker/DateRangePicker'
import {MediaSearchComponent} from './components/MediaSearchComponent'
import moment from 'moment'
import useMedia from '../../../hooks/useMedia'

interface Props {
  title?: string
}
const TitleHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useMedia()

  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 mb-4 w-100'>
      {title && <h4>{title}</h4>}
      <div className='d-flex flex-column-reverse flex-sm-row align-items-start justify-content-between w-100'>
        <div className='d-flex align-items-center flex-column flex-wrap flex-sm-row  w-100 '>
          <MediaSearchComponent />
        </div>
        <CustomDateRangePicker
          handleDateChange={handleDateChange}
          initialValue={{
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
          }}
        />
      </div>
    </div>
  )
}

export {TitleHeader}
