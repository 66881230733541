/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {getClassesByEmployee} from '../../../../../../actions/kids'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const CalenderFilter: FC = (): ReactElement => {
  const intl = useIntl()
  const [classes, setClasses] = useState([])
  const [filters, setFilters] = useState([])
  const {translations, setCalendarFilter, calendarFilter} = useAppContext()
  const {mode} = useThemeMode()
  useEffect(() => {
    let temp: any = [
      {
        id: -1,
        type: intl.formatMessage({id: 'COMMON.MINE'}),
        icon: 'fa-user',
        filter: '',
      },
    ]

    classes?.forEach((item: any, index: number) => {
      temp.push({
        id: item.value,
        type: item.label,
        icon: 'fa-user-group',
        filter: `ClassesIds=${item.value}`,
      })
    })

    setFilters(temp)
    setCalendarFilter(temp[0])
  }, [classes, translations])

  useEffect(() => {
    getClassesList()
  }, [])

  const getClassesList = async () => {
    const resp = await getClassesByEmployee()
    if (resp) {
      let temp: any = []
      resp?.map((option: any, index: number) =>
        temp.push({value: option.classesId, label: option.classesName})
      )
      setClasses(temp)
    }
  }

  return (
    <div className='w-100 d-flex align-items-center justify-content-center flex-wrap'>
      <h5 className='"me-2'>{intl.formatMessage({id: 'COMMON.FILTER'})}</h5>
      {filters.map((item: any, index: number) => (
        <div
          className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start pt-8 px-12 pb-2 m-2  d-flex align-items-center flex-column position-relative ${
            calendarFilter?.id === item.id && 'active'
          } `}
          data-kt-button='true'
          key={index}
          onClick={() => {
            setCalendarFilter(item)
          }}
        >
          <i className={`fs-1 fa-solid mb-2 ${item.icon} ${mode === 'dark' && 'text-dark'}`}></i>
          <input
            className={`form-check-input  position-absolute top-0 start-0 m-2`}
            type='radio'
            name='childFilter'
            checked={calendarFilter?.id === item.id}
            readOnly
          />

          <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
            <div className='fs-4 fw-bold text-gray-800  '>{item.type}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export {CalenderFilter}
