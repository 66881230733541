import React, {FC} from 'react'
import './styles.scss'

interface IProps {
  onClick?: any
  className?: string
  arrowOutsideSlide?: boolean
  color?: string
  disabled?: boolean
}
const SliderArrowNext: FC<IProps> = ({onClick, className, arrowOutsideSlide, color, disabled}) => {
  return (
    <div onClick={onClick} color={color}>
      <div className='next__arrow'>
        <i
          className={`fa-solid fa-chevron-right fs-4 text-white m-0 p-0 ${
            (className?.includes('slick-disabled') || disabled) && 'disabled'
          }`}
        ></i>
      </div>
    </div>
  )
}

export default SliderArrowNext
