/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {MedicalsHeader} from './header/MedicalsHeader'
import {KidMedicalModal} from '../../kid-management/kids-list/kid-medical-modal/KidMedicalModal'
import useMedicalRecords from '../../../../hooks/useMedicalRecords'
function MedicalRecords() {
  const {internalPagesDatesFilters, globalLoader} = useAppContext()
  const {readMedicalRecords, data, editModal, setEditModal} = useMedicalRecords()
  const intl = useIntl()

  useEffect(() => {
    if (!globalLoader) readMedicalRecords()
  }, [internalPagesDatesFilters])

  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <MedicalsHeader title={intl.formatMessage({id: 'KIDLING.MENU.MEDICALRECORDS'})} />
      <TableCardsContainer data={data} columns={columns} />
      {editModal?.show && <KidMedicalModal data={editModal.data} setEditModal={setEditModal} />}
    </div>
  )
}

export default MedicalRecords
