/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {deleteKidService, getKidsServices} from '../../actions/services'
import {useAuth} from '../modules/auth'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'

const useServices = () => {
  const intl = useIntl()
  const {
    kidsServices,
    setKidsServices,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
  } = useAppContext()
  const {currentUser} = useAuth()
  const [data, setData] = useState([])

  useEffect(() => {
    let temp: any = []
    kidsServices.forEach((item: any) => {
      let services: any = []
      item.services?.map((service: any) =>
        services.push({
          id: service.kidServiceId,
          title: service.serviceName,
          desc: `${moment(service.startDate).format('DD.MM.yyyy')} - ${moment(
            service.endDate
          ).format('DD.MM.yyyy')}`,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: services,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsServices])

  const readServices = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.services.classValue && !internalPagesFilters.services.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.services.classValue)
      filter = `classesId=${internalPagesFilters.services.classValue}`
    if (internalPagesFilters.services.classValue && internalPagesFilters.services.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.services.searchTerm)
      filter = filter + internalPagesFilters.services.searchTerm

    const res = await getKidsServices(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.services.From
        ? moment(internalPagesDatesFilters.services.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.services.To
        ? moment(internalPagesDatesFilters.services.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsServices(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidService(id)
      if (resp) {
        await readServices()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  return {readServices, doDelete, data}
}

export default useServices
