/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {DiapersHeader} from './header/DiapersHeader'
import useDiapers from '../../../../hooks/useDiapers'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
function Diapers() {
  const intl = useIntl()
  const {data, readWindingProtocols} = useDiapers()
  const {globalLoader, internalPagesDatesFilters} = useAppContext()
  const columns: any = useMemo(() => _columns(), [])

  useEffect(() => {
    if (!globalLoader) readWindingProtocols()
  }, [internalPagesDatesFilters])

  return (
    <div className='container p-16'>
      <DiapersHeader title={intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Diapers
