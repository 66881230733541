import {FC} from 'react'
import {ActivitySearchComponent} from '../modules/apps/activities/components/header/ActivitySearchComponent'
import {Activitytoolbar} from '../modules/apps/activities/components/header/ActivityToolbar'
import CustomDateRangePicker from './date-range-picker/DateRangePicker'

interface Props {
  title?: string
  handleDateChange?: any
}
const ActivityHeader: FC<Props> = ({title, handleDateChange}) => {
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100'>
      {title && <h4>{title}</h4>}
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <ActivitySearchComponent />
          <Activitytoolbar />
        </div>
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>
    </div>
  )
}

export {ActivityHeader}
