/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {
  addNewEvent,
  addNewEventSchedule,
  deleteContactEvent,
  deleteEmployeeFromEvent,
  deleteKidEvent,
  getAllEventsWithRecurrents,
  getEventSchedule,
  updateEvent,
} from '../../../actions/events'
import './eventDialog.scss'
import {deleteEvent} from '../../../actions/events'
import {toast} from 'react-toastify'
import {useAuth} from '../../modules/auth'
import {selectStyle} from '../../utils/select-style'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../error-text/ErrorText'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {UsersListLoading} from '../loading/UsersListLoading'
import {getAttendanceLocations} from '../../../actions/location'
import {getKidContacts} from '../../../actions/contacts'
import AddRecipientsModal from '../add-recepiant-modal/AddRecipientsModal'
import ResponsiveDatePicker from '../responsive-material-datepicker/ResponsiveDatePicker'
import {useCompanyContext} from '../../../_metronic/layout/core/CompanyProvider'

interface Props {
  modal: any
  setModal: any
  readEvents?: any
}
const EventDialog: FC<Props> = ({modal, setModal, readEvents}): ReactElement => {
  const [currentModal, setCurrentModal] = useState(modal)
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [showDelete, setShowDelete] = useState(false)
  const [showAddRecipientsModal, setShowAddRecipientsModal] = useState<any>({
    data: null,
    show: false,
  })
  const {
    attendanceLocations,
    setAllEvents,
    eventsCategories,
    setAttendanceLocations,
    calendarFilter,
    calenderDateFilter,
    setContacts,
    setCalendarFilter,
  } = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [globalEnable, setGlobalEnable] = useState<boolean>(false)
  const [listOfDaysValues, setListOfDaysValues] = useState<any>([])
  const [preview, setPreview] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [base64Image, setBase64Image] = useState<any>(null)
  const [initialCategory, setInitialCategory] = useState<any>(null)
  const [kidPart, setKidPart] = useState([])
  const [groupPart, setGroupPart] = useState([])
  const [employeePart, setEmployeePart] = useState([])
  const [showDelete1, setShowDelete1] = useState<any>({show: false, data: null})
  const [currentTab, setCurrentTab] = useState('0')
  const [addingNewEvent, setAddingNewEvent] = useState(false)
  const data = currentModal?.data?.extendedProps
    ? currentModal?.data?.extendedProps
    : currentModal?.data
  let calendarApi = data?.view?.calendar

  const [eventInitialValues, setEventInitialValues] = useState<any>({})

  const weekDays = [
    {id: 1, name: intl.formatMessage({id: 'KIDLING.HOME.MONDAY'})},
    {id: 2, name: intl.formatMessage({id: 'KIDLING.HOME.TUESDAY'})},
    {id: 3, name: intl.formatMessage({id: 'KIDLING.HOME.WEDNESDAY'})},
    {id: 4, name: intl.formatMessage({id: 'KIDLING.HOME.THURSDAY'})},
    {id: 5, name: intl.formatMessage({id: 'KIDLING.HOME.FRIDAY'})},
    {id: 6, name: intl.formatMessage({id: 'KIDLING.HOME.SATURDAY'})},
    {id: 0, name: intl.formatMessage({id: 'KIDLING.HOME.SUNDAY'})},
  ]

  const kidSchema = Yup.object().shape({
    eventsName: Yup.string()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .nullable(),
    category: Yup.mixed().nullable(),
    description: Yup.string().nullable(),
    nurseryClosed: Yup.boolean(),
    date: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    toDate: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
      .test(
        'isValidDate',
        intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
        (value: any, context: any) => {
          const totaldays = moment
            .duration(moment(value).diff(moment(context.parent.date)))
            .asDays()

          if (Math.trunc(totaldays) >= 0) {
            return true
          }
          return false
        }
      ),
    startsOn: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    locationName: Yup.mixed().nullable(),

    endsOn: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
      .test(
        'isValidDate',
        intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
        (value: any, context: any) => {
          const totalminutes = moment
            .duration(moment(value).diff(moment(context.parent.startsOn)))
            .asMinutes()

          if (totalminutes >= 0) {
            return true
          }
          return false
        }
      ),
  })

  useEffect(() => {
    setCurrentModal(modal)
  }, [modal])

  const formik: any = useFormik({
    initialValues: eventInitialValues,
    enableReinitialize: true,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        const data: any = {
          eventsName: values.eventsName,
          description: values.description,

          startsOn: moment(values.date)
            .set('hours', moment(values.startsOn).hours())
            .set('minutes', moment(values.startsOn).minutes())
            .format('yyyy-MM-DDTHH:mm:ss'),
          endsOn: moment(values.toDate)
            .set('hours', moment(values.endsOn).hours())
            .set('minutes', moment(values.endsOn).minutes())
            .format('yyyy-MM-DDTHH:mm:ss'),
          registerBefore: moment(values.startsOn).format('yyyy-MM-DDTHH:mm:ss'),
          fee: 0,
          indoor: false,
          roomId: 0,
          locationName: values?.locationName,
          nurseryClosed: values?.nurseryClosed,
          eventsCategoryId: values?.category?.value || 0,
          employeeId: currentUser?.employee?.employeeId,
          companyId: currentCompany?.value,
        }
        if (base64Image) data.imagePath = base64Image
        else if (!preview) {
          data.imagePath = ''
        }

        const resp = currentModal?.data?.extendedProps
          ? await updateEvent({...data, eventsId: currentModal?.data?.extendedProps.eventsId})
          : await addNewEvent(data)

        if (!!resp) {
          if (listOfDaysValues.length > 0) {
            const schedule = listOfDaysValues.map((item: any) => ({
              weekdayId: item,
              eventsId: currentModal?.data?.extendedProps
                ? currentModal?.data?.extendedProps.eventsId
                : resp.eventsId,
              fromTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
              toTime: moment(values.toDate).format('yyyy-MM-DDTHH:mm:ss'),
            }))
            await addNewEventSchedule(schedule)
          }

          const resp2 = await getAllEventsWithRecurrents(
            calendarFilter?.filter ?? `EmployeeId=${currentUser?.employee?.employeeId}`,
            calenderDateFilter?.start
              ? moment(calenderDateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
              : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
            calenderDateFilter?.end
              ? moment(calenderDateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
              : moment().startOf('week').add(1, 'years').format('yyyy-MM-DDTHH:mm:ss')
          )

          if (!!resp2) {
            setAllEvents(resp2)
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          }

          if (resp.eventsId) {
            setCalendarFilter({
              id: -1,
              type: intl.formatMessage({id: 'COMMON.MINE'}),
              icon: 'fa-user',
              filter: null,
            })
            setAddingNewEvent(true)
            readEvents(null, resp.eventsId, true)
          } else {
            handleClose()
          }
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }

        calendarApi?.render()

        calendarApi?.addEvent({
          id: 1,
          title: values.eventName,
          start: values.startsOn,
          end: values.endsOn,
          extendedProps: {...values},
          allDay: false,
        })
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    if (addingNewEvent && currentModal?.data?.extendedProps) setCurrentTab('1')
  }, [addingNewEvent, currentModal?.data?.extendedProps])

  useEffect(() => {
    readContacts()
    if (attendanceLocations.length === 0) readAttendanceLocations()
  }, [])

  useEffect(() => {
    if (listOfDaysValues.length === 0) handleWeekDays()
  }, [formik.values])

  useEffect(() => {
    const cat = eventsCategories?.find(
      (item: any) => item.eventsCategoryId === data?.eventsCategoryId
    )
    if (cat)
      setInitialCategory({
        value: cat?.eventsCategoryId,
        label: cat?.eventsCategoryName,
        colorCode: cat?.colorCode,
      })
  }, [eventsCategories, data])

  useEffect(() => {
    setListOfDaysValues([])
  }, [])

  useEffect(() => {
    if (listOfDaysValues.length > 0) setGlobalEnable(true)
    else setGlobalEnable(false)
  }, [listOfDaysValues])

  useEffect(() => {
    readEventSchedule()
  }, [data])

  useEffect(() => {
    if (currentModal?.data?.extendedProps?.eventsKid) {
      const groups = currentModal?.data?.extendedProps?.eventsKid?.filter(
        (item: any) => item.classesName !== null
      )
      setGroupPart(groups)
      const kids = currentModal?.data?.extendedProps?.eventsKid?.filter(
        (item: any) => item.kidName !== null
      )
      setKidPart(kids)

      const employees = currentModal?.data?.extendedProps?.employeeEvent?.filter(
        (item: any) => item.employeeName !== null
      )
      setEmployeePart(employees)
    }
  }, [data])

  useEffect(() => {
    if (currentModal?.data?.extendedProps) {
      setEventInitialValues({
        ...data,
        endsOn: moment(data?.endsOn).toDate(),
        startsOn: moment(data?.startsOn).toDate(),
        date: moment(data?.startsOn).toDate(),
        toDate: moment(data?.endsOn).toDate(),
        locationName: data?.locationName,
        category: initialCategory,
      })
    } else
      setEventInitialValues((prev: any) => ({
        ...prev,
        eventsName: '',
        startsOn: data?.start || new Date(),
        date: data?.start || new Date(),
        toDate: moment(data?.start).toDate(),
        endsOn: data?.end || new Date(),

        description: '',
      }))
    setPreview(data?.imagePath)
  }, [attendanceLocations, data, initialCategory])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    getBase64(selectedFile)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  const handleClose = () => {
    setCurrentModal({data: null, showModal: false})
    setModal({data: null, showModal: false})
    setPreview(null)
    setBase64Image(null)
    setCurrentTab('0')
    setAddingNewEvent(false)
    setEventInitialValues({
      eventsName: null,
      locationName: null,
      description: '',
      startsOn: null,
      endsOn: null,
      nurseryClosed: false,
    })
    setListOfDaysValues([])
  }

  const getBase64 = async (file: any) => {
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      return setBase64Image(reader.result)
    })

    reader.readAsDataURL(file)
  }

  const DoDelete = async () => {
    formik.setSubmitting(true)
    const resp: any = await deleteEvent(data.eventsId)
    if (resp.status === 200) {
      const resp = await getAllEventsWithRecurrents(
        calendarFilter.filter,
        calenderDateFilter?.start
          ? moment(calenderDateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
          : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
        calenderDateFilter?.end
          ? moment(calenderDateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
          : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
      )
      if (resp) {
        setAllEvents(resp)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        handleClose()
      }
    }
    formik.setSubmitting(false)
  }

  const readEventSchedule = async () => {
    if (data?.eventsId) {
      setIsLoading(true)
      const resp = await getEventSchedule(data.eventsId)

      if (resp) {
        if (resp.length > 0) {
          const days: any = []
          resp.map((item: any) => days.push(item.weekdayId))
          setListOfDaysValues(days)
        }
      }
      setIsLoading(false)
    }
  }

  const DeleteParticipient = async (value: any) => {
    setIsLoading(true)
    if (value?.kid) {
      const resp = await deleteKidEvent(value?.kid)
      if (!!resp) {
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      }
    } else if (value.contact) {
      const resp = await deleteContactEvent(value?.contact)
      if (!!resp) {
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      }
    } else if (value.employee) {
      await deleteEmployeeFromEvent(value?.employee)

      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
    readEvents(null, currentModal?.data?.extendedProps.eventsId)
    setIsLoading(false)
  }

  const readAttendanceLocations = async () => {
    const resp = await getAttendanceLocations(`companyId=${currentCompany?.value}`)
    if (resp) setAttendanceLocations(resp)
  }

  const handleWeekDays = () => {
    let start = moment(formik.values.date).startOf('day')
    const end = moment(formik.values.toDate).startOf('day')

    let days: any = []
    while (moment(end).diff(start, 'days') > 0) {
      const dayNum = start.weekday()
      days.push(dayNum === 6 ? 0 : dayNum + 1)
      start = start.add(1, 'days')
    }

    if (days.length > 0) setGlobalEnable(true)
    setListOfDaysValues([...new Set(days)])
  }

  const readContacts = async () => {
    const resp = await getKidContacts(`companyId=${currentCompany.value} AND Email!=null`)

    setContacts(resp)
  }

  return (
    <Modal
      isOpen={currentModal.showModal}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1100}
      onClosed={handleClose}
      modalClassName='EventModal'
    >
      <ModalHeader toggle={handleClose}>
        {currentModal?.data?.extendedProps
          ? intl.formatMessage({id: 'KIDLING.HOME.EDIT_EVENT'})
          : intl.formatMessage({id: 'KIDLING.HOME.ADD_EVENT'})}
      </ModalHeader>
      <Tabs
        activeKey={currentTab}
        id='uncontrolled-tab-example'
        className='my-3'
        onSelect={(key: any) => {
          setCurrentTab(key)
        }}
      >
        <Tab
          eventKey={'0'}
          title={
            <h5>
              {currentModal?.data?.extendedProps
                ? intl.formatMessage({id: 'KIDLING.HOME.EDIT_EVENT'})
                : intl.formatMessage({id: 'KIDLING.HOME.ADD_EVENT'})}
            </h5>
          }
        >
          <form
            id='kt_modal_add_user_form'
            className='form container'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <ModalBody>
              <div className='row align-items-center'>
                <div className=' col-12'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: preview
                        ? `url(${preview})`
                        : 'url("/media/svg/avatars/blank.svg")',
                    }}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: preview
                          ? `url(${preview})`
                          : 'url("/media/svg/avatars/blank.svg")',
                      }}
                    ></div>

                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Change avatar'
                    >
                      <i className='fa-solid fa-pencil'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>

                      <input
                        type='file'
                        name='avatar'
                        accept='.png, .jpg, .jpeg'
                        onChange={(e: any) =>
                          setSelectedFile(e.target?.files.length > 0 ? e.target?.files[0] : null)
                        }
                      />
                      <input type='hidden' name='avatar_remove' />
                    </label>

                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                    >
                      <i className='fa-solid fa-xmark'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>

                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove avatar'
                      onClick={() => setPreview(null)}
                    >
                      <i className='fa-solid fa-xmark'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  </div>

                  <div className='form-text'>
                    {intl.formatMessage({id: 'KIDLING.HOME.ALLOWED_FILE_TYPES'})}: png, jpg, jpeg.
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'KIDLING.HOME.FROMDATE'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.date && formik.errors.date},
                        {
                          'is-valid': formik.touched.date && !formik.errors.date,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        disablePast
                        onAccept={(date: any) => {
                          formik.setFieldValue('date', date)
                        }}
                        value={formik.values.date}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.date && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.date}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'KIDLING.HOME.TODATE'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.toDate && formik.errors.toDate},
                        {
                          'is-valid': formik.touched.toDate && !formik.errors.toDate,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        disablePast
                        onAccept={(date: any) => {
                          formik.setFieldValue('toDate', date)
                        }}
                        value={formik.values.toDate}
                        disabled={formik.isSubmitting || isLoading}
                        minDate={moment(formik.values.date).toDate()}
                      />
                    </div>
                    {formik.errors.toDate && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.toDate}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'KIDLING.HOME.START_TIME'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.startsOn && formik.errors.startsOn},
                        {
                          'is-valid': formik.touched.startsOn && !formik.errors.startsOn,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        timeOnly
                        onAccept={(date: any) => {
                          formik.setFieldValue('startsOn', date)
                        }}
                        value={formik.values.startsOn}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.endsOn && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.startsOn}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'KIDLING.HOME.END_TIME'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.endsOn && formik.errors.endsOn},
                        {
                          'is-valid': formik.touched.endsOn && !formik.errors.endsOn,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        timeOnly
                        onAccept={(date: any) => {
                          formik.setFieldValue('endsOn', date)
                        }}
                        value={formik.values.endsOn}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.endsOn && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.endsOn}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='required form-label fs-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'KIDLING.HOME.EVENT_NAME'})}
                  </label>
                  <input
                    {...formik.getFieldProps('eventsName')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.eventsName && formik.errors.eventsName},
                      {
                        'is-valid': formik.touched.eventsName && !formik.errors.eventsName,
                      }
                    )}
                    name='eventsName'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />
                  {formik.touched.eventsName && formik.errors.eventsName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.eventsName}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='   label  form-label fs-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'KIDLING.EVENT_CATEGORY'})}
                  </label>

                  <Select
                    value={formik.values.category}
                    onChange={(val: any) => {
                      formik.setFieldValue('category', val)
                    }}
                    options={eventsCategories?.map((item: any) => ({
                      value: item.eventsCategoryId,
                      label: item.eventsCategoryName,
                      colorCode: item.colorCode,
                    }))}
                    isClearable={true}
                    formatOptionLabel={(item: any) => (
                      <div
                        className='d-flex align-items-center'
                        onClick={(e: any) => e.preventDefault()}
                      >
                        <div
                          className='  rounded-circle me-2'
                          style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: `${item.colorCode}`,
                          }}
                        ></div>
                        <p className='p-0 m-0'>{item.label}</p>
                      </div>
                    )}
                    placeholder={intl.formatMessage({id: 'KIDLING.EVENT_CATEGORY'})}
                    isDisabled={formik.isSubmitting || isLoading}
                    theme={(theme) => ({
                      ...theme,

                      colors: {
                        ...theme.colors,
                        primary25: 'var(--bs-dark-light)',
                        primary: 'var(--bs-gray)',
                        neutral0: 'var(--bs-dark-light)',
                      },
                    })}
                    styles={selectStyle}
                  />
                  {formik.errors.category && (
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.category}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='  label  form-label fs-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'KIDLING.HOME.LOCATION'})}
                  </label>
                  <input
                    {...formik.getFieldProps('locationName')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.locationName && formik.errors.locationName},
                      {
                        'is-valid': formik.touched.locationName && !formik.errors.locationName,
                      }
                    )}
                    name='locationName'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />

                  {formik.errors.locationName && (
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.locationName}</span>
                    </div>
                  )}
                </div>

                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='   form-label fs-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'KIDLING.HOME.NURSERY_CLOSED'})}
                  </label>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid col-md-6 col-lg-3 col-12 my-lg-0 my-7 h-100 '>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={formik.values.nurseryClosed}
                      name='notifications'
                      onChange={(e) => {
                        formik.setFieldValue('nurseryClosed', e.target.checked)
                      }}
                      disabled={formik.isSubmitting || isLoading}
                    />
                  </div>
                </div>

                <div className=' col-lg-6 col-12 my-7'>
                  <label className='  form-label fs-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'KIDLING.HOME.DESCRIPTION'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('description')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.description && formik.errors.description},
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    name='description'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className=' col-lg-6 col-12 my-7'>
                  <label className='   form-label fs-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('notes')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.notes && formik.errors.notes},
                      {
                        'is-valid': formik.touched.notes && !formik.errors.notes,
                      }
                    )}
                    name='notes'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />
                  {formik.touched.notes && formik.errors.notes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.notes}</span>
                      </div>
                    </div>
                  )}
                </div>
                <hr className='hr' />
                <div className='form-check d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center '>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={globalEnable}
                      onChange={(e) => setGlobalEnable((prev) => !prev)}
                      disabled={
                        moment(formik.values.toDate).startOf('day') <=
                        moment(formik.values.date).startOf('day').startOf('day')
                      }
                    />
                    <label
                      className='form-check-label ms-2'
                      onClick={() =>
                        moment(formik.values.toDate).startOf('day') >
                          moment(formik.values.date).startOf('day') &&
                        setGlobalEnable((prev) => !prev)
                      }
                    >
                      {intl.formatMessage({id: 'KIDLING.HOME.RECURRING'})}?
                    </label>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-4 my-4 flex-wrap'>
                  {weekDays.map((item: any, index: number) => (
                    <div className='form-check' key={index}>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={
                          listOfDaysValues.find((checkItem: number) => checkItem === item.id) >= 0
                        }
                        id={item.id}
                        disabled={!globalEnable}
                        onChange={(e) => {
                          let newList = [...listOfDaysValues]
                          const isExists = newList.find(
                            (checkItem: number) => checkItem === item.id
                          )

                          if (isExists >= 0) {
                            newList = newList.filter((checkItem: number) => checkItem !== item.id)
                          } else {
                            newList.push(item.id)
                          }

                          setListOfDaysValues([...new Set(newList)])
                        }}
                      />
                      <label className='form-check-label'>{item.name}</label>
                    </div>
                  ))}
                </div>

                {/* end::Scroll */}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={handleClose}>
                {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
              </Button>
              {currentModal?.data?.extendedProps &&
                currentModal?.data?.employeeId === currentUser?.employee?.employeeId && (
                  <Button
                    color='danger'
                    type='button'
                    disabled={formik.isSubmitting || isLoading}
                    onClick={() => setShowDelete(true)}
                  >
                    {intl.formatMessage({id: 'AUTH.GENERAL.DELETE'})}
                  </Button>
                )}
              {((currentModal.enableEdit &&
                currentModal?.data?.employeeId === currentUser?.employee?.employeeId) ||
                !currentModal?.data?.extendedProps) && (
                <Button color='primary' type='submit' disabled={formik.isSubmitting || isLoading}>
                  {intl.formatMessage({
                    id: currentModal?.data?.extendedProps
                      ? 'AUTH.GENERAL.SUBMIT_BUTTON'
                      : 'KIDLING.NEXT',
                  })}
                </Button>
              )}
            </ModalFooter>
          </form>
        </Tab>

        {currentModal?.data?.extendedProps && (
          <Tab eventKey={'1'} title={<h5>{intl.formatMessage({id: 'COMMON.RECIPIENTS'})}</h5>}>
            <div className='d-flex   form container py-8 gap-4 flex-wrap'>
              {/* {currentModal.data?.extendedProps?.employeeEvent.length > 0 && (
                <div className='d-flex flex-column border p-4'>
                  <h4 className='border-bottom-2  '>
                    {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
                  </h4>
                  <hr />
                  {modal.data?.extendedProps.employeeEvent?.map((emp: any, index: number) => (
                    <h5 className='badge badge-info fw-semibold me-1' key={index}>
                      {emp.employeeName}
                    </h5>
                  ))}
                </div>
              )} */}

              <div className='d-flex flex-column border p-4 w-md-200px'>
                <h4 className='border-bottom-2  '>{intl.formatMessage({id: 'COMMON.CONTACTS'})}</h4>
                <hr />
                {currentModal?.data?.extendedProps?.contactEvent?.map(
                  (cont: any, index: number) => (
                    <div className='d-flex align-items-center justify-content-between my-1'>
                      <h5 className='badge badge-info fw-semibold me-1 mb-0' key={index}>
                        {cont.eventsContactAttending ? (
                          <i className='fa-solid fa-plus text-success me-2' />
                        ) : (
                          <i className='fa-solid fa-minus text-danger me-2' />
                        )}
                        {cont.contactName}
                      </h5>
                      <button
                        className='btn bt-sm p-0'
                        type='button'
                        onClick={() => {
                          setShowDelete1({show: true, data: {contact: cont.eventsContactId}})
                        }}
                      >
                        <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                      </button>
                    </div>
                  )
                )}

                {currentModal.enableEdit && data.employeeId === currentUser?.employee?.employeeId && (
                  <button
                    className='btn bt-sm p-0 w-100 mt-4 add__btn'
                    type='button'
                    onClick={() => {
                      setShowAddRecipientsModal({
                        show: true,
                        data: {contact: true, event: currentModal?.data?.extendedProps},
                      })
                    }}
                  >
                    <i className='mx-2 fa-solid fa-plus text-primary  plusIcon'></i>
                  </button>
                )}
              </div>

              <div className='d-flex flex-column border p-4   w-md-200px'>
                <h4 className='border-bottom-2  '>
                  {intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'})}
                </h4>
                <hr />
                {kidPart?.map((cont: any, index: number) => (
                  <div className='d-flex align-items-center justify-content-between my-1'>
                    <h5 className='badge badge-info fw-semibold me-1  mb-0' key={index}>
                      {cont.eventsKidAttending ? (
                        <i className='fa-solid fa-plus text-success me-2' />
                      ) : (
                        <i className='fa-solid fa-minus text-danger me-2' />
                      )}
                      {cont.kidName}
                    </h5>
                    <button
                      className='btn bt-sm p-0'
                      type='button'
                      onClick={() => {
                        setShowDelete1({show: true, data: {kid: cont.eventsKidId}})
                      }}
                    >
                      <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                    </button>
                  </div>
                ))}
                {currentModal.enableEdit && data.employeeId === currentUser?.employee?.employeeId && (
                  <button
                    className='btn bt-sm p-0 w-100 mt-4   add__btn'
                    type='button'
                    onClick={() => {
                      setShowAddRecipientsModal({
                        show: true,
                        data: {kid: true, event: currentModal?.data?.extendedProps},
                      })
                    }}
                  >
                    <i className='mx-2 fa-solid fa-plus text-primary  plusIcon'></i>
                  </button>
                )}
              </div>

              <div className='d-flex flex-column border p-4 w-md-200px'>
                <h4 className='border-bottom-2  '>
                  {intl.formatMessage({id: 'KIDLING.HOME.GROUP_NAME'})}
                </h4>
                <hr />
                {groupPart?.map((cont: any, index: number) => (
                  <div className='d-flex align-items-center justify-content-between my-1'>
                    <h5 className='badge badge-info fw-semibold me-1 mb-0' key={index}>
                      {cont.classesName}
                    </h5>
                    <button
                      className='btn bt-sm p-0'
                      type='button'
                      onClick={() => {
                        setShowDelete1({show: true, data: {kid: cont.eventsKidId}})
                      }}
                    >
                      <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                    </button>
                  </div>
                ))}
                {currentModal.enableEdit && data.employeeId === currentUser?.employee?.employeeId && (
                  <button
                    className='btn bt-sm p-0 w-100 mt-4   add__btn'
                    type='button'
                    onClick={() => {
                      setShowAddRecipientsModal({
                        show: true,
                        data: {group: true, event: currentModal?.data?.extendedProps},
                      })
                    }}
                  >
                    <i className='mx-2 fa-solid fa-plus text-primary  plusIcon'></i>
                  </button>
                )}
              </div>

              <div className='d-flex flex-column border p-4 w-md-200px'>
                <h4 className='border-bottom-2  '>
                  {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
                </h4>
                <hr />
                {employeePart?.map((cont: any, index: number) => (
                  <div className='d-flex align-items-center justify-content-between my-1'>
                    <h5 className='badge badge-info fw-semibold me-1 mb-0' key={index}>
                      {cont.employeeName}
                    </h5>
                    <button
                      className='btn bt-sm p-0'
                      type='button'
                      onClick={() => {
                        setShowDelete1({show: true, data: {employee: cont.eventsEmployeeId}})
                      }}
                    >
                      <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                    </button>
                  </div>
                ))}
                {currentModal.enableEdit && data.employeeId === currentUser?.employee?.employeeId && (
                  <button
                    className='btn bt-sm p-0 w-100 mt-4   add__btn'
                    type='button'
                    onClick={() => {
                      setShowAddRecipientsModal({
                        show: true,
                        data: {employee: true, event: currentModal?.data?.extendedProps},
                      })
                    }}
                  >
                    <i className='mx-2 fa-solid fa-plus text-primary  plusIcon'></i>
                  </button>
                )}
              </div>
            </div>
          </Tab>
        )}
      </Tabs>

      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          DoDelete()
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />

      <SweetAlert
        show={showDelete1.show}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          DeleteParticipient(showDelete1.data)
          setShowDelete1({show: false, data: null})
        }}
        onCancel={() => setShowDelete1({show: false, data: null})}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />

      {isLoading && <UsersListLoading />}
      <AddRecipientsModal
        setModal={setShowAddRecipientsModal}
        modal={showAddRecipientsModal}
        readEvents={readEvents}
      />
    </Modal>
  )
}

export default EventDialog
