import {InboxSelectionCell} from './InboxSelectionCell'
import moment from 'moment-timezone'
import {useLang} from '../../../../../../../_metronic/i18n/Metronici18n'
import {UserCustomHeader} from '../../../../../../general-components/table-custom-header/UserCustomHeader'
import {MESSAGE_LENGTH, MOBILE_MESSAGE_LENGTH} from '../../../../../../../config/constants'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import {useThemeMode} from '../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

function _columns({handleMarkPinned, navigate}: any) {
  const lang = useLang()
  const {mode} = useThemeMode()
  return [
    {
      id: 'selection',
      Cell: ({...props}) => (
        <InboxSelectionCell id={props.data[props.row.index].notificationUsersId} />
      ),
      width: 5,
    },

    {
      width: 5,

      id: 'Attributes',
      accessor: 'id',
      Cell: ({cell}: any) => {
        const pinned = cell.row.original.pinned
        return (
          <div className='d-flex  align-items-center'>
            <button
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              type='button'
              onClick={() => handleMarkPinned(cell.row.original.notificationUsersId, pinned)}
            >
              <i className={`fa-solid fa-star fs-5 m-0 ${pinned && 'text-warning'}`}></i>
            </button>

            {cell.row.original.attachmentExists && (
              <i className={`fa-solid fa-paperclip fs-5 m-0  `}></i>
            )}
            {cell.row.original.highPriority && (
              <i className='fa-sharp fa-solid fa-circle-exclamation text-danger ms-4'></i>
            )}
          </div>
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.SENDER' />,
      id: 'Name',
      accessor: 'name',
      width: 50,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        const nameArray = row.usersName
          .trim()
          .replace(/\s{2,}/g, ' ')
          .split(' ')

        return (
          <div className='d-flex align-items-center link'>
            <AvatarWithLetters
              image={row.imagePath}
              alt={`${nameArray[0].charAt(0).toUpperCase()} ${
                nameArray.length > 1 ? nameArray[1].charAt(0).toUpperCase() : ''
              } `}
              verysmall
            />
            <div className='mx-4  d-flex flex-column justify-content-center'>
              <p
                onClick={() =>
                  navigate(
                    `details/${
                      cell.row.original.mainMessageId !== 0
                        ? cell.row.original.mainMessageId
                        : cell.row.original.notificationId
                    }`
                  )
                }
                className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
                  cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
                }`}
              >
                {row.usersName}
              </p>
            </div>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.TITLE' />,
      accessor: 'title',
      id: 'title',
      width: 300,
      Cell: ({cell}: any) => {
        return (
          <div className='d-flex flex-column link'>
            <p
              onClick={() =>
                navigate(
                  `details/${
                    cell.row.original.mainMessageId !== 0
                      ? cell.row.original.mainMessageId
                      : cell.row.original.notificationId
                  }`
                )
              }
              className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
                cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
              }`}
            >
              {cell.row.original.notificationTitle}
            </p>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.MESSAGE' />,
      accessor: 'message',
      id: 'message',
      width: 300,
      Cell: ({cell}: any) => {
        const htmlNode = document.createElement('div')
        htmlNode.innerHTML = cell.row.original.message

        return (
          <div className='d-flex flex-column link'>
            <p
              onClick={() =>
                navigate(
                  `details/${
                    cell.row.original.mainMessageId !== 0
                      ? cell.row.original.mainMessageId
                      : cell.row.original.notificationId
                  }`
                )
              }
              dangerouslySetInnerHTML={{
                __html: `${htmlNode.innerText.substring(0, MESSAGE_LENGTH)} ${
                  htmlNode.innerText.length > MESSAGE_LENGTH ? ' ...' : ''
                }`,
              }}
              className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
                cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
              }`}
            ></p>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.DATETIME' />,

      id: 'dateTime',
      width: 50,
      accessor: 'dateTime',
      Cell: ({cell}: any) => {
        return (
          <p
            className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
              cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
            }`}
          >
            <i className='fa-solid fa-clock me-2'></i>
            {moment
              .duration(moment(new Date()).diff(moment(cell.row.original.dateTime)))
              .locale(lang)
              .humanize()}
          </p>
        )
      },
    },
  ]
}

export default _columns

export function _mobileColumns({handleMarkPinned, navigate}: any) {
  const lang = useLang()

  return [
    {
      id: 'Name',
      accessor: 'name',
      width: 50,
      hideHeader: true,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        const nameArray = row.usersName
          .trim()
          .replace(/\s{2,}/g, ' ')
          .split(' ')
        const htmlNode = document.createElement('div')
        htmlNode.innerHTML = cell.row.original.message
        const pinned = cell.row.original.pinned
        return (
          <div className='d-flex align-items-center w-100 shadow-sm p-4'>
            <InboxSelectionCell id={row.notificationUsersId} />
            <div className='d-flex align-items-center link mx-4  d-flex justify-content-center w-100'>
              <AvatarWithLetters
                image={row.imagePath}
                alt={`${nameArray[0].charAt(0).toUpperCase()} ${
                  nameArray.length > 1 ? nameArray[1].charAt(0).toUpperCase() : ''
                } `}
                extrasmall
              />
              <div className='w-100 ms-4'>
                <div
                  className=' d-flex align-items-center justify-content-between w-100'
                  onClick={() =>
                    navigate(
                      `details/${
                        cell.row.original.mainMessageId !== 0
                          ? cell.row.original.mainMessageId
                          : cell.row.original.notificationId
                      }`
                    )
                  }
                >
                  <div className='d-flex align-items-center'>
                    <h5
                      className={`fs-7 me-4 my-0 ${
                        cell.row.original.readDateTime ? 'fw-normal  ' : 'fw-bold text-dark'
                      }`}
                    >
                      {row.usersName}
                    </h5>
                    {cell.row.original.attachmentExists && (
                      <i className={`fa-solid fa-paperclip fs-5 mx-1  `}></i>
                    )}
                    {cell.row.original.highPriority && (
                      <i className='fa-sharp fa-solid fa-circle-exclamation text-danger mx-1'></i>
                    )}
                  </div>

                  <div
                    className={`my-0 ${
                      cell.row.original.readDateTime ? 'fw-normal  ' : 'fw-bold text-dark'
                    }`}
                  >
                    {/* <i className='fa-solid fa-clock me-2'></i> */}
                    {moment
                      .duration(moment(new Date()).diff(moment(cell.row.original.dateTime)))
                      .locale(lang)
                      .humanize()}
                  </div>
                </div>

                <p
                  className={`my-0  fs-7 ${
                    cell.row.original.readDateTime ? 'fw-normal  ' : 'fw-bold text-dark'
                  }`}
                  onClick={() =>
                    navigate(
                      `details/${
                        cell.row.original.mainMessageId !== 0
                          ? cell.row.original.mainMessageId
                          : cell.row.original.notificationId
                      }`
                    )
                  }
                >
                  {cell.row.original.notificationTitle}
                </p>
                <div
                  className={`d-flex align-items-center justify-content-between ${
                    cell.row.original.readDateTime ? 'fw-normal  ' : 'fw-bold text-dark'
                  }`}
                >
                  <p
                    className='my-0  fs-7'
                    dangerouslySetInnerHTML={{
                      __html: `${htmlNode.innerText.substring(0, MOBILE_MESSAGE_LENGTH)} ${
                        htmlNode.innerText.length > MOBILE_MESSAGE_LENGTH ? ' ...' : ''
                      }`,
                    }}
                    onClick={() =>
                      navigate(
                        `details/${
                          cell.row.original.mainMessageId !== 0
                            ? cell.row.original.mainMessageId
                            : cell.row.original.notificationId
                        }`
                      )
                    }
                  ></p>

                  <button
                    className='btn  btn-clear btn-active-light-primary p-0 '
                    type='button'
                    onClick={() => handleMarkPinned(cell.row.original.notificationUsersId, pinned)}
                  >
                    <i className={`fa-solid fa-star fs-5 m-0 ${pinned && 'text-warning'}`}></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
}
