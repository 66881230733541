import React, {FC, ReactElement} from 'react'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import AvatarWithLetters from '../../../../general-components/avatar-with-letters'
import {KidAssessmentModal} from '../../kid-management/kids-list/kid-assessment-modal/KidAssessmentModal'
import {useIntl} from 'react-intl'
import {Progress} from 'reactstrap'
import './styles.scss'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import useAssessment from '../../../../hooks/useAssessments'
interface Props {
  data: any
}
const AssessmentAccordions: FC<Props> = ({data}): ReactElement => {
  const intl = useIntl()
  const {globalLoader, kidsList} = useAppContext()
  const {
    orginilaGroupedData,
    groupedData,
    setSelected,
    selected,
    intitialPositionId,
    setIntitialPositionId,
  } = useAssessment(data)
  return (
    <div>
      {!globalLoader &&
        groupedData?.length > 0 &&
        groupedData?.map((item: any, index: number) => {
          const currentKid = kidsList?.find((kid: any) => kid.kidId === item.kid.kidId)
          const original = orginilaGroupedData.find((ii: any) => ii.name === currentKid?.kidName)
          if (!currentKid) return <UsersListLoading />
          return (
            <UncontrolledAccordion>
              <AccordionItem>
                <AccordionHeader targetId={`${index}`}>
                  <div className=' '>
                    <div className='d-flex align-items-start'>
                      <AvatarWithLetters
                        image={item.image}
                        alt={item.name.charAt(0).toUpperCase()}
                        verysmall
                      />
                      <div className='m-0 ms-3 '>
                        <h5 className='m-0'>{item.name}</h5>
                        <p className='m-0 text-muted'>
                          {item?.classes.map(
                            (cc: any, index: number) =>
                              `${cc.classesName} ${item.classes.length - 1 > index ? ',' : ' '} `
                          )}
                        </p>
                        <div className='d-flex gy-2 justify-content-start  '>
                          <p className=' text-start'>
                            <b className='text-muted'>
                              {intl.formatMessage({id: 'KIDLING.HOME.MEDICINE'})}:
                            </b>
                          </p>
                          <p className=' text-start text-muted me-2'>
                            {currentKid?.medicine ?? '--'}
                          </p>
                          <span className='text-muted'>{' | '}</span>
                          <p className=' text-start text-muted ms-2'>
                            <b>{intl.formatMessage({id: 'KIDLING.HOME.MEDICAL_ISSUES'})}:</b>
                          </p>
                          <p className=' text-start text-muted me-2'>
                            {currentKid?.medicalIssues ?? '--'}
                          </p>
                          <span className='text-muted'>{' | '}</span>

                          <p className=' text-start text-muted ms-2'>
                            <b className='text-muted'>
                              {intl.formatMessage({id: 'KIDLING.HOME.FEARS'})}:
                            </b>
                          </p>
                          <div className='text-muted text-start'>{currentKid?.fears ?? '--'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={`${index}`}>
                  {item.subItems.map((innerItem: any, index1: number) => {
                    const originalSubItem = original?.subItems.find(
                      (ii: any) => ii.name === innerItem.name
                    )
                    return (
                      <UncontrolledAccordion>
                        <AccordionItem>
                          <AccordionHeader targetId={`${innerItem.name}`}>
                            <div className='m-0 p-0   w-100   rounded-top  p-4 bg-light'>
                              <div className='d-flex align-items-center justify-content-between'>
                                <h5> {innerItem.name}</h5>
                                {innerItem &&
                                innerItem?.items?.length === innerItem?.countOfFilled ? (
                                  <i className='fa-solid fs-3 fa-circle-check text-success'></i>
                                ) : (
                                  <h5>
                                    {originalSubItem?.countOfFilled}/
                                    {originalSubItem?.items?.length}
                                  </h5>
                                )}
                              </div>
                              <div className='progressbar'>
                                <Progress
                                  max={originalSubItem?.items?.length}
                                  value={originalSubItem?.countOfFilled}
                                />
                              </div>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId={`${innerItem.name}`}>
                            {innerItem &&
                              innerItem?.items?.map((subItem: any, index1: number) => (
                                <div className=' border border-1 p-5' key={index1}>
                                  <div className='d-flex align-items-center'>
                                    <p className='m-0 p-0 text-info'>
                                      {subItem.assessmentCategoryName}
                                    </p>
                                    <h5 className='m-0 mx-2 p-0'>/</h5>
                                    <p className='m-0 p-0 text-info'>
                                      {subItem.assessmentSubCategoryName}
                                    </p>
                                  </div>
                                  <div
                                    className='d-flex align-items-center justify-content-between'
                                    key={subItem.id}
                                  >
                                    <div className='d-flex flex-column'>
                                      <p className='m-0 p-0 fw-bold'>{subItem.title}</p>
                                      <p className='text-muted'>{subItem.desc}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-start'>
                                      {subItem.tag ? (
                                        <span
                                          className={`badge`}
                                          style={{backgroundColor: 'var(--bs-primary)'}}
                                        >
                                          {subItem.tag}
                                        </span>
                                      ) : (
                                        <button
                                          type='button'
                                          className='btn  p-1 m-0 d-flex align-items-center justify-content-center'
                                          onClick={() => {
                                            setSelected && setSelected([item.kid])
                                            setIntitialPositionId(subItem.title)
                                          }}
                                        >
                                          <i className='fa-solid fa-pen-to-square fs-3 m-0 p-0'></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div className='w-100 d-flex justify-content-end'>
                                    <p className='m-0   p-0 text-muted'>{subItem.date}</p>
                                  </div>
                                </div>
                              ))}
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    )
                  })}
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          )
        })}
      {intitialPositionId && selected?.length > 0 && (
        <KidAssessmentModal intitialPositionId={intitialPositionId} />
      )}
    </div>
  )
}

export default AssessmentAccordions
