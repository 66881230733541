/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {MoodHeader} from './header/MoodHeader'
import useMood from '../../../../hooks/useMood'
function Moods() {
  const {internalPagesDatesFilters, globalLoader} = useAppContext()
  const intl = useIntl()
  const {readMoods, data} = useMood()

  useEffect(() => {
    if (!globalLoader) readMoods()
  }, [internalPagesDatesFilters])

  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <MoodHeader title={intl.formatMessage({id: 'KIDLING.HOME.MOODS'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Moods
