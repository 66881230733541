import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getKidsProtocols = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.KID_PROTOCOLS_LIST}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteKidProtocol = async (id: string) => {
  return axios
    .delete(`${endpoints.KID_PROTOCOL}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidProtocol = async (data: any) => {
  return axios
    .put(`${endpoints.KID_PROTOCOL}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
