/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useIntl} from 'react-intl'
import {
  getKidsMedicalRecords,
  getMedicalTypes,
  updateKidMedicalRecord,
} from '../../../../../../actions/medical'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../../auth'
import {BulkMedical} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import moment from 'moment-timezone'
import {selectStyle} from '../../../../../utils/select-style'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
const animatedComponents = makeAnimated()

interface Props {
  setEditModal?: any
  isUserLoading: boolean
  data?: any
}
const KidMedicalModalForm: FC<Props> = ({setEditModal, isUserLoading, data}): ReactElement => {
  const {
    medicalTypes,
    setMedicalTypes,
    internalPagesFilters,
    setGlobalLoader,
    setKidsMedicalRecords,
    mobileConfiguration,
  } = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [notifyContact, setNotifyContact] = useState(false)
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [showTest, setShowTest] = useState<boolean>(data?.item?.covid19Case || false)
  const [testResult, setTestResult] = useState(false)

  const [userForEdit] = useState<any>({
    illness: data?.item?.medicalRecordTypeId
      ? {
          value: data?.item?.medicalRecordTypeId,
          label: data?.item?.medicalRecordTypeName,
        }
      : null,
    observation: data?.item?.remarks || '',
    temperature: data?.item?.temperatureCelcius || null,
    supplied: data?.item?.medicineName || '',
    date: moment(data?.item?.recordedAtDateTime).toDate() || new Date(),
    remediationTime: moment(data?.item?.checkupDateTime).toDate() || new Date(),
    fromDate: moment(data?.item?.covid19FromDate).toDate() || new Date(),
    toDate: moment(data?.item?.covid19ToDate).toDate() || new Date(),
  })

  const kidSchema = Yup.object().shape({
    illness: Yup.mixed(),
    observation: Yup.string(),
    temperature: Yup.string().nullable(),
    supplied: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    remediationTime: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    fromDate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    toDate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  useEffect(() => {
    if (medicalTypes.length === 0) readMedicalTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
    if (setEditModal) {
      setEditModal({show: false, data: null})
    }
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = []
        selected.map((kid) => kidIds.push(kid.kidId))
        let payload: any = {
          employeeId: currentUser?.employee?.employeeId,
          medicalRecordTypeId: values?.illness?.value,
          recordedAtDateTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          checkupDateTime: moment(values.remediationTime).format('yyyy-MM-DDTHH:mm:ss'),
          medicineName: values.supplied,
          temperatureCelcius: values.temperature,
          remarks: values.observation,
          covid19Case: showTest ? testResult : false,
          covid19FromDate: moment(values.fromDate).format('yyyy-MM-DDTHH:mm:ss'),
          covid19ToDate: moment(values.toDate).format('yyyy-MM-DDTHH:mm:ss'),
          companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
          mobileConfigurationId:
            mobileConfiguration?.KidMedicalRecord[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }

        if (data) {
          payload['kidId'] = data?.kid?.kidId
          payload['kidMedicalRecordId'] = data?.item?.kidMedicalRecordId
        } else {
          payload['kidIds'] = kidIds
        }
        let resp
        if (data) resp = await updateKidMedicalRecord(payload)
        else resp = await BulkMedical(payload)

        if (!!resp) {
          if (data) readMedicalRecords()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        reset()
        setSubmitting(true)
        //  cancel(true)
      }
    },
  })
  const reset = () => formik.resetForm()
  const readMedicalTypes = async () => {
    const resp = await getMedicalTypes()
    if (resp) setMedicalTypes(resp)
  }

  const readMedicalRecords = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.medical.classValue && !internalPagesFilters.medical.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.medical.classValue)
      filter = `Classes.Any(e=>e.ClassesId==${internalPagesFilters.medical.classValue})`
    if (internalPagesFilters.medical.classValue && internalPagesFilters.medical.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.medical.searchTerm)
      filter = filter + internalPagesFilters.medical.searchTerm

    const res = await getKidsMedicalRecords(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesFilters.medical.From,
      internalPagesFilters.medical.To
    )

    setKidsMedicalRecords(res)
    setGlobalLoader(false)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {data?.kid && (
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {data?.kid.imagePath ? (
                    <img
                      src={
                        data?.kid.imagePath !== ''
                          ? data?.kid.imagePath
                          : '/media/avatars/blank.png'
                      }
                      alt={`${data?.kid.firstName.charAt(0).toUpperCase()} ${data?.kid.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${data?.kid.firstName.charAt(0).toUpperCase()} ${data?.kid.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{data?.kid.firstName}</div>
              </div>
            )}
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          <div className='fv-row my-7'>
            <label className='form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HOME.ILLNESS_TYPE'})}
            </label>
            <div className='  w-100 w-md-auto'>
              <Select
                openMenuOnFocus={true}
                value={formik.values.illness}
                onChange={(val: any) => formik.setFieldValue('illness', val)}
                options={medicalTypes.map((item: any) => ({
                  value: item.medicalRecordTypeId,
                  label: item.medicalRecordTypeName,
                }))}
                isClearable={true}
                components={animatedComponents}
                isLoading={medicalTypes.length === 0}
                placeholder={intl.formatMessage({id: 'KIDLING.HOME.ILLNESS_TYPE'})}
                className='w-100  '
                formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.label}</p>}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                styles={selectStyle}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.HOME.TEMPERATURE'})} C
                </label>
                <input
                  {...formik.getFieldProps('temperature')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.temperature && formik.errors.temperature},
                    {
                      'is-valid': formik.touched.temperature && !formik.errors.temperature,
                    }
                  )}
                  name='temperature'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.temperature && formik.errors.temperature && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.temperature}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>{' '}
            <div className='col-6'>
              {' '}
              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.HOME.SUPPLIED'})}
                </label>
                <input
                  {...formik.getFieldProps('supplied')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.supplied && formik.errors.supplied},
                    {
                      'is-valid': formik.touched.supplied && !formik.errors.supplied,
                    }
                  )}
                  name='supplied'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.supplied && formik.errors.supplied && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.supplied}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            {' '}
            <div className='col-6'>
              {' '}
              <div className='fv-row '>
                <label className='label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.DETECTED_DATE'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.date && formik.errors.date},
                    {
                      'is-valid': formik.touched.date && !formik.errors.date,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    dateTime
                    onAccept={(date: any) => {
                      formik.setFieldValue('date', date)
                    }}
                    value={formik.values.date}
                  />
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='fv-row '>
                <label className='label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.REMEDIATION_AT'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.remediationTime && formik.errors.remediationTime},
                    {
                      'is-valid': formik.touched.remediationTime && !formik.errors.remediationTime,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    dateTime
                    onAccept={(date: any) => {
                      formik.setFieldValue('remediationTime', date)
                    }}
                    value={formik.values.remediationTime}
                  />
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='fv-row my-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.HOME.OBSERVATION'})}
                </label>
                <textarea
                  {...formik.getFieldProps('observation')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.observation && formik.errors.observation},
                    {
                      'is-valid': formik.touched.observation && !formik.errors.observation,
                    }
                  )}
                  name='observation'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  rows={1}
                />
                {formik.touched.observation && formik.errors.observation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.observation}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className='hr' />
          <div className='d-flex-align-items-center mt-7'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={showTest}
              onChange={(e) => {
                setShowTest((prev) => !prev)
              }}
            />{' '}
            <label className='ml-2 label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HOME.COVID19'})}
            </label>
          </div>

          {showTest && (
            <div className='row mt-7'>
              <div className='col-12 my-2'>
                <label className='label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.TEST_RESULTS'})}
                </label>
              </div>
              <div className='fv-row mb-10'>
                <div className='row g-9  w-100'>
                  <div className='col-12 col-sm-4'>
                    <label
                      className='btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-2'
                      data-kt-button='true'
                    >
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='testResult'
                          onChange={() => setTestResult(true)}
                          checked={testResult === true}
                        />
                      </span>

                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>
                          {' '}
                          {intl.formatMessage({id: 'KIDLING.HOME.POSITIVE'})}
                        </span>
                      </span>
                    </label>
                  </div>

                  <div className='col-12 col-sm-4'>
                    <label
                      className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-2'
                      data-kt-button='true'
                    >
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='testResult'
                          onChange={() => setTestResult(false)}
                          checked={testResult === false}
                        />
                      </span>

                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>
                          {intl.formatMessage({id: 'KIDLING.HOME.NEGATIVE'})}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                {' '}
                <div className='col-6'>
                  {' '}
                  <div className='fv-row '>
                    <label className='label  form-label fs-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'KIDLING.HOME.FROM_DATE'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.fromDate && formik.errors.fromDate},
                        {
                          'is-valid': formik.touched.fromDate && !formik.errors.fromDate,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        dateTime
                        onAccept={(date: any) => {
                          formik.setFieldValue('fromDate', date)
                        }}
                        value={formik.values.fromDate}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='fv-row '>
                    <label className='label  form-label fs-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'KIDLING.HOME.TO_DATE'})}
                    </label>
                    <div
                      className={clsx(
                        {
                          'is-invalid': formik.touched.toDate && formik.errors.toDate,
                        },
                        {
                          'is-valid': formik.touched.toDate && !formik.errors.toDate,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        dateTime
                        onAccept={(date: any) => {
                          formik.setFieldValue('toDate', date)
                        }}
                        value={formik.values.toDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid my-2'>
          <input
            className='form-check-input w-30px h-20px'
            type='checkbox'
            name='notifications'
            checked={notifyContact}
            onChange={() => setNotifyContact((prev) => !prev)}
          />
          <span className='form-check-label text-muted fs-7'>
            {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
          </span>
        </label>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidMedicalModalForm}
