import {DeregistrationFilter} from './DeregistrationFilter'

const Deregistrationtoolbar = () => {
  return (
    <div
      className='d-flex flex-md-row flex-column  w-100  w-md-auto '
      data-kt-user-table-toolbar='base'
    >
      <DeregistrationFilter />
    </div>
  )
}

export {Deregistrationtoolbar}
