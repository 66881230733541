/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {MealsHeader} from './header/MealsHeader'
import useMealTimes from '../../../../hooks/useMealTimes'
function MealTimes() {
  const {globalLoader, internalPagesDatesFilters} = useAppContext()
  const intl = useIntl()
  const {readMealTimes, data} = useMealTimes()

  useEffect(() => {
    if (!globalLoader) readMealTimes()
  }, [internalPagesDatesFilters])

  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <MealsHeader title={intl.formatMessage({id: 'KIDLING.MENU.MEALTIME'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default MealTimes
