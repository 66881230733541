import axios from 'axios'
import {PINBOARD} from '../config/endpoints'
import moment from 'moment'

export const getPinboard = async (filter: string) => {
  return axios
    .get(`${PINBOARD}/listwhere/${filter}`)
    .then((response) =>
      response.data.sort((a: any, b: any) => moment(b?.pinboardDatetime).diff(a?.pinboardDatetime))
    )
    .catch((error) => {
      console.log(error)
    })
}
