import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {HeaderProps} from 'react-table'
import {User} from '../../modules/apps/kid-management/kids-list/core/_models'
import {useMediaQuery} from 'react-responsive'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<User>>
}
const UserCustomHeader: FC<Props> = ({className, title, tableProps}) => {
  const intl = useIntl()
  const isMobile = useMediaQuery({
    query: '(max-width:1000px)',
  })

  const id = tableProps.column.id
  const [state, setState] = useState<any>({})
  const isSelectedForSorting = useMemo(() => {
    return state?.sort && state?.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state?.order, [state])

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      // enable sort asc

      setState({sort: id, order: 'asc'})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        // enable sort desc
        setState({sort: id, order: 'desc'})
        return
      }

      // disable sort
      setState({sort: undefined, order: undefined})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isMobile ? 'min-w-50px' : 'min-w-125px',
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {intl.formatMessage({id: title})}
    </th>
  )
}

export {UserCustomHeader}
