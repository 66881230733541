import {FC} from 'react'
import {RequestsSearchComponent} from './RequestsSearchComponent'
import {RequestsToolbar} from './RequestsToolbar'
import {DeliveredFilter} from './DeliveredFilter'
import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import moment from 'moment-timezone'
import useRequests from '../../../../../hooks/useRequests'

interface Props {
  title?: string
}
const RequestsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useRequests()
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100'>
      {title && <h4>{title}</h4>}
      <div className='d-flex  align-items-center justify-content-between w-100 flex-wrap'>
        <div className='d-flex align-items-center w-100 w-sm-auto'>
          <RequestsSearchComponent />
          <RequestsToolbar />
        </div>
        <div className='d-flex align-items-center gap-5 justify-content-end w-100 w-sm-auto'>
          <DeliveredFilter />
          <CustomDateRangePicker
            handleDateChange={handleDateChange}
            initialValue={{
              startDate: moment().startOf('year').toDate(),
              endDate: moment().endOf('year').toDate(),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export {RequestsHeader}
