/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import makeAnimated from 'react-select/animated'
import {useAuth} from '../../../../auth'
import {BulkMood} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {Link} from 'react-router-dom'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {getKidsMoods} from '../../../../../../actions/moods'
import {momentDateFormat} from '../../../../../../config/constants'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
const animatedComponents = makeAnimated()
type Props = {
  isUserLoading: boolean
}

const KidMoodModalForm: FC<Props> = ({isUserLoading}) => {
  const company = window.location.pathname.split('/')[1]
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [currentMoods, setCurrentMoods] = useState([])
  const [kidMoods, setKidMoods] = useState([])
  const {moods, mobileConfiguration} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [notifyContact, setNotifyContact] = useState(false)
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [userForEdit] = useState({
    mood: '',
    notes: '',
    date: new Date(),
    startTime: null,
    endTime: null,
  })

  useEffect(() => {
    const temp: any = []
    moods.map((item: any) => temp.push({value: item.moodId, label: item.moodName}))
    setCurrentMoods(temp)
  }, [moods])

  const kidSchema = Yup.object().shape({
    mood: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    // startTime: Yup.date()
    //   .nullable()
    //   .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
    //   .required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    // endTime: Yup.date()
    //   .nullable()
    //   .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
    //   .test(
    //     'isValidTime',
    //     intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
    //     (value: any, context: any) => {
    //       const totalMin = moment.duration(moment(value).diff(context.parent.startTime)).asMinutes()

    //       if (totalMin > 0) {
    //         return true
    //       }
    //       return false
    //     }
    //   )
    //   .required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let moodIds: number[] = []
        formik.values.mood.map((mood: any) => moodIds.push(mood.value))

        let kidIds: number[] = []
        selected.map((kid) => kidIds.push(kid.kidId))
        const data = {
          kidIds,
          moodIds,
          employeeId: currentUser?.employee?.employeeId,
          companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
          date: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          fromTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          toTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          notes: values.notes,
          mobileConfigurationId: mobileConfiguration?.KidMood[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }

        const resp = await BulkMood(data)

        if (!!resp) {
          //clearSelected()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          //cancel(true)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // clearSelected()
        reset()
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  useEffect(() => {
    readMoods()
  }, [selected, formik.values])

  const reset = () => formik.resetForm()

  const readMoods = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'
    const res = await getKidsMoods(
      filter,
      currentUser?.employee?.employeeId || 0,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )

    setKidMoods(res)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidMoods?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'0'}>
                {kidMoods?.map((item: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.kidMoods?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.moodName}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal.notes}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.date).format('DD.MM.yyyy')}
                                  {'  '}
                                  {moment(meal.fromTime).format('HH:mm')} {' - '}{' '}
                                  {moment(meal.toTime).format('HH:mm')}
                                </p>
                              </div>
                              <Link
                                to={
                                  company
                                    ? `/${company}/kid-management/mood`
                                    : '/kidling/kid-management/mood'
                                }
                                className='btn btn-light p-1 px-4 mt-2'
                              >
                                <i className='fa-solid fa-arrow-right'></i>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row mt-7'>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>
          {/* <div className=' row my-7'>
            <div className='col-6'>
              <label className='label  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.HOME.START_TIME'})}
              </label>
              <div
                className={clsx(
                  {'is-invalid': formik.touched.startTime && formik.errors.startTime},
                  {
                    'is-valid': formik.touched.startTime && !formik.errors.startTime,
                  }
                )}
              >
                <ResponsiveDatePicker
                  timeOnly
                  onAccept={(date: any) => {
                    formik.setFieldValue('startTime', date)
                  }}
                  value={formik.values.startTime}
                />
              </div>
            </div>
            <div className='col-6'>
              <label className='label  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.HOME.END_TIME'})}
              </label>
              <div
                className={clsx(
                  {'is-invalid': formik.touched.endTime && formik.errors.endTime},
                  {
                    'is-valid': formik.touched.endTime && !formik.errors.endTime,
                  }
                )}
              >
                <ResponsiveDatePicker
                  timeOnly
                  onAccept={(date: any) => {
                    formik.setFieldValue('endTime', date)
                  }}
                  value={formik.values.endTime}
                />
              </div>
              {formik.errors.endTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.endTime}</span>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          <div className='fv-row my-7'>
            <Select
              openMenuOnFocus={true}
              value={formik.values.mood}
              onChange={(val) => {
                formik.setFieldValue('mood', val)
              }}
              options={currentMoods}
              isClearable={true}
              components={animatedComponents}
              isMulti
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.MOOD'})}
              className='w-100  '
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
            {formik.errors.mood && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.mood}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('notes')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.notes && formik.errors.notes},
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              name='notes'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
          <input
            className='form-check-input w-30px h-20px'
            type='checkbox'
            name='notifications'
            checked={notifyContact}
            onChange={() => setNotifyContact((prev) => !prev)}
          />
          <span className='form-check-label text-muted fs-7'>
            {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
          </span>
        </label>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidMoodModalForm}
