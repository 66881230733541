import moment from 'moment-timezone'
import {FC, ReactElement, useState} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useIntl} from 'react-intl'
import AvatarWithLetters from '../avatar-with-letters'
import ErrorText from '../error-text/ErrorText'
import './activityCard.scss'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
interface Props {
  image: string
  name: string
  activities: any
  setShowEditModal?: any
  handleDelete?: any
}
const ActivityCard: FC<Props> = ({
  image,
  name,
  activities,
  setShowEditModal,
  handleDelete,
}): ReactElement => {
  const [showDelete, setShowDelete] = useState(false)
  const [activityId, setActivityId] = useState(null)
  const intl = useIntl()
  const {mode} = useThemeMode()
  const nameArray = name
    .trim()
    .replace(/\s{2,}/g, ' ')
    .split(' ')

  return (
    <div className='shadow-lg rounded d-flex flex-column align-items-center pt-8 pb-8 h-100  '>
      <AvatarWithLetters
        image={image}
        alt={`${nameArray[0].charAt(0).toUpperCase()} ${nameArray[1].charAt(0).toUpperCase()} `}
        rounded
      />
      <h5 className='mt-2'>{name}</h5>
      <div className='d-flex flex-column mt-8 w-100  '>
        {activities.map((activity: any, index: number) => (
          <div
            className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2 activity__card__item position-relative '
            style={{minHeight: activity.covid ? '7rem' : 'auto'}}
            key={index}
          >
            {activity.covid && (
              <img
                src='/media/logos/covide.png'
                alt='covid'
                className='position-absolute bottom-0 end-0 w-60px object-contain my-2 ms-7 me-2'
              />
            )}
            <div className='position-absolute bottom-0 end-0 w-60px object-contain m-2  d-flex justify-content-end mx-6'>
              {activity.Icon && activity.Icon}
            </div>

            <div className='w-100 ps-2'>
              <div className='d-flex align-items-center justify-content-between'>
                <h5 className='p-0 m-0'>{activity.title}</h5>
                {activity.tag && (
                  <span
                    className={`badge ${mode === 'dark' && 'text-dark'}`}
                    style={{backgroundColor: 'var(--bs-primary)'}}
                  >
                    {activity.tag}
                  </span>
                )}
              </div>

              {activity?.subtitle && (
                <p
                  className={`m-0 p-0 ${mode === 'dark' ? 'text-dark' : 'text-muted '}`}
                  dangerouslySetInnerHTML={{__html: activity.subtitle}}
                ></p>
              )}
              {activity?.subtitle1 !== '' && (
                <p
                  className={`m-0 p-0   fs-9 lh-sm   ${
                    mode === 'dark' ? 'text-dark' : 'text-muted '
                  }`}
                >
                  {activity.subtitle1}
                </p>
              )}
              {activity?.desc && (
                <p className={`m-0 p-0  ${mode === 'dark' ? 'text-dark' : 'text-muted '}`}>
                  {activity.desc}
                </p>
              )}
              {activity?.date && (
                <p className={`m-0 p-0  ${mode === 'dark' ? 'text-dark' : 'text-muted '}`}>
                  {moment(activity.date).format('DD.MM.yyyy HH:mm')}
                </p>
              )}
            </div>

            <div className='d-flex align-items-center ms-2'>
              {activity.setShowEditModal && (
                <button className='btn bt-sm p-0' type='button' onClick={activity.setShowEditModal}>
                  <i className='fa-solid fa-pen delete__icon'></i>
                </button>
              )}
              <button
                className='btn bt-sm p-0  '
                type='button'
                onClick={() => {
                  setActivityId(activity.id)
                  setShowDelete(true)
                }}
              >
                <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
              </button>
            </div>
          </div>
        ))}
      </div>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          handleDelete(activityId)
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default ActivityCard
