import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {KTCard} from '../../../../_metronic/helpers'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useEffect} from 'react'
import Diapers from './components/Diapers'

const DiapersPage = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidWindingProtocol[0]?.showInEducatorApp) {
      navigate('/')
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileConfiguration])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <Diapers />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default DiapersPage
