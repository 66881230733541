/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../../auth'
import {BulkRequest} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {Link} from 'react-router-dom'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {getKidsRequests} from '../../../../../../actions/requests'
import {momentDateFormat} from '../../../../../../config/constants'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
type Props = {
  isUserLoading: boolean
}

const KidRequestModalForm: FC<Props> = ({isUserLoading}) => {
  const company = window.location.pathname.split('/')[1]
  const [kidsRequests, setKidsRequests] = useState([])
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {requestItems, mobileConfiguration} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [selectedRequests, setSelectedRequests] = useState<any>([])
  const [notes, setNotes] = useState<any>({})
  const intl = useIntl()
  const [notifyContact, setNotifyContact] = useState(false)
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = []
        let requests: any = []
        selected.map((kid) => kidIds.push(kid.kidId))
        Object.keys(notes).map((key, value) =>
          requests.push({
            requestItemId: key,
            employeeId: currentUser?.employee?.employeeId,
            companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
            sentDate: moment().format('yyyy-MM-DDTHH:mm:ss'),
            dueDate: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            note: notes[key],
            delivered: false,
            deliveredDate: moment().format('yyyy-MM-DDTHH:mm:ss'),
          })
        )
        const data = {
          kidIds,
          requestKids: requests,
          mobileConfigurationId: mobileConfiguration?.RequestKid[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }

        const resp = await BulkRequest(data)

        if (!!resp) {
          // clearSelected()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          // cancel(true)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // clearSelected()
        reset()
        setSubmitting(true)
        // cancel(true)
      }
    },
  })
  const reset = () => formik.resetForm()
  const handleCheckRequest = (item: any) => {
    let tempRequests: any = [...selectedRequests]

    const isExitst = tempRequests.find((i: number) => i === item.requestItemId)

    if (!!isExitst) {
      tempRequests = tempRequests.filter((i: number) => i !== item.requestItemId)
      delete notes[item.requestItemId]
    } else {
      tempRequests.push(item.requestItemId)
      setNotes((prev: any) => ({...prev, [item.requestItemId]: ''}))
    }
    setSelectedRequests(tempRequests)
  }
  useEffect(() => {
    readRequests()
  }, [selected, formik.values])

  const readRequests = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (filter !== null) filter += ' AND '
    filter += `(requests.Any(x => x.delivered=true) OR requests.Any(x => x.delivered=false))`
    if (selected.length > 0) filter += ' ) '

    if (filter === '') filter = '1=1'
    const res: any = await getKidsRequests(
      filter,
      currentUser?.employee?.employeeId || 0,
      null,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )
    setKidsRequests(res)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidsRequests?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'0'}>
                {kidsRequests?.map((item: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.requests?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.requestItemName}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal.note}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.sentDate).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <div className='d-flex flex-column align-items-center'>
                                {!meal.delivered && (
                                  <i className='fa-solid fa-people-arrows m-0 p-0' />
                                )}
                                <Link
                                  to={
                                    company
                                      ? `/${company}/kid-management/requests`
                                      : '/kidling/kid-management/requests'
                                  }
                                  className='btn btn-light p-1 px-4 mt-2'
                                >
                                  <i className='fa-solid fa-arrow-right'></i>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className=' row mt-7'>
            <div className='col-12  '>
              {/* <label className='label  form-label  fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.HOME.DUEDATE'})}
              </label> */}
              <div
                className={clsx(
                  {'is-invalid': formik.touched.date && formik.errors.date},
                  {
                    'is-valid': formik.touched.date && !formik.errors.date,
                  }
                )}
              >
                <ResponsiveDatePicker
                  onAccept={(date: any) => {
                    formik.setFieldValue('date', date)
                  }}
                  value={formik.values.date}
                />
              </div>
            </div>
          </div>
          <div className='fv-row mb-7'>
            <div className='form-check form-check-inline w-100 ps-0'>
              {requestItems?.map((item: any, index: number) => (
                <div className='d-flex flex-column w-100 my-4 ' key={index}>
                  <div className='d-flex align-items-center mb-2'>
                    {/* <i className='fa-solid fa-mug-hot me-2'></i> */}
                    <div className='form-check form-check-success'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                        onChange={() => handleCheckRequest(item)}
                      />
                      <label className='form-check-label'>{item.requestItemName}</label>
                    </div>
                  </div>
                  <input
                    {...formik.getFieldProps('notes')}
                    className={clsx(
                      'form-control   w-100',
                      {'is-invalid': formik.touched.notes && formik.errors.notes},
                      {
                        'is-valid': formik.touched.notes && !formik.errors.notes,
                      }
                    )}
                    name='notes'
                    value={notes[item.requestItemId]}
                    onChange={(e) => {
                      setNotes((prev: any) => ({...prev, [item.requestItemId]: e.target.value}))
                    }}
                    disabled={
                      selectedRequests?.find((i: number) => i === item.requestItemId) ? false : true
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidRequestModalForm}
