/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useAppContext} from '../../../layout/core/AppContextProvider'
import {useIntl} from 'react-intl'
import {IconButton, Menu} from '@mui/material'

const Languages: FC = () => {
  const lang = useLang()
  const {languages}: any = useAppContext()
  const [currentLanguage, setCurrentLanguage] = useState<any>(null)
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    if (languages?.length > 0) {
      const temp = languages?.find((x: any) => x.value === lang)
      setCurrentLanguage(temp)
    }
  }, [languages])

  return (
    <div
      className='menu-item  w-100'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <IconButton
        onClick={handleClick}
        size='small'
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        className='  w-100 rounded-0 px-7'
      >
        <span className=' position-relative d-flex align-items-center justify-content-between w-100 px-1'>
          <span className='menu-link fs-6 ps-2'>
            {intl.formatMessage({id: 'KIDLING.HOME.LANGUAGE'})}
          </span>

          <span className='fs-8 rounded bg-light px-2  py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.label}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <div className='menu-sub menu-sub-dropdown d-block  w-150px w-sm-175px py-4'>
          {languages?.map((l: any) => (
            <div
              className='menu-item px-3'
              key={l.value}
              onClick={() => {
                setLanguage(l.value)
              }}
            >
              <a
                href='#!'
                className={clsx('menu-link d-flex px-5', {
                  active: l.value === currentLanguage?.value,
                })}
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={l.flag} alt='metronic' />
                </span>
                {l.label}
              </a>
            </div>
          ))}
        </div>
      </Menu>
    </div>
  )
}

export {Languages}
