import {FC, ReactElement, useState} from 'react'
import './styles.scss'
interface ImageProps {
  img: string
}
const ImageWithEnlargeChat: FC<ImageProps> = ({img}): ReactElement => {
  const [enlarge, setEnlarge] = useState(false)

  return (
    <div
      className={` ${
        enlarge
          ? 'full__image__chat d-flex justify-content-center align-items-center  w-screen h-screen'
          : 'image__container__chat '
      }`}
      onClick={() => !enlarge && setEnlarge(true)}
    >
      {enlarge && (
        <button className='close__btn' onClick={() => setEnlarge(false)}>
          <i className='fa-solid fa-xmark'></i>
        </button>
      )}

      {!enlarge && (
        <button className='enlarge__btn' onClick={() => setEnlarge(true)}>
          <i className='fa-solid fa-expand'></i>
        </button>
      )}
      <div
        className={`${
          enlarge &&
          'slider__image__animation__chat d-flex justify-content-center align-items-center'
        }`}
      >
        <img
          src={img}
          className={`${
            enlarge
              ? 'd-flex justify-content-center align-items-center object-fit-contain'
              : 'cursor-pointer rounded object-fit-cover w-200px h-150px w-100'
          }`}
          alt={`imagechat`}
        />
      </div>
    </div>
  )
}

export default ImageWithEnlargeChat
