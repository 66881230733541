/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useAuth} from '../../../../../../auth'
import {UsersListLoading} from '../../../../../../../general-components/loading/UsersListLoading'
import moment from 'moment'
import {useAppContext} from '../../../../../../../../_metronic/layout/core/AppContextProvider'
import {BulkAttendace} from '../../../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import ResponsiveDatePicker from '../../../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {getAttendanceByFilter} from '../../../../../../../../actions/attendace'
import {groupBy} from '../../../../../../../utils/common'
import {getAttendanceLocations} from '../../../../../../../../actions/location'
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap'
import {Avatar} from '@mui/material'
import {useCompanyContext} from '../../../../../../../../_metronic/layout/core/CompanyProvider'
interface Props {
  showModal: {kidId: number | null; kidAttendanceId: number | null; show: boolean}
  setShowModal: any
  readAllKids: any
}
const CheckinDialog: FC<Props> = ({showModal, setShowModal, readAllKids}): ReactElement => {
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]
  const {setAttendanceLocations, attendanceLocations, kidsList} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [showSuccess, setShowSuccess] = useState(false)
  const {currentUser} = useAuth()
  const [kidsAttendance, setKidsAttendance] = useState<any>({})
  const [userForEdit] = useState({
    location: null,
    employee: '',
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    employee: Yup.string().nullable(),
    location: Yup.mixed(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          kidIds: [showModal.kidId],
          employeeId: currentUser?.employee?.employeeId,
          dateTimeIn: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          attendanceLocationId: 0,
          companyId: currentCompany?.value,
          employeeRemarks: values.employee,
          mobileConfigurationId: 0,
        }

        const resp = await BulkAttendace(data)

        if (!!resp) {
          if (readAllKids) readAllKids()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          close()
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex: any) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const close = () => {
    formik.resetForm()
    setShowModal({kidId: null, show: false})
  }

  useEffect(() => {
    if (showModal.kidId) readAttendace()
  }, [showModal.kidId, formik.values])
  useEffect(() => {
    if (attendanceLocations?.length === 0 && currentCompany?.value) readAttendanceLocations()
  }, [])

  const readAttendace = async () => {
    let filter: string = ''

    filter += `kidId=${showModal.kidId} AND `

    filter += `dateTimeIn >="${moment(formik.values.date)
      .startOf('day')
      .format('yyyy-MM-DDTHH:mm:ss')}" AND dateTimeIn<"${moment(formik.values.date)
      .endOf('day')
      .format('yyyy-MM-DDTHH:mm:ss')}" `

    const res = await getAttendanceByFilter(filter)

    const grouped = groupBy(res, 'kidId')

    setKidsAttendance(grouped)
  }

  const readAttendanceLocations = async () => {
    const resp = await getAttendanceLocations(`companyId=${currentCompany?.value}`)
    if (resp) setAttendanceLocations(resp)
  }

  return (
    <Modal isOpen={showModal.show} toggle={close}>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'KIDLING.HOME.CHECKIN'})}</ModalHeader>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <ModalBody>
          <>
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {Object.keys(kidsAttendance)?.length > 0 && (
                <div className='mt-4 mh-200px overflow-auto'>
                  <UncontrolledAccordion defaultOpen={'-1'}>
                    {Object.keys(kidsAttendance)?.map((key: any, index: number) => {
                      const fullKid: any = kidsList?.find((i: any) => i.kidId === Number(key))
                      const Exists = kidsAttendance[key].find(
                        (item: any) =>
                          (item.employeeRemarks && item.employeeRemarks !== '') ||
                          (item.contactRemarks && item.contactRemarks !== '')
                      )

                      return (
                        Exists && (
                          <AccordionItem id={`${index}`}>
                            <AccordionHeader targetId={`${index}`}>
                              <div className='d-flex align-items-center'>
                                <Avatar
                                  src={fullKid?.imagePath}
                                  alt={`${fullKid?.firstName
                                    ?.charAt(0)
                                    ?.toUpperCase()} ${fullKid?.lastName
                                    ?.charAt(0)
                                    ?.toUpperCase()}`}
                                />
                                <h5 className='ms-2 my-0'> {fullKid?.kidName}</h5>
                              </div>
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                              <div className='d-flex flex-column w-100  '>
                                {kidsAttendance[key]?.map(
                                  (meal: any, index1: number) =>
                                    ((meal.employeeRemarks && meal.employeeRemarks !== '') ||
                                      (meal.contactRemarks && meal.contactRemarks !== '')) && (
                                      <div
                                        className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                                        key={index1}
                                      >
                                        <div>
                                          <h5 className='p-0 m-0'>
                                            {
                                              attendanceLocations?.find(
                                                (i: any) =>
                                                  i.attendanceLocationId ===
                                                  meal.attendanceLocationId
                                              )?.attendanceLocationName
                                            }
                                          </h5>

                                          {meal.employeeRemarks && (
                                            <p
                                              className='m-0 p-0 text-muted '
                                              dangerouslySetInnerHTML={{
                                                __html: meal.employeeRemarks,
                                              }}
                                            ></p>
                                          )}
                                          {meal.contactRemarks && (
                                            <p
                                              className='m-0 p-0 text-muted '
                                              dangerouslySetInnerHTML={{
                                                __html: meal.contactRemarks,
                                              }}
                                            ></p>
                                          )}
                                          <p className='m-0 p-0 text-muted'>
                                            {moment(meal.dateTimeIn).format('DD.MM.yyyy HH:mm')}
                                          </p>
                                        </div>
                                        <Link
                                          to={
                                            company
                                              ? `/${company}/kid-management/attendance`
                                              : '/kidling/kid-management/attendance'
                                          }
                                          className='btn btn-light p-1 px-4 mt-2'
                                        >
                                          <i className='fa-solid fa-arrow-right'></i>
                                        </Link>
                                      </div>
                                    )
                                )}
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        )
                      )
                    })}
                  </UncontrolledAccordion>
                </div>
              )}
              <div className='fv-row my-7'>
                <label className='label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.DATETIME'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.date && formik.errors.date},
                    {
                      'is-valid': formik.touched.date && !formik.errors.date,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    dateTime
                    onAccept={(val: any) => formik.setFieldValue('date', val)}
                    value={formik.values.date}
                  />
                </div>
              </div>

              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.EMPLOYEE_REMARKS'})}
                </label>
                <textarea
                  {...formik.getFieldProps('employee')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.employee && formik.errors.employee},
                    {
                      'is-valid': formik.touched.employee && !formik.errors.employee,
                    }
                  )}
                  name='employee'
                  autoComplete='off'
                  rows={1}
                />
                {formik.touched.employee && formik.errors.employee && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.employee}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}

            {/* end::Actions */}
          </>
        </ModalBody>
        <ModalFooter>
          <div className='text-center  '>
            <button
              type='reset'
              onClick={() => close()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </ModalFooter>
      </form>
      {formik.isSubmitting && <UsersListLoading />}
      <SweetAlert
        show={showSuccess}
        success
        title='Success!'
        onConfirm={(e) => setShowSuccess(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      {/* <SweetAlert
        show={showErrorMessage}
        error
        title={<ErrorText text='The Label Already Exists!' />}
        onConfirm={(e) => setShowErrorMessage(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      /> */}
    </Modal>
  )
}

export default CheckinDialog
