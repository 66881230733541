/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAuth} from '../../auth'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {RectShape} from 'react-placeholder/lib/placeholders'
import DashboardImageCard from '../../../general-components/Dashboard-image-card/DashboardImageCard'
import {getAllDashboardNotifications} from '../../../../actions/dashboard'
import moment from 'moment'
import {groupBy} from '../../../utils/common'
import NotificationActivityCard from '../../../general-components/NotificationActivityCard/NotificationActivityCard'
import {useSearchParams} from 'react-router-dom'
import TimeLine from '../../../general-components/Timeline/TimeLine'
import {NoRecords} from '../../../general-components/NoRecords'
import ImagesSliderProfile from '../../../general-components/Images-slider-profile/ImagesSlider'

function KidProfile() {
  const {mobileConfiguration} = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [searchParams] = useSearchParams()
  const intl = useIntl()
  const [attendanceTimeline, setAttendanceTimeline] = useState<any>({})
  const [absenceTimeline, setAbsenceTimeline] = useState<any>({})
  const [allActivites, setAllActivites] = useState<any>([])
  const [mediaData, setMediaData] = useState<any>({})
  const [noData, setNoData] = useState(true)
  const [isToday, setIsToday] = useState(true)
  const [kidId, setKidId] = useState<any>(-1)
  useEffect(() => {
    const kid = searchParams?.get('kidId')
    setKidId(Number(kid) ?? -1)
  }, [searchParams])

  useEffect(() => {
    if (!isLoading && kidId > 0 && currentUser) {
      setIsLoading(true)
      readAllTodayNotifications()
    }
  }, [kidId, mobileConfiguration, currentUser, isToday])

  const readAllTodayNotifications = async () => {
    let filter: string = ''

    filter = `kidId= ${kidId}`
    const resp = await getAllDashboardNotifications(
      filter,
      (isToday ? moment().startOf('day') : moment().subtract(1, 'days').startOf('day')).format(
        'yyyy-MM-DDTHH:mm:ss'
      ),
      (isToday ? moment().endOf('day') : moment().subtract(1, 'days').endOf('day')).format(
        'yyyy-MM-DDTHH:mm:ss'
      ),
      1,
      10
    )
    if (resp) {
      if (
        mobileConfiguration?.KidMedia &&
        mobileConfiguration?.KidMedia[0]?.showInParentApp === true
      ) {
        if (resp?.activities?.kidsMedia?.length > 0) setNoData(false)
        const grouped = groupBy(resp?.activities?.kidsMedia, 'createdDate')

        setMediaData(grouped)
      }

      let attendanceItems: any = []
      if (resp?.activities?.attendances?.length > 0) setNoData(false)
      const sortedAtt = resp.activities?.attendances?.sort((a: any, b: any) =>
        moment(a?.dateTimeIn).diff(b?.dateTimeIn)
      )

      sortedAtt?.map((item: any) => {
        attendanceItems.push({
          icon: 'fa-solid fa-turn-down ',
          title: `${moment(item.dateTimeIn).format('HH:mm')} `,
          subtitle: item.employeeRemarks,
          backgroundColor: '#A1EBE7',
          iconColor: '#1BC5BD',
          tag: item.attendanceLocationName,
          tagIcon: 'fa-solid fa-location-dot',
        })
        if (item.dateTimeOut !== null)
          attendanceItems.push({
            icon: 'fa-solid fa-turn-up ',
            title: ` ${moment(item.dateTimeOut).format('HH:mm')} `,
            subtitle: item.employeeRemarks,
            backgroundColor: '#EEE5FF',
            iconColor: '#8950FC',
            tag: item.attendanceLocationName,
            tagIcon: 'fa-solid fa-location-dot',
          })
        return item
      })

      ////absences
      let absencesItems: any = []
      if (resp?.activities?.absences?.length > 0) setNoData(false)
      resp.activities?.absences?.map((item: any) => {
        absencesItems.push({
          icon: 'fa-solid fa-arrow-left',
          title: `${moment(item.fromDate).format('DD.MM.yyyy')} `,
          subtitle: item.employeeRemarks,
          backgroundColor: '#ebc5a1',
          iconColor: '#c5211b',
          tag: item.attendanceLocationName,
          tagIcon: 'fa-solid fa-location-dot',
        })
        absencesItems.push({
          icon: 'fa-solid fa-arrow-right',
          title: `${moment(item.toDate).format('DD.MM.yyyy')} `,
          subtitle: item.employeeRemarks,
          backgroundColor: '#A1EBE7',
          iconColor: '#1BC5BD',
          tag: item.attendanceLocationName,
          tagIcon: 'fa-solid fa-location-dot',
        })
        return item
      })

      //rest of activities
      let drinks: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DRINKS'}),
        items: [],
        link: 'kid-management/drinks',
      }
      let events: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.EVENTS'}),
        items: [],
        link: 'kid-management/calender',
      }
      let protocols: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.PROTOCOLS'}),
        items: [],
        link: 'kid-management/protocols',
      }
      let activities: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ACTIVITIES'}),
        items: [],
        link: 'kid-management/activities',
      }
      let requests: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.REQUESTS'}),
        items: [],
        link: 'kid-management/requests',
      }
      let windingProtocols: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DIAPERS'}),
        items: [],
        link: 'kid-management/diapers',
      }
      let mealTime: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.EATING_HABIT'}),
        items: [],
        link: 'kid-management/mealtimes',
      }
      let sleeps: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.SLEEPS'}),
        items: [],
        link: 'kid-management/sleeptimes',
      }
      if (resp.activities?.drinks?.length > 0) {
        setNoData(false)
        resp.activities?.drinks[0].kidDrinks?.map((item: any) => {
          drinks.items.push({
            image: item.imagePath ?? '/media/dashboard/Drinks.jfif',
            defaultImage: '/media/dashboard/Drinks.jfif',
            title: item.kidDrinkName,
            subtitle: `Ml:${item.kidDrinkMl}`,
            date: item.dateTime,
            link: 'kid-management/drinks',
          })
          return item
        })
      }
      if (resp.activities?.events?.length > 0) {
        setNoData(false)
        resp.activities?.events?.map((item: any) => {
          events.items.push({
            image: item.imagePath ?? '/media/dashboard/Events.jfif',
            defaultImage: '/media/dashboard/Events.jfif',
            title: item.eventsName,
            subtitle: item.locationName,
            date: item.startsOn,
            endDate: item.endsOn,
            link: 'kid-management/calender',
          })
          return item
        })
      }
      if (resp.activities?.kidsProtocols?.length > 0) {
        setNoData(false)
        resp.activities?.kidsProtocols[0]?.kidProtocols?.map((item: any) => {
          protocols.items.push({
            image: '/media/dashboard/Protocols.jfif',
            defaultImage: '/media/dashboard/Protocols.jfif',
            title: item.title,
            subtitle: item.remarks,
            date: item.dateTime,
            link: 'kid-management/protocols',
          })
          return item
        })
      }
      if (resp.activities?.kidsActivities?.length > 0) {
        setNoData(false)
        resp.activities?.kidsActivities[0]?.kidsActivities?.map((item: any) => {
          activities.items.push({
            image: '/media/dashboard/Activities.jfif',
            defaultImage: '/media/dashboard/Activities.jfif',
            title: item.activityName,
            subtitle: '',
            date: item.dateTime,
            link: 'kid-management/activities',
          })
          return item
        })
      }

      if (resp.activities?.requestsKid?.length > 0) {
        setNoData(false)
        resp.activities?.requestsKid[0]?.requests?.map((item: any) => {
          requests.items.push({
            image: '/media/dashboard/Request.jpg',
            defaultImage: '/media/dashboard/Request.jpg',
            title: item.requestItemName,
            subtitle: item.note,
            date: item.dueDate,
            link: 'kid-management/requests',
          })
          return item
        })
      }

      if (resp.activities?.protocols?.length > 0) {
        setNoData(false)
        resp.activities?.protocols[0]?.windingProtocols?.map((item: any) => {
          windingProtocols.items.push({
            image: '/media/dashboard/WindingProtocols.jfif',
            defaultImage: '/media/dashboard/WindingProtocols.jfif',
            title: item.diaperContentName,
            subtitle: item.notes,
            date: item.dateTime,
            link: 'kid-management/diapers',
          })
          return item
        })
      }

      if (resp.activities?.kidsMealTime?.length > 0) {
        setNoData(false)
        resp.activities?.kidsMealTime[0]?.kidMealTimes.map((item: any) => {
          mealTime.items.push({
            image: '/media/dashboard/Meal.jfif',
            defaultImage: '/media/dashboard/Meal.jfif',
            title: `${item.foodItemName}/${item.mealTimeName}/${item.mealPortionName}`,
            subtitle: item.notes,
            date: item.dateTime,
            link: 'kid-management/mealtimes',
          })
          return item
        })
      }
      if (resp.activities?.kidsSleeps?.length > 0) {
        setNoData(false)
        resp.activities?.kidsSleeps.map((item: any) => {
          sleeps.items.push({
            image: '/media/dashboard/Sleeps.jfif',
            defaultImage: '/media/dashboard/Sleeps.jfif',
            title: `${item.fromTime ? moment(item.fromTime).format('HH:mm') : '--'} - ${
              item.toTime ? moment(item.toTime).format('HH:mm') : '--'
            }`,
            subtitle: '',
            date: item.date,
            link: 'kid-management/sleeptimes',
          })
          return item
        })
      }
      if (
        mobileConfiguration?.KidAttendance &&
        mobileConfiguration?.KidAttendance[0]?.showInParentApp
      ) {
        setAttendanceTimeline({
          title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ATTENDANCE'}),
          subtitle: '',
          items: attendanceItems,
          link: 'kid-management/attendance',
        })
        setAbsenceTimeline({
          title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ABSENCE'}),
          subtitle: '',
          items: absencesItems,
          link: 'kid-management/absense',
        })
      }
      let finatlOutput = []
      if (mobileConfiguration?.KidDrink && mobileConfiguration?.KidDrink[0]?.showInParentApp) {
        finatlOutput.push(drinks)
      }
      if (mobileConfiguration?.Events && mobileConfiguration?.Events[0]?.showInParentApp) {
        finatlOutput.push(events)
      }
      if (
        mobileConfiguration?.KidProtocolRemark &&
        mobileConfiguration?.KidProtocolRemark[0]?.showInParentApp
      ) {
        finatlOutput.push(protocols)
      }
      finatlOutput.push(activities)

      if (mobileConfiguration?.RequestKid && mobileConfiguration?.RequestKid[0]?.showInParentApp) {
        finatlOutput.push(requests)
      }
      if (
        mobileConfiguration?.KidWindingProtocol &&
        mobileConfiguration?.KidWindingProtocol[0]?.showInParentApp
      ) {
        finatlOutput.push(windingProtocols)
      }
      if (
        mobileConfiguration?.KidMealTime &&
        mobileConfiguration?.KidMealTime[0]?.showInParentApp
      ) {
        finatlOutput.push(mealTime)
      }
      if (
        mobileConfiguration?.KidSleepTime &&
        mobileConfiguration?.KidSleepTime[0]?.showInParentApp
      ) {
        finatlOutput.push(sleeps)
      }
      setAllActivites(finatlOutput)
    }
    setIsLoading(false)
  }
  console.log('====================================')
  console.log('media', mediaData)
  console.log('====================================')
  return (
    <div className='  border-0 pt-6 d-flex flex-column align-items-center w-100 pb-24'>
      <h2 className='my-16'>{intl.formatMessage({id: 'KIDLING.DASHBOAD.KID_ACTIVITIES'})}</h2>

      <div className='row w-100 g-8'>
        <div className='d-flex'>
          <button
            className={`btn ${isToday ? 'btn-primary' : 'btn-light'} me-2`}
            type='button'
            onClick={() => setIsToday(true)}
          >
            {intl.formatMessage({id: 'COMMON.TODAY'})}
          </button>
          <button
            className={`btn ${!isToday ? 'btn-primary' : 'btn-light'} me-2`}
            type='button'
            onClick={() => setIsToday(false)}
          >
            {intl.formatMessage({id: 'COMMON.YESTERDAY'})}
          </button>
        </div>
        <div className='col-12 '>
          {isLoading ? (
            <RectShape color='#ccc' className=' h-200px' />
          ) : (
            <div className='row g-4 justify-content-start'>
              {Object.keys(mediaData)?.map(
                (key: any, index: number) =>
                  mediaData[key].length > 0 && (
                    <div className={`${'col-12 col-sm-6 col-md-4'}`} key={index}>
                      {mediaData[key].length > 1 ? (
                        <div className='shadow rounded h-100 w-100'>
                          <ImagesSliderProfile data={mediaData[key]} hideCheckbox={true} />
                        </div>
                      ) : (
                        mediaData[key].length === 1 && (
                          <DashboardImageCard
                            date={mediaData[key][0].createdDate}
                            image={mediaData[key][0].filePath}
                            userName={mediaData[key][0].userName}
                            userImagePath={mediaData[key][0].userImagePath}
                            details={mediaData[key][0].kidMediaFileDescription}
                            title={mediaData[key][0].title}
                            kidName={mediaData[key][0].kidName}
                            KidMediaFileName={mediaData[key][0].kidMediaFileName}
                            locked={mediaData[key][0].Confidential}
                            Hashtags={mediaData[key][0]?.hashtags}
                            KidMediaFileId={mediaData[key][0]?.kidMediaFileId}
                            readData={readAllTodayNotifications}
                            hideCheckbox={true}
                            enableDelete={false}
                          />
                        )
                      )}
                    </div>
                  )
              )}
            </div>
          )}
        </div>
        {attendanceTimeline?.items?.length > 0 && (
          <div className='col-12 col-sm-6 col-md-4'>
            {isLoading ? (
              <RectShape color='#ccc' className=' h-200px' />
            ) : (
              <TimeLine {...attendanceTimeline} />
            )}
          </div>
        )}
        {absenceTimeline?.items?.length > 0 && (
          <div className='col-12 col-sm-6 col-md-4'>
            {isLoading ? (
              <RectShape color='#ccc' className=' h-200px' />
            ) : (
              <TimeLine {...absenceTimeline} />
            )}
          </div>
        )}
        {allActivites?.map(
          (item: any, index: number) =>
            item.items?.length > 0 && (
              <div className='col-12 col-sm-6 col-md-4' key={index}>
                {isLoading ? (
                  <RectShape color='#ccc' className=' h-200px' />
                ) : (
                  item.items?.length > 0 && <NotificationActivityCard {...item} />
                )}
              </div>
            )
        )}
        {!isLoading && noData && <NoRecords />}
      </div>
    </div>
  )
}

export default KidProfile
