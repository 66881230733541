import {DiapersFilter} from './DiapersFilter'

const DiapersToolbar = () => {
  return (
    <div className='d-flex justify-content-end w-100 w-md-auto' data-kt-user-table-toolbar='base'>
      <DiapersFilter />
    </div>
  )
}

export {DiapersToolbar}
