import {useIntl} from 'react-intl'

const NoRecords = () => {
  const intl = useIntl()
  return (
    <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center  px-8 text-center'>
      <i className='fa-solid fa-database  ' style={{fontSize: '5rem'}}></i>
      <p className='mt-4 text-muted fs-3'>
        {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_RECORDS_YET'})}
      </p>
    </div>
  )
}

export {NoRecords}
