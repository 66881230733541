import imageCompression from 'browser-image-compression'
export const imageCompressor = async (imageFile: any) => {
  // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }
  try {
    const compressedFile = await imageCompression(imageFile, options)
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

    return compressedFile
  } catch (error) {
    console.log(error)
  }
}
