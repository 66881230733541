import clsx from 'clsx'
import {useAppContext} from '../../core/AppContextProvider'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  const {mobileConfiguration} = useAppContext()
  const company = window.location.pathname.split('/')[1]

  return (
    <>
      {mobileConfiguration?.KidActivity &&
        mobileConfiguration?.KidActivity[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.ACTIVITIES'})?.toLocaleUpperCase()}
            to={
              company
                ? `/${company}/kid-management/activities`
                : '/kidling/kid-management/activities'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-person-running'
          ></MenuItem>
        )}
      {mobileConfiguration?.Messaging && mobileConfiguration?.Messaging[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.ANNOUNCMENTS'})?.toLocaleUpperCase()}
          to={company ? `/${company}/messages` : '/kidling/messages'}
          hasBullet={false}
          fontIcon='fa-solid fa-bullhorn'
        ></MenuItem>
      )}
      {mobileConfiguration?.Events && mobileConfiguration?.Events[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.CALENDER'})?.toLocaleUpperCase()}
          to={company ? `/${company}/kid-management/calender` : '/kidling/kid-management/calender'}
          hasBullet={false}
          fontIcon='fa-solid fa-calendar'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidAttendance &&
        mobileConfiguration?.KidAttendance[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})?.toLocaleUpperCase()}
            to={
              company
                ? `/${company}/kid-management/attendance`
                : '/kidling/kid-management/attendance'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-person-circle-plus'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidAbsent && mobileConfiguration?.KidAbsent[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.KIDSABSENSES'})?.toLocaleUpperCase()}
          to={company ? `/${company}/kid-management/absense` : '/kidling/kid-management/absense'}
          hasBullet={false}
          fontIcon='fa-solid fa-person-circle-minus'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidMealTime &&
        mobileConfiguration?.KidMealTime[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.MEALTIME'})?.toLocaleUpperCase()}
            to={
              company ? `/${company}/kid-management/mealtimes` : '/kidling/kid-management/mealtimes'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-utensils'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidFood && mobileConfiguration?.KidFood[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.FOOD'})?.toLocaleUpperCase()}
          to={company ? `/${company}/foodMenu` : '/kidling/foodMenu'}
          hasBullet={false}
          fontIcon='fa-solid fa-utensils'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidSleepTime &&
        mobileConfiguration?.KidSleepTime[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.SLEEPS'})?.toLocaleUpperCase()}
            to={
              company
                ? `/${company}/kid-management/sleeptimes`
                : '/kidling/kid-management/sleeptimes'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-bed'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidDrink && mobileConfiguration?.KidDrink[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.DRINKS'})?.toLocaleUpperCase()}
          to={company ? `/${company}/kid-management/drinks` : '/kidling/kid-management/drinks'}
          hasBullet={false}
          fontIcon='fa-solid fa-mug-hot'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidDrink && mobileConfiguration?.KidMood[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.HOME.MOODS'})?.toLocaleUpperCase()}
          to={company ? `/${company}/kid-management/mood` : '/kidling/kid-management/mood'}
          hasBullet={false}
          fontIcon='fa-solid fa-face-smile'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidProtocolRemark &&
        mobileConfiguration?.KidProtocolRemark[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.PROTOCOLS'})?.toLocaleUpperCase()}
            to={
              company ? `/${company}/kid-management/protocols` : '/kidling/kid-management/protocols'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-handshake-simple'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidMedicalRecord &&
        mobileConfiguration?.KidMedicalRecord[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.MEDICALRECORDS'})?.toLocaleUpperCase()}
            to={
              company
                ? `/${company}/kid-management/medical-records`
                : '/kidling/kid-management/medical-records'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-notes-medical'
          ></MenuItem>
        )}
      {mobileConfiguration?.RequestKid && mobileConfiguration?.RequestKid[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.REQUESTS'})?.toLocaleUpperCase()}
          to={company ? `/${company}/kid-management/requests` : '/kidling/kid-management/requests'}
          hasBullet={false}
          fontIcon='fa-solid fa-people-arrows'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidService && mobileConfiguration?.KidService[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.KIDSSERVICES'})?.toLocaleUpperCase()}
          to={
            company
              ? `/${company}/kid-management/kid-services`
              : '/kidling/kid-management/kid-services'
          }
          hasBullet={false}
          fontIcon='fa-solid fa-child-reaching'
        ></MenuItem>
      )}
      {mobileConfiguration?.MealSubscription &&
        mobileConfiguration?.MealSubscription[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HOME.DEREGISTERATION'})?.toLocaleUpperCase()}
            to={
              company
                ? `/${company}/kid-management/deregistration`
                : '/kidling/kid-management/deregistration'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-person-circle-minus'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidMedia && mobileConfiguration?.KidMedia[0]?.showInEducatorApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})?.toLocaleUpperCase()}
          to={company ? `/${company}/media` : '/kidling/media'}
          hasBullet={false}
          fontIcon='fa-solid fa-image'
        ></MenuItem>
      )}
      {mobileConfiguration?.KidWindingProtocol &&
        mobileConfiguration?.KidWindingProtocol[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/kid-management/diapers` : '/kidling/kid-management/diapers'}
            hasBullet={false}
            fontIcon='fa-solid fa-baby'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidAssessment &&
        mobileConfiguration?.KidAssessment[0]?.showInEducatorApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})?.toLocaleUpperCase()}
            to={
              company
                ? `/${company}/kid-management/assessments`
                : '/kidling/kid-management/assessments'
            }
            hasBullet={false}
            fontIcon='fa-solid fa-people-arrows'
          ></MenuItem>
        )}

      <MenuItem
        title={intl.formatMessage({id: 'KIDLING.COMPANY_INFO'})?.toLocaleUpperCase()}
        to={company ? `/${company}/companyInformation` : '/kidling/companyInformation'}
        hasBullet={false}
        fontIcon='fa-solid fa-school'
      ></MenuItem>

      <div className={clsx(' mt-auto border-top')}>
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.NEED_HELP'})?.toLocaleUpperCase()}
          to={'https://quintic.atlassian.net/servicedesk/customer/portal/5'}
          hasBullet={false}
          fontIcon='fa-solid fa-circle-question fa-2x'
          external
        ></MenuItem>
      </div>
    </>
  )
}
