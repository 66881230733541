/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {getFoodItems, getMealPortions, getMealTimes} from '../../../actions/meal'
import {getAttendanceLocations} from '../../../actions/location'
import {
  getAbsentReasons,
  getActivities,
  getMoods,
  getRequestItems,
  getSleepHabits,
} from '../../../actions/kids'
import {readEventsCategory} from '../../../actions/events'
import {getMedicalTypes} from '../../../actions/medical'
import {useAuth} from '../../modules/auth'
import {getAllMessagesCounts} from '../../../actions/messages'
import {useNavigate} from 'react-router-dom'
import {groupBy} from '../../utils/common'
import {getMobileConfiguration} from '../../../actions/users'
import {getHashtags} from '../../../actions/hashtag'
import {getDiapersContent} from '../../../actions/diapers'
import {useChatContext} from '../../store/context/ChatProvider'
import {getAllUserChats} from '../../../actions/chat'
import moment from 'moment'
import {getPinboard} from '../../../actions/pinboard'
import {useCompanyContext} from '../../../_metronic/layout/core/CompanyProvider'

function GlobalDataLoader() {
  const {
    setMealtime,
    setAttendanceLocations,
    setAbsentReasons,
    setActivities,
    setMoods,
    setRequestItems,
    setMealPortions,
    setFoodItems,
    setSleepHabits,
    setMedicalTypes,
    setEventsCategories,
    setMobileConfiguration,
    setTabMessagesCounts,
    setAllHashtags,
    setDiapersContent,
    setPinboard,
  } = useAppContext()
  const {currentCompany, setCurrentCompany} = useCompanyContext()
  const {currentUser, auth} = useAuth()
  const {setAllChats} = useChatContext()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    if (currentUser && currentCompany) {
      readMealTimes()
      readAttendanceLocations()
      readAbsentList()
      readAactivities()
      readMoods()
      readRequestItems()
      readMealPortions()
      readFoodItems()
      readSleepHabits()
      readMedicalTypes()
      readMessageCounts()
      readEventsCat()
      readMobileConfiguration()
      getAllHashtags()
      getAllDiapersContent()
      readAllChats()
      readPinboard()
    }
  }, [currentUser, currentCompany])

  useEffect(() => {
    try {
      if (auth) {
        constructMobileConfiguration(auth?.configurtations)
        const comp: any = localStorage.getItem('kidling-selectedCompany')
        if (!comp) {
          navigate(company ? `/${company}/company` : '/kidling/company')
        } else {
          setCurrentCompany(JSON.parse(comp))
        }
      }
    } catch (e) {
      console.log('error', e)
    }
  }, [auth])

  const readMobileConfiguration = async () => {
    const resp = await getMobileConfiguration()

    if (resp) constructMobileConfiguration(resp)
  }

  const constructMobileConfiguration = (conf: any) => {
    const resp = groupBy(conf, 'mobileConfigurationName')
    if (resp) setMobileConfiguration(resp)
  }
  const readMealTimes = async () => {
    const resp = await getMealTimes()
    if (resp) setMealtime(resp)
  }
  const readAttendanceLocations = async () => {
    const resp = await getAttendanceLocations(`companyId=${currentCompany?.value}`)
    if (resp) setAttendanceLocations(resp)
  }
  const readAbsentList = async () => {
    const resp = await getAbsentReasons()
    if (resp) setAbsentReasons(resp)
  }

  const readAactivities = async () => {
    const resp = await getActivities()
    if (resp) setActivities(resp)
  }

  const readMoods = async () => {
    const resp = await getMoods()
    if (resp) setMoods(resp)
  }

  const readRequestItems = async () => {
    const resp = await getRequestItems()
    if (resp) setRequestItems(resp)
  }

  const readMealPortions = async () => {
    const resp = await getMealPortions()
    if (resp) setMealPortions(resp)
  }

  const readFoodItems = async () => {
    const resp = await getFoodItems()
    if (resp) setFoodItems(resp)
  }

  const readSleepHabits = async () => {
    const resp = await getSleepHabits()
    if (resp) setSleepHabits(resp)
  }

  const readMedicalTypes = async () => {
    const resp = await getMedicalTypes()
    if (resp) setMedicalTypes(resp)
  }

  const readMessageCounts = async () => {
    if (currentUser) {
      let resp = await getAllMessagesCounts(currentUser?.usersId ?? 0)
      setTabMessagesCounts(resp)
    }
  }

  const readEventsCat = async () => {
    if (currentUser) {
      const resp = await readEventsCategory(`companyId=${currentCompany?.value}`)

      if (resp) setEventsCategories(resp)
    }
  }
  const getAllHashtags = async () => {
    const resp = await getHashtags()
    setAllHashtags(resp)
  }

  const getAllDiapersContent = async () => {
    const resp = await getDiapersContent()
    setDiapersContent(resp)
  }
  const readAllChats = async () => {
    const resp = await getAllUserChats()
    setAllChats(resp)
  }

  const readPinboard = async () => {
    let filter = ''

    filter = 'companyId=' + currentCompany?.value

    filter = filter === '' ? '1=1' : filter

    const resp = await getPinboard(
      `pinboardShow=true&pinboardDatetime<="${moment()
        .endOf('day')
        .format('yyyy-MM-DDTHH:mm:ss')}"&(${filter})`
    )
    setPinboard(resp)
  }

  return <></>
}

export default GlobalDataLoader
