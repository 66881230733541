import {useEffect, FC} from 'react'
import {KidAssessmentModalHeader} from './KidAssessmentModalHeader'
import {KidAssessmentModalFormWrapper} from './KidAssessmentModalFormWrapper'
import ActionsDrawerContainer from '../../../../../general-components/ActionsDrawerContainer/ActionsDrawerContainer'
type Props = {
  intitialPositionId?: string
}
const KidAssessmentModal: FC<Props> = ({intitialPositionId}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block   '
        id='kt_modal_check_in'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
        style={{maxHeight: '100vh', overflowY: 'auto'}}
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-fullscreen  '>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidAssessmentModalHeader />
            {/* begin::Modal body */}
            <ActionsDrawerContainer
              children={
                <div className='modal-body scroll-y overflow-auto mx-5 mx-xl-15 my-0 h-100'>
                  <KidAssessmentModalFormWrapper intitialPositionId={intitialPositionId} />
                </div>
              }
            />

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidAssessmentModal}
