import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {SleepsHeader} from './header/SleepsHeader'
import useSleepTimes from '../../../../hooks/useSleepTimes'
function Sleeptimes() {
  const {internalPagesDatesFilters, globalLoader} = useAppContext()
  const {readSleeptimes, data} = useSleepTimes()
  const intl = useIntl()

  useEffect(() => {
    if (!globalLoader) readSleeptimes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPagesDatesFilters])

  const columns: any = useMemo(() => {
    _columns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <SleepsHeader title={intl.formatMessage({id: 'KIDLING.MENU.SLEEPS'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Sleeptimes
