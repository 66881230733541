import CustomDateRangePicker from '../../../../../../general-components/date-range-picker/DateRangePicker'
import {AttendanceSearchComponent} from './AttendanceSearchComponent'
import {useIntl} from 'react-intl'
import {useThemeMode} from '../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useKidAttendance from '../../../../../../hooks/useKidAttendance'

const UsersListHeader = () => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const {printPage, handleDateChange} = useKidAttendance({})
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100 p-md-16'>
      <h4>{intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})}</h4>
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <AttendanceSearchComponent />
        </div>
        <div className='d-flex align-items-center'>
          <div
            className='btn btn-icon  btn-sm btn-light-primary w-40px  d-flex align-items-center justify-content-center mx-2'
            data-kt-users-modal-action='close'
            onClick={() => printPage()}
            style={{cursor: 'pointer'}}
          >
            <i className={` fa-solid fa-print fs-3 m-0 ${mode === 'dark' && 'text-dark'} `}></i>
          </div>
          <CustomDateRangePicker handleDateChange={handleDateChange} />
        </div>
      </div>
    </div>
  )
}

export {UsersListHeader}
