/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import Select from 'react-select'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../../auth'
import {BulkAbsence} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {getAbsentReasons, getAllKids} from '../../../../../../actions/kids'
import moment from 'moment-timezone'
import {selectStyle} from '../../../../../utils/select-style'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import useKidAbsence from '../../../../../hooks/useKidAbsence'
import _columns from './_columns'
import TableContainer from '../../../../../general-components/table-container/TableContainer'
import {useDateContext} from '../../../../../store/context/DateProvider'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'

type Props = {
  isUserLoading: boolean
}

const KidAbsenseModalForm: FC<Props> = ({isUserLoading}) => {
  const {setAbsenceDate} = useDateContext()
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {
    absentReasons,
    setAbsentReasons,
    setKidsList,
    filters,
    mobileConfiguration,
    setAllAbsense,
  } = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [notifyContact, setNotifyContact] = useState(false)
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [userForEdit] = useState({
    employee: '',
    contact: '',
    fromDate: new Date(),
    toDate: new Date(),
    reason: '',
  })
  const {columns, data} = useKidAbsence({_columns})
  useEffect(() => {
    setAllAbsense([])
    setAbsenceDate({
      From: moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    })
  }, [])

  useEffect(() => {
    if (absentReasons.length === 0) readAbsentList()
  }, [])

  const kidSchema = Yup.object().shape({
    employee: Yup.string(),
    contact: Yup.string(),
    fromDate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    toDate: Yup.date()
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
      .test(
        'isValidTime',
        intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
        (value: any, context: any) => {
          const totalMin = moment
            .duration(
              moment(value).startOf('day').diff(moment(context.parent.fromDate).startOf('day'))
            )
            .asDays()

          if (totalMin >= 0) {
            return true
          }
          return false
        }
      ),
    reason: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }
  const getkidsData = async () => {
    const temp = await getAllKids({
      filter: filters,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (temp) setKidsList(temp)
  }
  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        let kidIds: number[] = []

        selected.forEach((kid) => {
          kidIds.push(kid.kidId)
        })

        setSubmitting(true)
        const data = {
          kidIds,
          updatedDateTime: moment().format('yyyy-MM-DDTHH:mm:ss'),
          employeeId: currentUser?.employee?.employeeId,
          fromDate: moment(values.fromDate).format('yyyy-MM-DDTHH:mm:ss'),
          toDate: moment(values.toDate).format('yyyy-MM-DDTHH:mm:ss'),
          kidAbsentReasonId: values.reason || null,
          companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
          employeeRemarks: values.employee,
          contactId: 0,
          mobileConfigurationId: mobileConfiguration?.KidAbsent[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
          contactRemarks: values.contact,
        }

        const resp = await BulkAbsence(data)

        if (!!resp) {
          await getkidsData()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          // cancel(true)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        //  clearSelected()
        reset()
        setSubmitting(false)
      }
    },
  })
  const reset = () => formik.resetForm()
  const readAbsentList = async () => {
    const resp = await getAbsentReasons()
    if (resp) setAbsentReasons(resp)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {data?.length > 0 && (
            <div className='h-200px overflow-auto border border-1 border-bottom'>
              <TableContainer
                columns={columns}
                data={data}
                handleDelete={() => {}}
                tableName='absenceModal'
              />
            </div>
          )}
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <div className='fv-row my-7'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.FROM_DATE'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.fromDate && formik.errors.fromDate},
                    {
                      'is-valid': formik.touched.fromDate && !formik.errors.fromDate,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    onAccept={(date: any) => {
                      formik.setFieldValue('fromDate', date)
                      formik.setFieldValue('toDate', date)
                      setAbsenceDate((prev: any) => ({...prev, From: date}))
                    }}
                    value={formik.values.fromDate}
                  />
                </div>
                {formik.errors.fromDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.fromDate}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-sm-6'>
              <div className='fv-row my-7'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.TO_DATE'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.toDate && formik.errors.toDate},
                    {
                      'is-valid': formik.touched.toDate && !formik.errors.toDate,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    onAccept={(date: any) => {
                      formik.setFieldValue('toDate', date)
                      setAbsenceDate((prev: any) => ({...prev, To: date}))
                    }}
                    value={formik.values.toDate}
                  />
                </div>
                {formik.errors.toDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.toDate}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12  '>
              <div className='fv-row mb-7'>
                <Select
                  onChange={(val: any) => formik.setFieldValue('reason', val?.value)}
                  options={absentReasons.map((item: any) => ({
                    value: item.kidAbsentReasonId,
                    label: item.kidAbsentReasonName,
                  }))}
                  isClearable={true}
                  formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.label}</p>}
                  className='w-100  '
                  placeholder={intl.formatMessage({id: 'KIDLING.HOME.KID_ABSENT_REASON'})}
                  isLoading={absentReasons.length === 0}
                  theme={(theme) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  styles={selectStyle}
                />
                {formik.errors.reason && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.reason}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12  '>
              <div className='fv-row mb-7'>
                <textarea
                  {...formik.getFieldProps('employee')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.employee && formik.errors.employee},
                    {
                      'is-valid': formik.touched.employee && !formik.errors.employee,
                    }
                  )}
                  name='employee'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  rows={1}
                  placeholder={intl.formatMessage({id: 'KIDLING.HOME.EMPLOYEE_REMARKS'})}
                />
                {formik.touched.employee && formik.errors.employee && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.employee}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 '>
              <div className='fv-row mb-7'>
                <textarea
                  {...formik.getFieldProps('contact')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.contact && formik.errors.contact},
                    {
                      'is-valid': formik.touched.contact && !formik.errors.contact,
                    }
                  )}
                  name='contact'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                  rows={1}
                  placeholder={intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
                />
                {formik.touched.contact && formik.errors.contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.contact}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-sm-6'></div>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidAbsenseModalForm}
