/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {BulkAttendace, BulkCheckout} from '../../../../../../actions/bulk-actions'
import {useAuth} from '../../../../auth'
import {toast} from 'react-toastify'
import {getAllKids} from '../../../../../../actions/kids'
import {getAttendanceLocations} from '../../../../../../actions/location'
import {selectStyle} from '../../../../../utils/select-style'
import moment from 'moment-timezone'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {getAttendanceByFilter} from '../../../../../../actions/attendace'
import {groupBy} from '../../../../../utils/common'
import {Link} from 'react-router-dom'
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap'
import {Avatar} from '@mui/material'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
type Props = {
  isUserLoading: boolean
}

const KidCheckinModalForm: FC<Props> = ({isUserLoading}) => {
  const [kidAttendanceIds, setKidAttendanceIds] = useState([])
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {
    attendanceLocations,
    filters,
    setKidsList,
    setAttendanceLocations,

    mobileConfiguration,
  } = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [notifyContact, setNotifyContact] = useState(false)
  const [kidsAttendance, setKidsAttendance] = useState<any>({})
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    location: null,
    employee: '',
    contact: '',
    date: new Date(),
  })

  useEffect(() => {
    if (attendanceLocations?.length === 0 && currentCompany?.value) readAttendanceLocations()
  }, [])

  const kidSchema = Yup.object().shape({
    employee: Yup.string(),
    contact: Yup.string(),
    location: Yup.mixed(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }
  const getkidsData = async () => {
    const temp = await getAllKids({
      filter: filters,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (temp) setKidsList(temp)
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = []
        let absense = false
        selected.forEach((kid) => {
          if (kid.presentStatus === 1) {
            absense = true
            toast.error(
              intl.formatMessage({
                id: 'KIDLING.ONE_OR_MORE_SELECTED_KIDS_ARE_ABSNET_CHANGE_THEM_FIRST',
              })
            )
            return
          }
          kidIds.push(kid.kidId)
        })
        if (!absense) {
          if (kidAttendanceIds.length > 0) {
            const data1 = {
              kidAttendanceIds,
              employeeId: currentUser?.employee?.employeeId,
              dateTimeOut: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
              employeeRemarks: values.employee,
              companyId: currentCompany?.value,
              mobileConfigurationId:
                mobileConfiguration?.KidAttendance[0]?.mobileConfigurationMasterId,
              notifyByEmail: notifyContact,
            }

            await BulkCheckout(data1)
          }

          const data = {
            kidIds,
            employeeId: currentUser?.employee?.employeeId,
            dateTimeIn: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            attendanceLocationId: values.location || 0,
            companyId: currentCompany?.value,
            employeeRemarks: values.employee,
            mobileConfigurationId:
              mobileConfiguration?.KidAttendance[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkAttendace(data)

          if (!!resp) {
            await getkidsData()
            clearSelected()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            cancel(true)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        clearSelected()
        reset()
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    if (selected.length > 0) readAttendace()
  }, [selected])
  const reset = () => formik.resetForm()
  const readAttendanceLocations = async () => {
    const resp = await getAttendanceLocations(`companyId=${currentCompany?.value}`)
    if (resp) setAttendanceLocations(resp)
  }

  const readAttendace = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter !== '') filter += ` AND `

    filter += `dateTimeIn >= "${moment(formik.values.date)
      .startOf('day')
      .format('yyyy-MM-DDTHH:mm:ss')}" AND dateTimeIn<"${moment(formik.values.date)
      .endOf('day')
      .format('yyyy-MM-DDTHH:mm:ss')}" `

    const res = await getAttendanceByFilter(filter)
    const grouped = groupBy(res, 'kidId')

    setKidsAttendance(grouped)
    let kidAttendanceIds: any = []
    res.forEach((element: any) => {
      kidAttendanceIds = [...kidAttendanceIds, element.kidAttendanceId]
    })
    kidAttendanceIds = [...new Set(kidAttendanceIds)]

    setKidAttendanceIds(kidAttendanceIds)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {Object.keys(kidsAttendance)?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {Object.keys(kidsAttendance)?.map((key: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === Number(key))

                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <Avatar
                            src={fullKid?.imagePath}
                            alt={`${fullKid?.firstName
                              ?.charAt(0)
                              ?.toUpperCase()} ${fullKid?.lastName?.charAt(0)?.toUpperCase()}`}
                            sx={{width: 16, height: 16}}
                          />
                          <h5 className='ms-2 my-0'> {fullKid.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {kidsAttendance[key]?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>
                                  {
                                    attendanceLocations.find(
                                      (i: any) =>
                                        i.attendanceLocationId === meal.attendanceLocationId
                                    )?.attendanceLocationName
                                  }
                                </h5>

                                {meal.employeeRemarks && (
                                  <p
                                    className='m-0 p-0 text-muted '
                                    dangerouslySetInnerHTML={{__html: meal.employeeRemarks}}
                                  ></p>
                                )}
                                {meal.contactRemarks && (
                                  <p
                                    className='m-0 p-0 text-muted '
                                    dangerouslySetInnerHTML={{__html: meal.contactRemarks}}
                                  ></p>
                                )}
                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.dateTimeIn).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <Link
                                to={
                                  company
                                    ? `/${company}/kid-management/attendance`
                                    : '/kidling/kid-management/attendance'
                                }
                                className='btn btn-light p-1 px-4 mt-2'
                              >
                                <i className='fa-solid fa-arrow-right'></i>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row my-7'>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                dateTime
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>

          <div className='fv-row mb-7'>
            <Select
              openMenuOnFocus={true}
              name='location'
              // value={value}
              onChange={(val: any) => formik.setFieldValue('location', val?.value)}
              options={attendanceLocations.map((item: any) => ({
                value: item.attendanceLocationId,
                label: item.attendanceLocationName,
              }))}
              isClearable={true}
              isLoading={attendanceLocations.length === 0}
              formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.label}</p>}
              className='w-100  '
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.LOCATION'})}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
              styles={selectStyle}
            />
            {formik.touched.location && formik.errors.location && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.location}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('employee')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.employee && formik.errors.employee},
                {
                  'is-valid': formik.touched.employee && !formik.errors.employee,
                }
              )}
              name='employee'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.EMPLOYEE_REMARKS'})}
            />
            {formik.touched.employee && formik.errors.employee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.employee}</span>
                </div>
              </div>
            )}
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
          {/* <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'>
              {' '}
              {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
            </label>
            <textarea
              {...formik.getFieldProps('contact')}
              className={clsx(
                'form-control   mb-3 mb-lg-0',
                {'is-invalid': formik.touched.contact && formik.errors.contact},
                {
                  'is-valid': formik.touched.contact && !formik.errors.contact,
                }
              )}
              name='contact'
              autoComplete='off'
              disabled={true}
              rows={1}
            />
            {formik.touched.contact && formik.errors.contact && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.contact}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidCheckinModalForm}
