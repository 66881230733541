/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import FeedCard from '../../../../general-components/FeedCard/FeedCard'
import {getAllKidsFeedBack} from '../../../../../actions/dashboard'
import moment from 'moment'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import {momentDateFormat} from '../../../../../config/constants'
import {useIntl} from 'react-intl'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import {NoRecords} from '../../../../general-components/NoRecords'
import MeidaFeedCard from '../../../../general-components/MeidaFeedCard/MeidaFeedCard'
import {useCompanyContext} from '../../../../../_metronic/layout/core/CompanyProvider'

function Feed() {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const {currentCompany} = useCompanyContext()
  const activityType: any = {
    0: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ACTIVITIES'}),
      image: '/media/dashboard/Activities.jfif',
      link: 'kid-management/activities',
    },
    1: {title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ASSESSMENT'})},
    2: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.EVENTS'}),
      image: '/media/dashboard/Events.jfif',
      link: 'kid-management/calender',
    },
    3: {title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DEREGISTRATION'})},
    4: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DIAPERS'}),
      image: '/media/dashboard/WindingProtocols.jfif',
      link: 'kid-management/diapers',
    },
    5: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DRINKS'}),
      image: '/media/dashboard/Drinks.jfif',
      link: 'kid-management/drinks',
    },
    6: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ABSENCE'}),
      link: 'kid-management/absense',
    },
    7: {title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ATTENDANCE'})},
    8: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.EATING_HABIT'}),
      image: '/media/dashboard/Meal.jfif',
      link: 'kid-management/mealtimes',
    },
    9: {
      title: intl.formatMessage({id: 'KIDLING.HOME.ADD_SLEEPS_START'}),
      image: '/media/dashboard/Sleeps.jfif',
      link: 'kid-management/sleeptimes',
    },
    10: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.PROTOCOLS'}),
      image: '/media/dashboard/Protocols.jfif',
      link: 'kid-management/protocols',
    },
    11: {
      title: intl.formatMessage({id: 'KIDLING.DASHBOARD.REQUESTS'}),
      image: '/media/dashboard/Request.jpg',
      link: 'kid-management/requests',
    },
    12: {title: 'KidService'},
    13: {title: intl.formatMessage({id: 'KIDLING.DASHBOARD.MEDICAL_RECORD'})},
    14: {title: intl.formatMessage({id: 'KIDLING.DASHBOARD.MOOD'})},
    15: {title: intl.formatMessage({id: 'KIDLING.HOME.MEDIA'}), link: 'media'},
    16: {
      title: intl.formatMessage({id: 'KIDLING.HOME.ADD_SLEEPS_END'}),
      image: '/media/dashboard/Sleeps.jfif',
      link: 'kid-management/sleeptimes',
    },
  }
  const [data, setData] = useState([])
  const [date, setDate] = useState<any>({
    startDate: moment().subtract(1, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  })
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  useEffect(() => {
    const load = async () => {
      setIsLoading(true)

      const resp = await getAllKidsFeedBack(
        moment(date.startDate).format(momentDateFormat),
        moment(date.endDate).format(momentDateFormat),
        currentCompany.value
      )
      if (!!resp) {
        const filterd = resp.filter((item: any) => item.actionFeedType !== 7)

        setData(filterd)
      } else {
        setData([])
      }
      setIsLoading(false)
    }
    if (!isLoading && currentCompany) load()
  }, [date, currentCompany])

  return (
    <KTCard>
      <div className='mt-8 w-100 px-8'>
        <CustomDateRangePicker handleDateChange={handleDateChange} initialValue={date} />

        {data?.map((item: any, index: number) => {
          let cardItem: any =
            item.actionFeedType === 15
              ? {
                  mediaFiles: item.mediaFiles,
                  kids: item.kids,
                  link: activityType[item.actionFeedType].link,
                  title: activityType[item.actionFeedType].title,
                  desc: item.mediaFiles[0].notes,
                  date: moment(item.dateTimeFilter).format('DD.MM.yyyy HH:mm'),
                }
              : {
                  activity: {
                    image: item.actionImageUrl ?? activityType[item.actionFeedType].image,
                    link: activityType[item.actionFeedType].link,
                    title: activityType[item.actionFeedType].title,
                  },
                  kid: {
                    kidName: item?.kidName ?? '',
                    imageUrl: item.kidImageUrl,
                  },
                  title: item.sleepHabitName
                    ? item.title + ' | ' + item.sleepHabitName
                    : item.title,
                  desc: item.notes,
                  date: moment(item.dateTimeFilter).format('DD.MM.yyyy HH:mm'),
                }

          return item.actionFeedType === 15 ? (
            <MeidaFeedCard {...cardItem} key={index} />
          ) : (
            <FeedCard {...cardItem} key={index} />
          )
        })}
        {isLoading && <UsersListLoading />}
        {!isLoading && data?.length === 0 && <NoRecords />}
      </div>
    </KTCard>
  )
}

export default Feed
