/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../modules/auth'
import moment from 'moment'
import {deleteKidMood, getKidsMoods} from '../../actions/moods'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const useMood = () => {
  const intl = useIntl()
  const {
    kidMoods,
    setKidMoods,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
    globalLoader,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const [date, setDate] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readMoods()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.mood = {
      ...newFilters.mood,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])
  useEffect(() => {
    let temp: any = []
    kidMoods?.forEach((item: any) => {
      let KidsMoods: any = []
      item.kidMoods?.forEach((mood: any) =>
        KidsMoods.push({
          id: mood.kidMoodId,
          title: mood.moodName,
          subtitle: `${moment(mood.date).format('DD.MM.yyyy')}`,
          desc: mood.notes,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: KidsMoods,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidMoods])

  const readMoods = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.mood.classValue && !internalPagesFilters.mood.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.mood.classValue)
      filter = `classesId=${internalPagesFilters.mood.classValue}`
    if (internalPagesFilters.mood.classValue && internalPagesFilters.mood.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.mood.searchTerm) filter = filter + internalPagesFilters.mood.searchTerm

    const res = await getKidsMoods(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.mood.From
        ? moment(internalPagesDatesFilters.mood.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.mood.To
        ? moment(internalPagesDatesFilters.mood.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidMoods(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteKidMood(id)
      if (resp) {
        await readMoods()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.mood = {
      ...newFilters.mood,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }
    setInternalPagesFilters(newFilters)
  }
  return {
    data,
    doDelete,
    readMoods,
    handleDateChange,
    setClicked,
    clicked,
    searchTerm,
    setSearchTerm,
  }
}

export default useMood
