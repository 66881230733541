import {FC, ReactElement, useEffect, useId, useState} from 'react'
import './styles.scss'
import SliderArrowNext from '../slider-arrow-next'
import SliderArrowBack from '../slider-arrow-back'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import moment from 'moment'
import {useIntl} from 'react-intl'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import {Avatar, AvatarGroup} from '@mui/material'
import SliderImageWithEnlarge from '../slider-image-with-enlarge'
import TaggedKidsModal from '../../modules/apps/Media/TaggedKidsModal'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../error-text/ErrorText'
import {deleteMediaFile} from '../../../actions/media'
import {toast} from 'react-toastify'

interface Props {
  data: any
  readData?: any
  hideCheckbox?: boolean
}
const ImagesSlider: FC<Props> = ({data, readData, hideCheckbox}): ReactElement => {
  const intl = useIntl()
  const [enlargeImage, setEnlargeImage] = useState('')
  const [showImageDelete, setShowImageDelete] = useState(false)
  const [showTaggedKids, setShowTaggedKids] = useState(false)
  const [sliderData, setSliderData] = useState<any>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const id = useId()

  useEffect(() => {
    let temp: any = []
    data?.MediaFiles?.map((item: any) =>
      temp.push({
        original: item.filePath ?? item.FilePath,
        thumbnail: item.filePath ?? item.FilePath,
        ...item,
      })
    )
    setSliderData(temp)
  }, [data])

  const handleDeleteFile = async () => {
    sliderData.map(async (k: any, index: number) => {
      const resp: any = await deleteMediaFile(k.KidMediaFileId)
      if (resp.status === 200 && index === sliderData.length - 1) {
        readData()
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      }
    })
  }

  return (
    <div className='slider_card card h-100 '>
      <div className='slider__section'>
        {sliderData?.length > 0 && (
          <div className='d-flex flex-column   rounded p-3 h-100 '>
            <div className='d-flex align-items-center justify-content-between mt-4 '>
              <Avatar
                src={data?.UserImagePath ?? data?.userImagePath}
                alt={
                  data?.UserName
                    ? data?.UserName?.charAt(0)?.toUpperCase()
                    : data?.UserName?.charAt(0)?.toUpperCase()
                }
              />

              <div className='ms-2 w-100 d-flex justify-content-between'>
                <div>
                  <h5 className='m-0 p-0'>{data?.UserName ?? data?.userName}</h5>
                  <p className='fs-7 text-muted m-0 p-0'>
                    {moment(data?.CreatedDate).format('DD.MM.yyyy')}
                  </p>
                </div>
              </div>
            </div>
            <p className='mb-4 p-0 text-muted '>
              {data[0]?.KidMediaFileDescription ?? data[0]?.kidMediaFileDescription}
            </p>

            <ImageGallery
              items={sliderData}
              showPlayButton={false}
              useBrowserFullscreen={false}
              onSlide={(currentIndex) => {
                setCurrentIndex(currentIndex)
              }}
              renderLeftNav={(onClick: any, disabled: any) => (
                <SliderArrowBack onClick={onClick} disabled={disabled} />
              )}
              renderRightNav={(onClick: any, disabled: any) => (
                <SliderArrowNext onClick={onClick} disabled={disabled} />
              )}
              renderFullscreenButton={(onClick: any, isFullscreen: any) => (
                <button className='enlarge__btn' onClick={onClick}>
                  {isFullscreen ? (
                    <i className='fas fa-compress '></i>
                  ) : (
                    <i className='fa-solid fa-expand'></i>
                  )}
                </button>
              )}
              renderItem={(item: any) => (
                <div className='slide h-100 mh-100 position-relative'>
                  <SliderImageWithEnlarge
                    {...item}
                    img={item.filePath ?? item.FilePath}
                    setEnlargeImage={setEnlargeImage}
                    enlargeImage={enlargeImage}
                    activateLongPress
                    hideCheckbox={hideCheckbox}
                    desc={item?.KidMediaFileDescription}
                    currentIndex={currentIndex}
                  />
                  {item?.Confidential && (
                    <div className='position-absolute ' style={{right: '3rem', top: '10px'}}>
                      <i className='fa-solid fa-lock fs-3 text-info'></i>
                    </div>
                  )}
                </div>
              )}
            />
            <p className='mb-4 p-0 text-muted fs-4'>
              {sliderData[0]?.kidMediaFileDescription ?? sliderData[0]?.KidMediaFileDescription}
            </p>
            <div className='d-flex align-items-center justify-content-between'>
              {data?.Kids?.length > 0 && (
                <AvatarGroup
                  max={4}
                  renderSurplus={(surplus: any) => <span>+{surplus.toString()[0]}</span>}
                  sx={{
                    '& .MuiAvatar-root': {width: 26, height: 26, fontSize: 12},
                  }}
                  onClick={() => setShowTaggedKids(true)}
                >
                  {data?.Kids?.map((kid: any) => (
                    <Avatar alt={kid.KidName} src={kid?.KidImageUrl} key={kid.KidName} />
                  ))}
                </AvatarGroup>
              )}

              <button
                type='button'
                className='btn   p-0 m-0'
                onClick={() => setShowImageDelete(true)}
              >
                <i className='fa-solid fa-trash fs-3 text-danger p-0'></i>
              </button>
            </div>
          </div>
        )}

        {enlargeImage !== '' && (
          <div className='position-relative '>
            <div
              className={`  full__image  d-flex justify-content-center align-items-center  w-screen h-screen`}
            >
              <button className='close__btn' onClick={() => setEnlargeImage('')}>
                <i className='fa-solid fa-xmark'></i>
              </button>

              <div
                className={`  d-flex justify-content-center align-items-center  slider__image__animation`}
              >
                <img
                  src={enlargeImage}
                  className='h-screen w-screen object-fit-contain'
                  alt={`slider-${id}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <TaggedKidsModal show={showTaggedKids} setShow={setShowTaggedKids} kids={data?.Kids} />
      <SweetAlert
        show={showImageDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          handleDeleteFile()
          setShowImageDelete(false)
        }}
        onCancel={() => setShowImageDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default ImagesSlider
