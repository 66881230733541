/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {KidServicesSearchComponent} from './KidServicesSearchComponent'
import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import moment from 'moment'

interface Props {
  title?: string
}
const KidServicesHeader: FC<Props> = ({title}) => {
  const [date, setDate] = useState<any>({})
  const {internalPagesDatesFilters, setInternalPagesDatesFilters} = useAppContext()
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.services = {
      ...newFilters.services,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100'>
      {title && <h4>{title}</h4>}
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <KidServicesSearchComponent />
          {/* <KidServicesToolbar /> */}
        </div>
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>
    </div>
  )
}

export {KidServicesHeader}
