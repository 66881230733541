/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive'
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useSortBy,
  UseSortByInstanceProps,
  useTable,
  useGlobalFilter,
  useRowSelect,
} from 'react-table'
import CustomPagination from '../custom-pagination/CustomPagination'
import TableMobileView from '../mobile-table'
import TableMobileViewWithExpandedCards from '../mobile-table-with-expand'
import TableMobileViewWithShrinkedCards from '../mobile-table-with-shrinked-view'
import './styles.scss'
export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>
  }
interface TableProps {
  columns: any
  data: any
  handleDelete?: any
  editCell?: any
  hideCards?: boolean
  pageSizeItems?: number
  hidePagination?: boolean
  useExpand?: boolean
  shrinkedView?: boolean
  _mobileColumns?: any
  tableName: any
}
const TableContainer: FC<TableProps> = ({
  columns,
  data,
  hideCards,
  pageSizeItems,
  hidePagination,
  useExpand,
  _mobileColumns,
  shrinkedView,
  tableName,
}): ReactElement => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })
  const [sort, setSort] = useState([])
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    // @ts-ignore <-- tell TypeScript to ignore the next line.
    state: {
      pageIndex,
      pageSize = pageSizeItems || 50, // @ts-ignore <-- tell TypeScript to ignore the next line.
      sortBy,
    },
  } = useTable(
    {
      columns,
      data, // @ts-ignore <-- tell TypeScript to ignore the next line.
      initialState: {sortBy: sort},
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks: any) => {
      hooks.allColumns.push((_columns: any) => [..._columns])
    }
  ) as TableInstanceWithHooks<any>

  useEffect(() => {
    setSortBy(sort)
  }, [sort])

  useEffect(() => {
    setPageSize(pageSizeItems || 50)
    const temp = localStorage.getItem('sortBy')
    const sort: any = temp ? JSON.parse(temp) : {[tableName]: [{id: 'Name', asc: true}]}

    const temp1: any = {
      ...JSON.parse(temp ? temp : '{}'),
      [tableName]: sort[tableName] ? sort[tableName] : [{id: 'Name', asc: true}],
    }

    localStorage.setItem('sortBy', JSON.stringify(temp1))
    // @ts-ignore <-- tell TypeScript to ignore the next line.
    setSort(sort[tableName] ? sort[tableName] : [{id: 'Name', asc: true}])
  }, [localStorage])

  useEffect(() => {
    window.scrollTo({top: 100, left: 100, behavior: 'smooth'})
  }, [pageIndex])

  return (
    <>
      {(!isMobile || hideCards) && (
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer w-100 mw-100'
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(
                (headerGroup) =>
                  headerGroup.headers.length > 0 && (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(
                        (column: any) =>
                          !column.hideHeader && (
                            <th
                              className='cell'
                              {...(!column.canSort
                                ? column.getHeaderProps()
                                : column.getHeaderProps(column.getSortByToggleProps()))}
                              onClick={() => {
                                if (column.canSort) {
                                  const exists = sortBy.findIndex((i: any) => i?.id === column.id)
                                  let newData: any = [...sortBy]

                                  if (exists >= 0) {
                                    if (newData[exists].asc === true) {
                                      newData[exists].asc = false
                                      newData[exists].desc = true
                                    } else if ((newData[exists].desc = true)) {
                                      newData = newData.filter((i: any) => i.id !== column.id)
                                    } else {
                                      newData[exists].asc = true
                                      newData[exists].desc = false
                                    }
                                  } else {
                                    newData = [
                                      ...newData,
                                      {
                                        id: column.id, // @ts-ignore <-- tell TypeScript to ignore the next line.
                                        asc: true,
                                      },
                                    ]
                                  }
                                  const local: any = localStorage.getItem('sortBy')
                                  const temp = {...JSON.parse(local || ''), [tableName]: newData}
                                  setSortBy(newData)
                                  localStorage.setItem('sortBy', JSON.stringify(temp))
                                }
                              }}
                            >
                              {column.render('Header')}
                            </th>
                          )
                      )}
                    </tr>
                  )
              )}
            </thead>
            <tbody className='text-gray-600 fw-semibold' {...getTableProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className='truncate cursor-pointer '>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className='p-0 p-md-2'>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {isMobile && !hideCards && !shrinkedView && !useExpand && (
        <TableMobileView
          data={page}
          columns={columns}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          sortBy={sortBy}
          setSortBy={setSortBy}
          tableName={tableName}
        />
      )}
      {isMobile && !hideCards && shrinkedView && (
        <TableMobileViewWithShrinkedCards
          data={page}
          columns={columns}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          _mobileColumns={_mobileColumns}
          sortBy={sortBy}
          setSortBy={setSortBy}
          tableName={tableName}
        />
      )}
      {isMobile && !hideCards && useExpand && (
        <TableMobileViewWithExpandedCards
          data={page}
          columns={columns}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          _mobileColumns={_mobileColumns}
          sortBy={sortBy}
          setSortBy={setSortBy}
          tableName={tableName}
        />
      )}
      {!hidePagination && (
        <CustomPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pageCount={pageCount}
          nextPage={nextPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          show={data.length > pageSize}
          total={data.length}
        />
      )}
    </>
  )
}

export default TableContainer
