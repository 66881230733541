import {FC, ReactElement, useState} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'
import {useListView} from '../../modules/apps/kid-management/kids-list/core/ListViewProvider'
import './styles.scss'
interface Props {
  children: any
  classes?: string
}
const ActionsDrawerContainer: FC<Props> = ({children, classes}): ReactElement => {
  const intl = useIntl()
  const {mobileConfiguration}: any = useAppContext()
  const {selected, setItemIdForUpdate, setCurrentKidModal} = useListView()

  const [showMenu, setShowMenu] = useState(false)
  return (
    <div className={`menu__container mh-100`}>
      <div className={`chid__container ${showMenu && 'showMenu'}`}>
        {!showMenu && (
          <div className='d-flex w-100 justify-content-end'>
            <button
              type='button'
              className=' btn   w-20px ms-auto me-4'
              onClick={() => setShowMenu((prev) => !prev)}
            >
              <i className={`fa-solid fa-bars fs-1`}></i>
            </button>
          </div>
        )}

        {children}
      </div>

      <div className={`actions__sideMenu ${showMenu && 'sideMenuShow shadow mh-100'} ${classes} `}>
        <div className='d-flex flex-column overflow-y-auto'>
          <button
            type='button'
            className=' btn   w-20px   me-auto'
            onClick={() => setShowMenu((prev) => !prev)}
          >
            <i className={`fa-solid ${showMenu ? 'fa-close' : 'fa-circle-plus'}  fs-1`}></i>
          </button>
          <>
            {mobileConfiguration?.KidAbsent &&
              mobileConfiguration?.KidAbsent[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom   fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('absense')
                  }}
                  disabled={selected.length === 0}
                >
                  <i className='fa-solid fa-person-circle-minus me-2 fs-4 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.ABSENSE'})}
                </button>
              )}
            {mobileConfiguration?.KidMedia && mobileConfiguration?.KidMedia[0]?.showInEducatorApp && (
              <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4 '
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('media')
                }}
                disabled={selected.length === 0}
              >
                <i className='fa-solid fa-photo-film me-2 fs-4'></i>
                {intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})}
              </button>
            )}

            {mobileConfiguration?.KidActivity &&
              mobileConfiguration?.KidActivity[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4 '
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('activity')
                  }}
                  disabled={selected.length === 0}
                >
                  <i className='fa fa-sync-alt me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.ACTIVITY'})}
                </button>
              )}
          </>
          <>
            {mobileConfiguration?.KidMealTime &&
              mobileConfiguration?.KidMealTime[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('meal')
                  }}
                >
                  <i className='fa-solid fa-utensils me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.MEAL'})}
                </button>
              )}

            {mobileConfiguration?.KidSleepTime &&
              mobileConfiguration?.KidSleepTime[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4 '
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('sleepsStart')
                  }}
                >
                  <i className='fa-solid fa-bed me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.ADD_SLEEPS_START'})}
                </button>
              )}
            {mobileConfiguration?.KidSleepTime &&
              mobileConfiguration?.KidSleepTime[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4 '
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('sleepsEnd')
                  }}
                >
                  <i className='fa-solid fa-child me-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.ADD_SLEEPS_END'})}
                </button>
              )}
            {mobileConfiguration?.KidDrink && mobileConfiguration?.KidDrink[0]?.showInEducatorApp && (
              <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('drinks')
                }}
              >
                <i className='fa-solid fa-mug-hot me-2 fs-4'></i>
                {intl.formatMessage({id: 'KIDLING.HOME.ADD_DRINKS'})}
              </button>
            )}

            {mobileConfiguration?.KidMedicalRecord &&
              mobileConfiguration?.KidMedicalRecord[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('medical')
                  }}
                >
                  <i className='fa-solid fa-notes-medical me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.MEDICAL'})}
                </button>
              )}

            {mobileConfiguration?.KidProtocolRemark &&
              mobileConfiguration?.KidProtocolRemark[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('protocol')
                  }}
                >
                  <i className='fa-solid fa-handshake-simple me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.PROTOCOL'})}
                </button>
              )}
            {mobileConfiguration?.KidMood && mobileConfiguration?.KidMood[0]?.showInEducatorApp && (
              <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('moods')
                }}
              >
                <i className='fa-solid fa-face-smile me-2 fs-4'></i>

                {intl.formatMessage({id: 'KIDLING.HOME.MOODS'})}
              </button>
            )}

            {mobileConfiguration?.KidAssessment &&
              mobileConfiguration?.KidAssessment[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('assessment')
                  }}
                >
                  <i className='fa-solid fa-table-list me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})}
                </button>
              )}

            {mobileConfiguration?.RequestKid &&
              mobileConfiguration?.RequestKid[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('request')
                  }}
                >
                  <i className='fa-solid fa-people-arrows me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.REQUEST'})}
                </button>
              )}

            {mobileConfiguration?.KidWindingProtocol &&
              mobileConfiguration?.KidWindingProtocol[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('diapers')
                  }}
                >
                  <i className='fa-solid fa-baby me-2 fs-4'></i>
                  {intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})}
                </button>
              )}
            {mobileConfiguration?.Events && mobileConfiguration?.Events[0]?.showInEducatorApp && (
              <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('event')
                }}
              >
                <i className='fa-solid fa-calendar-check me-2 fs-4'></i>
                {intl.formatMessage({id: 'KIDLING.HOME.EVENT'})}
              </button>
            )}
            {mobileConfiguration?.MealSubscription &&
              mobileConfiguration?.MealSubscription[0]?.showInEducatorApp && (
                <button
                  type='button'
                  className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                  onClick={() => {
                    setItemIdForUpdate(selected[0])
                    setCurrentKidModal('deregisteration')
                  }}
                >
                  <i className='fa-solid fa-square-minus  me-2 fs-4'></i>

                  {intl.formatMessage({id: 'KIDLING.HOME.DEREGISTERATION'})}
                </button>
              )}
            {/* <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4'
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('assignMeal')
                }}
              >
                <i className='fa-solid fa-child-reaching me-2 fs-4'></i> 
                {intl.formatMessage({id: 'KIDLING.HOME.ASSIGN_MEAL'})}
              </button> */}

            {/* <button
                type='button'
                className='btn  font-weight-bolder font-size-sm d-flex align-items-center btn-sm py-4  border-bottom fs-4 '
                onClick={() => {
                  setItemIdForUpdate(selected[0])
                  setCurrentKidModal('attach')
                }}
              >
                <i className='fa-solid fa-paperclip me-2 fs-4'></i> 
                {intl.formatMessage({id: 'KIDLING.HOME.ATTACH'})}
              </button> */}
          </>
        </div>
      </div>
    </div>
  )
}

export default ActionsDrawerContainer
