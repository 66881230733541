/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list'
import EventDialog from '../../../../general-components/EventDialog'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import EventCell from './EventCell'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {CalenderHeader} from './header/CalenderHeader'
import ErrorText from '../../../../general-components/error-text/ErrorText'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useIntl} from 'react-intl'
import useCalendar from '../../../../hooks/useCalendar'

function Calender() {
  const locale = useLang()
  const intl = useIntl()
  const {
    readEvents,
    calendarRef,
    formattedEvents,
    handleEventClick,
    handleDateSelect,
    triggerDateChanged,
    isLoading,
    DoDelete,
    readHolidayesAndDaysOff,
  } = useCalendar()
  const {
    setEventDetailsModal,
    eventDetailsModal,
    deleteEventConfirmation,
    setDeleteEventConfirmation,
    calendarFilter,
    calenderDateFilter,
    allEvents,
  } = useAppContext()

  useEffect(() => {
    readHolidayesAndDaysOff()
  }, [])

  useEffect(() => {
    if (!isLoading && allEvents.length === 0) {
      readEvents()
    }
  }, [calendarFilter])
  useEffect(() => {
    if (!isLoading) {
      readEvents()
    }
  }, [calenderDateFilter])
  return (
    <div className='p-4 p-sm-8 p-md-16'>
      <CalenderHeader setModal={setEventDetailsModal} />

      <FullCalendar
        ref={calendarRef}
        plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listDay',
        }}
        slotMinTime='07:00'
        slotMaxTime='20:00'
        initialView='timeGridWeek'
        allDaySlot={false}
        weekends={true}
        editable={false}
        selectable={true}
        selectMirror={false}
        dayMaxEvents={false}
        initialEvents={formattedEvents}
        events={formattedEvents}
        eventContent={EventCell}
        eventClick={handleEventClick}
        select={handleDateSelect}
        locale={locale}
        locales={allLocales}
        firstDay={1}
        nowIndicator={true}
        datesSet={triggerDateChanged}
      />

      {isLoading && <UsersListLoading />}
      <EventDialog
        modal={eventDetailsModal}
        setModal={setEventDetailsModal}
        readEvents={readEvents}
      />
      <SweetAlert
        show={deleteEventConfirmation.show}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setDeleteEventConfirmation({show: false, data: -1})
          DoDelete()
        }}
        onCancel={() => setDeleteEventConfirmation({show: false, data: -1})}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default Calender
