import {useState} from 'react'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import SweetAlert from 'react-bootstrap-sweetalert'
import TableContainer from '../../../../../general-components/table-container/TableContainer'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import _columns from './columns/_columns'
import ErrorText from '../../../../../general-components/error-text/ErrorText'
import {TableLoader} from '../../../../../general-components/table-loader/TableLoader'
import useKidAbsence from '../../../../../hooks/useKidAbsence'

const KidsTable = () => {
  const [showDelete, setShowDelete] = useState(false)
  const {globalLoader, globalLoader2} = useAppContext()
  const intl = useIntl()
  const {editCell, doDelete, handleDeleteAbsense, columns, data} = useKidAbsence({
    setShowDelete,
    _columns,
  })

  return (
    <>
      <KTCardBody className='py-4 px-0 px-sm-16'>
        <div className=' py-10'>
          {globalLoader ? (
            <TableLoader colNum={5} rows={10} />
          ) : data?.length > 0 ? (
            <TableContainer
              columns={columns}
              data={data}
              handleDelete={handleDeleteAbsense}
              editCell={editCell}
              tableName='abcenses'
            />
          ) : (
            <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
              <i className='fa-solid fa-database  ' style={{fontSize: '5rem'}}></i>
              <p className='mt-4 text-muted fs-3'>
                {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_RECORDS_YET'})}
              </p>
            </div>
          )}
          {globalLoader2 && <UsersListLoading />}
        </div>
      </KTCardBody>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={() => doDelete()}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </>
  )
}

export {KidsTable}
