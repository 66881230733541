import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

const KidDiapersModalHeader = () => {
  const {setItemIdForUpdate, clearSelected} = useListView()
  const intl = useIntl()
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <div className=''>
        <h2 className='fw-bold'>{intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})}</h2>
        <h2 className='label  form-label fs-6 fw-semibold text-muted'>
          {intl.formatMessage({
            id: 'KIDLING.ADD_MULTIPLE_NOTE',
          })}
        </h2>
      </div>

      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          clearSelected()
          setItemIdForUpdate(undefined)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {KidDiapersModalHeader}
