import React, {FC, ReactElement} from 'react'
import {Link} from 'react-router-dom'
import './style.scss'
type Props = {
  title: string
  url: string
  stat?: any
  icon: string
  extrnalLink?: boolean
}
const HrCard: FC<Props> = ({title, url, stat, icon, extrnalLink}): ReactElement => {
  const company = window.location.pathname.split('/')[1]

  return (
    <Link
      to={
        extrnalLink
          ? url
          : company
          ? `/${company}/kid-management/kids/simplified/${url}`
          : `/kidling/kid-management/kids/simplified/${url}`
      }
    >
      <div className='home_card  rounded   h-200px w-100 p-2 pt-8 '>
        <div className='d-flex flex-column justify-content-center align-items-center content w-100 h-100'>
          <i className={`text-primary icon  ${icon}`} style={{fontSize: '7rem'}}></i>
          {title && <h2 className='mt-2  fw-normal '> {title}</h2>}
          <div className='p-2 p-sm-4   w-100 d-flex justify-content-center mt-2'>
            <div className='d-flex align-items-center justify-content-around flex-wrap w-100 p-2  '>
              {stat?.map((s: any, index1: number) => (
                <div className='border rounded   p-4 flex-1' key={index1}>
                  <div className=' d-flex flex-column align-items-center   '>
                    <div className='d-flex align-items-center'>
                      <p className='fs-7 m-0 p-0 text-primary me-2 '>{s.title}</p>
                      {s.icon && <i className={`   ${s.icon}`}></i>}
                    </div>

                    {s.count && (
                      <p className={`fs-7 text-primary  m-0 p-0 ${s.color}  `}>{s.count}</p>
                    )}
                    {s.text && (
                      <p className={`fs-7 badge text-white badge-primary m-0 p-2 ${s.color}`}>
                        {s.text}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default HrCard
