import {FC} from 'react'
import {SleepsSearchComponent} from './SleepsSearchComponent'
import {SleepsToolbar} from './SleepsToolbar'
import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import useSleepTimes from '../../../../../hooks/useSleepTimes'

interface Props {
  title?: string
}
const SleepsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useSleepTimes()
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100'>
      {title && <h4>{title}</h4>}
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <SleepsSearchComponent />
          <SleepsToolbar />
        </div>
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>
    </div>
  )
}

export {SleepsHeader}
