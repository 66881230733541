import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const deleteMediaFile = async (id: number) => {
  return axios
    .delete(`${endpoints.KIDMEDIA}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
