import {useEffect} from 'react'
import {KidAbsenseModalHeader} from './KidAbsenseModalHeader'
import {KidAbsenseModalFormWrapper} from './KidAbsenseModalFormWrapper'
import ActionsDrawerContainer from '../../../../../general-components/ActionsDrawerContainer/ActionsDrawerContainer'
import {useMediaQuery} from 'react-responsive'

const KidAbsenseModal = () => {
  const isMobile = useMediaQuery({
    query: '(max-width:762px)',
  })

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidAbsenseModalHeader />
            {/* begin::Modal body */}
            <ActionsDrawerContainer
              children={
                <div className='modal-body scroll-y overflow-auto mx-5 mx-xl-12 my-0'>
                  <KidAbsenseModalFormWrapper />
                </div>
              }
              classes={isMobile ? 'mh-700px' : 'mh-600px'}
            />

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidAbsenseModal}
