import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'

export const getAttendanceByDate = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.KID_ATTENDACE}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => {
      return response.data.data?.sort((a: any, b: any) => moment(b?.dateTimeIn).diff(a?.dateTimeIn))
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteAttendance = async (id: string) => {
  return axios
    .delete(`${endpoints.ATTENDACE}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateAttendance = async (data: any) => {
  return axios
    .put(`${endpoints.ATTENDACE}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
export const getAttendanceByFilter = async (filter: string) => {
  return axios
    .get(`${endpoints.ATTENDACE}/listwhere/${filter}`)
    .then((response) => {
      console.log('response.data', response.data)

      return response.data?.sort((a: any, b: any) =>
        moment(b?.dateTimeIn).diff(a?.dateTimeIn) > 0 ? 1 : -1
      )
    })
    .catch((error) => {
      console.log(error)
    })
}
