import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useAuth} from '../../../../auth'
import {BulkEditSleep} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {selectStyle} from '../../../../../utils/select-style'
import {getAllKids} from '../../../../../../actions/kids'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'

const animatedComponents = makeAnimated()

type Props = {
  isUserLoading: boolean
}

const KidSleepsEndModalForm: FC<Props> = ({isUserLoading}) => {
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {sleepHabits, mobileConfiguration, setKidsList, filters} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const [habit, setHabit] = useState<any>(null)
  const [currentHabits, setCurrentHabits] = useState([])
  const [filterdKids, setFilterdKids] = useState<any>([])
  const intl = useIntl()
  const {currentUser} = useAuth()

  const [notifyContact, setNotifyContact] = useState(false)

  const [userForEdit] = useState({
    notes: '',
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
  })

  useEffect(() => {
    let temp: any = []
    sleepHabits.map((item: any) =>
      temp.push({label: item.sleepHabitName, value: item.sleepHabitId, desc: item.description})
    )
    setCurrentHabits(temp)
  }, [sleepHabits])

  useEffect(() => {
    setFilterdKids(selected.filter((item) => item.sleeps.length !== 0))
  }, [selected])

  const kidSchema = Yup.object().shape({
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),

    endTime: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidSleepTimeIds: number[] = []
        filterdKids.forEach((kid: any) => {
          if (kid.sleeps.length > 0) kidSleepTimeIds.push(kid.sleeps[0].kidSleepTimeId)
        })

        if (kidSleepTimeIds.length > 0) {
          const data = {
            kidSleepTimeIds,
            sleepHabitId: habit?.value ?? -1,
            toTime: moment(values.endTime).format('yyyy-MM-DDTHH:mm:ss'),
            companyId: filterdKids[0]?.classes[0]?.companyId || currentCompany?.value,
            notes: values.notes,
            totalSleepTimeMinute: 0,
            mobileConfigurationId:
              mobileConfiguration?.KidSleepTimeEnd[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkEditSleep(data)

          if (!!resp) {
            await getkidsData()
            // clearSelected()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            // cancel(true)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        reset()
        setSubmitting(true)
        //cancel(true)
        //  clearSelected()
      }
    },
  })
  const reset = () => formik.resetForm()
  const getkidsData = async () => {
    const temp = await getAllKids({
      filter: filters,
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (temp) setKidsList(temp)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {filterdKids?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>

          <div className=' row my-7'>
            <div className='col-8'>
              <div
                className={clsx(
                  {'is-invalid': formik.touched.date && formik.errors.date},
                  {
                    'is-valid': formik.touched.date && !formik.errors.date,
                  }
                )}
              >
                <ResponsiveDatePicker
                  onAccept={(date: any) => {
                    formik.setFieldValue('date', date)
                  }}
                  value={formik.values.date}
                />
              </div>
            </div>
            <div className='col-4'>
              <div
                className={clsx(
                  {'is-invalid': formik.touched.endTime && formik.errors.endTime},
                  {
                    'is-valid': formik.touched.endTime && !formik.errors.endTime,
                  }
                )}
              >
                <ResponsiveDatePicker
                  timeOnly
                  onAccept={(date: any) => {
                    formik.setFieldValue('endTime', date)
                  }}
                  value={formik.values.endTime}
                />
              </div>
              {formik.errors.endTime && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.endTime}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-7'>
            <Select
              openMenuOnFocus={true}
              value={habit}
              onChange={(val: any) => setHabit(val)}
              options={currentHabits}
              isClearable={true}
              components={animatedComponents}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.SLEEP_HABIT'})}
              className='w-100  '
              formatOptionLabel={(item: any) => (
                <div className='d-flex flex-column'>
                  <p className='p-0 m-0'>{item.label}</p>
                  <p className='p-0 m-0 text-muted'>{item.desc}</p>
                </div>
              )}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
              styles={selectStyle}
            />
          </div>
          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('notes')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.notes && formik.errors.notes},
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              name='notes'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
          <input
            className='form-check-input w-30px h-20px'
            type='checkbox'
            name='notifications'
            checked={notifyContact}
            onChange={() => setNotifyContact((prev) => !prev)}
          />
          <span className='form-check-label text-muted fs-7'>
            {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
          </span>
        </label>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {' '}
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidSleepsEndModalForm}
