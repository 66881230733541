/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {ProtocolsHeader} from './header/ProtocolsHeader'
import {KidProtocolModal} from '../../kid-management/kids-list/kid-protocol-modal/KidProtocolModal'
import useProtocols from '../../../../hooks/useProtocols'

function Protocol() {
  const {internalPagesDatesFilters, globalLoader, editModal, setEditModal} = useAppContext()
  const intl = useIntl()
  const {readProtocol, data} = useProtocols()

  useEffect(() => {
    if (!globalLoader) readProtocol()
  }, [internalPagesDatesFilters])

  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])
  return (
    <div className='container p-16'>
      <ProtocolsHeader title={intl.formatMessage({id: 'KIDLING.MENU.PROTOCOLS'})} />
      <TableCardsContainer data={data} columns={columns} />
      {editModal?.show && <KidProtocolModal data={editModal.data} setEditModal={setEditModal} />}
    </div>
  )
}

export default Protocol
