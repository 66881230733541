import {FC, useState} from 'react'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {UsersListFilter} from './UsersListFilter'
import {getAllAssessmentsWithCat} from '../../../../../../../actions/assessment'

const AssessmentHeader: FC = () => {
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState('')
  const {setGlobalLoader, setAllAssessmentsWithCats} = useAppContext()

  const readData = async (data?: string) => {
    let finalFilter = filters
    if (!!search) {
      if (!!filters) finalFilter += '&'
      finalFilter += `Search=${search}`
    }
    if (data === '') {
      setSearch('')
    }
    setGlobalLoader(true)
    const resp1 = await getAllAssessmentsWithCat(finalFilter)
    setGlobalLoader(false)
    if (!!resp1) {
      setAllAssessmentsWithCats(resp1)
    }
  }

  return (
    <div className='card-header border-0 pt-6 d-flex flex-column align-items-start'>
      <div className='d-flex align-items-sm-center align-items-start flex-sm-row flex-column w-100'>
        <UsersListSearchComponent filterData={setSearch} search={search} />
        <div className='d-flex justify-content-end w-100' data-kt-user-table-toolbar='base'>
          <UsersListFilter filterData={setFilters} readData={readData} />
        </div>
      </div>
    </div>
  )
}

export {AssessmentHeader}
