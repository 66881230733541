/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import {getAllkKidsAssessments} from '../../actions/assessment'
import {useEffect, useState} from 'react'
import {useListView} from '../modules/apps/kid-management/kids-list/core/ListViewProvider'
import {useAuth} from '../modules/auth'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {getAllKids} from '../../actions/kids'
import {useCompanyContext} from '../../_metronic/layout/core/CompanyProvider'
import {groupBy} from '../utils/common'

const useAssessment = (rawData?: any) => {
  const intl = useIntl()
  const {
    allKidsAssessments,
    setAllKidsAssessments,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    globalLoader,
    assessementFilter,
    kidsList,
    setKidsList,
    setInternalPagesFilters,
  } = useAppContext()
  const [date, setDate] = useState<any>({})
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [currentData, setCurrentData] = useState(rawData)
  const [orginilaGroupedData, setOrginilaGroupedData] = useState<any>([])
  const [groupedData, setGroupedData] = useState([])
  const [intitialPositionId, setIntitialPositionId] = useState(null)
  const {currentCompany} = useCompanyContext()
  const {selected, setSelected} = useListView()
  const [clicked, setClicked] = useState(false)
  const [filters, setFilters] = useState([])
  const [selectedFilter, setSelectedFilter] = useState<any>(null)
  const {translations, setAssessementFilter} = useAppContext()
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    let temp: any = []
    allKidsAssessments?.forEach((item: any) => {
      let KidsAssessments: any = []

      item.assessments?.forEach((item: any) =>
        KidsAssessments.push({
          id: item.assessmentItemId,
          title: item.assessmentItemTitle,
          date: `${moment(item.kidAssessmentDate).format('DD.MM.yyyy')} `,
          desc: item.kidAssessmentEducatorNotes,
          tag: item.assessmentItemRateName,
          assessmentName: item.assessmentName,
          assessmentCategoryName: item.assessmentCategoryName,
          assessmentSubCategoryName: item.assessmentSubCategoryName,
        })
      )
      let myClasses: any = []
      item.classes.forEach(
        (cc: any, index: number) =>
          cc.employeeId === currentUser?.employee?.employeeId &&
          cc.classesName &&
          myClasses.push(cc)
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        classes: myClasses,
        subItems: KidsAssessments,
        kid: item,
        // handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
    setGlobalLoader(false)
  }, [allKidsAssessments])

  useEffect(() => {
    setIntitialPositionId(null)
    if (kidsList.length === 0 && currentCompany) readKids()
  }, [currentCompany])

  useEffect(() => {
    let grouped: any = []

    currentData?.forEach((item: any) => {
      let gr = groupBy(item.subItems, 'assessmentName')

      let countOfFilled = 0
      let subItems: any = []
      Object.keys(gr).forEach((key, val) => {
        countOfFilled = 0

        gr[key].forEach((i: any) => {
          if (i.tag) countOfFilled++
        })

        subItems = [...subItems, {name: key, items: gr[key], countOfFilled: countOfFilled}]
      })
      grouped = [...grouped, {...item, subItems: subItems}]
    })

    setGroupedData(grouped)
  }, [currentData])

  useEffect(() => {
    let grouped: any = []

    data?.forEach((item: any) => {
      let gr = groupBy(item.subItems, 'assessmentName')

      let countOfFilled = 0
      let subItems: any = []
      Object.keys(gr).forEach((key, val) => {
        countOfFilled = 0
        gr[key].forEach((i: any) => {
          if (i.tag) countOfFilled++
        })
        subItems = [...subItems, {name: key, items: gr[key], countOfFilled: countOfFilled}]
      })
      grouped = [...grouped, {...item, subItems: subItems}]
    })
    setOrginilaGroupedData(grouped)
  }, [data])

  useEffect(() => {
    let output: any = []
    if (assessementFilter?.id > 0) {
      currentData.forEach((item: any) => {
        let subItems: any = []
        item?.subItems?.forEach((sub: any) => {
          if (!sub.tag) {
            subItems = [...subItems, sub]
          }
        })

        output = [...output, {...item, subItems}]
      })

      setCurrentData(output)
    } else {
      setCurrentData(data)
    }
  }, [data, assessementFilter])

  const readAssessments = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.assessment.classValue && !internalPagesFilters.assessment.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.assessment.classValue)
      filter = `classesId=${internalPagesFilters.assessment.classValue}`
    if (internalPagesFilters.assessment.classValue && internalPagesFilters.assessment.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.assessment.searchTerm)
      filter = filter + internalPagesFilters.assessment.searchTerm

    const res = await getAllkKidsAssessments(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.assessment.From
        ? moment(internalPagesDatesFilters.assessment.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.assessment.To
        ? moment(internalPagesDatesFilters.assessment.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
    )

    setAllKidsAssessments(res)
  }

  const readKids = async () => {
    const res = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })
    if (res) {
      setKidsList(res)
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.assessment = {
      ...newFilters.assessment,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.assessment = {
      ...newFilters.assessment,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }
    setInternalPagesFilters(newFilters)
  }

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (!!clicked && !globalLoader) {
      readAssessments()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    let temp: any = [
      {
        id: -1,
        type: intl.formatMessage({id: 'COMMON.All'}),
        icon: 'fa-solid fa-list-check',
        filter: '',
      },
      {
        id: 1,
        type: intl.formatMessage({id: 'KIDLING.NOT_ANSWERD'}),
        icon: 'fa-solid fa-pen-to-square',
        filter: '',
      },
    ]

    setFilters(temp)
    setSelectedFilter(temp[0])
  }, [translations])

  useEffect(() => {
    if (selectedFilter) setAssessementFilter(selectedFilter)
  }, [selectedFilter])

  return {
    readAssessments,
    data,
    orginilaGroupedData,
    groupedData,
    intitialPositionId,
    setIntitialPositionId,
    setSelected,
    selected,
    handleDateChange,
    setClicked,
    searchTerm,
    setSearchTerm,
    filters,
    selectedFilter,
    setSelectedFilter,
  }
}

export default useAssessment
