import React, {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'

import {useNavigate, useParams} from 'react-router-dom'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const MessagesSidebar: FC = (): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const navigate = useNavigate()
  const params: any = useParams()
  const {tabMessagesCounts} = useAppContext()
  const menuData = [
    {
      id: 'inbox',
      title: intl.formatMessage({id: 'KIDLING.HOME.INBOX'}),
      icon: 'fa-regular fa-envelope',
      count: tabMessagesCounts?.unread,
    },
    {
      id: 'marked',
      title: intl.formatMessage({id: 'KIDLING.HOME.MARKED'}),
      icon: 'fa-solid fa-star',
      count: tabMessagesCounts?.pinned,
    },
    // {
    //   id: 'draft',
    //   title: intl.formatMessage({id: 'KIDLING.HOME.DRAFT'}),
    //   icon: 'fa-solid fa-file',
    //   count: tabMessagesCounts?.status.draft,
    // },
    {
      id: 'sent',
      title: intl.formatMessage({id: 'KIDLING.HOME.SENT'}),
      icon: 'fa-solid fa-paper-plane',
      count: tabMessagesCounts?.sent,
    },
    {
      id: 'trash',
      title: intl.formatMessage({id: 'KIDLING.HOME.TRASH'}),
      icon: 'fa-solid fa-trash',
      count: tabMessagesCounts?.delete,
    },
  ]

  return (
    <div className='card-body  '>
      <button className='btn btn-primary fw-bold w-100 mb-8' onClick={() => navigate('compose')}>
        {intl.formatMessage({id: 'KIDLING.HOME.NEW_MESSAGE'})}
      </button>
      <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10'>
        {menuData?.map((item: any, index: number) => (
          <div className='menu-item mb-3' key={index}>
            <span
              className={`menu-link ${params['*'] === item.id && 'active'}`}
              onClick={() => navigate(`${item.id}`)}
            >
              <span className='menu-icon'>
                <i className={`${item.icon} fs-3 me-3 ${mode === 'dark' && 'text-dark'}`}></i>
              </span>
              <span className={` menu-title fw-bold ${mode === 'dark' && 'text-dark'}`}>
                {item.title}
              </span>
              <span className='badge badge-light-success'>{item.count}</span>
            </span>
          </div>
        ))}
      </div>
      {/* <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary '>
        <button
          className='btn btn-primary fw-bold w-100 mb-8'
          onClick={() => navigate('new-group')}
        >
          {intl.formatMessage({id: 'KIDLING.HOME.NEW_GROUP'})}
        </button>
        <GroupMessagesSideBar />
      </div> */}
    </div>
  )
}

export default MessagesSidebar
