/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment'
import {FormattedMessage, useIntl} from 'react-intl'
import makeAnimated from 'react-select/animated'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import Select from 'react-select'
import EventDialog from '../../../../../general-components/EventDialog'
import {useAuth} from '../../../../auth'
import {BulkEvent, BulkEventContact} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {
  addNewEventSchedule,
  getAllEventsWithRecurrents,
  getEventSchedule,
} from '../../../../../../actions/events'
import {selectStyle, selectStyleDarkMode} from '../../../../../utils/select-style'
import SelectWithAllOption from '../../../../../general-components/select-with-all/SelectWithAllOption'
import {Link} from 'react-router-dom'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import {momentDateFormat} from '../../../../../../config/constants'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
const animatedComponents = makeAnimated()

type Props = {
  isUserLoading: boolean
}

const KidEventModalForm: FC<Props> = ({isUserLoading}) => {
  const {mode} = useThemeMode()
  const company = window.location.pathname.split('/')[1]
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [globalEnable, setGlobalEnable] = useState<boolean>(false)
  const [listOfDaysValues, setListOfDaysValues] = useState<any>([])
  const [eventsList, setEventsList] = useState([])
  const [prevEvents, setPrevEvents] = useState([])
  const [modal, setModal] = useState<any>({showModal: false, data: null, enableEdit: true})
  const [isLoading, setIsLoading] = useState(false)
  const [allowConctacts, setAllowConctacts] = useState(false)
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {allEvents, setAllEvents} = useAppContext()
  const [contacts, setContacts] = useState([])
  const [notifyContact, setNotifyContact] = useState(false)
  const {currentCompany} = useCompanyContext()
  const {mobileConfiguration} = useAppContext()
  const [userForEdit] = useState({
    event: eventsList[0] || null,
    fromdate: new Date(),
    todate: new Date(),
  })

  const kidSchema = Yup.object().shape({
    event: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    startdate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    enddate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })
  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = [],
          contactIds: number[] = []
        selected.map((kid) => kidIds.push(kid.kidId))
        let contactCount = 0
        if (allowConctacts) {
          formik.values?.contact?.map((cont: any) => contactIds.push(cont.contactId))
          const contactData = {
            contactIds,
            eventsId: values.event['value'] || 0,
            employeeId: currentUser?.employee?.employeeId,
            companyId: currentCompany?.value,

            mobileConfigurationId: mobileConfiguration?.Events[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }
          const resp4 = await BulkEventContact(contactData)

          contactCount = resp4?.saved?.length ?? 0
        }

        const data = {
          kidIds,
          eventsId: values.event['value'] || 0,
          employeeId: currentUser?.employee?.employeeId,
          companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
          mobileConfigurationId: mobileConfiguration?.Events[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }

        const resp = await BulkEvent(data)

        if (!!resp) {
          if (listOfDaysValues.length > 0) {
            const schedule = listOfDaysValues.map((item: any) => ({
              weekdayId: item,
              eventsId: values.event['value'],
              fromTime: moment(values.fromdate).format('yyyy-MM-DDTHH:mm:ss'),
              toTime: moment(values.todate).format('yyyy-MM-DDTHH:mm:ss'),
            }))
            const resp1 = await addNewEventSchedule(schedule)
            if (resp1) {
              //   cancel(true)
            }
          }
          //  clearSelected()
          toast.success(
            <FormattedMessage
              id='ADDED_TO_KIDS_AND_CONTACTS'
              values={{
                kidCount: resp.saved.length,
                contactCount: contactCount,
              }}
            />
          )
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        // cancel(true)
      }
    },
  })

  useEffect(() => {
    if (selected.length > 0) {
      let temp: any = []
      selected.forEach((kid) => {
        kid?.contacts.forEach((cont: any) => {
          temp.push({kidName: kid.kidName, label: cont.contactName, value: cont.contactId, ...cont})
        })
      })
      setContacts(temp)
    }
  }, [selected])

  useEffect(() => {
    if (!globalEnable) {
      setListOfDaysValues([])
    }
  }, [globalEnable])

  useEffect(() => {
    readEvents()
  }, [])

  useEffect(() => {
    const temp: any = []
    allEvents.map((item: any) =>
      temp.push({
        value: item.eventsId,
        label: item.eventsName,
        desc: item.description,
        startDate: item.startsOn,
        endDate: item.endsOn,
      })
    )
    setEventsList(temp)
  }, [allEvents])

  useEffect(() => {
    readEventSchedule()
  }, [formik.values])

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const readEventSchedule = async () => {
    if (formik.values.event?.value) {
      const resp = await getEventSchedule(formik.values.event?.value)

      if (resp) {
        if (resp.length > 0) {
          setGlobalEnable(true)
          const days: any = []
          resp.map((item: any) => days.push(item.weekdayId))
          setListOfDaysValues(days)
          formik.setFieldValue('fromdate', resp[0]?.fromTime)
          formik.setFieldValue('todate', resp[0]?.toTime)
        } else {
          setGlobalEnable(false)
        }
      }
    }
  }

  const readEvents = async (date?: any, eventId?: number) => {
    setIsLoading(true)
    const resp: any = await getAllEventsWithRecurrents(
      `EmployeeId=${currentUser?.employee?.employeeId}`,
      moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      moment().startOf('day').add(1, 'years').format('yyyy-MM-DDTHH:mm:ss')
    )

    setIsLoading(false)
    if (resp) {
      setAllEvents(resp)
      let newEvent: any = null
      if (modal?.data?.eventsId)
        newEvent = resp?.find((item: any) => item.eventsId === modal?.data?.eventsId)
      else newEvent = resp?.find((item: any) => item.eventsId === eventId)

      if (modal.showModal)
        setModal({
          showModal: true,
          data: {...newEvent, extendedProps: {...newEvent}},
          enableEdit: true,
        })
    }
  }

  useEffect(() => {
    readPrevEvents()
  }, [selected, formik.values])
  const readPrevEvents = async (dateFilter?: any, currentEventId?: number, mine?: boolean) => {
    try {
      let filter: string = ''

      selected?.forEach((kid: any, index) => {
        if (index === 0) filter += ' ( '
        if (index > 0) filter += ' OR '
        filter += `eventsKid.Any(x => x.kidId=${kid.kidId})`
      })
      if (selected.length > 0) filter += ' ) '
      if (filter === '') filter = '1=1'
      const resp = await getAllEventsWithRecurrents(
        '1=1',
        formik.values.date
          ? moment(formik.values.date).startOf('day').format(momentDateFormat)
          : moment().startOf('day').format(momentDateFormat),
        moment(formik.values.date).endOf('day').format(momentDateFormat),
        filter
      )

      setPrevEvents(resp)
    } catch (e) {
      console.log('error', e)
      setIsLoading(false)
    }
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {prevEvents?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'0'}>
                {prevEvents?.map((item: any, index: number) => {
                  // const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)

                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={item?.imagePath}
                            alt={`${item?.eventsName?.charAt(0)?.toUpperCase()}  `}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.eventsName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          <div className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '>
                            <div>
                              <h5 className='p-0 m-0'>{item.locationName}</h5>

                              <p
                                className='m-0 p-0 text-muted '
                                dangerouslySetInnerHTML={{__html: item.description}}
                              ></p>

                              <p className='m-0 p-0 text-muted'>
                                {moment(item.dateTime).format('DD.MM.yyyy HH:mm')}
                              </p>
                              <div className=' mt-2 border border-1 border-top'>
                                <div className='text-dark fw-bold w-100px'>
                                  {intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'})}:
                                </div>

                                {item.eventsKid.map((kid: any, i: number) => (
                                  <p className='my-0 py-0' key={i}>
                                    {kid.kidName}
                                  </p>
                                ))}
                              </div>
                            </div>
                            <div className='d-flex flex-column align-items-center'>
                              <Link
                                to={
                                  company
                                    ? `/${company}/kid-management/diapers`
                                    : '/kidling/kid-management/diapers'
                                }
                                className='btn btn-light p-1 px-4 mt-2'
                              >
                                <i className='fa-solid fa-arrow-right'></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row mb-7 mt-7'>
            <div className='form-check d-flex align-items-center justify-content-between mb-4'>
              <div className='d-flex align-items-center '>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={allowConctacts}
                  onChange={(e) => setAllowConctacts((prev) => !prev)}
                />
                <label
                  className='form-label fs-6 fw-semibold text-muted ms-2 mb-0'
                  onClick={() => setAllowConctacts((prev) => !prev)}
                >
                  {intl.formatMessage({id: 'KIDLING.HOME.CONTACT'})}?
                </label>
              </div>
            </div>

            <div className='  w-100 w-md-auto'>
              <SelectWithAllOption
                openMenuOnFocus={true}
                value={formik.values.contact}
                onChange={(val: any) => {
                  formik.setFieldValue('contact', val)
                }}
                isMulti
                allowSelectAll
                isDisabled={!allowConctacts}
                // isLoading={contacts.length === 0}
                options={contacts}
                isClearable={true}
                components={animatedComponents}
                placeholder={intl.formatMessage({id: 'KIDLING.HOME.CONTACT'})}
                className={`w-100 ${formik.errors.event && 'is-invalid'}`}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex flex-column'>
                    <p className='p-0 m-0 fs-7  '>{item.label}</p>
                    <p className='p-0 m-0 text-muted fs-8'>{item.kidName}</p>
                  </div>
                )}
                theme={(theme: any) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              />
              {formik.errors.contact && (
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.contact}</span>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-7 '>
            <div className='  w-100 w-md-auto'>
              <Select
                openMenuOnFocus={true}
                value={formik.values.event}
                onChange={(val) => {
                  formik.setFieldValue('event', val)
                }}
                isLoading={isLoading}
                options={eventsList}
                isClearable={true}
                components={animatedComponents}
                placeholder={intl.formatMessage({id: 'KIDLING.HOME.EVENTS'})}
                className={`w-100 ${formik.errors.event && 'is-invalid'}`}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex flex-column'>
                    <p className='p-0 m-0'>{item.label}</p>
                    <p className='p-0 m-0 text-muted'>{item.desc}</p>
                    <p className='p-0 m-0 text-muted'>
                      {moment(item.startDate).format('DD.MM.yyyy HH:mm')} {'  -  '}
                      {moment(item.endDate).format('DD.MM.yyyy HH:mm')}
                    </p>
                  </div>
                )}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                styles={selectStyle}
              />
              {formik.errors.event && (
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.event}</span>
                </div>
              )}
            </div>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
          <hr className='hr' />
          <button
            className='btn btn-primary'
            type='button'
            onClick={() => setModal({showModal: true, data: null, enableEdit: true})}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.OR_CREATE_NEW_EVENT'})}
          </button>
        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      <EventDialog modal={modal} setModal={setModal} readEvents={readEvents} />
    </>
  )
}

export {KidEventModalForm}
