/* eslint-disable react-hooks/exhaustive-deps */
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {KTCard} from '../../../../_metronic/helpers'
import Mealtimes from './components/Mealtimes'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useEffect} from 'react'
import {getAllKids} from '../../../../actions/kids'
import {useAuth} from '../../auth'
import {useCompanyContext} from '../../../../_metronic/layout/core/CompanyProvider'

const MealtimesPage = () => {
  const {setKidsList, mobileConfiguration}: any = useAppContext()
  const {currentCompany} = useCompanyContext()
  const {currentUser} = useAuth()

  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidMealTime[0]?.showInEducatorApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const temp = await getAllKids({
      filter: '1=1',
      employeeId: currentUser?.employee?.employeeId,
      companyId: currentCompany?.value,
    })

    if (temp) setKidsList(temp)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <Mealtimes />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default MealtimesPage
