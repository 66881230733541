/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import SweetAlert from 'react-bootstrap-sweetalert'
import {addNewActivity, getActivities} from '../../../../../../actions/kids'
import {useAuth} from '../../../../auth'
import {BulkActivity} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import ErrorText from '../../../../../general-components/error-text/ErrorText'
import moment from 'moment-timezone'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {Link} from 'react-router-dom'
import {getKidsAcivities} from '../../../../../../actions/activities'
import {momentDateFormat} from '../../../../../../config/constants'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'

type Props = {
  isUserLoading: boolean
}

const KidActivityModalForm: FC<Props> = ({isUserLoading}) => {
  const company = window.location.pathname.split('/')[1]
  const {activities, setActivities, mobileConfiguration} = useAppContext()
  const {currentCompany} = useCompanyContext()
  const {currentUser}: any = useAuth()
  const [showAddActivity, setShowAddActivity] = useState(false)
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [selectedActivities, setSelectedActivities] = useState([])
  const [notifyContact, setNotifyContact] = useState(false)
  const [kidsActivites, setKidsActivites] = useState([])
  const intl = useIntl()
  const [userForEdit] = useState({
    activity: '',
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    activity: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })
  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = []
        selected.map((kid) => kidIds.push(kid.kidId))
        const data = {
          kidIds,
          activityIds: selectedActivities,
          employeeId: currentUser?.employee?.employeeId,
          dateTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
          mobileConfigurationId: mobileConfiguration?.KidActivity[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }

        const resp = await BulkActivity(data)

        if (!!resp) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          // cancel(true)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        // clearSelected()
        reset()
        setSubmitting(true)
        // cancel(true)
      }
    },
  })
  useEffect(() => {
    readActivities()
  }, [selected, formik.values])
  const reset = () => formik.resetForm()
  const AddActivity = async (value: any) => {
    setShowAddActivity(false)
    const data = {activityName: value, companyId: currentCompany?.value}
    const resp = await addNewActivity(data)
    if (resp) {
      const resp1 = await getActivities()
      if (resp1) setActivities(resp1)
    }
  }

  const handleCheckActivity = (e: any, item: any) => {
    let tempActivites: any = [...selectedActivities]
    const isExitst = tempActivites.find((i: number) => i === item.activityId)

    if (!!isExitst) {
      tempActivites = tempActivites.filter((i: number) => i !== item.activityId)
    } else {
      tempActivites.push(item.activityId)
    }
    setSelectedActivities(tempActivites)
  }

  const readActivities = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'
    const res = await getKidsAcivities(
      filter,
      currentUser?.employee?.employeeId || 0,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )

    setKidsActivites(res)
  }
  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidsActivites?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {kidsActivites?.map((item: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.kidActivities?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.activityName}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal?.remarks}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.activityDatetime).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <Link
                                to={
                                  company
                                    ? `/${company}/kid-management/activities`
                                    : '/kidling/kid-management/activities'
                                }
                                className='btn btn-light p-1 px-4 mt-2'
                              >
                                <i className='fa-solid fa-arrow-right'></i>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row my-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HOME.DATETIME'})}
            </label>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HOME.ACTIVITY'})}
            </label>
            {activities.map((item: any, index: number) => (
              <div className='form-check col-12 my-4' key={index}>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  id='flexCheckDefault'
                  onChange={(e) => handleCheckActivity(e, item)}
                />
                <label className='form-check-label'>{item.activityName}</label>
              </div>
            ))}
            <button
              type='button'
              onClick={() => setShowAddActivity(true)}
              className='btn btn-primary btn-sm '
            >
              {intl.formatMessage({id: 'KIDLING.HOME.ADD'})}
              <i className='fa-solid fa-plus fs-3 ms-2'></i>
            </button>
          </div>
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
          <input
            className='form-check-input w-30px h-20px'
            type='checkbox'
            name='notifications'
            checked={notifyContact}
            onChange={() => setNotifyContact((prev) => !prev)}
          />
          <span className='form-check-label text-muted fs-7'>
            {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
          </span>
        </label>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {' '}
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})} ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      <SweetAlert
        input
        required
        inputType='text'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.HOME.ADD_NEW_ACTIVITY'})} />}
        validationMsg={intl.formatMessage({id: 'KIDLING.HOME.YOU_MUST_ENTER_VALUE'})}
        onConfirm={AddActivity}
        onCancel={() => setShowAddActivity(false)}
        show={showAddActivity}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </>
  )
}

export {KidActivityModalForm}
