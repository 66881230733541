import React, {ReactElement, FC} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useIntl} from 'react-intl'
interface Props {
  row: any
}
const CustomAttribuesCell: FC<Props> = ({row}): ReactElement => {
  const intl = useIntl()
  return (
    <div className='d-flex  align-items-center justify-content-end'>
      {row?.attendance.length > 0 &&
        row?.attendance[row?.attendance.length - 1]?.employeeRemarks &&
        row?.attendance[row?.attendance.length - 1]?.employeeRemarks !== '' && (
          <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={(props) => (
              <Tooltip id='button-tooltip' {...props}>
                <div className='d-flex flex-column align-items-start'>
                  <h5 className='border-bottom  border-dark'>
                    {intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
                  </h5>

                  {row?.attendance.length > 0 && (
                    <p className=' py-0'>
                      {row?.attendance[row?.attendance.length - 1].employeeRemarks}
                    </p>
                  )}
                </div>
              </Tooltip>
            )}
          >
            <button className='btn text-muted p-0 me-4'>
              <i className='bi bi-sticky text-primary   fs-3'></i>
            </button>
          </OverlayTrigger>
        )}
      {row?.absents.length > 0 &&
        row?.absents[row?.absents.length - 1]?.employeeRemarks &&
        row?.absents[row?.absents.length - 1]?.employeeRemarks !== '' && (
          <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={(props) => (
              <Tooltip id='button-tooltip' {...props}>
                <div className='d-flex flex-column align-items-start'>
                  <h5 className='border-bottom  border-dark'>
                    {intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
                  </h5>

                  {row?.absents.length > 0 && (
                    <p className=' py-0'>{row?.absents[row?.absents.length - 1].employeeRemarks}</p>
                  )}
                </div>
              </Tooltip>
            )}
          >
            <button className='btn text-muted p-0 me-4'>
              <i className='bi bi-sticky text-primary   fs-3'></i>
            </button>
          </OverlayTrigger>
        )}
      {row?.attendance.length > 0 &&
        row?.attendance[row?.attendance.length - 1]?.contactRemarks &&
        row?.attendance[row?.attendance.length - 1]?.contactRemarks !== '' && (
          <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={(props) => (
              <Tooltip id='button-tooltip' {...props}>
                <div className='d-flex flex-column align-items-start'>
                  <h5 className='border-bottom  border-dark'>
                    {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
                  </h5>

                  {row?.attendance.length > 0 && (
                    <p className=' py-0'>
                      {row?.attendance[row?.attendance.length - 1].contactRemarks}
                    </p>
                  )}
                </div>
              </Tooltip>
            )}
          >
            <button className='btn text-muted p-0 me-4'>
              <i className='bi bi-sticky text-primary   fs-3'></i>
            </button>
          </OverlayTrigger>
        )}
      {row?.absents.length > 0 &&
        row?.absents[row?.absents.length - 1]?.contactRemarks &&
        row?.absents[row?.absents.length - 1]?.contactRemarks !== '' && (
          <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={(props) => (
              <Tooltip id='button-tooltip' {...props}>
                <div className='d-flex flex-column align-items-start'>
                  <h5 className='border-bottom  border-dark'>
                    {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
                  </h5>

                  {row?.absents.length > 0 && (
                    <p className=' py-0'>{row?.absents[row?.absents.length - 1].contactRemarks}</p>
                  )}
                </div>
              </Tooltip>
            )}
          >
            <button className='btn text-muted p-0 me-4'>
              <i className='bi bi-sticky text-primary   fs-3'></i>
            </button>
          </OverlayTrigger>
        )}
      {row.kidAllergyExists && (
        <OverlayTrigger
          placement='top'
          delay={{show: 250, hide: 400}}
          overlay={(props) => (
            <Tooltip id='button-tooltip' {...props}>
              <div className='d-flex flex-column align-items-start'>
                <h5 className='border-bottom  border-dark'>
                  {intl.formatMessage({id: 'KIDLING.HOME.ALLERGIES'})}
                </h5>

                {row?.kidAllergies.length > 0 &&
                  row.kidAllergies?.map((aller: any, i: number) => (
                    <p className=' py-0' key={i}>
                      {aller.allergyName}
                    </p>
                  ))}
              </div>
            </Tooltip>
          )}
        >
          <button className='btn text-muted p-0 me-4'>
            <i className='fas fa-allergies text-primary fs-3'></i>
          </button>
        </OverlayTrigger>
      )}

      {(row.medicine || row.medicalIssues || row.fears) && (
        <OverlayTrigger
          placement='top'
          delay={{show: 250, hide: 400}}
          overlay={(props) => (
            <Tooltip id='button-tooltip' {...props}>
              <div className='d-flex flex-column'>
                <h5 className='border-bottom  border-dark'>
                  {intl.formatMessage({id: 'KIDLING.HOME.MEDICALRECORDS'})}
                </h5>

                <div className='row gy-2 justify-content-start'>
                  {row.medicine && (
                    <>
                      <div className='col-6 text-start'>
                        <b className=''>{intl.formatMessage({id: 'KIDLING.HOME.MEDICINE'})}:</b>
                      </div>
                      <div className='col-6 text-start'>{row.medicine ?? '--'}</div>
                    </>
                  )}
                  {row.medicalIssues && (
                    <>
                      <div className='col-6 text-start'>
                        <b className=''>
                          {intl.formatMessage({id: 'KIDLING.HOME.MEDICAL_ISSUES'})}:
                        </b>
                      </div>
                      <div className='col-6 text-start'>{row.medicalIssues ?? '--'}</div>
                    </>
                  )}
                  {row.fears && (
                    <>
                      <div className='col-6 text-start'>
                        <b className=''>{intl.formatMessage({id: 'KIDLING.HOME.FEARS'})}:</b>
                      </div>
                      <div className='col-6 text-start'>{row.fears ?? '--'}</div>
                    </>
                  )}
                </div>
              </div>
            </Tooltip>
          )}
        >
          <button className='btn  text-muted p-0 me-4'>
            <i className='fa-solid fa-stethoscope text-primary fs-3'></i>
          </button>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        placement='top'
        delay={{show: 250, hide: 400}}
        overlay={(props: any) => (
          <Tooltip id='button-tooltip' {...props}>
            {row.allowTakePhoto
              ? intl.formatMessage({id: 'KIDLING.HOME.PHOTOS_ALLOWED'})
              : intl.formatMessage({id: 'KIDLING.HOME.PHOTOS_NOT_ALLOWED'})}
          </Tooltip>
        )}
      >
        <button className='btn  text-muted p-0 me-4'>
          {row.allowTakePhoto ? (
            <i className='fa-solid fa-camera text-primary fs-3'></i>
          ) : (
            <i className='bi bi-camera-video-off text-primary fs-3'></i>
          )}
        </button>
      </OverlayTrigger>

      {row.teacherInfo && (
        <OverlayTrigger
          placement='top'
          delay={{show: 250, hide: 400}}
          overlay={(props: any) => (
            <Tooltip id='button-tooltip' {...props}>
              {row.teacherInfo}
            </Tooltip>
          )}
        >
          <button className='btn  text-muted p-0'>
            <i className='fa-solid fa-circle-info text-primary fs-3'></i>
          </button>
        </OverlayTrigger>
      )}
    </div>
  )
}

export default CustomAttribuesCell
