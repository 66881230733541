/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import GroupMembersDialog from './GroupMembersDialog'
import '../styles.scss'
import {Link, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PrivateChatInner} from './PrivateChatInner'
import {Avatar, AvatarGroup} from '@mui/material'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useChatContext} from '../../../../store/context/ChatProvider'
import {useAuth} from '../../../auth'
import {getAllUserChats, getChatDetails} from '../../../../../actions/chat'
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr'

function PrivateDetailsMessages() {
  const params = useParams()
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]

  const [showMembersDialog, setShowMembersDialog] = useState(false)
  const {globalLoader, setGlobalLoader} = useAppContext()
  const {
    onlineUsers,
    allChats,
    setAllChats,
    setConnection,
    connection,
    setOnlineUsers,
    chatDetails,
    setChatDetails,
    mobileConfiguration,
  } = useChatContext()
  const {currentUser, auth, socketUrl} = useAuth()
  const [isConnecting, setIsConnecting] = useState(false)
  const [chatUsers, setChatUsers] = useState([])

  useEffect(() => {
    if (connection) {
      connection.on('RemovedUserFromChat', (response: any) => {
        let temp = [...allChats]

        const index = temp.findIndex((i) => i?.chatGroupId === Number(response?.chatGroupId))

        if (!!index)
          temp[index].users = temp[index]?.users?.filter(
            (u: any) => u?.usersId !== response?.usersId
          )

        setAllChats(temp)
      })
      connection.on('AddedUsersToChat', (response: any) => {
        let temp = [...allChats]

        const index = temp.findIndex((i) => i?.chatGroupId === Number(response[0]?.chatGroupId))
        if (!!index) temp[index].users = [...temp[index].users, ...response]

        setAllChats(temp)
      })
    }
  }, [connection, allChats])

  useEffect(() => {
    if (
      ((connection?._connectionState !== 'Connected' &&
        connection?._connectionState !== 'Connecting') ||
        !connection) &&
      auth &&
      mobileConfiguration?.ChatGroup &&
      mobileConfiguration?.ChatGroup[0]?.showInEducatorApp &&
      !isConnecting &&
      !!socketUrl
    )
      startConnection()
  }, [connection, auth, mobileConfiguration, socketUrl])

  useEffect(() => {
    if (params?.id && chatDetails?.chatGroupId !== params?.id) {
      const users = allChats?.find((i: any) => i?.chatGroupId === Number(params?.id))?.users

      setChatUsers(users)
    }
  }, [params?.id, allChats])

  useEffect(() => {
    if (params?.id && chatDetails?.chatGroupId !== params?.id) {
      readChatDetails(params?.id)
    }
  }, [params.id])

  const readChatDetails = async (id: string) => {
    if (id) {
      setGlobalLoader(true)
      const resp = await getChatDetails(id)
      setChatDetails(resp)
      setGlobalLoader(false)
    }
  }
  const startConnection = async () => {
    try {
      setIsConnecting(true)
      if (!!socketUrl) {
        const connection: any = new HubConnectionBuilder()
          .withUrl(socketUrl + 'chatHub', {
            accessTokenFactory: () => `${auth?.token}`,
          })
          .configureLogging(LogLevel.Error)
          .build()
        setConnection(connection)
        await connection.start()

        connection.on('OnlineUsers', function (response: any) {
          setOnlineUsers(response)
        })
      }
      setIsConnecting(false)
    } catch (e) {
      setIsConnecting(false)
    }
  }
  const readAllChats = async () => {
    setGlobalLoader(true)
    const resp = await getAllUserChats()
    setAllChats(resp)
    // setCurrentChats(resp)
    setGlobalLoader(false)
  }
  return (
    <div className='flex-lg-row-fluid  mh-100 h-100 bg-transparent '>
      <div className='card mh-100 h-100 p-0 m-0 ' id='kt_chat_messenger'>
        {!globalLoader && (
          <div
            className='card-header d-flex flex-row align-items-center  position-relative w-100 px-4 py-2'
            id='kt_chat_messenger_header'
          >
            <Link to={company ? `/${company}/chat` : '/kidling/chat'}>
              <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
            </Link>

            <div className='d-flex align-items-center justify-content-between flex-grow-1  '>
              <div className='d-flex align-items-center'>
                <div className='card-title d-flex align-items-center'>
                  {chatDetails?.users?.length > 2 ? (
                    <AvatarGroup
                      max={3}
                      onClick={() => setShowMembersDialog(!showMembersDialog)}
                      sx={{
                        '& .MuiAvatar-root': {width: 25, height: 25, fontSize: 8},
                      }}
                    >
                      {chatDetails?.users?.map((av: any, index1: number) => (
                        <Avatar
                          alt={av.usersName?.charAt(0).toUpperCase()}
                          key={index1}
                          src={av?.imageUrl}
                        />
                      ))}
                    </AvatarGroup>
                  ) : (
                    <Avatar alt={chatDetails?.chatGroupName?.charAt(0).toUpperCase()} />
                  )}
                  <div className='ms-2 d-flex align-items-center'>
                    <div>
                      <h6 className=' m-0 p-0'>
                        {chatDetails?.users?.length > 2
                          ? chatDetails?.chatGroupName
                          : `${
                              chatDetails?.users?.find(
                                (i: any) => i.usersId !== currentUser?.usersId
                              )?.usersName
                            }  `}
                      </h6>
                      {/* <p className='fs-7 text-muted m-0 p-0'>{chatDetails?.chatGroupName}</p> */}
                      {chatDetails?.users?.length === 2 &&
                        (onlineUsers?.includes(
                          Number(
                            chatDetails?.users?.find((i: any) => i.usersId !== currentUser?.usersId)
                              ?.usersId ?? -1
                          )
                        ) ? (
                          <span className='badge badge-success'>
                            {intl.formatMessage({id: 'COMMON.ONLINE'})}
                          </span>
                        ) : (
                          <span className='badge badge-offline'>
                            {intl.formatMessage({id: 'COMMON.OFFLINE'})}
                          </span>
                          // <p className='text-muted fs-7 m-0'>
                          //   Last seen:{' '}
                          //   <span>
                          //     {chatDetails?.messages?.length > 0
                          //       ? moment
                          //           .duration(
                          //             moment(moment().format('yyyy-MM-DDTHH:mm:ss')).diff(
                          //               moment(
                          //                 chatDetails?.messages[chatDetails?.messages?.length - 1]
                          //                   ?.dateTime
                          //               )
                          //             )
                          //           )
                          //           .locale(lang)
                          //           .humanize()
                          //       : '--'}
                          //   </span>
                          // </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <PrivateChatInner />
      </div>
      <GroupMembersDialog
        showModal={showMembersDialog}
        setShowModal={setShowMembersDialog}
        title={intl.formatMessage({id: 'KIDLING.GROUP_MEMBERS'})}
        users={chatUsers}
        readAllChats={readAllChats}
      />
    </div>
  )
}

export default PrivateDetailsMessages
