/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useAssessment from '../../../../../hooks/useAssessments'

const SwitchAssessmentFilter: FC = (): ReactElement => {
  const intl = useIntl()
  const {filters, selectedFilter, setSelectedFilter} = useAssessment()
  const {mode} = useThemeMode()

  return (
    <div className='w-100 d-flex align-items-center justify-content-center flex-wrap'>
      <h5 className='"me-2'>{intl.formatMessage({id: 'COMMON.FILTER'})}</h5>
      {filters.map((item: any, index: number) => (
        <div
          className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start pt-8 px-12 pb-2 m-2  d-flex align-items-center flex-column position-relative ${
            selectedFilter?.id === item.id && 'active'
          } `}
          data-kt-button='true'
          key={index}
          onClick={() => {
            setSelectedFilter(item)
          }}
        >
          <i className={`fs-1 fa-solid mb-2 ${mode === 'dark' && 'text-dark'} ${item.icon}`}></i>
          <input
            className={`form-check-input  position-absolute top-0 start-0 m-2`}
            type='radio'
            name='childFilter'
            checked={selectedFilter.id === item.id}
          />

          <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
            <div className='fs-4 fw-bold text-gray-800  '>{item.type}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export {SwitchAssessmentFilter}
