import axios from 'axios'
import {
  FOOD,
  KID_DEREGISTRATION,
  MEALTIME,
  MEAL_DEREGISTRATION,
  MEAL_PORTION,
} from '../config/endpoints'

export const getMealTimes = async () => {
  return axios
    .get(`${MEALTIME}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getMealPortions = async () => {
  return axios
    .get(`${MEAL_PORTION}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getFoodItems = async (filter?: string) => {
  console.log('filter is', filter)

  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${FOOD}/listwhere/companyId=${companyId} AND ${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAlldeRegisterMeal = async (
  filter: string,
  EmployeeId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${MEAL_DEREGISTRATION}/${filter}`, {
      params: {EmployeeId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteKiddeRegisterMeal = async (id: string) => {
  return axios
    .delete(`${KID_DEREGISTRATION}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
