import {ListViewProvider} from './core/ListViewProvider'
import {UsersListHeader} from './components/header/AttendanceHeader'
import {KidsTable} from './table/KidsTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {QueryRequestProvider} from '../../../../general-components/QueryRequestProvider'

const AttendanceList = () => {
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <KidsTable />
      </KTCard>
    </>
  )
}

const AttendanceListWrapper = () => (
  <QueryRequestProvider>
    <ListViewProvider>
      <AttendanceList />
    </ListViewProvider>
  </QueryRequestProvider>
)

export {AttendanceListWrapper}
