import KidsPage from '../../modules/apps/kid-management/KidsPage'
const KidsWrapper = () => {
  return (
    <>
      <KidsPage />
    </>
  )
}

export default KidsWrapper
