import React, {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {useMediaQuery} from 'react-responsive'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

interface Props {
  setCurrentTab: any
  currentTab: number
}
const ProfileSidebar: FC<Props> = ({setCurrentTab, currentTab}): ReactElement => {
  const {mode} = useThemeMode()
  const {employeeInfo} = useAppContext()
  const intl = useIntl()
  const isMobile = useMediaQuery({
    query: '(max-width:992px)',
  })

  const menuData = [
    {
      title: intl.formatMessage({id: 'KIDLING.PROFILE.USER_INFO'}),
      icon: 'fa-solid fa-user',
      count: '',
      show: true,
    },
    {
      title: intl.formatMessage({id: 'KIDLING.PROFILE.EMPLOYEE_INFO'}),
      icon: 'fa-solid fa-user-tie',
      count: '',
      show: !!employeeInfo,
    },
    {
      title: intl.formatMessage({id: 'KIDLING.PROFILE.ADDRESS'}),
      icon: 'fa-solid fa-building',
      count: '',
      show: true,
    },
    {
      title: intl.formatMessage({id: 'KIDLING.CHANGE_PASSWORD'}),
      icon: 'fa-solid fa-lock',
      count: '',
      show: true,
    },
    {
      title: intl.formatMessage({id: 'KIDLING.MENU.NOTIFICATIONS'}),
      icon: 'fa-solid fa-bell',
      count: '',
      show: true,
    },
  ]

  return (
    <div className={` card-body  ${isMobile && 'pb-0'} `}>
      <div
        className={`menu ${
          isMobile ? ' menu-row flex-wrap ' : ' menu-column  mb-10'
        } menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary`}
      >
        {menuData.map(
          (item: any, index: number) =>
            item.show && (
              <div className='menu-item mb-3' key={index}>
                <span
                  className={`menu-link ${currentTab === index && 'active'}`}
                  onClick={() => setCurrentTab(index)}
                >
                  <span className='menu-icon'>
                    <i className={`${item.icon} fs-3 me-3 ${mode === 'dark' && 'text-dark'}`}></i>
                  </span>
                  <span className={`menu-title fw-bold ${mode === 'dark' && 'text-dark'}`}>
                    {item.title}
                  </span>
                  <span className='badge badge-light-success'>{item.count}</span>
                </span>
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default ProfileSidebar
