/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import useMealTimes from '../../../../../hooks/useMealTimes'

const MealSearchComponent = () => {
  const intl = useIntl()
  const {setSearchTerm, searchTerm, setClicked} = useMealTimes()

  useEffect(() => {
    const handleSubmit = (event: any) => {
      if (event.keyCode === 13) {
        setClicked(true)
      }
    }
    window.addEventListener('keydown', handleSubmit)

    return () => {
      window.removeEventListener('keydown', handleSubmit)
      setClicked(false)
    }
  }, [])

  return (
    <div className='card-title my-2 w-100 w-md-auto mt-4'>
      {/* begin::Search */}
      <div className='d-flex flex-column w-100 w-md-auto'>
        <div className='d-flex align-items-center position-relative w-100 w-md-auto '>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-md-250px w-100  '
            style={{padding: '.6rem 0 .6rem 4rem'}}
            placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {MealSearchComponent}
