/* eslint-disable react-hooks/exhaustive-deps */
import {Tab} from 'bootstrap'
import {useEffect, useRef} from 'react'
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from '../assets/ts/components'
import {useLayout} from './core'
import {getLanguages, getTranslationsById} from '../../actions/language'
import {useAppContext} from './core/AppContextProvider'
import {useLang} from '../i18n/Metronici18n'

export function MasterInit() {
  const {config} = useLayout()
  const {setLanguages, setTranslations, languages}: any = useAppContext()
  const locale = useLang()
  const isFirstRun = useRef(true)
  const pluginsInitialization = () => {
    isFirstRun.current = false
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
      SwapperComponent.bootstrap()
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab)
      })
    }, 500)
  }

  useEffect(() => {
    if (locale) {
      const lang = languages?.filter((i: any) => i.value === locale)[0]
      if (lang) readTranslations(lang?.languageId)
    }
  }, [locale, languages])

  useEffect(() => {
    const lang: any = localStorage.getItem('languages')
    if (lang) {
      const temp = JSON.parse(lang)
      setLanguages(temp)
    }
    const trans: any = localStorage.getItem('translations')
    if (trans) {
      const temp = JSON.parse(trans)
      setTranslations(temp)
    }

    readLanguages()
  }, [])

  const readLanguages = async () => {
    const languages = await getLanguages()
    if (languages) {
      localStorage.setItem('languages', JSON.stringify(languages))
      setLanguages(languages)
    }
  }

  const readTranslations = async (id: number) => {
    const trans = await getTranslationsById(id)
    const temp: any = {}
    trans.map((item: any) => (temp[item.labelKey] = item.translationName))
    localStorage.setItem('translations', JSON.stringify(temp))
    setTranslations(temp)
  }
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      pluginsInitialization()
    }
  }, [config])

  return <></>
}
