import {FC, ReactElement, useEffect, useId, useState} from 'react'
import './styles.scss'
import SliderArrowNext from '../slider-arrow-next'
import SliderArrowBack from '../slider-arrow-back'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import moment from 'moment'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import {Avatar} from '@mui/material'
import SliderImageWithEnlarge from '../slider-image-with-enlarge'

interface Props {
  data: any
  readData?: any
  hideCheckbox?: boolean
}
const ImagesSliderProfile: FC<Props> = ({data, readData, hideCheckbox}): ReactElement => {
  const [enlargeImage, setEnlargeImage] = useState('')
  const [sliderData, setSliderData] = useState([])
  const id = useId()
  useEffect(() => {
    let temp: any = []
    data?.map((item: any) =>
      temp.push({
        original: item.filePath,
        thumbnail: item.filePath,
        ...item,
      })
    )

    setSliderData(temp)
  }, [data])

  return (
    <div className='slider_card card h-100 '>
      <div className='slider__section'>
        {sliderData?.length > 0 && (
          <div className='d-flex flex-column   rounded p-3 h-100 '>
            <div className='d-flex align-items-center justify-content-between mt-4 '>
              <Avatar
                src={data?.userImagePath}
                alt={
                  data?.userName
                    ? data?.userName?.charAt(0)?.toUpperCase()
                    : data?.userName?.charAt(0)?.toUpperCase()
                }
              />

              <div className='ms-2 w-100 d-flex justify-content-between'>
                <div>
                  <h5 className='m-0 p-0'>{data?.userName}</h5>
                  <p className='fs-7 text-muted m-0 p-0'>
                    {moment(data?.createdDate).format('DD.MM.yyyy')}
                  </p>
                </div>
              </div>
            </div>
            <p className='mb-4 p-0 text-muted '>{data[0]?.kidMediaFileDescription}</p>

            <ImageGallery
              items={sliderData}
              showPlayButton={false}
              renderLeftNav={(onClick: any, disabled: any) => (
                <SliderArrowBack onClick={onClick} disabled={disabled} />
              )}
              renderRightNav={(onClick: any, disabled: any) => (
                <SliderArrowNext onClick={onClick} disabled={disabled} />
              )}
              renderFullscreenButton={(onClick: any, isFullscreen: any) => (
                <button className='enlarge__btn' onClick={onClick}>
                  {isFullscreen ? (
                    <i className='fas fa-compress '></i>
                  ) : (
                    <i className='fa-solid fa-expand'></i>
                  )}
                </button>
              )}
              renderItem={(item: any) => (
                <div className='slide h-100 mh-100 position-relative'>
                  <SliderImageWithEnlarge
                    {...item}
                    img={item.filePath}
                    setEnlargeImage={setEnlargeImage}
                    enlargeImage={enlargeImage}
                    activateLongPress
                    hideCheckbox={hideCheckbox}
                    desc={item?.KidMediaFileDescription}
                  />
                  {item?.Confidential && (
                    <div className='position-absolute ' style={{right: '3rem', top: '10px'}}>
                      <i className='fa-solid fa-lock fs-3 text-info'></i>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        )}

        {enlargeImage !== '' && (
          <div className='position-relative '>
            <div
              className={`  full__image  d-flex justify-content-center align-items-center  w-screen h-screen`}
            >
              <button className='close__btn' onClick={() => setEnlargeImage('')}>
                <i className='fa-solid fa-xmark'></i>
              </button>

              <div
                className={`  d-flex justify-content-center align-items-center  slider__image__animation`}
              >
                <img
                  src={enlargeImage}
                  className='h-screen w-screen object-fit-contain'
                  alt={`slider-${id}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ImagesSliderProfile
