import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'

export const getEmployeeInfoByUserId = async (id: number) => {
  return axios
    .get(`${endpoints.EMPLOYEE}/listwhere/usersId=${id}?IsMobile=1`)
    .then((response) => response.data[0])
    .catch((error) => {
      console.log(error)
    })
}

export const updateEmployeeInfo = async (data: any) => {
  return axios
    .put(endpoints.EMPLOYEE, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAttendance = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ATTENDANCE}/listwhere/${filter}`)
    .then((response) => {
      const data: any = response.data.sort((a: any, b: any) => moment(b?.date).diff(a?.date))
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const addEmployeeAttendance = async (data: any) => {
  return axios
    .post(`${endpoints.EMPLOYEE_ATTENDANCE} `, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateEmployeeAttendance = async (data: any) => {
  return axios
    .put(`${endpoints.EMPLOYEE_ATTENDANCE} `, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeShift = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE_SHIFT}/listwhere/${filter}`)
    .then((response) => {
      const data: any = response.data.sort((a: any, b: any) => moment(b?.date).diff(a?.date))
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllEmployeesByKita = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE}/listwhere/${filter}?IsMobile=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
