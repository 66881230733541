/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import allLocales from '@fullcalendar/core/locales-all'
import AvatarWithLetters from '../../../general-components/avatar-with-letters'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import useFoodMenu from '../../../hooks/useFoodMenu'

function FoodMenuContent() {
  const {mode} = useThemeMode()
  const locale = useLang()
  const intl = useIntl()
  const {globalLoader} = useAppContext()
  const {
    triggerDateChanged,
    employeeClasses,
    FoodItems,
    openFile,
    currentDownload,
    isReading,
    currentTab,
    setCurrentTab,
    start,
    setFoodItems,
    readFoodMenu,
  } = useFoodMenu()

  useEffect(() => {
    if (employeeClasses.length > 0) {
      setFoodItems(null)
      readFoodMenu()
    }
  }, [start, employeeClasses])

  return (
    <div className='col-12 col-sm-6 col-md-4 mb-4 w-100 p-sm-8 p-4'>
      <FullCalendar
        plugins={[listPlugin]}
        headerToolbar={{
          left: 'title',
          center: '',
          right: 'prev,next',
        }}
        contentHeight='0'
        initialView='listDay'
        locale={locale}
        locales={allLocales}
        datesSet={triggerDateChanged}
        noEventsText=''
      />
      {employeeClasses?.length > 0 && (
        <Tabs
          activeKey={currentTab}
          id='uncontrolled-tab-example'
          className='my-3'
          onSelect={(key: any) => setCurrentTab(key)}
        >
          {employeeClasses?.map((item: any, index: number) => (
            <Tab eventKey={index} title={<h5>{item.name}</h5>}>
              {FoodItems &&
                FoodItems[item.name] &&
                Object.keys(FoodItems[item.name])?.length === 0 && (
                  <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
                    <i className='fa-solid fa-database  ' style={{fontSize: '5rem'}}></i>
                    <p className='mt-4 text-muted fs-3'>
                      {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_RECORDS_YET'})}
                    </p>
                  </div>
                )}
              {FoodItems &&
                FoodItems[item.name] &&
                Object.keys(FoodItems[item.name]) &&
                Object.keys(FoodItems[item.name]).map((key) => (
                  <div
                    className={`card w-100 border  ${mode === 'dark' && 'bg-body text-white'} `}
                    style={{backgroundColor: '#F3F6F9'}}
                  >
                    <div
                      className='card-header align-items-center fw-bold fs-4'
                      style={{minHeight: '3rem'}}
                    >
                      {key}
                    </div>
                    <ul className='list-group list-group-flush ps-8  bg-body'>
                      {FoodItems[item.name][key].map((item: any) => (
                        <li className='list-group-item d-flex align-items-center mb-4 position-relative bg-body'>
                          <AvatarWithLetters
                            image={item.imagePath}
                            alt={item.foodItemName.charAt(0).toUpperCase()}
                            small
                          />

                          <div className='ms-4'>
                            {!!item.filePath ? (
                              <div className=' d-flex align-items-center'>
                                <h5
                                  className='m-0 p-0 text-primary cursor-pointer'
                                  onClick={() => openFile(item)}
                                >
                                  {item.foodItemName}{' '}
                                </h5>
                                {isReading === item.filePath && (
                                  <span className='fs-5 text-warning ms-2 '>
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}

                                {currentDownload[item.filePath] && (
                                  <a
                                    className='btn btn-link text-warning fs-5 ms-2'
                                    target='_blank'
                                    href={currentDownload[item.filePath]}
                                    rel='noreferrer'
                                  >
                                    <i className='fa-solid fa-download'></i>
                                  </a>
                                )}
                              </div>
                            ) : (
                              // <Link to={item.filePath} target='_blank'>
                              //   <h5 className='m-0 p-0 text-primary'>{item.foodItemName} </h5>
                              // </Link>

                              <h5 className='m-0 p-0'>{item.foodItemName}</h5>
                            )}
                            <p className='text-muted s-7 my-0'>{item.remarks}</p>
                          </div>
                          <p className='text-muted fs-7 position-absolute bottom-0 end-0 me-2 mb-1'>
                            {moment(item.startDate).format('DD.MMM')} -{' '}
                            {moment(item.endDate).format('DD.MMM')}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </Tab>
          ))}
        </Tabs>
      )}

      {globalLoader && <UsersListLoading />}
    </div>
  )
}
export default FoodMenuContent
