import axios from 'axios'
import {ATTENDANCE_LOCATION} from '../config/endpoints'

export const getAttendanceLocations = async (filter: string) => {
  return axios
    .get(`${ATTENDANCE_LOCATION}/listwhere/${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
