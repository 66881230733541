/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../../auth'
import {BulkMeal} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {getFoodItems} from '../../../../../../actions/meal'
import {momentDateFormat} from '../../../../../../config/constants'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {getKidsMealTimes} from '../../../../../../actions/meals'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {Link} from 'react-router-dom'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
type Props = {
  isUserLoading: boolean
}

const KidMealModalForm: FC<Props> = ({isUserLoading}) => {
  const company = window.location.pathname.split('/')[1]
  const {currentCompany} = useCompanyContext()
  const {mealtime, mobileConfiguration} = useAppContext()
  const [currentMealTime, setCurrentMealTime] = useState(1)
  const {mealPortions} = useAppContext()
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [selectedPortions, setSelectedPortions] = useState([])
  const [portions, setPortions] = useState<any>({})
  const {currentUser} = useAuth()
  const [notifyContact, setNotifyContact] = useState(false)
  const [foodItems, setFoodItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [allKidsTheSame, setAllKidsTheSame] = useState([])
  const intl = useIntl()
  const [kidsMealTimes, setKidsMealTimes] = useState([])
  const [userForEdit] = useState({
    mood: '',
    notes: '',
    date: new Date(),
  })

  const kidSchema = Yup.object().shape({
    mood: Yup.string(),
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidMealTimes: any = []
        let kidIds: number[] = []
        allKidsTheSame.map((kid: any) => kidIds.push(kid.kidId))

        Object.keys(portions).map((key, value) =>
          kidMealTimes.push({
            dateTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            mealTimeId: currentMealTime,
            foodItemId: key,
            mealPortionId: portions[key].mealPortionId,
            employeeId: currentUser?.employee?.employeeId,
            companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
            notes: portions[key].notes,
          })
        )
        const data = {
          kidIds,
          kidMealTimes,
          mobileConfigurationId: mobileConfiguration?.KidMealTime[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }

        const resp = await BulkMeal(data)

        if (!!resp) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          //   cancel(true)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        reset()
        setSubmitting(true)
        // cancel(true)
      }
    },
  })
  const reset = () => formik.resetForm()
  const handleMealPortion = (item: any) => {
    let tempRequests: any = [...selectedPortions]

    const isExitst = tempRequests.find((i: number) => i === item.foodItemId)

    if (!!isExitst) {
      tempRequests = tempRequests.filter((i: number) => i !== item.foodItemId)
      delete portions[item.foodItemId]
    } else {
      tempRequests.push(item.foodItemId)
      setPortions((prev: any) => ({...prev, [item.foodItemId]: {}}))
    }
    setSelectedPortions(tempRequests)
  }

  useEffect(() => {
    let allTheSame: any = [selected[0]]
    let firstKidClasses = selected[0].classes

    selected.forEach((kid, index) => {
      if (index !== 0 && firstKidClasses.length === kid.classes.length) {
        let same = true
        kid.classes.forEach((item: any) => {
          if (!firstKidClasses.find((item1: any) => item1.classesId === item.classesId))
            same = false
        })
        if (same) allTheSame = [...allTheSame, kid]
      }
    })

    setAllKidsTheSame(allTheSame)
    readFoodItems(allTheSame)
    readMealTimes()
  }, [formik.values.date, selected])

  const readFoodItems = async (allTheSame: any) => {
    setIsLoading(true)
    let kidClasses: number[] = []
    let filter = ''
    allTheSame[0]?.classes?.map((item: any) => (kidClasses = [...kidClasses, item.classesId]))
    const uniqueArray = [...new Set(kidClasses)]
    uniqueArray?.forEach((item: number, index: number) => {
      if (index > 0) filter += ' OR '
      filter += `classesId=${item}`
    })
    const resp = await getFoodItems(
      ` endDate>="${moment(formik.values.date).startOf('day').format(momentDateFormat)}" AND (${
        filter ?? '1=1'
      })`
    )
    if (resp) setFoodItems(resp)
    setIsLoading(false)
  }

  const readMealTimes = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'

    const res = await getKidsMealTimes(
      filter,
      currentUser?.employee?.employeeId || 0,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )

    setKidsMealTimes(res)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {allKidsTheSame?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidsMealTimes?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'0'}>
                {kidsMealTimes?.map((item: any, index: number) => {
                  const fullKid: any = allKidsTheSame.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.kidMealTimes?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2   position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.mealTimeName}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal.foodItemName}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.dateTime).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`badge  `}
                                  style={{backgroundColor: 'var(--bs-primary)'}}
                                >
                                  {meal.mealPortionName}
                                </div>
                                <Link
                                  to={
                                    company
                                      ? `/${company}/kid-management/mealtimes`
                                      : '/kidling/kid-management/mealtimes'
                                  }
                                  className='btn btn-light p-1 px-4 mt-2'
                                >
                                  <i className='fa-solid fa-arrow-right'></i>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row my-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {' '}
              {intl.formatMessage({id: 'KIDLING.HOME.DATETIME'})}
            </label>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                dateTime
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.HOME.MEAL_TIME'})}
          </label>
          <div className='fv-row mb-10'>
            <div className='row g-3  w-100'>
              {mealtime.map((item: any, index: number) => (
                <div className='col-12 col-sm-4'>
                  <label
                    className='btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-1'
                    data-kt-button='true'
                    key={index}
                  >
                    <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start '>
                      <input
                        className='form-check-input'
                        type='radio'
                        name={item.mealTimeName}
                        value={item.mealTimeId}
                        onChange={() => setCurrentMealTime(item.mealTimeId)}
                        checked={currentMealTime === item.mealTimeId}
                      />
                    </span>

                    <span className='ms-3'>
                      <span className='fs-6 fw-bold text-gray-800 d-block'>
                        {item.mealTimeName}
                      </span>
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.HOME.MEAL_PORTION'})}
          </label>
          {foodItems?.map((item: any, index: number) => (
            <div className='card' key={index}>
              <div className='card-header p-1'>
                <div className='row  w-100  '>
                  <div className='col-lg-2 col-12  my-1'>
                    <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                      <input
                        className='form-check-input w-30px h-20px'
                        type='checkbox'
                        name='notifications'
                        value={portions[item.requestItemId]}
                        onChange={() => handleMealPortion(item)}
                      />
                      <span className='form-check-label text-muted fs-7'> {item.foodItemName}</span>
                    </label>
                  </div>
                  <div className='col-lg-7 col-12  my-1'>
                    <div className='row g-3  w-100'>
                      {mealPortions.map((item1: any, index1: number) => (
                        <div className='col-12 col-sm-3'>
                          <label
                            className='btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-1'
                            data-kt-button='true'
                            key={index1}
                          >
                            <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start  '>
                              <input
                                className='form-check-input'
                                type='radio'
                                name={`${item1.mealPortionName}- ${item.foodItemName}`}
                                checked={
                                  item1.mealPortionId === portions[item.foodItemId]?.mealPortionId
                                }
                                onChange={() => {
                                  console.log(
                                    'portions',
                                    item1.mealPortionId,
                                    portions[item.foodItemId]?.mealPortionId
                                  )

                                  setPortions((prev: any) => ({
                                    ...prev,
                                    [item.foodItemId]: {
                                      ...prev[item.foodItemId],
                                      mealPortionId: item1.mealPortionId,
                                    },
                                  }))
                                }}
                                disabled={
                                  selectedPortions?.find((i: number) => i === item.foodItemId)
                                    ? false
                                    : true
                                }
                              />
                            </span>

                            <span className='ms-2'>
                              <span className='fs-7 fw-bold text-gray-800 d-block'>
                                {item1.mealPortionName}
                              </span>
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='col-lg-3 col-12  '>
                    <div className='fv-row my-1'>
                      <input
                        {...formik.getFieldProps('notes')}
                        className={clsx('form-control  mb-3 mb-lg-0')}
                        name='notes'
                        autoComplete='off'
                        placeholder={intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
                        value={portions && portions[item.foodItemId]?.notes}
                        onChange={(e) =>
                          setPortions((prev: any) => ({
                            ...prev,
                            [item.foodItemId]: {...prev[item.foodItemId], notes: e.target.value},
                          }))
                        }
                        disabled={
                          selectedPortions?.find((i: number) => i === item.foodItemId)
                            ? false
                            : true
                        }
                      />
                      {formik.touched.notes && formik.errors.notes && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span className='alert-danger'>{formik.errors.notes}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
          <input
            className='form-check-input w-30px h-20px'
            type='checkbox'
            name='notifications'
            checked={notifyContact}
            onChange={() => setNotifyContact((prev) => !prev)}
          />
          <span className='form-check-label text-muted fs-7'>
            {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
          </span>
        </label>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {' '}
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading || isLoading) && <UsersListLoading />}
    </>
  )
}

export {KidMealModalForm}
