/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {AssessmentHeader} from './components/AssessmentHeader'
import './styles.scss'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {
  addKidAssessment,
  addKidAssessmentList,
  getAllAssessmentCategory,
  getAllAssessmentItem,
  getAllAssessmentItemRate,
  getAllAssessmentRateMethod,
  getAllAssessmentSubCategory,
  getAllAssessments,
  getAllAssessmentsWithCat,
  getKidAssessmentList,
  updateKidAssessment,
  updateKidAssessmentList,
} from '../../../../../../actions/assessment'
import {useAuth} from '../../../../auth'
import {toast} from 'react-toastify'
import moment from 'moment'

type Props = {
  isUserLoading: boolean
  intitialPositionId?: string
}

const KidAssessmentModalForm: FC<Props> = ({isUserLoading, intitialPositionId}) => {
  const {currentUser} = useAuth()
  const {setItemIdForUpdate, selected, setSelected, clearSelected} = useListView()

  const {
    setAssessmentSubCategory,
    setAssessmentItemRate,
    setAssessmentCategory,
    setAssessmentRateMethod,
    setAllAssessments,
    setAssessmentItem,
    globalLoader2,
    setGlobalLoader2,
    setAllAssessmentsWithCats,
    allAssessmentsWithCats,
    initialAssessments,
    selectedKidAssessments,
    setInitialAssessments,
    setSelectedKidAssessments,
  } = useAppContext()

  const intl = useIntl()
  const [userForEdit] = useState({})
  const [open, setOpen] = useState('')
  const kidSchema = Yup.object().shape({})

  useEffect(() => {
    readData()
  }, [])

  useEffect(() => {
    let tempRequests: any = [...selectedKidAssessments]
    allAssessmentsWithCats?.map((assessment: any, index0: number) =>
      assessment?.categories?.map((cat: any, index1: number) =>
        cat?.subCategories?.map((sub: any, index2: number) =>
          sub?.items?.forEach((s: any, index1: number) => {
            const isExitst = tempRequests.find(
              (i: any) => i.assessmentItemId === s.assessmentItemId
            )

            if (!isExitst) {
              tempRequests.push({
                kidId: selected[0].kidId,
                assessmentId: assessment.assessmentId,
                assessmentCategoryId: cat.assessmentCategoryId,
                assessmentSubCategoryId: sub.assessmentSubCategoryId,
                assessmentItemId: s.assessmentItemId,
                employeeId: currentUser?.employee.employeeId,
                imagePath: '',
                assessmentItemRateId: 0,
                kidAssessmentEducatorNotes: '',
                kidAssessmentParentsRemarks: '',
                kidAssessmentDate: moment().format('yyyy-MM-DDTHH:mm:ss'),
              })
            }
          })
        )
      )
    )
    setSelectedKidAssessments(tempRequests)
  }, [allAssessmentsWithCats])

  useEffect(() => {
    if (intitialPositionId) handleScroll(intitialPositionId)
  }, [allAssessmentsWithCats, intitialPositionId])

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    setSelected && setSelected([])
    clearSelected()
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let resp = null

        const promises: any = []
        if (
          initialAssessments.length > 0 &&
          initialAssessments.length !== selectedKidAssessments.length
        ) {
          await selectedKidAssessments.forEach(async (element: any) => {
            const isExitst = initialAssessments.find(
              (i: any) => i.assessmentItemId === element.assessmentItemId
            )

            if (isExitst) {
              promises.push(updateKidAssessment(element))
            } else {
              promises.push(addKidAssessment(element))
            }
          })
          Promise.all(promises)
            .then(function (resp) {
              toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
              //  cancel(true)
            })
            .catch(console.log)
        } else if (initialAssessments.length === selectedKidAssessments.length) {
          resp = await updateKidAssessmentList(selectedKidAssessments)
          if (!!resp) {
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            cancel(true)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        } else if (initialAssessments.length === 0) {
          resp = await addKidAssessmentList(selectedKidAssessments)
          if (!!resp) {
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            cancel(true)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        // clearSelected()
        setSubmitting(false)
      }
    },
  })

  const readData = async () => {
    setGlobalLoader2(true)
    Promise.all([
      getKidAssessmentList(`kidId=${selected[0].kidId}`),
      getAllAssessmentsWithCat(),
      getAllAssessmentSubCategory(),
      getAllAssessmentRateMethod(),
      getAllAssessmentItemRate(),
      getAllAssessmentItem(),
      getAllAssessmentCategory(),
      getAllAssessments(),
    ])
      .then(function ([resp1, resp2, resp3, resp4, resp5, resp6, resp7, resp8]) {
        setSelectedKidAssessments(resp1)
        if (resp1.length > 0) {
          setInitialAssessments(resp1)
        } else setInitialAssessments([])
        setAllAssessmentsWithCats(resp2)
        setAssessmentSubCategory(resp3)
        setAssessmentRateMethod(resp4)
        setAssessmentItemRate(resp5)
        setAssessmentItem(resp6)
        setAssessmentCategory(resp7)
        setAllAssessments(resp8)
        setGlobalLoader2(false)
      })
      .catch(console.log)
  }
  const handleScroll = (id: any) => {
    var element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
      element.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth',
      })
      element.scrollIntoView({block: 'start'})
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        {!globalLoader2 && (
          <div
            className='d-flex flex-column scroll-y overflow-auto me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='symbol-group symbol-hover'>
              {selected?.map(
                (item: any, index: number) =>
                  index === 0 && (
                    <div
                      className='d-flex flex-column justify-content-center align-items-center'
                      key={index}
                    >
                      <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                        {item.imagePath ? (
                          <img
                            src={
                              item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'
                            }
                            alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                              .charAt(0)
                              .toUpperCase()}`}
                            className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                          />
                        ) : (
                          <div
                            className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                            style={{
                              backgroundColor: `#F2E5E5`,
                            }}
                          >
                            {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                              .charAt(0)
                              .toUpperCase()}`}
                          </div>
                        )}
                      </div>
                      <div className='dispaly-6'>{item.firstName}</div>
                    </div>
                  )
              )}
            </div>
            <AssessmentHeader />
            <div className='d-flex flex-column mt-7 '>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {allAssessmentsWithCats.map((assessment: any, index0: number) => (
                  <AccordionItem id={assessment.assessmentName}>
                    <AccordionHeader targetId={`${index0}`}>
                      <h6 className='ms-4'>{assessment.assessmentName}</h6>
                    </AccordionHeader>
                    <AccordionBody accordionId={`${index0}`}>
                      {assessment.categories.map((cat: any, index1: number) => (
                        <UncontrolledAccordion defaultOpen={`-1`}>
                          {' '}
                          <AccordionItem id={cat.assessmentCategoryName}>
                            <AccordionHeader targetId={`${index1}`}>
                              <h6 className='ms-4'>{cat.assessmentCategoryName}</h6>
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index1}`}>
                              {' '}
                              {cat.subCategories.map((sub: any, index2: number) => (
                                <UncontrolledAccordion
                                  defaultOpen={open}
                                  id={sub.assessmentSubCategoryName}
                                  onChange={() => setOpen(`${index2}`)}
                                >
                                  <AccordionItem>
                                    <AccordionHeader targetId={`${index2}`}>
                                      <h6 className='ms-4'>{sub.assessmentSubCategoryName}</h6>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={`${index2}`}>
                                      {sub.items.map((items: any, index1: number) => (
                                        <div
                                          className='row w-100 my-4 d-flex align-items-center'
                                          key={index1}
                                          id={items.assessmentItemTitle}
                                        >
                                          <div className='col-lg-2 col-12 d-flex align-items-center my-1'>
                                            <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                                              <span className='form-check-label text-muted fs-7'>
                                                {items.assessmentItemTitle}
                                              </span>
                                            </label>
                                          </div>

                                          <div className=' col-12 col-sm-6 col-md-6 d-flex align-items-center '>
                                            <textarea
                                              {...formik.getFieldProps('notes')}
                                              className={clsx('form-control  mb-3 mb-lg-0')}
                                              name='notes'
                                              autoComplete='off'
                                              value={
                                                selectedKidAssessments.find(
                                                  (i: any) =>
                                                    i.assessmentItemId === items.assessmentItemId
                                                )?.kidAssessmentEducatorNotes
                                              }
                                              onChange={(e) => {
                                                let temp = [...selectedKidAssessments]
                                                temp.forEach((t: any) => {
                                                  if (t.assessmentItemId === items.assessmentItemId)
                                                    t.kidAssessmentEducatorNotes = e.target.value
                                                })

                                                setSelectedKidAssessments(temp)
                                              }}
                                            />
                                          </div>
                                          <div className='col-12 col-sm-6 col-md-4 d-flex align-items-center justify-content-around flex-wrap gap-2'>
                                            {items.assessmentItemRates.map(
                                              (option: any, index2: number) => {
                                                return (
                                                  <div
                                                    className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start    d-flex flex-column align-items-center  position-relative ${
                                                      selectedKidAssessments.find(
                                                        (i: any) =>
                                                          items.assessmentItemId ===
                                                            i.assessmentItemId &&
                                                          i.assessmentItemRateId ===
                                                            option.assessmentItemRateId
                                                      ) && 'active'
                                                    }
                                                  } `}
                                                    data-kt-button='true'
                                                    key={index2}
                                                    onClick={() => {
                                                      let temp = [...selectedKidAssessments]
                                                      temp.forEach((t: any) => {
                                                        if (
                                                          items.assessmentItemId ===
                                                          t.assessmentItemId
                                                        )
                                                          return (t.assessmentItemRateId =
                                                            option.assessmentItemRateId)
                                                      })

                                                      setSelectedKidAssessments(temp)
                                                    }}
                                                  >
                                                    <input
                                                      className={`form-check-input`}
                                                      type='radio'
                                                      name={`reasult-${items.assessmentItemId}`}
                                                      checked={selectedKidAssessments.find(
                                                        (i: any) =>
                                                          items.assessmentItemId ===
                                                            i.assessmentItemId &&
                                                          i.assessmentItemRateId ===
                                                            option.assessmentItemRateId
                                                      )}
                                                    />

                                                    <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
                                                      <div className='fs-4 fw-bold text-gray-800  '>
                                                        {option.assessmentItemRateName}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    </AccordionBody>
                                  </AccordionItem>{' '}
                                </UncontrolledAccordion>
                              ))}{' '}
                            </AccordionBody>
                          </AccordionItem>{' '}
                        </UncontrolledAccordion>
                      ))}{' '}
                    </AccordionBody>
                  </AccordionItem>
                ))}{' '}
              </UncontrolledAccordion>
            </div>
            <div className='text-center pt-8'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={
                  isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className='indicator-label'>
                  {' '}
                  {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                </span>
                {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
        {/* end::Scroll */}

        {/* begin::Actions */}

        {/* end::Actions */}
        {(formik.isSubmitting || globalLoader2) && <UsersListLoading />}
      </form>
    </>
  )
}

export {KidAssessmentModalForm}
