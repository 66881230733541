import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllKids = async ({filter, employeeId, companyId}: any) => {
  return (
    axios
      .get(`${endpoints.KIDS}/${filter || '1=1'}?EmployeeId=${employeeId}&companyId=${companyId}`)
      // .then((response) => response.data?.data)
      .then((response) => {
        const data: any = response.data?.data.sort((a: any, b: any) =>
          a.firstName > b.firstName ? 1 : -1
        )
        return data
      })
      .catch((error) => {
        console.log(error)
      })
  )
}

export const getClassesByEmployee = async (BACKEND_URL?: any) => {
  const emp: any = localStorage.getItem('kt-auth-react-v')
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const empId = JSON.parse(emp).employeeId
  const companyId = JSON.parse(selComp).value

  return axios
    .get(
      `${endpoints.CLASSES_WITH_EXPRESSION}/employees.Any(x => x.employeeId == ${empId}) &companyId=${companyId}`
    )
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAbsentReasons = async () => {
  return axios
    .get(endpoints.ABSENT_REASONS)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getActivities = async () => {
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${endpoints.ACTIVITES}/listwhere/companyId=${companyId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addNewActivity = async (data: any) => {
  return axios
    .post(endpoints.ACTIVITES, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getMoods = async () => {
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${endpoints.MOOD}/listwhere/companyId=${companyId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getRequestItems = async () => {
  const selComp: any = localStorage.getItem('kidling-selectedCompany')
  const companyId = JSON.parse(selComp).value
  return axios
    .get(`${endpoints.REQUEST_ITEMS}/listwhere/companyId=${companyId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getSleepHabits = async () => {
  return axios
    .get(endpoints.SLEEP_HABITS)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getKidsMediaByEmplpyee = async (data: any) => {
  return axios
    .post(`${endpoints.KIDMEDIA_BY_EMPLOYEE}?isMedia=true`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
