import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {KTCard} from '../../../../_metronic/helpers'
import MedicalRecords from './components/MedicalRecords'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useEffect} from 'react'

const MedicalRecordsPage = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidMedicalRecord[0]?.showInEducatorApp) {
      navigate('/')
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileConfiguration])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <MedicalRecords />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default MedicalRecordsPage
