/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {Spinner} from 'react-bootstrap'
import Select from 'react-select'

const style = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    border: 0,
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'var(--bs-gray-100)',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
}
type Props = {
  filterData: any
  readData: any
}
const UsersListFilter: FC<Props> = ({filterData, readData}) => {
  const intl = useIntl()
  const {
    classes,
    assessmentSubCategory,
    assessmentItem,
    assessmentCategory,
    allAssessments,
    globalLoader,
  } = useAppContext()
  const [assessment, setAssessment] = useState<any>(null)
  const [category, setCategory] = useState<any>(null)
  const [subCategory, setSubCategory] = useState<any>(null)
  const [categoryItems, setCategoryItems] = useState<any>(null)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [filterData])
  useEffect(() => {
    constructFilter()
  }, [assessment, category, subCategory, categoryItems])

  const resetData = async () => {
    setAssessment(null)
    setCategory(null)
    setSubCategory(null)
    setCategoryItems(null)
    filterData('')
    readData('')
  }
  const constructFilter = () => {
    let filter = ''
    if (assessment) filter += `AssessmentId=${assessment?.value}`

    if (category) {
      if (assessment) filter += '&'

      filter += `CategoryId=${category?.value}`
    }
    if (subCategory) {
      if (category || assessment) filter += '&'

      filter += `SubCategoryId=${subCategory?.value}`
    }

    if (categoryItems) {
      if (category || assessment || subCategory) filter += '&'

      filter += `ItemId=${categoryItems?.value}`
    }

    filterData(filter)
  }
  return (
    <>
      {/* begin::Content */}
      <div
        className='my-2  ms-sm-5  d-flex align-items-end flex-column flex-sm-row w-100'
        data-kt-user-table-filter='form'
      >
        <div className='d-flex flex-column flex-sm-row w-100 flex-wrap flex-sm-nowrap'>
          {/* begin::Input group */}
          <div className='me-5 w-100   mt-2'>
            <Select
              value={assessment}
              options={allAssessments.map((item: any) => ({
                value: item.assessmentId,
                label: item.assessmentName,
              }))}
              isLoading={allAssessments.length === 0}
              name='assessment'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})}
              onChange={(val: any) => setAssessment(val)}
              styles={style}
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>

          <div className='me-5 w-100  mt-2'>
            <Select
              value={category}
              options={assessmentCategory.map((item: any) => ({
                value: item.assessmentCategoryId,
                label: item.assessmentCategoryName,
              }))}
              isLoading={classes.length === 0}
              isClearable
              name='cateogry'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder={intl.formatMessage({id: 'KIDLING.CATEGORY'})}
              onChange={(val: any) => setCategory(val)}
              styles={style}
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='me-5 w-100 mt-2'>
            <Select
              value={subCategory}
              options={assessmentSubCategory.map((item: any) => ({
                value: item.assessmentSubCategoryId,
                label: item.assessmentSubCategoryName,
              }))}
              name='subCategory'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder={intl.formatMessage({id: 'KIDLING.SUB_CATEGORY'})}
              onChange={(val: any) => setSubCategory(val)}
              styles={style}
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>

          <div className='me-5 w-100 mt-2'>
            <Select
              value={categoryItems}
              options={assessmentItem.map((item: any) => ({
                value: item.assessmentItemId,
                label: item.assessmentItemTitle,
              }))}
              name='item'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder={intl.formatMessage({id: 'KIDLING.ITEM'})}
              onChange={(val: any) => setCategoryItems(val)}
              styles={style}
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
              theme={(theme) => ({
                ...theme,

                colors: {
                  ...theme.colors,
                  primary25: 'var(--bs-dark-light)',
                  primary: 'var(--bs-gray)',
                  neutral0: 'var(--bs-dark-light)',
                },
              })}
            />
          </div>
        </div>
        {/* end::Input group */}

        {/* begin::Actions */}
        <div className='d-flex justify-content-end align-items-center align-self-sm-end  align-self-start me-2    '>
          <button
            type='button'
            disabled={globalLoader}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-semibold me-2 px-6 py-3 mt-2'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='reset'
          >
            {intl.formatMessage({id: 'COMMON.RESET'})}
          </button>
          <button
            disabled={globalLoader}
            type='button'
            onClick={readData}
            className='btn btn-primary fw-semibold px-6 py-3 mt-2'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='filter'
          >
            {globalLoader ? (
              <Spinner animation={'border'} />
            ) : (
              intl.formatMessage({id: 'COMMON.APPLY'})
            )}
          </button>
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Content */}

      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
