/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Spinner} from 'reactstrap'
import useKidAttendance from '../../../../../../hooks/useKidAttendance'

const AttendanceSearchComponent = () => {
  const intl = useIntl()
  const {globalLoader} = useAppContext()
  const {setClicked, setSearchTerm, searchTerm, readAttendace} = useKidAttendance({})
  useEffect(() => {
    const handleSubmit = (event: any) => {
      if (event.keyCode === 13) {
        setClicked(true)
      }
    }
    window.addEventListener('keydown', handleSubmit)

    return () => {
      window.removeEventListener('keydown', handleSubmit)
      setClicked(false)
    }
  }, [])
  return (
    <div className='card-title my-2 w-100 w-md-auto mt-4'>
      {/* begin::Search */}
      <div className='d-flex flex-column w-100 w-md-auto'>
        <div className='d-flex align-items-center position-relative w-100 w-md-auto '>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-md-250px w-100  '
            style={{padding: '.6rem 0 .6rem 4rem'}}
            placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center align-self-sm-end  align-self-start mx-2    '>
        <button
          disabled={globalLoader}
          type='button'
          onClick={readAttendace}
          className='btn btn-primary fw-semibold px-6 py-3  '
          data-kt-menu-dismiss='true'
          data-kt-user-table-filter='filter'
        >
          {globalLoader ? (
            <Spinner animation={'border'} />
          ) : (
            intl.formatMessage({id: 'COMMON.APPLY'})
          )}
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {AttendanceSearchComponent}
