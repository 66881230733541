import {FC} from 'react'
import ReactPlayer from 'react-player'

interface Props {
  id: string
}
const CustomVideo: FC<Props> = ({id}) => {
  return (
    <div className='position-relative h-100 mh-200px '>
      <ReactPlayer
        url={id}
        width='100%'
        height='100%'
        controls={true}
        config={{
          file: {
            attributes: {
              crossOrigin: 'true',
            },
          },
        }}
      />
      {id === '' && (
        <span
          className='spinner-border spinner-border-sm align-middle ms-2 position-absolute  '
          style={{top: '45%', left: '45%'}}
        ></span>
      )}
    </div>
  )
}

export default CustomVideo
