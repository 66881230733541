import {FC} from 'react'
import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import moment from 'moment-timezone'
import {AssessmentsSearchComponent} from './AssessmentsSearchComponent'
import {AssessmentsToolbar} from './AssessmentsToolbar'
import {SwitchAssessmentFilter} from './SwitchAssessmentFilter'
import useAssessment from '../../../../../hooks/useAssessments'

interface Props {
  title?: string
}
const AssessmentsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useAssessment()
  return (
    <div className='card-header border-0 pt-6 d-flex flex-columnt px-4 pb-8 w-100'>
      {title && <h4>{title}</h4>}
      <SwitchAssessmentFilter />
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <AssessmentsSearchComponent />
          <AssessmentsToolbar />
        </div>
        <CustomDateRangePicker
          handleDateChange={handleDateChange}
          initialValue={{
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
          }}
        />
      </div>
    </div>
  )
}

export {AssessmentsHeader}
