import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {FC, ReactElement} from 'react'

interface Props {
  setEditModal?: any
}
const KidMedicalModalHeader: FC<Props> = ({setEditModal}): ReactElement => {
  const {setItemIdForUpdate, clearSelected} = useListView()
  const intl = useIntl()
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bold'>
        {intl.formatMessage({
          id: setEditModal ? 'KIDLING.HOME.EDIT_MEDICAL_RECORD' : 'KIDLING.HOME.ADD_MEDICAL_RECORD',
        })}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          setItemIdForUpdate(undefined)
          clearSelected()
          if (setEditModal) {
            setEditModal({show: false, data: null})
          }
        }}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {KidMedicalModalHeader}
