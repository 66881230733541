/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {Helmet} from 'react-helmet'
import {getWhiteLabel} from '../../../actions/whiteLabels'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

function Whitelabel() {
  const {
    companyMetaData,
    setCompanyMetaData,
    globalLoader,
    setGlobalLoader,
    setIsFinishedLoadingMetaData,
  } = useAppContext()
  const {mode} = useThemeMode()
  const [appIcon, setAppIcon] = useState<any>('')
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    if (company) {
      setIsFinishedLoadingMetaData(false)
      readLabels(company)
    }
  }, [company])

  useEffect(() => {
    const root: any = document.querySelector(':root')

    const setVariables = (vars: any) =>
      Object.entries(vars).forEach((v) => root.style.setProperty(v[0], v[1]))
    const myVariables = {
      '--bs-primary':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.primaryColor
          ? companyMetaData?.primaryColor
          : '#8d4290',
      '--bs-primary-active':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.primaryColor
          ? companyMetaData?.primaryColor
          : '#8d4290',

      '--bs-menu-link-color-active':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.primaryColor
          ? companyMetaData?.primaryColor
          : '#8d4290',
      '--bs-primary-rgb':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.primaryColor
          ? companyMetaData?.primaryColor
          : '#8d4290',

      '--bs-secondary': mode === 'dark' ? '#cfcfcf' : companyMetaData?.secondaryColor || '#ccc',
      '--bs-text-secondary':
        mode === 'dark' ? '#cfcfcf' : companyMetaData?.secondaryColor || '#8d4290',
      // '--bs-form-check-input-bg-solid':
      //   mode === 'dark' ? '#cfcfcf' : companyMetaData?.secondaryColor || '#ccc',
      '--bs-header-background-color':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.primaryColor
          ? companyMetaData?.primaryColor
          : '#8d4290',

      '--bs-text-primary': mode === 'dark' ? '#fff' : companyMetaData?.primaryColor || '#926594',
      '--bs-component-checked-bg':
        mode === 'dark' ? '#fff' : companyMetaData?.primaryColor || '#926594',
      '--bs-button-primary-color':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.buttonColor
          ? companyMetaData?.buttonColor
          : '#8d4290',
      '--bs-button-secondary-color':
        mode === 'dark'
          ? '#0e0e16'
          : companyMetaData?.secondaryColor
          ? companyMetaData?.secondaryColor
          : '#eeeeee',
      '--logo-url-dark': companyMetaData?.imagePathLogoDark
        ? companyMetaData?.imagePathLogoDark
        : '/media/logos/Kidling.webp',
      '--logo-url-light': companyMetaData?.imagePathLogoLight
        ? companyMetaData?.imagePathLogoLight
        : '/media/logos/Kidling.webp',
    }

    setVariables(myVariables)

    // document.body.style.backgroundImage = `url("/media/patterns/header-bg.jpg")`
  }, [companyMetaData, mode])

  useEffect(() => {
    if (!!appIcon && !globalLoader) {
      setManifest()
    }
  }, [appIcon])

  const readLabels = async (comp: string) => {
    setGlobalLoader(true)
    const resp = await getWhiteLabel(`educatorAppURL="${comp.toLocaleLowerCase()}"`)
    setGlobalLoader(false)
    setIsFinishedLoadingMetaData(true)
    if (resp.length > 0) {
      setCompanyMetaData(resp[0])
      setAppIcon(resp[0]?.imagePath)
    } else {
      setAppIcon(`${window.location.origin}/icon.png`)
    }
  }

  const setManifest = () => {
    const myDynamicManifest = {
      short_name: companyMetaData?.educatorAppTitle ?? 'Kidling ErzieherIn',
      name: companyMetaData?.educatorAppTitle ?? 'Kidling ErzieherIn',
      start_url: window.location.origin + '/' + window.location.pathname.split('/')[1],
      icons: [
        {
          src: appIcon,
          sizes: '1024x1024',
          type: 'image/png',
          purpose: 'any',
        },
        {
          src: appIcon,
          sizes: '1024x1024',
          type: 'image/png',
          purpose: 'maskable',
        },
      ],
      display: 'standalone',
      theme_color: '#000',
      background_color: '#fff',
    }

    const link = document.createElement('link')
    link.setAttribute('rel', 'manifest')

    const stringManifest = JSON.stringify(myDynamicManifest)
    link.setAttribute(
      'href',
      'data:application/manifest+json,' + encodeURIComponent(stringManifest)
    )
    document.querySelector('head')?.appendChild(link)
  }
  return (
    <>
      <Helmet>
        <title>{companyMetaData?.educatorAppTitle}</title>
      </Helmet>
    </>
  )
}

export default Whitelabel
