import React, {FC} from 'react'
import './styles.scss'

interface ArrowProps {
  onClick?: any
  className?: string
  arrowOutsideSlide?: boolean
  color?: string
  disabled?: boolean
}

const SliderArrowBack: FC<ArrowProps> = ({
  onClick,
  className,
  arrowOutsideSlide,
  color,
  disabled,
}) => {
  return (
    <div onClick={onClick} color={color}>
      <div className='back__arrow'>
        <i
          className={`fa-solid fa-chevron-left fs-4 text-white m-0 p-0 ${
            (className?.includes('slick-disabled') || disabled) && 'disabled'
          }`}
        ></i>
      </div>
    </div>
  )
}

export default SliderArrowBack
