import React from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {KTCard} from '../../../_metronic/helpers'
import FoodMenuContent from '../../modules/apps/FoodMenuContent'

function FoodMenu() {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <FoodMenuContent />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default FoodMenu
