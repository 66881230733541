/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useMemo, useState} from 'react'
import TableContainer from '../../../../../general-components/table-container/TableContainer'
import _columns, {_mobileColumns} from './columns/_columns'
import {
  getAllMessagesCounts,
  getAllMessagesV2,
  updateReceivedMessages,
  updateSentMessages,
} from '../../../../../../actions/messages'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../auth'
import {useNavigate} from 'react-router-dom'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {TableLoader} from '../../../../../general-components/table-loader/TableLoader'
import {useMediaQuery} from 'react-responsive'
import {MESSAGE_FOLDER_ID, MESSAGE_TYPE} from '../../../../../../config/constants'
import PaginationComponent from '../../../../../general-components/pagination/Pagination'
import MarkedTableHeader from './columns/MarkedTableHeader'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {toast} from 'react-toastify'

const MarkedTable: FC = () => {
  const [pageSize, setPageSize] = useState(15)
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(0)
  const [pinnedData, setPinnedData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const {setTabMessagesCounts} = useAppContext()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({
    query: '(max-width:700px)',
  })

  useEffect(() => {
    if (currentUser) readMessages()
  }, [currentPage, pageSize, currentUser])

  const handleRecievedMarkPinned = async (id: string, pinned: boolean) => {
    const data = {ids: [id], isPinned: !pinned}
    const resp = await updateReceivedMessages(data)

    if (resp) {
      readMessages()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const handleSentMarkPinned = async (id: string, pinned: boolean) => {
    const data = {ids: [id], isPinned: !pinned}
    const resp = await updateSentMessages(data)

    if (resp) {
      readMessages()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const readMessages = async (filter?: string) => {
    setIsLoading(true)
    const resp = await getAllMessagesV2(
      currentUser?.usersId ?? 0,
      filter ?? '1=1',
      currentPage + 1,
      pageSize,
      MESSAGE_FOLDER_ID.Pinned,
      MESSAGE_TYPE.Message
    )

    setPinnedData(resp)
    const resp1 = await getAllMessagesCounts(currentUser?.usersId ?? 0)
    setTabMessagesCounts(resp1)
    setIsLoading(false)
  }

  const columns: any = useMemo(
    () =>
      isMobile
        ? _mobileColumns({handleSentMarkPinned, handleRecievedMarkPinned, navigate})
        : _columns({handleSentMarkPinned, handleRecievedMarkPinned, navigate}),
    [isMobile]
  )
  return (
    <KTCardBody className='py-1  px-2'>
      <div className=' py-1  px-0'>
        <MarkedTableHeader getAllMessages={readMessages} data={pinnedData?.items} />
        {isLoading ? (
          <TableLoader colNum={4} rows={5} />
        ) : pinnedData?.items?.length > 0 ? (
          <TableContainer
            columns={columns}
            data={pinnedData?.items}
            hideCards
            hidePagination
            tableName='marked'
          />
        ) : (
          <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
            <i className='fa-sharp fa-solid fa-comments  ' style={{fontSize: '5rem'}}></i>
            <p className='mt-4 text-muted fs-3'>
              {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_MESSAGES_YET'})}
            </p>
          </div>
        )}

        {pinnedData?.totalPages > 1 && !isLoading && (
          <PaginationComponent
            pageCount={pinnedData?.totalPages}
            page={currentPage}
            setPage={setCurrentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            total={pinnedData?.totalCount}
          />
        )}
      </div>
    </KTCardBody>
  )
}

export {MarkedTable}
