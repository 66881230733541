import {FC, ReactElement, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Avatar, AvatarGroup} from '@mui/material'
import StackedImagesWithEnlarge from '../stacked-images-with-enlarge/StackedImagesWithEnlarge'
import TaggedKidsModal from '../../modules/apps/Media/TaggedKidsModal'
type KidType = {
  kidName: string
  imageUrl: string
}
type MediaType = {
  actionImageUrl: string
  title: string
  notes: string
}
interface Props {
  kids: KidType[]
  mediaFiles: MediaType[]
  title: string
  desc: string
  date: string
  key: number
  link: string
}
const MeidaFeedCard: FC<Props> = ({
  kids,
  mediaFiles,
  title,
  desc,
  date,
  key,
  link,
}): ReactElement => {
  const company = window.location.pathname.split('/')[1]
  const [showTaggedKids, setShowTaggedKids] = useState(false)
  const [images, setImages] = useState<string[]>([])
  useEffect(() => {
    let temp: string[] = []
    mediaFiles.map((item: MediaType, index: number) => index < 5 && temp.push(item.actionImageUrl))
    setImages(temp)
  }, [mediaFiles])

  return (
    <>
      <div
        className='d-flex align-items-center justify-content-between w-100 my-4   border-bottom p-4'
        key={key}
      >
        <div className='d-flex align-items-start  w-100'>
          <div className='d-flex flex-column align-items-start  w-100 '>
            <div className='d-flex align-items-center align-items-sm-start w-100 flex-column flex-sm-row'>
              <StackedImagesWithEnlarge images={images} />

              {/* <AvatarGroup>
                {activity.images?.map((image: string) => (
                  <Avatar alt='user image' src={image} />
                ))}
              </AvatarGroup> */}

              <div className='ms-4 mt-16 mt-sm-0'>
                <h6 className='m-0'>{title}</h6>

                <div className='mt-1'>
                  <AvatarGroup
                    max={4}
                    renderSurplus={(surplus) => <span>+{surplus.toString()[0]}</span>}
                    sx={{
                      '& .MuiAvatar-root': {width: 26, height: 26, fontSize: 12},
                    }}
                    onClick={() => setShowTaggedKids(true)}
                    style={{flexDirection: 'row'}}
                  >
                    {kids.map((kid: any) => (
                      <Avatar alt={kid.kidName} src={kid.kidImageUrl} />
                    ))}
                  </AvatarGroup>
                  {/* {kids?.map((kid: KidType) => (
                    <p className='m-0 mx-1 badge badge-primary'>{kid.kidName}</p>
                  ))} */}

                  {/* <p className='m-0 text-muted'> Test title</p> */}
                  <p className='m-0 text-muted '> {desc}</p>
                </div>
              </div>
            </div>

            <p className='m-0 fs-7 text-muted ms-auto'>{date}</p>
          </div>
        </div>
        <Link to={company ? `/${company}/${link}` : `/kidling/${link}`}>
          <i className='fa-solid fa-arrow-right'></i>
        </Link>
      </div>
      <TaggedKidsModal show={showTaggedKids} setShow={setShowTaggedKids} kids={kids} />
    </>
  )
}

export default MeidaFeedCard
