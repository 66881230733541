/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

interface Props {
  icon: string
  title: string
}

const ChatDocument: FC<Props> = ({icon, title}) => {
  return (
    <div className='card h-100  border-bottom'>
      <div className='card-body d-flex justify-content-center align-items-center text-center flex-column p-8 position-relative '>
        <button className='btn text-gray-800 text-hover-primary d-flex flex-column align-items-center  '>
          <div className='symbol symbol-75px mb-6 '>
            <img src={toAbsoluteUrl(icon)} alt='attachment' />
          </div>
          <h3 className=' mb-2'>{title}</h3>
        </button>
      </div>
    </div>
  )
}

export default ChatDocument
