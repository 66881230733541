import {MessageAttachModalForm} from './MessageAttachModalForm'

const MessageAttachModalFormWrapper = ({setSelectedFiles, setShowAttachModal}: any) => {
  return (
    <MessageAttachModalForm
      setShowAttachModal={setShowAttachModal}
      setSelectedFiles={setSelectedFiles}
    />
  )
}

export default MessageAttachModalFormWrapper
