import {useRef, useState} from 'react'

export default function useLongPress({initialClick, handleLongPress}: any) {
  const [action, setAction] = useState('')

  const timerRef = useRef<any>()
  const isLongPress = useRef<any>()

  function startPressTimer() {
    isLongPress.current = false
    timerRef.current = setTimeout(() => {
      isLongPress.current = true
      setAction('longpress')
    }, 500)
  }

  function handleOnClick(e: any) {
    if (isLongPress.current) {
      handleLongPress()
      return
    } else {
      initialClick()
    }
  }

  function handleOnMouseDown() {
    startPressTimer()
  }

  function handleOnMouseUp() {
    clearTimeout(timerRef.current)
  }

  function handleOnTouchStart() {
    startPressTimer()
  }

  function handleOnTouchEnd() {
    if (action === 'longpress') return

    clearTimeout(timerRef.current)
  }

  return {
    action,
    handlers: {
      onClick: handleOnClick,
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
    },
  }
}
