/* eslint-disable react-hooks/exhaustive-deps */
import {useIntl} from 'react-intl'
import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import KidAttendance from '../kid-attendance'
import {KidsListWrapper} from './kids-list/KidsList'
import {KTCard} from '../../../../_metronic/helpers'
import CalenderPage from '../calender'
import ActivitiesPage from '../activities'
import KidAbsense from '../kid-absenses'
import MessagesWrapper from '../messages/MessagesWrapper'
import MealtimesPage from '../meal-times'
import SleeptimesPage from '../sleep-times'
import DrinksPage from '../drinks'
import ProtocolPage from '../protocols'
import MedicalRecordsPage from '../medical-records'
import RequestsPage from '../requests'
import KidServicesPage from '../kid-services'
import DeregistrationPage from '../deregistration'
import {useEffect} from 'react'
import MoodPage from '../Mood'
import DiapersPage from '../diapers'
import AssessmentsPage from '../Assessments'
import {useCompanyContext} from '../../../../_metronic/layout/core/CompanyProvider'

const KidsPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const {currentCompany, setCurrentCompany} = useCompanyContext()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    const comp: any = localStorage.getItem('kidling-selectedCompany')
    if (!comp) {
      navigate(company ? `/${company}/company` : '/kidling/company')
    } else {
      setCurrentCompany(JSON.parse(comp))
    }
  }, [])
  return (
    currentCompany && (
      <Routes>
        <Route element={<Outlet />}>
          <Route path='deregistration/*' element={<DeregistrationPage />} />

          <Route path='activities/*' element={<ActivitiesPage />} />
          <Route path='KidActivity/*' element={<ActivitiesPage />} />
          <Route path='calender/*' element={<CalenderPage />} />
          <Route path='Events/*' element={<CalenderPage />} />
          <Route
            path='attendance'
            element={
              <>
                <PageTitle>{intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})}</PageTitle>
                <KidAttendance />
              </>
            }
          />
          <Route
            path='KidAttendance'
            element={
              <>
                <PageTitle>{intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})}</PageTitle>
                <KidAttendance />
              </>
            }
          />
          <Route
            path='absense'
            element={
              <>
                <PageTitle>{intl.formatMessage({id: 'KIDLING.MENU.KIDSABSENSES'})}</PageTitle>
                <KidAbsense />
              </>
            }
          />
          <Route
            path='KidAbsent'
            element={
              <>
                <PageTitle>{intl.formatMessage({id: 'KIDLING.MENU.KIDSABSENSES'})}</PageTitle>
                <KidAbsense />
              </>
            }
          />
          <Route path='mealtimes/*' element={<MealtimesPage />} />
          <Route path='KidMealTime/*' element={<MealtimesPage />} />

          <Route path='sleeptimes/*' element={<SleeptimesPage />} />
          <Route path='KidSleepTime/*' element={<SleeptimesPage />} />

          <Route path='mood/*' element={<MoodPage />} />
          <Route path='KidMood/*' element={<MoodPage />} />

          <Route path='drinks/*' element={<DrinksPage />} />
          <Route path='KidDrink/*' element={<DrinksPage />} />

          <Route path='protocols/*' element={<ProtocolPage />} />
          <Route path='KidProtocolRemark/*' element={<ProtocolPage />} />

          <Route path='medical-records/*' element={<MedicalRecordsPage />} />
          <Route path='KidMedicalRecord/*' element={<MedicalRecordsPage />} />

          <Route path='requests/*' element={<RequestsPage />} />
          <Route path='RequestKid/*' element={<RequestsPage />} />

          <Route path='kid-services/*' element={<KidServicesPage />} />
          <Route path='KidService/*' element={<KidServicesPage />} />
          <Route path='diapers/*' element={<DiapersPage />} />
          <Route path='KidDiapers/*' element={<DiapersPage />} />
          <Route path='assessments/*' element={<AssessmentsPage />} />
          <Route
            path='messages'
            element={
              <>
                <KTCard>
                  <MessagesWrapper />
                </KTCard>
              </>
            }
          />
          <Route
            path='kids/simplified/*'
            element={
              <>
                {/* <PageTitle breadcrumbs={usersBreadcrumbs}>Kids list</PageTitle> */}
                <KidsListWrapper />
              </>
            }
          />
          <Route
            path='kids'
            element={
              <>
                {/* <PageTitle breadcrumbs={usersBreadcrumbs}>Kids list</PageTitle> */}
                <KidsListWrapper />
              </>
            }
          />
        </Route>

        <Route index element={<Navigate to='kid-management/kids' />} />
      </Routes>
    )
  )
}

export default KidsPage
