/* eslint-disable react-hooks/exhaustive-deps */
import {useIntl} from 'react-intl'
import {AssessmentsHeader} from './header/AssessmentsHeader'
import AssessmentAccordions from './AssessmentAccordions'
import useAssessment from '../../../../hooks/useAssessments'
import {useEffect} from 'react'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useListView} from '../../kid-management/kids-list/core/ListViewProvider'

function Assessments() {
  const intl = useIntl()
  const {data, readAssessments} = useAssessment()
  const {globalLoader, internalPagesDatesFilters} = useAppContext()
  const {selected} = useListView()

  useEffect(() => {
    if (!globalLoader) readAssessments()
  }, [internalPagesDatesFilters, selected])

  return (
    <div className='container py-8'>
      <AssessmentsHeader title={intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})} />
      <AssessmentAccordions data={data} />
    </div>
  )
}

export default Assessments
