import React, {ReactElement, FC} from 'react'
import './styles.scss'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
type cardItem = {
  image: string
  defaultImage: string
  title: string
  subtitle: string
  subtitle1: string
  date: string
  endDate?: string
  link: string
}

interface Props {
  title: string
  subtitle?: string
  items: cardItem[]
  link?: string
}
const NotificationActivityCard: FC<Props> = ({title, subtitle, link, items}): ReactElement => {
  const company = window.location.pathname.split('/')[1]
  const {mode} = useThemeMode()
  return (
    <div className='card shadow mb-5 mb-xl-8 h-100 '>
      <div className='pt-5'>
        <Link to={company ? `/${company}/${link}` : `/kidling/${link}`}>
          <div className='card mb-5 mb-xl-8 h-100 mt-2'>
            <div className='card-header align-items-center border-0 mt-0'>
              <h3 className='card-title align-items-start flex-column'>
                <Link
                  to={company ? `/${company}/${link}` : `/kidling/${link}`}
                  className={`  ${mode === 'dark' ? 'text-dark' : 'text-black '}`}
                >
                  <span className={`fw-bold mb-2 ${mode === 'dark' && 'text-dark'}`}>{title}</span>
                </Link>
                <span
                  className={` fw-semibold fs-7  ${mode === 'dark' ? 'text-dark' : 'text-muted '}`}
                >
                  {subtitle}
                </span>
              </h3>
            </div>
            <div className='card-body pt-2'>
              {items.map((item: cardItem, index: number) => {
                return (
                  <div className='d-flex align-items-start my-4 ' key={index}>
                    <img
                      src={item.image}
                      alt={item.title}
                      className='item__image'
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = item.defaultImage
                      }}
                    />
                    <div className='d-flex align-items-center justify-content-between w-100'>
                      <div>
                        <h5 className='m-0 p-0'>{item.title}</h5>
                        {item.subtitle !== '' && (
                          <p
                            className={`m-0 p-0   fs-7 lh-sm   ${
                              mode === 'dark' ? 'text-dark' : 'text-muted '
                            }`}
                          >
                            {item.subtitle}
                          </p>
                        )}
                        {item?.subtitle1 !== '' && (
                          <p
                            className={`m-0 p-0   fs-9 lh-sm   ${
                              mode === 'dark' ? 'text-dark' : 'text-muted '
                            }`}
                          >
                            {item.subtitle1}
                          </p>
                        )}
                        {(item.date || item.endDate) && (
                          <p
                            className={`m-0 p-0 mt-1    fs-9 lh-sm  ${
                              mode === 'dark' ? 'text-dark' : 'text-muted '
                            }`}
                          >
                            {moment(item.date).format('DD.MM.yyyy HH:mm')}
                            {item.endDate && (
                              <span> - {moment(item.endDate).format('DD.MM.yyyy HH:mm')}</span>
                            )}
                          </p>
                        )}
                      </div>

                      <Link
                        to={company ? `/${company}/${item.link}` : `/kidling/${item.link}`}
                        className='btn btn-light p-2   '
                      >
                        <i className='fa-solid fa-arrow-right'></i>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NotificationActivityCard
