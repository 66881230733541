import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {Spinner} from 'reactstrap'
import useMealTimes from '../../../../../hooks/useMealTimes'

const MealFilter = () => {
  const intl = useIntl()
  const {globalLoader} = useAppContext()
  const {readMealTimes} = useMealTimes()
  return (
    <>
      <div
        className='my-2  d-flex align-items-end flex-column flex-sm-row w-100 w-md-auto'
        data-kt-user-table-filter='form'
      >
        <div className='d-flex justify-content-end align-items-center align-self-sm-end  align-self-start me-2    '>
          <button
            disabled={globalLoader}
            type='button'
            onClick={readMealTimes}
            className='btn btn-primary fw-semibold px-6 py-3 mt-2'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='filter'
          >
            {globalLoader ? (
              <Spinner animation={'border'} />
            ) : (
              intl.formatMessage({id: 'COMMON.APPLY'})
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export {MealFilter}
