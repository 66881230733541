import {matchRoutes, useLocation} from 'react-router-dom'

const useIsChatDetailsPath = () => {
  const company = window.location.pathname.split('/')[1]
  const routes = [{path: `${company}/chat/private/:id`}, {path: `${company}/chat/group/:id`}]
  const location = useLocation()
  const out = matchRoutes(routes, location)

  return out ? true : false
}
export default useIsChatDetailsPath
