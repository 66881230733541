/* eslint-disable react-hooks/exhaustive-deps */
import {toast} from 'react-toastify'
import {deleteWindingProtocol, getAllWindingProtocols} from '../../actions/diapers'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useAuth} from '../modules/auth'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useDiapers = () => {
  const {
    kidsDiapers,
    setKidsDiapers,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    globalLoader,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [date, setDate] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readWindingProtocols()
      setClicked(false)
    }
  }, [clicked])
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.diapers = {
      ...newFilters.diapers,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsDiapers?.forEach((item: any) => {
      let Diapers: any = []
      item.windingProtocols?.forEach((diaper: any) =>
        Diapers.push({
          id: diaper.windingProtocolId,
          title: diaper.diaperContentName,
          desc: diaper.notes,
          date: diaper.dateTime,
          tag: diaper.employeeName,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: Diapers,
        handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsDiapers])

  const readWindingProtocols = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.diapers.classValue && !internalPagesFilters.diapers.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.diapers.classValue)
      filter = `classesId=${internalPagesFilters.diapers.classValue}`
    if (internalPagesFilters.diapers.classValue && internalPagesFilters.diapers.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.diapers.searchTerm)
      filter = filter + internalPagesFilters.diapers.searchTerm

    const res = await getAllWindingProtocols(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesDatesFilters.diapers.From
        ? moment(internalPagesDatesFilters.diapers.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.diapers.To
        ? moment(internalPagesDatesFilters.diapers.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsDiapers(res)
    setGlobalLoader(false)
  }

  const doDelete = async (id: string) => {
    if (id !== '') {
      setGlobalLoader(true)
      const resp = await deleteWindingProtocol(id)
      if (resp) {
        await readWindingProtocols()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.diapers = {
      ...newFilters.diapers,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {
    doDelete,
    readWindingProtocols,
    data,
    handleDateChange,
    searchTerm,
    setSearchTerm,
    setClicked,
  }
}

export default useDiapers
