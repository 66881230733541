/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {DrinksHeader} from './header/DrinksHeader'
import useDrinks from '../../../../hooks/useDrinks'
function Drinks() {
  const intl = useIntl()
  const {data, readDrinks} = useDrinks()
  const {internalPagesDatesFilters, globalLoader} = useAppContext()
  const columns: any = useMemo(() => _columns(), [internalPagesDatesFilters])

  useEffect(() => {
    if (!globalLoader) readDrinks()
  }, [internalPagesDatesFilters])

  return (
    <div className='container p-16'>
      <DrinksHeader title={intl.formatMessage({id: 'KIDLING.MENU.DRINKS'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Drinks
