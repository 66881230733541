import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import KidsWrapper from '../pages/kids/KidsWrapper'
import KidsPage from '../modules/apps/kid-management/KidsPage'
import MessagesWrapper from '../modules/apps/messages/MessagesWrapper'
import ProfileWrapper from '../modules/apps/profile/ProfileWrapper'
import SuspensedView from '../general-components/SuspensedView/SuspensedView'
import {Company} from '../modules/auth/components/Company'
import Media from '../modules/apps/Media/Media'
import CompanyInfo from '../modules/apps/companyInfo'
import FoodMenu from '../pages/foodMenu/FoodMenu'
import KidProfilePage from '../pages/kid-profile/KidProfilePage'
import Chat from '../modules/apps/chat/Chat'
import Pinboard from '../modules/apps/pinboard/Pinboard'
import {useCompanyContext} from '../../_metronic/layout/core/CompanyProvider'

const PrivateRoutes = () => {
  const company = window.location.pathname.split('/')[1]
  const {currentCompany} = useCompanyContext()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='*'
          element={
            <Navigate
              to={
                currentCompany
                  ? company
                    ? `/${company}/kid-management/kids`
                    : '/kidling/kid-management/kids'
                  : company
                  ? `/${company}/company`
                  : '/kidling/company'
              }
            />
          }
        />
        <Route
          path='auth/*'
          element={<Navigate to={company ? `/${company}/company` : '/kidling/company'} />}
        />
        {/* Pages */}
        <Route
          path='company'
          element={
            <SuspensedView>
              <Company />
            </SuspensedView>
          }
        />
        <Route
          path='kids'
          element={
            <SuspensedView>
              <KidsWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfileWrapper />
            </SuspensedView>
          }
        />
        <Route
          path={`/media`}
          element={
            <SuspensedView>
              <Media />
            </SuspensedView>
          }
        />
        <Route
          path={`/KidMedia`}
          element={
            <SuspensedView>
              <Media />
            </SuspensedView>
          }
        />
        <Route
          path='messages/*'
          element={
            <SuspensedView>
              <MessagesWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='Messaging/*'
          element={
            <SuspensedView>
              <MessagesWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='chat/*'
          element={
            <SuspensedView>
              <Chat />
            </SuspensedView>
          }
        />
        <Route
          path='pinboard/*'
          element={
            <SuspensedView>
              <Pinboard />
            </SuspensedView>
          }
        />
        <Route
          path='companyInformation/*'
          element={
            <SuspensedView>
              <CompanyInfo />
            </SuspensedView>
          }
        />
        <Route
          path='foodMenu/*'
          element={
            <SuspensedView>
              <FoodMenu />
            </SuspensedView>
          }
        />
        <Route path='kidprofile/*' element={<KidProfilePage />} />
        <Route
          path='kid-management/*'
          element={
            <SuspensedView>
              <KidsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
