import {FC, ReactElement, useEffect} from 'react'
import {KidMedicalModalHeader} from './KidMedicalModalHeader'
import {KidMedicalModalFormWrapper} from './KidMedicalModalFormWrapper'
import ActionsDrawerContainer from '../../../../../general-components/ActionsDrawerContainer/ActionsDrawerContainer'

interface Props {
  setEditModal?: any
  data?: any
}
const KidMedicalModal: FC<Props> = ({setEditModal, data}): ReactElement => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block'
        id='kt_modal_check_in'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidMedicalModalHeader setEditModal={setEditModal} />
            {/* begin::Modal body */}
            <ActionsDrawerContainer
              children={
                <div className='modal-body scroll-y overflow-auto mx-5 mx-xl-15 my-0'>
                  <KidMedicalModalFormWrapper setEditModal={setEditModal} data={data} />
                </div>
              }
            />

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidMedicalModal}
