/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../auth'
import {BulkProtocol} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {getKidsProtocols, updateKidProtocol} from '../../../../../../actions/protocol'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import {momentDateFormat} from '../../../../../../config/constants'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {Link} from 'react-router-dom'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
type Props = {
  isUserLoading: boolean
  data?: any
  setEditModal?: any
}

const KidProtocolModalForm: FC<Props> = ({isUserLoading, data, setEditModal}) => {
  const company = window.location.pathname.split('/')[1]
  const {
    kidsProtocol,
    setKidsProtocol,
    setGlobalLoader,
    internalPagesFilters,
    mobileConfiguration,
  } = useAppContext()
  const {currentCompany} = useCompanyContext()
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [isAllowed, setIsAllowed] = useState(data?.item?.remarksSharedWithContact || false)
  const [notifyContact, setNotifyContact] = useState(false)
  const {currentUser} = useAuth()
  const intl = useIntl()

  const [userForEdit] = useState({
    title: data?.item?.title || '',
    date: data?.item?.dateTime ? moment(data?.item?.dateTime).toDate() : new Date(),
    remarks: data?.item?.remarks || '',
  })

  const kidSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    remarks: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    clearSelected()
    if (data) setEditModal({show: false, data: null})
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIds: number[] = []
        selected.map((kid) => kidIds.push(kid.kidId))
        let payload: any = {
          employeeId: currentUser?.employee?.employeeId,
          companyId: selected[0]?.classes[0]?.companyId || currentCompany?.value,
          dateTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
          remarks: values.remarks,
          title: values.title,
          remarksSharedWithContact: isAllowed,
          mobileConfigurationId:
            mobileConfiguration?.KidProtocolRemark[0]?.mobileConfigurationMasterId,
          notifyByEmail: notifyContact,
        }
        if (data) {
          payload['kidId'] = data?.kid?.kidId
          payload['kidProtocolRemarkId'] = data?.item?.kidProtocolRemarkId
        } else {
          payload['kidIds'] = kidIds
        }
        let resp
        if (data) resp = await updateKidProtocol(payload)
        else resp = await BulkProtocol(payload)

        if (!!resp) {
          if (data) readProtocol()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        //  clearSelected()
        //  cancel(true)
        reset()
        if (data) setEditModal({show: false, data: null})
      }
    },
  })

  useEffect(() => {
    readKidProtocol()
  }, [selected, formik.values])

  const reset = () => formik.resetForm()
  const readProtocol = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (!internalPagesFilters.protocols.classValue && !internalPagesFilters.protocols.searchTerm)
      filter = '1=1'
    if (internalPagesFilters.protocols.classValue)
      filter = `classesId=${internalPagesFilters.protocols.classValue}`
    if (internalPagesFilters.protocols.classValue && internalPagesFilters.protocols.searchTerm)
      filter = filter + ' AND '
    if (internalPagesFilters.protocols.searchTerm)
      filter = filter + internalPagesFilters.protocols.searchTerm

    const res = await getKidsProtocols(
      filter,
      currentUser?.employee?.employeeId || 0,
      internalPagesFilters.protocols.From,
      internalPagesFilters.protocols.To
    )

    setKidsProtocol(res)
    setGlobalLoader(false)
  }

  const readKidProtocol = async () => {
    let filter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) filter += ' ( '
      if (index > 0) filter += ' OR '
      filter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) filter += ' ) '
    if (filter === '') filter = '1=1'
    const res = await getKidsProtocols(
      filter,
      currentUser?.employee?.employeeId || 0,
      formik.values.date
        ? moment(formik.values.date).startOf('day').format(momentDateFormat)
        : moment().startOf('day').format(momentDateFormat),
      moment(formik.values.date).endOf('day').format(momentDateFormat)
    )

    setKidsProtocol(res)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {data?.kid && (
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {data?.kid.imagePath ? (
                    <img
                      src={
                        data?.kid.imagePath !== ''
                          ? data?.kid.imagePath
                          : '/media/avatars/blank.png'
                      }
                      alt={`${data?.kid.firstName.charAt(0).toUpperCase()} ${data?.kid.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${data?.kid.firstName.charAt(0).toUpperCase()} ${data?.kid.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{data?.kid.firstName}</div>
              </div>
            )}
            {selected?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                key={index}
              >
                <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                  {item.imagePath ? (
                    <img
                      src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                      alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                      className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                    />
                  ) : (
                    <div
                      className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                      style={{
                        backgroundColor: `#F2E5E5`,
                      }}
                    >
                      {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                        .charAt(0)
                        .toUpperCase()}`}
                    </div>
                  )}
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          {kidsProtocol?.length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {kidsProtocol?.map((item: any, index: number) => {
                  const fullKid: any = selected.find((i: any) => i.kidId === item.kidId)
                  return (
                    <AccordionItem id={`${index}`}>
                      <AccordionHeader targetId={`${index}`}>
                        <div className='d-flex align-items-center'>
                          <AvatarWithLetters
                            image={fullKid?.imagePath}
                            alt={`${item?.firstName?.charAt(0)?.toUpperCase()} ${item?.lastName
                              ?.charAt(0)
                              ?.toUpperCase()}`}
                            extrasmall
                          />
                          <h5 className='ms-2 my-0'> {item.kidName}</h5>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${index}`}>
                        <div className='d-flex flex-column w-100  '>
                          {item?.kidProtocols?.map((meal: any, index1: number) => (
                            <div
                              className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                              key={index1}
                            >
                              <div>
                                <h5 className='p-0 m-0'>{meal.title}</h5>

                                <p
                                  className='m-0 p-0 text-muted '
                                  dangerouslySetInnerHTML={{__html: meal.remarks}}
                                ></p>

                                <p className='m-0 p-0 text-muted'>
                                  {moment(meal.dateTime).format('DD.MM.yyyy HH:mm')}
                                </p>
                              </div>
                              <Link
                                to={
                                  company
                                    ? `/${company}/kid-management/protocols`
                                    : '/kidling/kid-management/protocols'
                                }
                                className='btn btn-light p-1 px-4 mt-2'
                              >
                                <i className='fa-solid fa-arrow-right'></i>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <div className='fv-row my-7'>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                dateTime
                onAccept={(date: any) => {
                  formik.setFieldValue('date', date)
                }}
                value={formik.values.date}
              />
            </div>
          </div>
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={isAllowed}
              name='notifications'
              onChange={() => setIsAllowed(!isAllowed)}
            />
            <label className='form-check-label'>
              {intl.formatMessage({id: 'KIDLING.HOME.ALLOW_WITH_PARENTS'})}
            </label>
          </div>
          <div className='fv-row my-7'>
            <input
              {...formik.getFieldProps('title')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.title && formik.errors.title},
                {
                  'is-valid': formik.touched.title && !formik.errors.title,
                }
              )}
              name='title'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.TITLE'})}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.title}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('remarks')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.remarks && formik.errors.remarks},
                {
                  'is-valid': formik.touched.remarks && !formik.errors.remarks,
                }
              )}
              name='remarks'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.REMARKS'})}
            />
            {formik.touched.remarks && formik.errors.remarks && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.remarks}</span>
                </div>
              </div>
            )}
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              checked={notifyContact}
              onChange={() => setNotifyContact((prev) => !prev)}
            />
            <span className='form-check-label text-muted fs-7'>
              {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
            </span>
          </label>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {KidProtocolModalForm}
