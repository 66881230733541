import {FC, ReactElement} from 'react'

interface Props {
  image?: string
  alt: string
  rounded?: boolean
  small?: boolean
  verysmall?: boolean
  extrasmall?: boolean
  objectContain?: boolean
  large?: boolean
}
const AvatarWithLetters: FC<Props> = ({
  image,
  alt,
  rounded,
  small,
  verysmall,
  extrasmall,
  objectContain,
  large,
}): ReactElement => {
  return (
    <div
      className={`rounded symbolmb-8 mb-sm-0 ${
        large
          ? 'h-125px w-125px w-sm-150px h-sm-150px'
          : small
          ? 'w-100px h-100px w-md-60px h-md-60px fs-3'
          : verysmall
          ? 'w-40px h-40px fs-4'
          : extrasmall
          ? 'w-30px h-30px mb-0 fs-5'
          : ' w-60px h-60px  w-md-100px h-md-100px fs-2 '
      }  ${rounded && 'rounded-circle'} ${
        objectContain ? 'object-fit-contain' : 'object-fit-cover'
      }`}
    >
      {image ? (
        <img
          src={image}
          alt={alt}
          className={`rounded  bg-secondary text-primary    ${
            large
              ? 'h-125px w-125px w-sm-150px h-sm-150px lh-sm-150px lh-125px '
              : small
              ? 'w-100px h-100px w-md-60px h-md-60px  fs-3'
              : verysmall
              ? 'w-40px h-40px  fs-4'
              : extrasmall
              ? 'w-30px h-30px mb-0 fs-5'
              : 'w-60px h-60px w-md-100px h-md-100px fs-2 '
          }    mb-md-0 d-flex justify-content-center align-items-center shadow-sm ${
            rounded && 'rounded-circle  '
          } ${objectContain ? 'object-fit-contain' : 'object-fit-cover'}`}
        />
      ) : (
        <div
          className={`rounded mb-8 bg-secondary text-primary symbol-label  ${
            large
              ? 'h-125px w-125px w-sm-150px h-sm-150px'
              : small
              ? 'w-100px h-100px w-md-60px h-md-60px fs-3'
              : verysmall
              ? 'w-40px h-40px fs-4'
              : extrasmall
              ? 'w-30px h-30px mb-0 fs-5'
              : ' w-60px h-60px w-md-100px h-md-100px fs-2 '
          }   mb-md-0 d-flex justify-content-center align-items-center shadow-sm text-black ${
            rounded && 'rounded-circle  '
          } ${objectContain ? 'object-fit-contain' : 'object-fit-cover'} `}
        >
          {alt}
        </div>
      )}
    </div>
  )
}

export default AvatarWithLetters
