import React, {FC, ReactElement} from 'react'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
interface TableProps {
  columns: any
  data: any
  headerGroups?: any
  prepareRow: any
  getTableProps: any
  _mobileColumns?: any
  setSortBy: any
  sortBy: any
  tableName: string
}
const TableMobileViewWithExpandedCards: FC<TableProps> = ({
  columns,
  data,
  headerGroups,
  prepareRow,
  getTableProps,
  _mobileColumns,
  sortBy,
  setSortBy,
  tableName,
}): ReactElement => {
  return (
    <div className='w-100  ' {...getTableProps()}>
      <div className='d-flex align-items-center ms-4 mb-2'>
        {columns[0].id === 'selection' && (
          <div className='text-muted'>{headerGroups[0].headers[0].render('Header')}</div>
        )}
        <i className='fa-solid fa-sort'></i>

        {Object.entries(columns).map(([key1, value1]: any, index) => {
          return (
            <div className='ms-1' key={index}>
              {headerGroups[0].headers[key1].id !== 'selection' &&
                headerGroups[0].headers[key1].id !== 'avatar' &&
                headerGroups[0].headers[key1].canSort && (
                  <th
                    {...(!headerGroups[0].headers[key1].canSort
                      ? headerGroups[0].headers[key1].getHeaderProps()
                      : headerGroups[0].headers[key1].getHeaderProps(
                          headerGroups[0].headers[key1].getSortByToggleProps()
                        ))}
                    className='text-muted'
                  >
                    {headerGroups[0].headers[key1].render('Header')}
                  </th>
                )}
            </div>
          )
        })}
      </div>
      {data?.map((item: any, index: number) => {
        prepareRow(item)
        return (
          <UncontrolledAccordion>
            <AccordionItem>
              <AccordionHeader targetId={`${index}`}>
                <tr
                  className='  p-1   rounded  d-flex flex-column  my-0 w-100'
                  key={index}
                  {...item.getRowProps()}
                >
                  <div className='row align-items-start'>
                    {Object.entries(_mobileColumns).map(([key1, value1]: any, index) => {
                      return (
                        (headerGroups[0].headers[key1].id === 'avatar' ||
                          headerGroups[0].headers[key1].id === 'Name' ||
                          headerGroups[0].headers[key1].id === 'selection' ||
                          _mobileColumns[key1].id === 'Attributes') && (
                          <div
                            className={
                              headerGroups[0].headers[key1].id === 'selection'
                                ? 'col-1'
                                : headerGroups[0].headers[key1].id === 'avatar'
                                ? 'col-2'
                                : _mobileColumns[key1].id === 'Attributes'
                                ? 'col-2 '
                                : 'col-7'
                            }
                            onClick={(e) =>
                              (headerGroups[0].headers[key1].id === 'selection' ||
                                _mobileColumns[key1].id === 'Attributes') &&
                              e.stopPropagation()
                            }
                            key={index}
                          >
                            <td
                              className={`${
                                _mobileColumns[key1].id === 'Attributes' &&
                                'row justify-content-end'
                              }`}
                            >
                              {value1?.Cell ? value1.Cell({cell: {row: {...item}}}) : value1}
                            </td>
                          </div>
                        )
                      )
                    })}
                  </div>
                </tr>
              </AccordionHeader>
              <AccordionBody accordionId={`${index}`}>
                <tr
                  className='card shadow-lg  p-8 bg-body rounded  d-flex flex-column m-4  '
                  key={index}
                  {...item.getRowProps()}
                >
                  <div className='row gap-2'>
                    {Object.entries(columns).map(([key1, value1]: any, index) => {
                      return (
                        <div className='row' key={index}>
                          {headerGroups[0].headers[key1].id !== 'selection' && (
                            <div className='col-5'>
                              {headerGroups[0].headers[key1].id !== 'avatar' && (
                                <th className='col-4 text-muted'>
                                  {headerGroups[0].headers[key1].render('Header')}
                                </th>
                              )}
                            </div>
                          )}

                          <div className='col-7'>
                            <td className='ml-4  d-flex flex-column justify-content-start mt-auto'>
                              {value1?.Cell ? value1.Cell({cell: {row: {...item}}}) : value1}
                            </td>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </tr>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        )
      })}
    </div>
  )
}

export default TableMobileViewWithExpandedCards
