import moment from 'moment'
import React, {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import './styles.scss'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import AvatarWithLetters from '../avatar-with-letters'

interface Props {
  data: any
}
const EventDetailsView: FC<Props> = ({data}): ReactElement => {
  const intl = useIntl()
  const {setEventDetailsModal, setDeleteEventConfirmation} = useAppContext()
  return (
    <div className=' w-100 h-100 position-relative'>
      {data.nurseryClosed && (
        <div
          className='position-absolute   end-0  object-contain  badge badge-danger'
          style={{top: '-1.5rem'}}
        >
          {intl.formatMessage({id: 'KIDLING.KITA_CLOSED'})}
        </div>
      )}
      {!data?.holidayName && !data?.dayOffName && (
        <div className='d-flex align-items-center justify-content-between w-100  border-bottom mt-4'>
          <AvatarWithLetters
            image={data.imagePath}
            alt={data.eventsName.charAt(0).toUpperCase()}
            small
          />
          <AddToCalendarButton
            name={data?.eventsName}
            options={['Apple', 'Google', 'Outlook.com']}
            location={data?.locationName}
            startDate={moment(data.startsOn).format('yyyy-MM-DD')}
            endDate={moment(data.startsOn).format('yyyy-MM-DD')}
            startTime={moment(data.startsOn).format('HH:mm')}
            endTime={moment(data.startsOn)
              .set('hours', moment(data.endsOn).hours())
              .set('minutes', moment(data.endsOn).minutes())
              .format('HH:mm')}
            description={data.description}
            timeZone='Europe/Berlin'
            hideTextLabelButton
            buttonsList
            trigger='click'
            styleLight='border-0 outline-0'
            lightMode='system'
          ></AddToCalendarButton>
          <button
            className='btn btn-sm btn-icon  me-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title={intl.formatMessage({id: 'COMMON.EDIT'})}
            onClick={() =>
              setEventDetailsModal({
                showModal: true,
                data: {...data, extendedProps: data},
                enableEdit: true,
              })
            }
          >
            <i className='fa-solid fa-pencil fs-7 m-0'></i>
          </button>
          <button
            className='btn btn-sm btn-icon  me-2  '
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title={intl.formatMessage({id: 'COMMON.DELETE'})}
            onClick={() => setDeleteEventConfirmation({show: true, data: data.eventsId})}
          >
            <i className='fa-solid fa-trash fs-7 m-0'></i>
          </button>
        </div>
      )}
      <div className='d-flex flex-column mt-4 w-100 p-4 pb-0 '>
        <div className='row w-100'>
          <i className='fa-solid fa-circle fs-3   mt-1 col-1' style={{color: data.colorCode}}></i>

          <div className='col-10 ms-4 ms-4'>
            <div className='d-flex align-items-center'>
              <h4 className='my-0'>{data?.dayOffName ?? data?.holidayName ?? data.eventsName}</h4>
            </div>

            <div className='my-0 text-muted d-flex align-items-center text-nowrap'>
              {moment(data?.dayOffDate ?? data?.holidayDate ?? data.startsOn).format(
                'dddd, MMMM DD '
              )}{' '}
              {data.startsOn && (
                <>
                  <span className='w-6px h-6px rounded-circle mx-2  bg-gray-500 d-inline-block'></span>
                  {moment(data.startsOn).format('HH:mm')}
                  {' - '}
                  {moment(data.endsOn).format('HH:mm')}
                </>
              )}
            </div>
          </div>
        </div>
        {data?.holidayName && (
          <div className='row w-100 mt-4'>
            <i className='fa-solid fa-hashtag fs-3 col-1'></i>
            <div className='col-10 ms-4'>
              <p className='my-0'>{data?.numberOfDays}</p>
            </div>
          </div>
        )}
        {!data?.holidayName && !data?.dayOffName && (
          <>
            {data?.locationName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-location-dot fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.locationName}</p>
                </div>
              </div>
            )}

            {data?.roomName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-building fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.roomName}</p>
                </div>
              </div>
            )}

            {data?.description && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-paragraph fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.description}</p>
                </div>
              </div>
            )}

            {data?.employeeName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-user fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.employeeName}</p>
                </div>
              </div>
            )}

            {/* <div className='row w-100 mt-4'>
              <i className='fa-solid fa-user-group fs-3 col-1'></i>
              <div className='col-10 ms-4'>
                <div className='d-flex flex-column'>
                  <p className='my-0'>
                    {data?.eventsKid?.length +
                      data?.contactEvent?.length +
                      data?.employeeEvent?.length}{' '}
                    {intl.formatMessage({id: 'COMMON.RECIPIENTS'})}
                  </p>
                  <div className='my-0 text-muted'>
                    {data?.contactEventAttendingCount +
                      data?.employeeEventAttendingCount +
                      data?.eventsKidAttendingCount}
                    <span className='ms-1'>{intl.formatMessage({id: 'COMMON.YES'})}</span>

                    <span className='ms-1'>
                      ( {data?.contactEventAttendingCount}{' '}
                      {intl.formatMessage({id: 'COMMON.CONTACTS'})},{' '}
                      {data?.employeeEventAttendingCount}{' '}
                      {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})},{' '}
                      {data?.eventsKidAttendingCount} {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}{' '}
                      )
                    </span>
                  </div>
                  <div className='my-0 text-muted'>
                    {data?.eventsKid?.length +
                      data?.contactEvent.length +
                      data?.employeeEvent.length -
                      (data?.contactEventAttendingCount +
                        data?.employeeEventAttendingCount +
                        data?.eventsKidAttendingCount)}
                    <span className='ms-1'> {intl.formatMessage({id: 'COMMON.NO'})}</span>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
        {/* <div className='border-top d-flex align-items-center justify-content-between mt-4'>
          <p className='p-0 m-0 fs-5'> {intl.formatMessage({id: 'KIDLING.GOING'})}</p>
          <div className='d-flex align-items-center mt-2'>
            <button
              className={`btn btn-outline btn-active-lignt-primary mx-1 py-1 px-2 ${
                data?.employeeEvent?.find(
                  (item: any) => item.employeeId === currentUser?.employee?.employeeId
                )?.eventsEmployeeAttending
                  ? 'bg-success'
                  : ''
              }`}
              type='button'
              onClick={() => handleAttendaceUpdate(true)}
              disabled={
                data?.employeeEvent?.find(
                  (item: any) => item.employeeId === currentUser?.employee?.employeeId
                )?.eventsEmployeeAttending
                  ? true
                  : false
              }
            >
              {intl.formatMessage({id: 'COMMON.YES'})}
            </button>
            <button
              className={`btn btn-outline btn-active-lignt-primary mx-1 py-1 px-2 ${
                data?.employeeEvent?.find(
                  (item: any) => item.employeeId === currentUser?.employee?.employeeId
                )?.eventsEmployeeAttending
                  ? ''
                  : 'bg-danger'
              }`}
              type='button'
              onClick={() => handleAttendaceUpdate(false)}
              disabled={
                data?.employeeEvent?.find(
                  (item: any) => item.employeeId === currentUser?.employee?.employeeId
                )?.eventsEmployeeAttending
                  ? false
                  : true
              }
            >
              {intl.formatMessage({id: 'COMMON.NO'})}
            </button>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default EventDetailsView
