/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from '../core/ListViewProvider'
import Select from 'react-select'
import moment from 'moment-timezone'
import {useDropzone} from 'react-dropzone'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {imageCompressor} from '../../../../../utils/imageCompressor'
import {getBase64} from '../../../../../utils/getBase64'
import {BulkMedia} from '../../../../../../actions/bulk-actions'
import {toast} from 'react-toastify'
import {useAuth} from '../../../../auth'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import clsx from 'clsx'
import {selectStyle} from '../../../../../utils/select-style'
import AddHashtagDialog from './AddHashtagDialog'
import {AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {Link} from 'react-router-dom'
import './styles.scss'
import {momentDateFormat} from '../../../../../../config/constants'
import {getKidsMediaByEmplpyee} from '../../../../../../actions/kids'
import {groupBy} from '../../../../../utils/common'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
export const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

export const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

export const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

type Props = {
  isUserLoading: boolean
}

const KidMediaModalForm: FC<Props> = ({isUserLoading}) => {
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const [notifyContact, setNotifyContact] = useState(false)
  const company = window.location.pathname.split('/')[1]
  const [files, setFiles] = useState<any>([])
  const [step, setStep] = useState(0)
  const [selectedKidsList, setSelectedKidsList] = useState([])
  const [allKids, setallKids] = useState([])
  const intl = useIntl()
  const {currentCompany} = useCompanyContext()
  const {kidsList, mobileConfiguration} = useAppContext()
  const [base64Files, setBase64Files] = useState([])
  const [shareWithParents, setShareWithParents] = useState(false)
  const [shareKids, setShareKids] = useState<any>([])
  const {currentUser} = useAuth()
  const [Hashtags, setHashtags] = useState([])
  const [showAddHashtagDialog, setShowAddHashtagDialog] = useState(false)
  const [kidsMedia, setKidsMedia] = useState([])
  const [isLoadingImages, setIsLoadingImages] = useState(false)
  useEffect(() => {
    const convertToBase64 = async () => {
      let temp: any = []
      files.map(async (singleFile: any) => {
        getBase64(singleFile, (result: string) => {
          temp.push({
            filePath: result,
            fileName: singleFile.name,
            description: singleFile.name,
            shareWithParents: shareWithParents,
          })
        })
      })
      setBase64Files(temp)
    }
    convertToBase64()
    if (files.length === 0) setHashtags([])
  }, [files, shareWithParents])

  useEffect(() => {
    let temp: any = []
    selected.map((item: any) =>
      temp.push({
        kidId: item.kidId,
        label: item.kidName,
        value: item.kidId,
        image: item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png',
        allowTakePhoto: item.allowTakePhoto,
      })
    )
    setSelectedKidsList(temp)
    setShareKids(temp.filter((item: any) => item.allowTakePhoto === true))
  }, [selected])

  useEffect(() => {
    let temp: any = []
    kidsList.map((item: any) =>
      temp.push({
        label: item.kidName,
        value: item.kidId,
        image: item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png',
        allowTakePhoto: item.allowTakePhoto,
      })
    )
    setallKids(temp)
  }, [kidsList])

  const kidSchema = Yup.object().shape({
    description: Yup.string(),
  })

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
      'video/*': [],
    },
    onDrop: async (acceptedFiles: any) => {
      setIsLoadingImages(true)
      await acceptedFiles.map(async (file: any, index: number) => {
        const compressed: any = file.type.startsWith('image/') ? await imageCompressor(file) : file
        setFiles((prev: any) => [
          ...prev,
          file.type.startsWith('image/')
            ? Object.assign(compressed, {
                preview: URL.createObjectURL(compressed),
              })
            : file,
        ])
        if (index === acceptedFiles?.length - 1) setIsLoadingImages(false)
      })
    },
  })

  const removeFile = (file: any) => {
    const temp = files.filter((item: any) => item !== file)
    setFiles(temp)
  }
  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name} className='position-relative image__preview'>
      <div style={thumbInner} className='d-flex align-items-center justify-content-center w-100'>
        {file.type.startsWith('video/') ? (
          <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
            <i className='fa-solid fa-circle-play   text-danger' style={{fontSize: '3rem'}}></i>
          </div>
        ) : (
          <img
            src={file.preview}
            className='image-input image-input-outline image-input-wrapper w-125px h-125px contain'
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
            alt='file'
          />
        )}
      </div>

      <span
        className='btn btn-icon position-absolute p-0 end-0 btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        title='Remove'
        onClick={() => removeFile(file)}
      >
        <i className='fa-solid fa-xmark'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])

  const [userForEdit] = useState({
    description: '',
  })

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined)
    setHashtags([])
    clearSelected()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        let kidIdList: number[] = [],
          hashtagIds: number[] = []
        shareKids.map((kid: any) => kidIdList.push(kid.kidId))
        if (kidIdList.length > 0) {
          setSubmitting(true)
          let attachmentList: any = []
          base64Files.map((file: any) =>
            attachmentList.push({...file, description: values.description})
          )

          Hashtags?.map((item: any) => (hashtagIds = [...hashtagIds, item.hashtagId]))
          const data = {
            kidIdList,
            hashtagIds,
            attachmentList,
            createdDate: moment().format('yyyy-MM-DDTHH:mm:ss'),
            usersId: currentUser?.usersId,
            companyId: currentCompany?.value,
            mobileConfigurationId: mobileConfiguration?.KidMedia[0]?.mobileConfigurationMasterId,
            notifyByEmail: notifyContact,
          }

          const resp = await BulkMedia(data)

          if (!!resp) {
            // clearSelected()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            // cancel(true)
            setFiles([])
            setStep(0)
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        //clearSelected()
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    loadOldImages()
  }, [selected, formik.values])

  const loadOldImages = async () => {
    let kidfilter: string = ''
    selected?.forEach((kid: any, index) => {
      if (index === 0) kidfilter += ' ( '
      if (index > 0) kidfilter += ' OR '
      kidfilter += `kidId=` + kid.kidId
    })
    if (selected.length > 0) kidfilter += ' ) '
    if (kidfilter === '') kidfilter = '1=1'
    let filter = {
      pagination: {
        generalSearch: '',
        page: 1,
        perpage: 100,
        sort: 'desc',
        field: 'CreatedDate',
        columnSearch: [],
        additionalSearch: `${kidfilter}`,
      },
      employeeId: currentUser?.employee?.employeeId,
    }

    filter.pagination.additionalSearch += ` AND CreatedDate>="${moment()
      .startOf('day')
      .format(momentDateFormat)}" AND CreatedDate<="${moment()
      .endOf('day')
      .format(momentDateFormat)}"`

    const resp = JSON.parse(await getKidsMediaByEmplpyee(filter))
    const grouped = groupBy(resp?.data, 'KidId')

    setKidsMedia(grouped)
  }
  return (
    <>
      {step === 0 && (
        <form
          id='kt_modal_add_user_form'
          className='form '
          onSubmit={formik.handleSubmit}
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column   me-n7 pe-7  '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='symbol-group symbol-hover'>
              {selected?.map((item: any, index: number) => (
                <div
                  className='d-flex flex-column justify-content-center align-items-center'
                  key={index}
                >
                  <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                    {item.imagePath ? (
                      <img
                        src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                        alt={`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                          .charAt(0)
                          .toUpperCase()}`}
                        className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                      />
                    ) : (
                      <div
                        className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                        style={{
                          backgroundColor: `#F2E5E5`,
                        }}
                      >
                        {`${item.firstName.charAt(0).toUpperCase()} ${item.lastName
                          .charAt(0)
                          .toUpperCase()}`}
                      </div>
                    )}
                  </div>
                  <div className='dispaly-6'>{item.firstName}</div>
                </div>
              ))}
            </div>
          </div>
          {Object.keys(kidsMedia).length > 0 && (
            <div className='mt-4 mh-200px overflow-auto'>
              <UncontrolledAccordion defaultOpen={'-1'}>
                {Object.keys(kidsMedia).map((key: any) => {
                  const item: any = kidsMedia[key]

                  const fullKid: any = selected.find((i: any) => i.kidId === Number(key))

                  return (
                    fullKid?.firstName && (
                      <AccordionItem id={`${key}`}>
                        <AccordionHeader targetId={`${key}`}>
                          <div className='d-flex align-items-center'>
                            <AvatarWithLetters
                              image={fullKid?.imagePath}
                              alt={`${fullKid?.firstName
                                ?.charAt(0)
                                ?.toUpperCase()} ${fullKid?.lastName?.charAt(0)?.toUpperCase()}`}
                              extrasmall
                            />
                            <h5 className='ms-2 my-0'> {fullKid?.kidName}</h5>
                          </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${key}`}>
                          <div className='d-flex flex-column w-100  '>
                            {item?.map((uploadFile: any, index1: number) => (
                              <div
                                className='d-flex align-items-start justify-content-between align-self-start border-top border-bottom w-100 py-2 px-2  position-relative '
                                key={index1}
                              >
                                <div className='d-flex '>
                                  <AvatarWithLetters
                                    image={uploadFile?.FilePath}
                                    alt={`${uploadFile.KidMediaFileName?.charAt(0)}`}
                                    small
                                  />
                                  <div className='ms-4'>
                                    <h5 className='p-0 m-0'>{uploadFile.KidMediaFileName}</h5>

                                    <p
                                      className='m-0 p-0 text-muted '
                                      dangerouslySetInnerHTML={{
                                        __html: uploadFile?.KidMediaFileDescription,
                                      }}
                                    ></p>

                                    <p className='m-0 p-0 text-muted'>
                                      {moment(uploadFile.CreatedDate).format('DD.MM.yyyy HH:mm')}
                                    </p>
                                  </div>
                                </div>
                                <Link
                                  to={company ? `/${company}/media` : '/kidling/media'}
                                  className='btn btn-light p-1 px-4 mt-2'
                                >
                                  <i className='fa-solid fa-arrow-right'></i>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    )
                  )
                })}
              </UncontrolledAccordion>
            </div>
          )}
          <section className='container mt-7'>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p>
                {intl.formatMessage({
                  id: 'KIDLING.HOME.DRAG_AND_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES',
                })}
              </p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </section>
          <div className='fv-row mb-7'>
            <textarea
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              name='description'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
              rows={1}
              placeholder={intl.formatMessage({id: 'KIDLING.HOME.DESCRIPTION'})}
            />

            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
          {/* {files.length > 0 && (
            <div className='fv-row mb-7'>
              <label className='  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.HOME.ADD_HASHTAG'})}
              </label>
              <div className='d-flex flex-wrap mt-2'>
                {Hashtags.map((item: any, index: number) => (
                  <span
                    className='badge badge-info fw-bold m-1 p-0 d-flex align-content-center justify-content-center tag__container '
                    key={index}
                  >
                    <div className='py-1 px-2'> {item.hashtagName}</div>

                    <button
                      type='button'
                      className='btn rounded-start-0  d-flex align-content-center justify-content-center m-0 p-0 tag__btn'
                      onClick={() =>
                        setHashtags((prev) =>
                          prev.filter((i: any) => item.hashtagId !== i.hashtagId)
                        )
                      }
                    >
                      <i className='fa-solid fa-xmark p-0 m-0 text-white icon'></i>
                    </button>
                  </span>
                ))}
                <button
                  className='btn border border-1 border-success border-dashed py-1 my-0 ms-1 px-2 d-flex align-items-center justify-content-center add_btn'
                  onClick={() => setShowAddHashtagDialog(true)}
                  type='button'
                >
                  <i className='fa-solid fa-plus p-0 m-0'></i>
                </button>
              </div>
            </div>
          )} */}
        </form>
      )}
      {step === 1 && (
        <>
          <div data-kt-stepper-element='content'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-12'>
                <h1 className='fw-bold text-dark'>
                  {intl.formatMessage({id: 'KIDLING.HOME.VERIFICATION_CHECK'})}
                </h1>

                <div className='text-muted fw-semibold fs-4'>
                  {intl.formatMessage({id: 'KIDLING.HOME.KIDS_WITHOUT_PARENT_CONSENT_REMOVED'})}
                </div>
              </div>

              <div className='fv-row mb-10'>
                <div className='row g-9  w-100'>
                  <div className='col-6'>
                    <label
                      className='btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-6'
                      data-kt-button='true'
                    >
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='tagShare'
                          checked={!shareWithParents}
                          onChange={() => setShareWithParents(false)}
                        />
                      </span>

                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>
                          {intl.formatMessage({id: 'KIDLING.HOME.TAG_ONLY'})}
                        </span>
                      </span>
                    </label>
                  </div>

                  <div className='col-6'>
                    <label
                      className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
                      data-kt-button='true'
                    >
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='tagShare'
                          checked={shareWithParents}
                          onChange={() => setShareWithParents(true)}
                        />
                      </span>

                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>
                          {intl.formatMessage({id: 'KIDLING.HOME.TAG_N_SHARE'})}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='fv-row mb-10'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.TAGGED_KIDS'})}
                </label>
                <Select
                  value={shareKids}
                  onChange={(val: any) => setShareKids(val)}
                  isMulti
                  name='allowdKids'
                  options={selectedKidsList.filter((item: any) => item.allowTakePhoto === true)}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isLoading={allKids.length === 0}
                  formatOptionLabel={(item: any) => (
                    <div className='d-flex align-items-center '>
                      <img
                        src={item.image}
                        className='rounded-circle w-30px h-30px object-fit-cover me-2'
                        alt={item.label}
                      />
                      <div className='d-flex flex-column'>
                        <p className='p-0 m-0'>{item.label}</p>
                      </div>
                    </div>
                  )}
                  theme={(theme) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  styles={selectStyle}
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.KIDS_NOT_ALLOWED_TO_TAG'})}
                </label>
                <Select
                  isMulti
                  name='notAllowedkids'
                  defaultValue={selectedKidsList.filter(
                    (item: any) => item.allowTakePhoto === false
                  )}
                  isDisabled
                  isLoading={allKids.length === 0}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  formatOptionLabel={(item: any) => (
                    <div className='d-flex align-items-center '>
                      <img
                        src={item.image}
                        className='rounded-circle w-30px h-30px object-fit-cover me-2'
                        alt={item.label}
                      />
                      <div className='d-flex flex-column'>
                        <p className='p-0 m-0'>{item.label}</p>
                      </div>
                    </div>
                  )}
                  theme={(theme) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  styles={selectStyle}
                />
              </div>
            </div>
          </div>
          {shareWithParents && (
            <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
              <input
                className='form-check-input w-30px h-20px'
                type='checkbox'
                name='notifications'
                checked={notifyContact}
                onChange={() => setNotifyContact((prev) => !prev)}
              />
              <span className='form-check-label text-muted fs-7'>
                {intl.formatMessage({id: 'KIDLING.NOTIFY_CONTACT'})}
              </span>
            </label>
          )}
        </>
      )}

      {/* begin::Actions */}
      <div className='text-center pt-8'>
        <button
          type='reset'
          onClick={() => cancel()}
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
          disabled={formik.isSubmitting || isUserLoading}
        >
          {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
        </button>

        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          disabled={
            isUserLoading ||
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.touched ||
            files.length === 0
          }
          onClick={() => {
            if (step === 0) setStep(1)
            else formik.handleSubmit()
          }}
        >
          <span className='indicator-label'>
            {step === 0
              ? intl.formatMessage({id: 'KIDLING.CONTINUE'})
              : intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
          </span>
          {(formik.isSubmitting || isUserLoading) && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <AddHashtagDialog
        show={showAddHashtagDialog}
        setShow={setShowAddHashtagDialog}
        Hashtags={Hashtags}
        setHashtags={setHashtags}
      />
      {/* end::Actions */}
      {(formik.isSubmitting || isUserLoading || isLoadingImages) && <UsersListLoading />}
    </>
  )
}

export {KidMediaModalForm}
