import {useEffect} from 'react'
import {KidDrinksModalHeader} from './KidDrinksModalHeader'
import {KidDrinksModalFormWrapper} from './KidDrinksModalFormWrapper'
import ActionsDrawerContainer from '../../../../../general-components/ActionsDrawerContainer/ActionsDrawerContainer'

const KidDrinksModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block '
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidDrinksModalHeader />
            {/* begin::Modal body */}
            <ActionsDrawerContainer
              children={
                <div className='modal-body scroll-y overflow-auto mx-5 mx-xl-15 my-0'>
                  <KidDrinksModalFormWrapper />
                </div>
              }
              classes='mh-500px'
            />

            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidDrinksModal}
